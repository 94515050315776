import {
    Button,
    DeleteButton,
} from "ra-ui-materialui";
import { useLocation } from 'react-router';
import {
    SaveButton,
    FileInput,
    FileField,
    useNotify,
    FormWithRedirect,
    useCreate,
    useRefresh,
    required
} from "react-admin";
import AddIcon from '@material-ui/icons/Add';
import HousingPlanDocumentsProvider from "../provider/housingPlanDocuments.provider";
import { useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import NormalButton from '@material-ui/core/Button';
import { useForm } from 'react-final-form';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { DocumentClient, OhlConstants_TypeDocument } from "../services/ohl.virtualCenter.api";


import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const apiClient = new DocumentClient(process.env.REACT_APP_PUBLIC_API);




export const HousingPlanDocumentsList = (props) => {

    const [documentName, setDocumentName] = useState(null)
    const [firstLoad, setFirstLoad] = useState(false)
    const location = useLocation();


    useEffect(() => {
        apiClient.generic(props.record?.identificationNumber, OhlConstants_TypeDocument.HousingPlan).then(
            resp => {
                var name = resp?.documents[0]?.name;
                if(name != null){
                    setDocumentName(resp?.documents[0]?.name);
                }
            }
        ).finally(
            ()=> setFirstLoad(true)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            {
                documentName !== null && firstLoad && props.permissions && props.permissions.housingPlanDocuments.create ?
                    <DownloadUserDocumentsButton 
                        record={{ ...props.record, name: documentName, identificationNumberUser: props.record.identificationNumber }}
                    /> : null
            }
            {
                documentName !== null && firstLoad && props.permissions && props.permissions.housingPlanDocuments.delete  ?
                    <DeleteButton
                        mutationMode="pessimistic"
                        resource="housingPlanDocuments"
                        redirect={location.pathname}
                        record={{ ...props.record, fileName: documentName }}
                        label="Borrar Plano Vivienda"
                    >
                        <CloseIcon />
                    </DeleteButton> : null
            }

            {
                documentName === null && firstLoad && props.permissions && props.permissions.housingPlanDocuments.create ? <AddHousingPlanDocumentsButton  identificationNumber={props.record.identificationNumber} {...props} disabled /> :
                    <HousingPlanDocumentShow userId={props.record.identificationNumber} documentName={documentName} />
            }




        </div>
    )
}

const DownloadUserDocumentsButton = ({record}) => (
    <Button
        onClick={async ()=>{
            const doc = await HousingPlanDocumentsProvider.getOne('', {
                identificationNumber: record.identificationNumberUser,
                documentName: record.name
            })
            // const byteCharacters = atob(doc.data.base64);
            // const byteNumbers = new Array(byteCharacters.length);
            // for (let i = 0; i < byteCharacters.length; i++) {
            //     byteNumbers[i] = byteCharacters.charCodeAt(i);
            // }
            // const byteArray = new Uint8Array(byteNumbers);
            // const blob = new Blob([byteArray], {type: 'application/octet-stream'});
            // const blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl)

            const a = document.createElement("a"); //Create <a>
            a.href = "data:application/octet-stream;base64," + doc.data.base64; //Image Base64 Goes here
            a.download = doc.data.name; //File name Here
            a.click(); //Downloaded file
        }}
        label="Descargar"
    >
        <CloudDownloadIcon />
    </Button>
);

export const HousingPlanDocumentShow = props => {
    // let { userId, documentName } = useParams();
    const { userId, documentName } = props;
    const [document, setdocument] = useState();
    const [numPages, setnumPages] = useState();
    const [page, setpage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setdocument(await HousingPlanDocumentsProvider.getOne('', {
                identificationNumber: userId,
                documentName: documentName
            }));
        }
        if(userId && documentName)fetchData();
    }, [userId, documentName])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setnumPages(numPages);
    };

    const goToPrevPage = () =>
        setpage(page - 1);
    const goToNextPage = () =>
        setpage(page + 1);

    if (!document) return null
    if (documentName.toLowerCase().includes(".pdf")) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }
            } >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Document
                        file={"data:application/pdf;base64," + document.data.base64}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={page} />
                    </Document>
                </div>
                <nav style={{ width: 400, display: 'flex', justifyContent: 'space-around' }}>
                    <NormalButton onClick={goToPrevPage} variant="contained" color="primary" disabled={page === 1}>
                        Anterior
                    </NormalButton>
                    <p>
                        Página {page} de {numPages}
                    </p>
                    <NormalButton onClick={goToNextPage} variant="contained" color="primary" disabled={page === numPages}>
                        Siguiente
                    </NormalButton>
                </nav>
            </div >);
    }
    else {
        return (             
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '80%',
                width: '80%',
                }}>
                <img
                    style={{
                    display: 'block',
                    maxHeight: '80%',
                    maxWidth: '80%',
                    objectFit: 'contain'
                    }}
                    id='base64image'
                    src={'data:image/jpeg;base64,' + document.data.base64}
                    alt="Imagen"
                />
                </div>
            </div>
        )
    }

};


export const AddHousingPlanDocumentsButton = (props) => {
    const { identificationNumber, ...otherProps } = props;
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [create, { loading }] = useCreate('housingPlanDocuments');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: TransformHousingPlanDocumentsEdit(values, identificationNumber) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!otherProps.permissions.housingPlanDocuments.create) return null;
    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar Plano de Vivienda"
            >
                <AddIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar documentos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir Plano Vivienda</DialogTitle>
                <DialogContent>
                    <FormWithRedirect
                        resource="housingPlanDocuments"
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving
                        }) => (
                            <>
                                <DialogContent>
                                    <FileInput label="Documento PDF o Imágenes (jpg,png) (Máximo 50mb)" source="base64" accept="application/pdf,.jpg,.jpeg,.png" maxSize={50000000} validate={[required()]}>
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        label="ra.action.cancel"
                                        onClick={handleCloseClick}
                                        disabled={loading}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </DialogActions>
                            </>
                        )}
                    />
                </DialogContent>
               
            </Dialog>
        </>
    )
};

const TransformHousingPlanDocumentsEdit = (values, identificationNumber) => {
    values.identificationNumber = identificationNumber;
    return values;
}