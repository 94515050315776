import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'ra-core';
import { useEffect, useState, useMemo  } from 'react';
import { getTherapeuticProfile } from '../../services/master-tables.service';

export const TheraPeuticalProfilesField = (props => {
    const { className, source, emptyText } = props;
    

    const record = useRecordContext(props);
    
    const values = useMemo(() => {
        return record ? record[source] : [];
    }, [record, source]);

    const [valueMT, setValueMT] = useState([]);

    
    useEffect(() => {
        if (values.length > 0) {
            getTherapeuticProfile().then(res => {
                const newValues = values.map(value => {
                    const foundElement = res.elements.find(e => e.id === value);
                    return foundElement ? foundElement.value : null;
                }).filter(v => v !== null);
                setValueMT(newValues);
            });
        } else {
            setValueMT([]);
        }
    }, [values]);

    
    const displayedValues = valueMT.slice(0, 3);
    const hasMoreValues = valueMT.length > 3;

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >
            {displayedValues.length > 0 
                ? (
                    <>
                        {displayedValues.map((item, index) => (
                            <span key={index}>
                                {item}
                                {index < 2 && <br />}
                                </span>
                        ))}
                        {hasMoreValues && '...'}
                    </>
                )
                : emptyText}
        </Typography>
    );

});