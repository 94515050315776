import { useEffect, useState } from "react";
import moment from "moment";
import ScheduledActivitiesProvider from "../../provider/scheduledActivities.provider";
import Calendar from 'react-calendar';
import { useHistory } from "react-router-dom";

import 'react-calendar/dist/Calendar.css'; // Asegúrate de importar los estilos

export const SmallCalendarCustom = (props) => {

  const {identity, isNextMonth, weightCustom, includeUsers, isProfesional} = props;
  const [value, setValue] = useState(moment().add(isNextMonth ? 1 : 0, "months"));
  const [myEventsList, setMyEventsList] = useState();
  const [profesionalId] = useState(props?.id === undefined ? identity?.professionalId : (isProfesional ? props.id: undefined));
  const [firstTime, setfirstTime] = useState(true);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [viewMode, setViewMode] = useState('month');
  let history = useHistory();

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, viewMode]);

  if(isNextMonth && firstTime){
    dateFilter.setMonth(dateFilter.getMonth() + 1);
    setfirstTime(false);
  }

  const fetchEvents = async () => {
    let dateFilterTo = new Date(dateFilter);

    let startDateFrom = '';

    if (viewMode === 'month') {
      // Avanzar al siguiente mes
      dateFilterTo.setMonth(dateFilter.getMonth() + 1);
      startDateFrom = moment(dateFilter).format('yyyy-MM-01T00:00:00');
      dateFilterTo.setDate(0);
    }

    // Establecer el día a 1 y la hora, minuto y segundo a cero
    dateFilterTo.setHours(0, 0, 0, 0);

    const res = await ScheduledActivitiesProvider.getList(null, {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'StartDate', order: 'DESC' },
      filter: {
        isRecord: false,
        includeUsers: includeUsers,
        includeDeleted: false,
        isCalendar:true,
        clientId: props?.id !== undefined && !isProfesional ? props.id : undefined,
        profesionalId: profesionalId,
        StartDateFrom: startDateFrom,
        startDateTo: dateFilterTo
      }
    });

    setMyEventsList(res?.data);
  }

  const myEventsListMap = myEventsList?.map((element) => {
    return {
      title: element?.activity?.name,
      date: new Date(element?.startDate),
      isRecord: element?.activity?.isRecord,
      activityId: element?.activity?.id
    };
  }); 

  
  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const eventForDate = myEventsListMap?.find(
        event =>
          event.date.toDateString() === date.toDateString() &&
          (!event.isRecord || event.isRecord)
      );
      return eventForDate ? (eventForDate.isRecord ? null : 'event-day') : null;
    }
    return null;
  };


  // Función para manejar el cambio de mes
  const handleMonthChange = ({ activeStartDate, view }) => {
    if(view === 'month') {
      setViewMode(view);
      setDateFilter(new Date(activeStartDate)); 
    }
  };


  // Función para manejar el clic en un día
  const handleDayClick = (date) => {
    // Filtrar eventos para la fecha seleccionada
    const dayEvents = myEventsListMap.filter(event => event.date.toDateString() === date.toDateString());
    if (dayEvents.length === 1) {
      history?.push(`/activities/${dayEvents[0].activityId}`);
    } if (dayEvents.length > 1) {
      //console.log('Eventos del día:', dayEvents);
      const formattedDate = formatDate(date)
      const url = buildUrl(profesionalId, props?.id, formattedDate);
      history?.push(url);
    }
  };

  function buildUrl(profesionalId, clientId, formattedDate, order = 'ASC', page = 1, perPage = 10, sort = 'id') {

    const displayedFilters = profesionalId ? { profesionalId: true } : !isProfesional && clientId ? { clientId: true } : {};
    const encodedDisplayedFilters = encodeURIComponent(JSON.stringify(displayedFilters));
    
    if (typeof clientId === 'string') {
      clientId = parseInt(clientId, 10); // Convertir a número entero base 10
    }

    // Determinar qué filtro usar en `filter`
    let filterParams = {};
    if (!displayedFilters) {
        filterParams.clientId = parseInt(clientId, 10);
    } else if (displayedFilters) {
      filterParams.profesionalId = profesionalId;
    }
    filterParams.date = formattedDate;
    filterParams.endDate = formattedDate;
    
    const encodedFilterParams = encodeURIComponent(JSON.stringify(filterParams));

    console.log(profesionalId)
    console.log(encodedFilterParams)
    return `/activities?displayedFilters=${encodedDisplayedFilters}&filter=${encodedFilterParams}&order=${order}&page=${page}&perPage=${perPage}&sort=${sort}`;
  }


  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      return date.getMonth() !== dateFilter.getMonth();
    }
    return false;
  };


  return (
    <div style={{ width: weightCustom ? weightCustom : '16rem' }}>
      <Calendar
        onChange={setValue}
        value={value}
        tileClassName={tileClassName}
        showFixedNumberOfWeeks={true}
        onActiveStartDateChange={handleMonthChange}
        onClickDay={handleDayClick} // Captura el clic en un día
        tileDisabled={tileDisabled}
      />
    </div>
  );
};