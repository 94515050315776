import {
    RolePermission, UsersPermissions, ProfessionalPermissions, UserInteractionsPermissions,
    UserHistoryPermissions, ActivitiesPermissions, ScheduledActivitiesPermissions, UserDocumentsPermissions,
    ContactsPermissions, ScheduledActivitiesOldPermissions, ContractsPermissions
} from "./permisions.model";

export default new RolePermission([
    new UsersPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new ProfessionalPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new UserInteractionsPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new UserHistoryPermissions({ enabled: true, list: false, create: false, edit: true, show: true, del: false }),
    new ActivitiesPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new ScheduledActivitiesPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new ContactsPermissions({ enabled: true, list: false, create: true, edit: true, show: false, del: false }),
    new UserDocumentsPermissions({ enabled: true, list: true, create: false, edit: true, show: true, del: false }),
    new ScheduledActivitiesOldPermissions({ enabled: true, list: true, create: false, edit: false, show: false, del: false }),
    new ContractsPermissions({ enabled: true, list: false, create: false, edit: false, show: false, del: false }),
])