import { toBase64 } from "../resources/utils";
import { DocumentClient, OhlConstants_TypeDocument, UserType } from "../services/ohl.virtualCenter.api";

const apiClient = new DocumentClient(process.env.REACT_APP_PUBLIC_API);

class UserDocumentsProvider {

    getOne = (resource, params) => {
        return apiClient.documentNameGet(params.identificationNumber, params.documentName).then((data) => {
            return { data }
        });
    }
    
    getDocumentLogos = (resource, params) => {
        return apiClient.documentGet(params.identificationNumber, params.documentName, OhlConstants_TypeDocument.ContractLogo, UserType.ContractLogo).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        return apiClient.list(params.target).then(
            res => {
                return { data: res.documents, total: res.length }
            }
        )
    }

    create = async (resource, params) => {
        const documentDto = {
            name: params.data.base64.title,
            base64: (await toBase64(params.data.base64.rawFile)).split('base64,')[1],
            identificationNumber: params.data.identificationNumber
        }
        return apiClient.documentPost(documentDto).then((data) => {
            return {
                data: {
                    ...data,
                    id: 1
                }
            }
        });
    }

    delete = (resource, params) => {
        return apiClient.documentNameDelete(params.previousData.identificationNumberUser, params.previousData.name).then((data) => {
            return {
                data: params.previousData
            }
        });
    }
};

export default new UserDocumentsProvider();