import { SSENotifierClient } from "./ohl.virtualCenter.api";

const apiClient = new SSENotifierClient(process.env.REACT_APP_PUBLIC_API);

export const publish = (event) => {

    let publishPromise = new Promise((resolve, reject) => {
        apiClient.publish(event)
            .then(
                res => {
                    console.log('Published SSE Event: ' + JSON.stringify(event));
                    resolve();
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return publishPromise;
}

export const notify = (event) => {

    let notifyPromise = new Promise((resolve, reject) => {
        apiClient.notify(event)
            .then(
                res => {
                    console.log('Notified SSE Event: ' + JSON.stringify(event));
                    resolve();
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return notifyPromise;
}