// src/components/CollapsibleMenu.js
import { useState, createElement } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import DevicesIcon from '@material-ui/icons/DevicesOther';
import VideoCallIcon from '@material-ui/icons/Videocam';
import { withRouter } from 'react-router-dom';

const CustomNestedMenu = ({ onMenuClick, logout }) => {
    const [openDevices, setOpenDevices] = useState(false);
    const [openVideoCalls, setOpenVideoCalls] = useState(false);

    const open = useSelector(state => state.admin.ui.sidebarOpen);

    const resources = useSelector(getResources)

    const indice = resources.findIndex(r => r.name === 'activities') + 1
    const primeraParte = resources.slice(0, indice);
    const segundaParte = resources.slice(indice);

    const devicesFilter = ['installations', 'devices', 'comunicationDevices', 'cameraDevices']
    const devicesSubmenu = ['installations', 'comunicationDevices']
    const videollamadasSubmenu = ['devices', 'cameraDevices']

    return (
        <List>
            {primeraParte.map(resource => (
                resource.hasList && <Tooltip title={(resource.options && resource.options.label) || resource.name} placement='right'>
                    <span key={resource.name}>
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={(resource.options && resource.options.label) || resource.name}
                            leftIcon={resource.icon && createElement(resource.icon)}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    </span>
                </Tooltip>
            ))}
            {
                segundaParte.filter(r => devicesFilter.some(d => d === r.name)).some(r => r.hasList) &&
                <Tooltip title="Dispositivos" placement='right'>
                    <ListItem button onClick={() => setOpenDevices(!openDevices)} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        <DevicesIcon></DevicesIcon>
                        <ListItemText style={{ marginLeft: '1rem' }} primary="Dispositivos" />
                        {openDevices ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
            }
            {
                segundaParte.filter(r => devicesFilter.some(d => d === r.name)).some(r => r.hasList) &&
                <Collapse in={openDevices} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ paddingLeft: 38 }}>
                        {resources.filter(r => devicesSubmenu.some(d => d === r.name)).map(resource => (
                            resource.hasList && <Tooltip title={(resource.options && resource.options.label) || resource.name} placement='right'>
                                <span key={resource.name}>
                                    <MenuItemLink
                                        key={resource.name}
                                        to={`/${resource.name}`}
                                        primaryText={(resource.options && resource.options.label) || resource.name}
                                        leftIcon={resource.icon && createElement(resource.icon)}
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />
                                </span>
                            </Tooltip>
                        ))}
                        <Tooltip title="Videollamadas" placement='right'>
                            <ListItem key={"Videollamadas"} button onClick={() => setOpenVideoCalls(!openVideoCalls)} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                <VideoCallIcon></VideoCallIcon>
                                <ListItemText primary="Videollamadas" style={{ marginLeft: '1rem' }} />
                                {openVideoCalls ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                        </Tooltip>
                        <Collapse in={openVideoCalls} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{ paddingLeft: 32 }}>
                                {resources.filter(r => videollamadasSubmenu.some(d => d === r.name)).map(resource => (
                                    resource.hasList && <Tooltip title={(resource.options && resource.options.label) || resource.name} placement='right'>
                                        <span key={resource.name}>
                                            <MenuItemLink
                                                key={resource.name}
                                                to={`/${resource.name}`}
                                                primaryText={(resource.options && resource.options.label) || resource.name}
                                                leftIcon={resource.icon && createElement(resource.icon)}
                                                onClick={onMenuClick}
                                                sidebarIsOpen={open}
                                            />
                                        </span>
                                    </Tooltip>
                                ))}
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
            }
            {segundaParte.filter(r => !devicesFilter.some(d => d === r.name)).map(resource => (
                resource.hasList && <Tooltip title={(resource.options && resource.options.label) || resource.name} placement='right'>
                    <span key={resource.name}>
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={(resource.options && resource.options.label) || resource.name}
                            leftIcon={resource.icon && createElement(resource.icon)}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    </span>
                </Tooltip>
            ))}
        </List>
    );
};

export default withRouter(CustomNestedMenu);
