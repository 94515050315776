import { Layout, AppBar } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import image from './assets/image/Ingesan_NEG.png'

const MyAppBar = props => (
    <AppBar {...props}>
        <img src={image} alt='logo' height={48} style={{marginRight: 10}} />
        <Box flex="1" >
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>
    </AppBar>
);

export const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;