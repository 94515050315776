import { ClientClient } from "../services/ohl.virtualCenter.api";
import userHistoryProvider from "./userHistory.provider";
import moment from 'moment';

const apiClient = new ClientClient(process.env.REACT_APP_PUBLIC_API);

class UserProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        
        params.filter.activeDate = (params.filter.activeDate)?moment(params.filter.activeDate).format('yyyy-MM-DDTHH:mm:ss'):null
        params.filter.leavingDate = (params.filter.leavingDate)?moment(params.filter.leavingDate).format('yyyy-MM-DDTHH:mm:ss'):null

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        const userPromise = apiClient.clientGet(params.id)
        if (resource === 'many') {
            return userPromise.then(
                res => {
                    return {
                        data: res
                    }
                }
            )
        } else {
            const userHistoryPromise = userHistoryProvider.getOne(resource, { id: params.id })

            return Promise.allSettled([userPromise, userHistoryPromise]).then(([userDTO, userHistoryDTO]) => {
                return {
                    data: {
                        ...userDTO.value,
                        userHistory: userHistoryDTO.value ? userHistoryDTO.value.data : {}
                    }
                }
            })
        }
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;

        return apiClient.list({
            contractId: params.id,
            pageNumber: page,
            pageSize: perPage,
            ...params.filter
        }).then(
            res => {
                return {
                    data: res.elements,
                    total: res.total
                }
            }
        )
    }

    update = async (resource, params) => {
        let userUpdatePromise;
        if (params.data.picture && params.data.picture.rawFile) {
            let convertedFile = await convertFileToBase64(params.data.picture.rawFile);
            userUpdatePromise = apiClient.clientPut({ ...params.data, picture: { title: "clientPortrait", src: convertedFile } });
        } else {
            userUpdatePromise = apiClient.clientPut({ ...params.data });
        }

        const userHistoryUpdatePromise = userHistoryProvider.update(resource, {
            data: {
                ...params.data.userHistory,
                clientId: params.data.id
            }
        })
        let [userDTO, userHistoryDTO] = await Promise.allSettled([userUpdatePromise, userHistoryUpdatePromise])
        
        if (userDTO.status === 'rejected')
            throw userDTO.reason
        if (userHistoryDTO.status === 'rejected')
            throw userHistoryDTO.reason
        return Promise.resolve({
            data: {
                ...userDTO.value,
                userHistory: userHistoryDTO.value ? userHistoryDTO.value.data : {}
            }
        })
    }

    create = (resource, params) => {
        return apiClient.clientPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.clientDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }

};

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export default new UserProvider();