import moment from "moment";
import { maxValue, number, useMutation, useNotify, useRedirect, required } from "ra-core";
import { Datagrid, List, TextField, DateField, Edit, TextInput, DateInput, BooleanInput, Filter, SelectInput, AutocompleteInput, Create, FunctionField, TabbedForm, FormTab } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { TabsUseStyles } from "../App";
import { WorkingCenterField } from "../components/Fields/WorkingCenterField";
import { ComunicationTypeField } from "../components/Fields/ComunicationTypeField";
import { getComunicationDeviceType, getWorkingCenter , getCommunicationDevicesRouterModel, getCommunicationDevicesCameraModel, getCommunicationLineStatus} from "../services/master-tables.service";
import { PostBulkActionButtons, CustomToolbarPermissions } from "./utils";
import { useFormState, useForm } from 'react-final-form';

const validateDevice = (values) => {
    const errors = {};
    if (!values.code) {
        errors.code = 'Requerido';
    }
    if (!values.communicationTypeId) {
        errors.communicationTypeId = 'Requerido';
    }
    if (values.code && values.code.trim() === "") {
        errors.code = 'Requerido';
    }
    if (values.purchaseDate && values.installationDate){
        if (moment(values.purchaseDate) > moment(values.installationDate)) {
            errors.purchaseDate = 'La fecha de compra no puede ser superior a la fecha de instalación'
        }
    }
    return errors
};

const DeviceTitle = ({ record }) => {
    return <span>Dispositivo {record ? `${record.code}` : ''}</span>;
};

const ComunicationDeviceFilter = props => {
    const [workingCenter, setworkingCenter] = useState()

    useEffect(() => {
        console.log('Loading user form -> Provinces');
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
    }, []);

    return (
        <Filter {...props}>
            <TextInput label="Código dispositivo" source="code" alwaysOn />
            {/* <DateInput label="Fecha de instalación" source="installationDate" alwaysOn/> */}
            <SelectInput label="Tipo de asignación" source="assignType" alwaysOn choices={[
                { id: 'all', name: 'Todos' },
                { id: 'assigned', name: 'Asignado' },
                { id: 'unassigned', name: 'Sin asignar' },
            ]} />
            <TextInput label="NIF/NIE Usuario" source="identificationNumber" />
            <BooleanInput label="Fallo dispositivo" source="isFailed" />
            {
                workingCenter ?
                    <AutocompleteInput
                        label="Centro de Trabajo"
                        source="associatedWorkingCenterId"
                        choices={workingCenter}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
        </Filter>
    )
}

export const CameraList = props => (
    <List bulkActionButtons={props.permissions?.comunicationDevices.delete ? <PostBulkActionButtons /> : false} {...props} exporter={false} filters={<ComunicationDeviceFilter />} filterDefaultValues={{ assignType: 'all' }}>
        <Datagrid rowClick="edit" isRowSelectable={record => props.permissions?.comunicationDevices.delete}>
            {/* <TextField source="id" /> */}
            <TextField label="Código dispositivo" source="code" />
            <ComunicationTypeField label="Tipo dispositivo" source="communicationTypeId" />
            <DateField label="Fecha de instalación" source="installationDate" />
            <DateField label="Fecha de compra" source="purchaseDate" />
            <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={true} sortBy="WorkingCenter.Name" />
            <FunctionField label="NIF/NIE usuario" sortable={true} sortBy="User.IdentificationNumber" render={record => {
                return record.relatedUser ? record.relatedUser.identificationNumber : null
            }} />
            <FunctionField label="Usuario" sortable={true} sortBy="User.Name" render={record => {
                return record.relatedUser ? record.relatedUser.name + ' ' + record.relatedUser.surnames : null
            }} />
            {/* <BooleanField label="Error" source="isFailed" /> */}
        </Datagrid>
    </List>
);

export const RouterList = props => (
    <List bulkActionButtons={props.permissions?.comunicationDevices.delete ? <PostBulkActionButtons /> : false} {...props} exporter={false} filters={<ComunicationDeviceFilter />} filterDefaultValues={{ assignType: 'all' }}>
        <Datagrid rowClick="edit" isRowSelectable={record => props.permissions?.comunicationDevices.delete}>
            {/* <TextField source="id" /> */}
            <TextField label="Código dispositivo" source="code" />
            <TextField label="Compañía de contrato" source="companyContract" />
            <TextField label="Código contrato" source="contractCode" />
            <TextField label="Teléfono contrato" source="companyPhone" />
            <ComunicationTypeField label="Tipo dispositivo" source="communicationTypeId" />
            <DateField label="Fecha de instalación" source="installationDate" />
            <DateField label="Fecha de compra" source="purchaseDate" />
            <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={true} sortBy="WorkingCenter.Name" />
            <FunctionField label="NIF/NIE usuario" sortable={true} sortBy="User.IdentificationNumber" render={record => {
                return record.relatedUser ? record.relatedUser.identificationNumber : null
            }} />
            <FunctionField label="Usuario" sortable={true} sortBy="User.Name" render={record => {
                return record.relatedUser ? record.relatedUser.name + ' ' + record.relatedUser.surnames : null
            }} />
            {/* <BooleanField label="Error" source="isFailed" /> */}
        </Datagrid>
    </List>
);

export const ComunicationDeviceEdit = props => {
    return (
        <Edit title={<DeviceTitle />} mutationMode="pessimistic" {...props} transform={transformComunicationDevices}>
            {ComunicationDeviceForm(props, 'edit')}
        </Edit>
    )
};

export const RouterDeviceCreate = props => {
    const [deviceTypes, setDeviceTypes] = useState(null)
    useEffect(() => {
        getComunicationDeviceType().then(res => {
            setDeviceTypes(res.elements)
        })
    },[])
    const getDeviceTypeId = (name) => {
        return deviceTypes?.find(e => e?.value === name)?.id
    }
    return (
        <Create mutationMode="pessimistic" {...props} transform={transformComunicationDevices} record={{communicationTypeId: getDeviceTypeId("Router")}}>
            {ComunicationDeviceForm(props)}
        </Create>
    )
};

export const CameraDeviceCreate = props => {
    const [deviceTypes, setDeviceTypes] = useState(null)
    useEffect(() => {
        getComunicationDeviceType().then(res => {
            setDeviceTypes(res.elements)
        })
    },[])
    const getDeviceTypeId = (name) => {
        return deviceTypes?.find(e => e?.value === name)?.id
    }
    return (
        <Create mutationMode="pessimistic" {...props} transform={transformComunicationDevices} record={{communicationTypeId: getDeviceTypeId("Cámara")}}>
            {ComunicationDeviceForm(props)}
        </Create>
    )
};

export const ComunicationDeviceForm = (props, mode = 'create') => {
    const [comunicationDevices, setcomunicationDevices] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [routers, setRouters] = useState();
    const [cameraList, setCameraList] = useState();
    const [lineStatusList, setLineStatusList] = useState();
    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'comunicationDevices',
                    payload: { data: transformComunicationDevices(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4003) {
                    notify(error.error.message, 'error')
                    return {
                        code: error.error.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    useEffect(() => {
        console.log('Loading user form -> Provinces');
        getComunicationDeviceType().then(
            res => {
                setcomunicationDevices(res.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getCommunicationDevicesRouterModel().then(
            res => {
                setRouters(res.elements);
            }
        )
        getCommunicationDevicesCameraModel().then(
            res => {
                setCameraList(res.elements);
            }
        )
        getCommunicationLineStatus().then(
            res => {
                setLineStatusList(res.elements);
            }
        )
        // eslint-disable-next-line

    }, []);

    return (
        <TabbedForm classes={classes} save={save} validate={validateDevice} toolbar={<CustomToolbarPermissions perm="comunicationDevices.delete" />}>
            <FormTab label="Dispositivo" contentClassName="tab-container">
                <TextInput label="Código dispositivo" validate={[required()]} source="code" />
                <DateInput label="Fecha de instalación" source="installationDate" />
                <DateInput label="Fecha de compra" source="purchaseDate" />

                <ContractCodeField comunicationDevices={comunicationDevices} />
                <ContractCompanyField comunicationDevices={comunicationDevices} />
                
                <ComunicationDevices comunicationDevices={comunicationDevices} mode={mode} />
                <CameraModel cameraList={cameraList} comunicationDevices= {comunicationDevices}/>
                <RouterModel routers={routers} comunicationDevices= {comunicationDevices} />
                <RouterLineStatus lineStatusList={lineStatusList} comunicationDevices= {comunicationDevices}/>
                <ContractPhoneField comunicationDevices={comunicationDevices} />
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                <FunctionField label="Usuario" render={record => record.relatedUser ?
                    record.relatedUser.identificationNumber + ' - ' + record.relatedUser.name + ' ' + record.relatedUser.surnames
                    : 'Sin asignar'} />
                <CsvAssigned comunicationDevices= {comunicationDevices} />
                
                
            </FormTab>
            <FormTab label="Fallo dispositivo" contentClassName="tab-container">
                <BooleanInput label="Fallo dispositivo" source="isFailed" />
                <TextInput label="Descripción fallo" source="failDetail" />
            </FormTab>
        </TabbedForm>
    )
}

const ContractCodeField = (props) => {
    const { comunicationDevices } = props;

    const { values } = useFormState();
    const deviceTypeName = comunicationDevices?.find(e => e?.id === values.communicationTypeId)?.value

    return deviceTypeName === "Router" ? 
                <TextInput label="Código contrato" source="contractCode" />
            :null
}

const ContractCompanyField = (props) => {
    const { comunicationDevices } = props;

    const { values } = useFormState();
    const deviceTypeName = comunicationDevices?.find(e => e?.id === values.communicationTypeId)?.value

    return deviceTypeName === "Router" ? 
                <TextInput label="Compañía de contrato" source="companyContract" />
            :null
}

const ContractPhoneField = (props) => {
    const { comunicationDevices } = props;

    const { values } = useFormState();
    const deviceTypeName = comunicationDevices?.find(e => e?.id === values.communicationTypeId)?.value

    return deviceTypeName === "Router" ? 
                <TextInput label="Teléfono contrato" source="companyPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} />
            :null
}

const ComunicationDevices = (props) => {
    const { comunicationDevices } = props;
    const form = useForm();

    const getCommunicationTypeName = (id) => comunicationDevices?.find(e => e?.id === id)?.value

    const handleChange = (event) => {

       if(event){
        if(getCommunicationTypeName(event) === "Router"){
            form.change('cameraModelId', null);
        }else{
            form.change('routerModelId', null);
            form.change('routerLineStatusId', null);
        }
       }
    };



    return (
        comunicationDevices ?
                        <AutocompleteInput
                            label="Tipo dispositivo"
                            source="communicationTypeId"
                            choices={comunicationDevices}
                            onChange={(newValue) => handleChange(newValue)}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                        : null

    )
}

const RouterModel = (props) => {
    const {routers, comunicationDevices} = props;
    const { values } = useFormState();


    const getCommunicationTypeName = (id) => comunicationDevices?.find(e => e?.id === id)?.value
    var isVisible = getCommunicationTypeName(values?.communicationTypeId) === "Router";

    return (
        routers && isVisible ?
        <AutocompleteInput
            label="Modelo de Router"
            source="routerModelId"
            choices={routers}
            optionText="value"
            optionValue="id"
            options={{ InputProps: { autoComplete: 'off' } }}
            resettable={true}
        />
        : null

    )
}


const RouterLineStatus = (props) => {
    const {lineStatusList, comunicationDevices} = props;
    const { values } = useFormState();


    const getCommunicationTypeName = (id) => comunicationDevices?.find(e => e?.id === id)?.value
    var isVisible = getCommunicationTypeName(values?.communicationTypeId) === "Router";

    return (
        lineStatusList && isVisible ?
        <AutocompleteInput
            label="Linea"
            source="routerLineStatusId"
            choices={lineStatusList}
            optionText="value"
            optionValue="id"
            options={{ InputProps: { autoComplete: 'off' } }}
            resettable={true}
        />
                        : null

    )
}


const CameraModel = (props) => {
    const {cameraList, comunicationDevices} = props;
    const { values } = useFormState();

    const getCommunicationTypeName = (id) => comunicationDevices?.find(e => e?.id === id)?.value
    var isVisible = getCommunicationTypeName(values?.communicationTypeId) === "Cámara"

    return (
        cameraList && isVisible ?
            <AutocompleteInput
                label="Modelo de camara"
                source="cameraModelId"
                choices={cameraList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
            />
            : null

    )
}



const CsvAssigned = (props) => {
    const { comunicationDevices } = props;
    const { values } = useFormState();
    const getCommunicationTypeName = (id) => comunicationDevices?.find(e => e?.id === id)?.value;
    const isVisible = getCommunicationTypeName(values?.communicationTypeId) === "Cámara";

    return (
        isVisible ? (
            
            <div style={{display: 'flex', flexDirection: 'column',width: '100%', gap: 8}}>
                <span className="typography-csv-assigned">Dispositivo CSV asignado</span>
                <div style={{display: 'flex', justifyContent: 'center',width: '100%'}}>     
                  {values?.androidDevice ? values?.androidDevice?.code : 'Sin asignar'}
                </div>
            </div>
        ) : null
    );
};

const transformComunicationDevices = (data) => {

    // Pasamos fechas a formato UTC Z
    const installationDate = data.installationDate ? moment(data.installationDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const purchaseDate = data.purchaseDate ? moment(data.purchaseDate).format('yyyy-MM-DDTHH:mm:ss') : null;

    return {
        ...data,
        installationDate,
        purchaseDate
    }
}