import './Footer.css'; // Importa el archivo CSS
import { useState } from "react";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MUIButton from '@material-ui/core/Button';
import LogoView from './LogoView';

const Collaborators = props => {
    const { logos } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {
                <div className="collaborators-container">
                    {logos?.data?.panelIzquierda && logos?.data?.panelIzquierda?.length > 0 ?
                        <div className="collaborators-block">
                            <Typography className='header-logo'>{logos?.data?.logoPrimaryText}</Typography>
                            <div className="logos">
                                {logos?.data?.panelIzquierda?.map((logo, index) => (
                                    <LogoView logo={logo} key={index} />
                                ))}
                            </div>
                        </div> : null
                    }
                    {logos?.data?.panelDerecha && logos?.data?.panelDerecha.length > 0 ?
                        <div className="financed-block">
                            <Typography className='header-logo'>{logos?.data?.logoSecondaryText}</Typography>
                            <div className="logos">
                                {logos?.data?.panelDerecha?.map((logo, index) => (
                                    <LogoView logo={logo}  key={index} />
                                ))}
                            </div>
                        </div> : null
                    }
                    {
                        (logos?.data?.panelIzquierda && logos?.data?.panelIzquierda.length > 0) || (logos?.data?.panelDerecha && logos?.data?.panelDerecha.length > 0) ?
                            <div className='more-information-block'>
                                <Typography component="span">
                                    <span className='link-style' onClick={handleClickOpen}>Más Información</span>
                                </Typography>
                                <Dialog open={open} onClose={handleClose}>
                                    {/* <MuiDialogTitle>Más Información</MuiDialogTitle> */}
                                    {logos?.data?.panelIzquierda && logos?.data?.panelIzquierda?.length > 0 ?
                                        <div className="collaborators-more-information-block">
                                            <span className='header-dialog-logo'>{logos?.data?.logoPrimaryText}</span>
                                            <div className="logos">
                                                {logos?.data?.panelIzquierda?.map((logo, index) => (
                                                    <LogoView logo={logo} height={120} key={index} />
                                                ))}
                                            </div>
                                        </div> : null
                                    }
                                    {logos?.data?.panelDerecha && logos?.data?.panelDerecha.length > 0 ?
                                        <div className="collaborators-more-information-block">
                                            <span className='header-dialog-logo'>{logos?.data?.logoSecondaryText}</span>
                                            <div className="logos">
                                                {logos?.data?.panelDerecha?.map((logo, index) => (
                                                    <LogoView logo={logo} height={120} key={index} />
                                                ))}
                                            </div>
                                        </div> : null
                                    }
                                    <DialogContent>
                                        {logos?.data?.logoMoreInfoText}
                                    </DialogContent>
                                    <DialogActions>
                                        <MUIButton onClick={handleClose} color="primary">
                                            Cerrar
                                        </MUIButton>
                                    </DialogActions>
                                </Dialog>
                            </div> : null
                    }
                </div>
            }
        </>
    );
};
export default Collaborators;
