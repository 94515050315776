import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Quicksand-VariableFont_wght.ttf';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Opcional si deseas usar también CSS

import './services/fetch.interceptor'

import moment from "moment";
import 'moment/locale/es';
moment.locale('es');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
