import { ContractingAuthorityClient } from "../services/ohl.virtualCenter.api";

const apiClient = new ContractingAuthorityClient(process.env.REACT_APP_PUBLIC_API);

class ContractingAuthorityProvider {

    getOne = (resource, params) => {
        return apiClient.contractingAuthorityGet(params.id).then((data) => {
            return { data }
        });
    }

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        
        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    update = (resource, params) => {
        return apiClient.contractingAuthorityPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.contractingAuthorityPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.contractingAuthorityDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new ContractingAuthorityProvider();