
import RichTextInput from 'ra-input-rich-text';
import { RichTextField } from 'react-admin';
import { usePermissions } from 'react-admin';
import { Paper } from '@material-ui/core';
// import { btoaReplacement } from '../common/utils';

export const UserHistoryView = (props) => {
    const { loading, permissions } = usePermissions();
    if (loading) return null;
    if (permissions.userHistory.edit) {
        return <UserHistoryForm {...props}></UserHistoryForm>
    } else {
        return <UserHistoryShow {...props}></UserHistoryShow>
    }
}

export const UserHistoryForm = (props) => {

    const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ 'color': [] }, { 'background': [] }],]

    return (
        <div className="richText-container">
            <RichTextInput toolbar={toolbarOptions} label="Situación de dependencia" source="userHistory.dependencyStatus" />
            <RichTextInput toolbar={toolbarOptions} label="Interpretación diagnóstica" source="userHistory.diagnosticInterpretation" />
            <RichTextInput toolbar={toolbarOptions} label="Contexto Familiar y social" source="userHistory.familySocialContext" />
            <RichTextInput toolbar={toolbarOptions} label="Vivienda" source="userHistory.home" />
            <RichTextInput toolbar={toolbarOptions} label="Objetivos" source="userHistory.objectives" />
            <RichTextInput toolbar={toolbarOptions} label="Otros" source="userHistory.other" />
        </div>
    )
}

export const UserHistoryShow = (props) => {

    const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ 'color': [] }, { 'background': [] }],]

    return (
        <div className="richText-container-show">
            <Paper elevation={3} className="history-container">
                <span className="history-title">Situación de dependencia</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Situación de dependencia" source="userHistory.dependencyStatus" />
                </div>
            </Paper>
            <Paper elevation={3} className="history-container">
                <span className="history-title">Interpretación diagnóstica</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Interpretación diagnóstica" source="userHistory.diagnosticInterpretation" />
                </div>
            </Paper>
            <Paper elevation={3} className="history-container">
                <span className="history-title">Contexto Familiar y social</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Contexto Familiar y social" source="userHistory.familySocialContext" />
                </div>
            </Paper>
            <Paper elevation={3} className="history-container">
                <span className="history-title">Vivienda</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Vivienda" source="userHistory.home" />
                </div>
            </Paper>
            <Paper elevation={3} className="history-container">
                <span className="history-title">Objetivos</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Objetivos" source="userHistory.objectives" />
                </div>
            </Paper>
            <Paper elevation={3} className="history-container">
                <span className="history-title">Otros</span>
                <div className="history-body">
                    <RichTextField toolbar={toolbarOptions} label="Otros" source="userHistory.other" />
                </div>
            </Paper>
        </div>
    )
}

export const transformUserHistory = (values, alone = false) => {
    for (const key in values) {
        if (values.hasOwnProperty(key) && key !== 'client') {
            // Check if the property is null or undefined
            if (values[key] === null || values[key] === undefined) {
                values[key] = ''; // Set null values to an empty string
            }
        }
    }
    // values.dependencyStatus = btoaReplacement(values.dependencyStatus)
    // values.diagnosticInterpretation = btoaReplacement(values.diagnosticInterpretation)
    // values.familySocialContext = btoaReplacement(values.familySocialContext)
    // values.home = btoaReplacement(values.home)
    // values.objectives = btoaReplacement(values.objectives)
    // values.other = btoaReplacement(values.other)
    if (alone) {
        values.clientId = values.client.id
    }
    return values
}