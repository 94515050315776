import * as React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useLogin, useNotify, Notification, useTranslate } from "react-admin";
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, CircularProgress, Button } from "@material-ui/core";
import image from '../assets/image/Azul.png'
// import soloLogos from '../assets/image/SoloLogos.png';
import './login.css';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const PasswordField = (props) => {
    const [visibility, setVisibility] = useState(false);

    const translate = useTranslate();

    const handleVisibilityClick = () => {
        setVisibility(!visibility)
    }

    return (
        <div className='input-form'>
            <Field
                name="password"
                // @ts-ignore
                component={renderInput}
                label={translate('ra.auth.password')}
                type={visibility ? 'text' : 'password'}
                disabled={props.loading}
            />

            <Button
                onClick={handleVisibilityClick}
                disabled={props.loading}
            >
                {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Button>
        </div>
    )
}

const Login = () => {
    const [loading, setLoading] = useState();
    const translate = useTranslate();
    const login = useLogin();
    const notify = useNotify();
    const location = useLocation();
    const history = useHistory();

    const handleSubmit = (auth) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/')
            .then((res) => {
                if (res.userName) {
                    localStorage.setItem('userName', res.userName);
                } else {
                    localStorage.setItem('userName', '');
                }
                if (res.firstLogin) {
                    localStorage.setItem('loginPassword', auth.password);
                    history.push('/changePasswordPage')
                }
            })
            .catch(
                (error) => {
                    setLoading(false);
                    notify(
                        error ? error.message : translate('ra.auth.sign_in_error'),
                        'warning'
                    );
                }
            );
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    // const footerStyle = {
    //     position: 'fixed',
    //     left: 0,
    //     bottom: 0,
    //     width: '100%',
    //     backgroundColor: 'white',
    //     color: 'black',
    //     textAlign: 'center',
    //     padding: '10px 0',
    //     height: '4rem',
    //     display: 'grid', 
    //     gridTemplateColumns: '1fr 3fr 1fr', 
    //     columnGap: '2rem', 
    //     alignItems: 'center', 
    //     justifyContent: 'center'
    // };
    

    return (
        <Form
            className='Login'
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <>
                <form onSubmit={handleSubmit}>
                    <div className='login-form'>
                        <div className='login-form-style'>
                            <img src={image} alt='logo' className='veraImg' />
                            <div className='input-form'>
                                <Field
                                    name="username"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('ra.auth.username')}
                                    disabled={loading}
                                />
                            </div>
                            <PasswordField loading={loading} />
                            <div className='input-form'>
                                <Field
                                    name="token"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('ra.auth.verificationCode')}
                                    disabled={loading}
                                />
                            </div>
                            <Button
                                variant="contained"
                                type='submit'
                                color="primary"
                                disabled={loading}
                                className="button"
                            >
                                Acceder
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                            </Button>
                        </div>
                    </div>
                    <Notification />
                </form>
                {/* <div style={footerStyle}>
                    <div></div>
                    <div style={{marginTop: '-1.3rem'}}>
                        <p style={{ marginLeft: '-25rem', fontSize: '0.8rem'}}>CON LA COLABORACION DE
                        <span style={{ marginLeft: '10rem', fontSize: '0.8rem' }}>FINANCIADO POR</span></p>
                        <img src={soloLogos}  style={{marginTop: '-0.6rem'}} height='50rem' width='100%' alt='Logos'/>
                        <p style={{fontSize: '0.5rem', marginTop: '-0.5rem'}}>
                        CAMINN: Cuidados, Autonomía e Inclusión Social. Innovación Sociosanitaria en Madrid es un proyecto piloto de innovación social, 
                        con cargo al Plan de Recuperación, Transformación y Resiliencia del Gobierno de España, Financiado por la Unión Europea - NextGenerationEU, 
                        y con la colaboración de la Consejería de Familia, Juventud y Asuntos Sociales de la Comunidad de Madrid. 
                        El contenido de esta publicación es de responsabilidad exclusiva de OHL Servicios Ingesan S.A., no asumiendo la Administración responsabilidad alguna sobre dicho contenido.
                        </p>
                    </div>
                    <div></div>
                </div> */}
                </>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};


export default Login





// export const login = () => {

//     console.log(document.querySelector("form"))
//     return (
//         <Login
//             className="login-form"
//             backgroundImage={loginBackground}
//         // dashboard={MyDashboard}
//         />
//     )
// }