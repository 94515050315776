import { ExternalInformationClient } from "../services/ohl.virtualCenter.api";
// import { btoaReplacement } from "../common/utils";
const apiClient = new ExternalInformationClient(process.env.REACT_APP_PUBLIC_API);

class UserInteractionsProvider {

    getOne = (resource, params) => {
        return apiClient.interactionGet(params.id).then((data) => {
            delete data.creationDate
            try { data.comment = atob(data.comment) } catch (ex) { }
            try { data.technicalComment = atob(data.technicalComment) } catch (ex) { }
            try { data.commentForRelative = atob(data.commentForRelative) } catch (ex) { }
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        if (!params.filter.is_scheduled) {
            return apiClient.interactions({
                pageNumber: page,
                pageSize: perPage,
                orderField: field,
                orderAsc: order === 'ASC',
                clientId: params.id,
                ...params.filter
            }).then(
                res => {
                    res.elements.forEach(e => {
                        try { e.comment = atob(e.comment) } catch (ex) { }
                        try { e.technicalComment = atob(e.technicalComment) } catch (ex) { }
                        try { e.commentForRelative = atob(e.commentForRelative) } catch (ex) { }
                        return e;
                    })
                    return { data: res.elements, total: res.total }
                }
            )
        } else {
            return apiClient.interactions({
                pageNumber: page,
                pageSize: perPage,
                orderField: field,
                orderAsc: order === 'ASC',
                scheduledActivityId: params.id,
                ...params.filter
            }).then(
                res => {
                    res.elements.forEach(e => {
                        try { e.comment = atob(e.comment) } catch (ex) { }
                        try { e.technicalComment = atob(e.technicalComment) } catch (ex) { }
                        try { e.commentForRelative = atob(e.commentForRelative) } catch (ex) { }
                        return e;
                    })
                    return { data: res.elements, total: res.total }
                }
            )
        }
    }

    create = (resource, params) => {

        for (const key in params) {
            if (params.hasOwnProperty(key) && (key === 'comment' || key === 'technicalComment' || key === 'commentForRelative')) {
                // Check if the property is null or undefined
                if (params[key] === null || params[key] === undefined) {
                    params[key] = ''; // Set null params to an empty string
                }
            }
        }

        if (!params.data.isActivityAssociated) {
            params.data.scheduledActivityId = null;
            params.data.mood = 0;
            params.data.healthCondition = 0;
            params.data.feedbackMark = 0;
        }
        if (!params.data.mood) {
            params.data.mood = 0;
        }
        if (!params.data.healthCondition) {
            params.data.healthCondition = 0;
        }
        if (!params.data.feedbackMark) {
            params.data.feedbackMark = 0;
        }
        // params.data.comment = btoaReplacement(params.data.comment)
        // params.data.technicalComment = btoaReplacement(params.data.technicalComment)
        // params.data.commentForRelative = btoaReplacement(params.data.commentForRelative)
        return apiClient.interactionPost(params.data).then((data) => ({
            data,
            // id: 
        }));
    }

    update = (resource, params) => {

        for (const key in params) {
            if (params.hasOwnProperty(key) && (key === 'comment' || key === 'technicalComment' || key === 'commentForRelative')) {
                // Check if the property is null or undefined
                if (params[key] === null || params[key] === undefined) {
                    params[key] = ''; // Set null params to an empty string
                }
            }
        }

        // params.data.comment = btoaReplacement(params.data.comment);
        // params.data.technicalComment = btoaReplacement(params.data.technicalComment);
        // params.data.commentForRelative = btoaReplacement(params.data.commentForRelative);
        if (!params.data.mood) {
            params.data.mood = 0;
        }
        if (!params.data.healthCondition) {
            params.data.healthCondition = 0;
        }
        if (!params.data.feedbackMark) {
            params.data.feedbackMark = 0;
        }
        if (!params.data.isActivityAssociated) { params.data.scheduledActivityId = null; }
        return apiClient.interactionPut(params.data).then((data) => {
            return { data }
        });
    }

    delete = (resource, params) => {
        return apiClient.interactionDelete(params.id).then((data) => {
            return {
                data: params.previousData
            }
        });
    }
};

export default new UserInteractionsProvider();