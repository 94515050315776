import fetchIntercept from 'fetch-intercept';
import { isPublicUrl } from '../provider/auth.provider';
import { LoginClient } from "../services/ohl.virtualCenter.api";
import moment from 'moment';

const apiClient = new LoginClient(process.env.REACT_APP_PUBLIC_API);

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        const token = localStorage.getItem('token');
        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: 'Bearer ' + token
            }
        }
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Check if error
        if(!isPublicUrl(window.location.pathname)){
            if (response && response.status === 401) {
                window.location.href = '/#/login'
                localStorage.removeItem('token');
                localStorage.removeItem('permissions');
                localStorage.removeItem('guestOptions');
                localStorage.removeItem('userIdentity');
                localStorage.removeItem('expiration');
                return response
            }
            const token = localStorage.getItem('token');
            if(token){
                const tokenParsed = parseJwt(token)
                const expiracion = moment.unix(tokenParsed.exp)
                if(moment().isBetween(expiracion.clone().subtract(30, 'minutes'), expiracion)){
                    apiClient.renew().then(res => {
                        localStorage.setItem('token', res.accessToken)
                    })
                }
            }
        }
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});