import { ContactClient } from "../services/ohl.virtualCenter.api";

const apiClient = new ContactClient(process.env.REACT_APP_PUBLIC_API);

class ContactsProvider {

    getOne = (resource, params) => {
        return apiClient.contactGet(params.id).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        return apiClient.client(params.id).then(
            res => {
                return { data: res, total: res.length }
            }
        )
    }

    update = (resource, params) => {
        return apiClient.contactPut(params).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.contactPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.contactDelete(params.previousData.contactRelativesIds[0]).then(() => ({
            data: params.previousData
            // data
        }));
    }
};

export default new ContactsProvider();