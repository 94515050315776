import React, { useState, useEffect, useRef } from 'react';
import userProvider from "../provider/user.provider";
import comunicationProvider from "../provider/comunicationDevices.provider";
import devicesProvider from "../provider/devices.provider";
import usersProvider from "../provider/user.provider";
import { useFormState, useForm, useField } from "react-final-form";
import { TextInput } from "ra-ui-materialui";
import { required } from "ra-core";

const Types = {
    Participant: "PARTICIPANT",
    ComunicationDevice: "COMUNICATIONDEVICE",
    Device: "DEVICE",
    Users: "USERS"
};

const getInputValue = (type, data, value) => {
    switch (type) {
        case Types.Participant:
            return data
                ? `${data.identificationNumber || ''} - ${data.name || ''} ${data.surnames || ''}`
                : "";
        case Types.ComunicationDevice:
            if (data !== undefined && data !== null) {
                return data?.code || '';
            } else {
                return value?.communicationDevice?.code || '';
            }
        case Types.Device:
            if (data !== undefined && data !== null) {
                return data?.code || '';
            } else {
                return value?.androidDevice?.code || '';
            }
        case Types.Users:
            if (data !== undefined && data !== null) {
                return `${data?.identificationNumber || ''} - ${data?.name || ''} ${data?.surnames || ''}` || '';
            } else {
                return value?.client !== undefined && value.client.identificationNumber !== undefined
                ? `${value.client.identificationNumber || ''} - ${value.client.name || ''} ${value.client.surnames || ''}` 
                : '';
            }
        default:
            return '';
    }
}


const AutoCompleteCustom = (props) => {
    const { type, label, placeholder, data, source, index, filterId, disabled, requiredInput } = props;
    const { values } = useFormState();
    const [valueId, setValueId] = useState();
    const [inputValueSearch, setInputValueSearch] = useState('');
    const [firstTime, setFirstTime] = useState(true);
    const [inputValue, setInputValue] = useState(() => getInputValue(type, data, values));
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [limit] = useState(15); //limite de busqueda
    const [limitFilter, setLimitFilter] = useState(limit);
    const [hasMore, setHasMore] = useState(true);
    const autocompleteRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const form = useForm();

    useEffect(() => {

        // Detecta clics fuera del componente
        const handleClickOutside = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setShowDropdown(false); // Oculta el dropdown si el clic es fuera del componente
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteRef]);


    useEffect(() => {

        if (inputValueSearch.length >= 3 || (inputValueSearch.length === 0 && limit !== limitFilter)) {
            setLoading(true);


            switch (type) {
                case Types.Participant:
                    fetchClients(inputValueSearch);
                    break;
                case Types.ComunicationDevice:
                    fetchComunicationDevices(inputValueSearch);
                    break;
                case Types.Device:
                    fetchDevices(inputValueSearch);
                    break;
                case Types.Users:
                    fetchUsers(inputValueSearch);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValueSearch, limitFilter]);

    const fetchClients = async (value) => {
        const res = await userProvider.getList(null, {
            pagination: { page: 1, perPage: limitFilter },
            sort: { field: 'Id', order: 'DESC' },
            filter: {
                contractId: filterId,
                nameINumber: value ? value : undefined
            }
        });

        setSuggestions(res?.data);
        setShowDropdown(true);        
        setHasMore(res?.total >= limitFilter);
        setLoading(false);
    };

    const fetchComunicationDevices = async (value) => {
        const res = await comunicationProvider.getList(null, {
            pagination: { page: 1, perPage: limitFilter },
            sort: { field: 'Id', order: 'DESC' },
            filter: {
                communicationDeviceTypeId: filterId,
                isAvailable: true,
                unassigned: true,
                assigned: false,
                isFailed: false,
                code: value ? value : undefined
            }
        });

        setSuggestions(res?.data);
        setHasMore(res?.total >= limitFilter);

        setLoading(false);
        setShowDropdown(true);
    };

    const fetchDevices = async (value) => {
        const res = await devicesProvider.getList(null, {
            pagination: { page: 1, perPage: limitFilter },
            sort: { field: 'Id', order: 'DESC' },
            filter: {
                unassigned: false,
                assigned: false,
                isFailed: false,
                code: value ? value : undefined
            }
        });

        setSuggestions(res?.data);
        setHasMore(res?.total >= limitFilter);

        setLoading(false);
        setShowDropdown(true);
    };


    const fetchUsers = async (value) => {
        const res = await usersProvider.getList(null, {
            pagination: { page: 1, perPage: limitFilter },
            sort: { field: 'Id', order: 'DESC' },
            filter: {
                hasAssociatedInstallation: false,
                name: value ? value : undefined
            }
        });

        setSuggestions(res?.data);
        setHasMore(res?.total >= limitFilter);

        setLoading(false);
        setShowDropdown(true);
    };

    const handleInputChange = (e) => {
        let valueData = e?.target?.value;

         //console.log(`handleInputChange -${e.target.value}`)
        setInputValue(valueData ?? "");
        setInputValueSearch(valueData ?? "");
       
        if((valueData === undefined || valueData === null || valueData?.length === 0)){            
            setFirstTime(true);
        }
    };

    const getValidateForType = (value) => {
        let isValid = true;
        
        switch (type) {
            case Types.Participant:
                const isValidNumber = typeof value === 'number';

                if(isValidNumber){
                    let comunicationDevice =  suggestions.filter(p => p !== undefined && p !== null && p === value);
                    isValid = comunicationDevice !== undefined && comunicationDevice !== null ? true : false;
                }

                if(requiredInput && value === undefined){
                    return 'Requerido';
                } else if(!requiredInput && value === undefined){
                    return undefined;
                }
                //if(requiredInput && value === undefined) return 'Requerido'
                if (!isValid || !isValidNumber) {
                    return 'Hay campos no válidos.';
                }
                break;
            case Types.ComunicationDevice:
                if((values?.communicationDevice?.id === undefined || values?.communicationDevice?.id === null) && !requiredInput) return undefined;
                const isValidNumberComunicationDevice = typeof values?.communicationDevice?.id === 'number';
                if(isValidNumberComunicationDevice){
                    let comunicationDevice =  suggestions.filter(p => p !== undefined && p !== null && p === values?.communicationDevice?.id);
                    isValid = comunicationDevice !== undefined && comunicationDevice !== null ? true : false;
                }
                if(requiredInput && value === undefined){
                    return 'Requerido';
                } else if(!requiredInput && value === undefined){
                    return undefined;
                }
                if (!isValid || !isValidNumberComunicationDevice) {
                    return 'Campo no válido.';
                }  
                break;
             case Types.Device:
                if((values?.androidDevice?.id === undefined || values?.androidDevice?.id === null) && !requiredInput) return undefined;
                const isValidNumberAndroidDevice = typeof values?.androidDevice?.id === 'number';
                if(isValidNumberAndroidDevice){
                    let androidDevice =  suggestions.filter(p => p !== undefined && p !== null && p === values?.androidDevice?.id);
                    isValid = androidDevice !== undefined && androidDevice !== null ? true : false;
                }
                if(requiredInput && value === undefined){
                    return 'Requerido';
                } else if(!requiredInput && value === undefined){
                    return undefined;
                }
                if (!isValid || !isValidNumberAndroidDevice) {
                    return 'Campo no válido.';
                }  
                break;
            case Types.Users:
                if((values?.clientId === undefined || values?.clientId === null) && !requiredInput) return undefined;
                const isValidclientId = typeof values?.clientId === 'number';
                if(isValidclientId){
                    let client =  suggestions.filter(p => p !== undefined && p !== null && p === values?.clientId);
                    isValid = client !== undefined && client !== null ? true : false;
                }
                if(requiredInput && value === undefined){
                    return 'Requerido';
                } else if(!requiredInput && value === undefined){
                    return undefined;
                }
                if (!isValid || !isValidclientId) {
                    return 'Campo no válido.';
                }  
                break;
            default:
                return undefined;
        }
    }
 
    const handleOnFocues = () => {
        if (firstTime && (filterId || type === Types.Device || type === Types.Users)) {
            setFirstTime(false);
           
            switch (type) {
                case Types.Participant:
                    fetchClients();
                    break;
                case Types.ComunicationDevice:
                    fetchComunicationDevices();
                    break;
                case Types.Device:
                    fetchDevices();
                    break;
                case Types.Users:
                    fetchUsers();
                    break;
                default:
                    break;
            }
        }

        setShowDropdown(true)
    };

    //Cada vez que selecciono uno salta este evento
    const handleSelectSuggestion = (suggestion) => {

        if (suggestion) {
            setInputValue(getInputValue(type, suggestion, values));
            setShowDropdown(false);
            setValueId(suggestion.id);
            addData({ id: suggestion.id, index: index });
        }
    };


    const addData = (newData) => {

        let updatedData = Array.isArray(values.participants)
            ? values.participants.filter((p) => p !== undefined && p !== null)
            : [];

        let dataFilter = updatedData.filter((p) => p.index === index);

        if (dataFilter && dataFilter[0]?.id === newData?.id) {
            updatedData = updatedData.filter((p) => p.id !== newData?.id);
        } else if (dataFilter && dataFilter.length > 0) {
            updatedData = updatedData.filter((p) => p.id !== dataFilter[0]?.id);
        } else if (data && data?.id !== newData.id) {
            updatedData = updatedData.filter((p) => p.id !== data?.id);
        }

        updatedData.push(newData);

        //console.log(updatedData)
        switch (type) {
            case Types.Participant:


                const result = {
                    participants: updatedData
                  };
                  console.log(JSON.stringify(result, null, 2));

                onChange(updatedData);
                values.participants = updatedData;
                form.change('participants', updatedData);
                break;
            case Types.ComunicationDevice:
                onChange(newData)
                values.communicationDevice = newData;
                form.change('communicationDevice', newData);
                break;
            case Types.Device:
                onChange(newData)
                values.androidDevice = newData;
                form.change('androidDevice', newData);
                break;
            case Types.Users:
                onChange(newData)
                values.clientId = newData.id;
                form.change('clientId', newData.id);
                break;
            default:
                break;
        }
    };

    const handleScroll = (e) => {

        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 5;

        if (bottom && hasMore) {
            setLimitFilter((prevLimit) => prevLimit + limit);
        }
    };

    const position = values?.participants?.findIndex(p => p?.id === data?.id);

    const suggestionsFilter = (suggestions ?? [])
        .filter(suggestion => suggestion && suggestion.id)
        .filter(suggestion => !(values?.participants?.filter(participant => participant && participant.id) ?? [])
            .some(participant => participant.id === suggestion.id)); // Se verifica que el id del participante no esté en suggestions


    const getSource = (name, type) => {
        switch (type) {
            case Types.Participant:
                return `participants[${position === -1 ? index : position}].${"id"}`;
            case Types.ComunicationDevice:
            case Types.Device:
            case Types.Users:
                return name;
            default:
                return '';
        }
    }
    
    const {
        input: { onChange },
        meta: { touched, error }
    } = useField(getSource(source, type));


    const getWidth = () => {
        switch (type) {
            case Types.Participant:
                return '25%';
            case Types.ComunicationDevice:
            case Types.Device:
                return '50%';
            default:
                return '';
        }
    }

    const getStyleDropdown = () => {
        switch (type) {
            case Types.Participant:
                return 'inputAutoCompleteParticipant-style';
            case Types.ComunicationDevice:
            case Types.Device:
            case Types.Users:
                return 'inputAutoComplete-style';
            default:
                return '';
        }
    }

    

    return (

        <div ref={autocompleteRef} style={{ position: 'relative', width: getWidth(), /* paddingTop: '0.5rem' */ }} >
            <TextInput
                error={!!(touched && error) ? error : null}
                label={label}
                style={{ display: '' }}
                placeholder={placeholder}
                format={(value) => inputValue}
                defaultValue={valueId}
                validate={requiredInput ? [required(), getValidateForType] : [getValidateForType]}
                source={getSource(source, type)}
                onChange={handleInputChange}
                disabled={disabled}
                resettable={true}
                onFocus={handleOnFocues} />
            {showDropdown && suggestionsFilter.length > 0 && (
                <div
                    className={getStyleDropdown()}
                    onScroll={handleScroll}
                >
                    <span style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                        {suggestionsFilter.map((suggestion) => (
                            <li
                                key={suggestion.id}
                                onClick={() => handleSelectSuggestion(suggestion)}
                                style={{ padding: '8px', cursor: 'pointer', fontSize: '0.9rem' }}
                            >
                                {getInputValue(type, suggestion, values)}
                            </li>
                        ))}
                    </span>
                    {loading && <div>Cargando más datos...</div>}
                </div>
            )}
        </div>
    );
};




export default AutoCompleteCustom;
//Mucha suerte