export const PESTANA_CONTRATOS = "contratos"
export const PESTANA_DOCUMENTOS = "documentacion"
export const PESTANA_HISTORIA_USUARIO = "historiausuario"
export const PESTANA_INTERACCIONES = "interacciones"

export const mapaPestanas = {
    contratos: {
        SUPER_ADMIN: 7,
        ADMIN: 7,
        DYNAMICS_MANAGER: 7,
        ALL_MANAGER: 7,
        LOGISTICS_TECHNICIAN: 7,
        CONTRACTING_AUTHORITY: 'show/7',
    },
    documentacion: {
        SUPER_ADMIN: 6,
        ADMIN: 6,
        USER_HISTORY_MANAGER: 'show/6',
        DYNAMICS_MANAGER: 6,
        ALL_MANAGER: 6,
        LOGISTICS_TECHNICIAN: 6,
        CONTRACTING_AUTHORITY: 'show/6',
    },
    historiausuario: {
        SUPER_ADMIN: 4,
        ADMIN: 4,
        USER_HISTORY_MANAGER: 'show/4',
        DYNAMICS_MANAGER: 4,
        ALL_MANAGER: 4,
        AAPP_TECHNICIAN: 'show/4',
        CONTRACTING_AUTHORITY: 'show/4',
    },
    interacciones: {
        SUPER_ADMIN: 5,
        ADMIN: 5,
        USER_HISTORY_MANAGER: 'show/5',
        DYNAMICS_MANAGER: 5,
        ALL_MANAGER: 5,
        CONTRACTING_AUTHORITY: 'show/5',
    }
}