import { Button, Create, Datagrid, DeleteButton, ReferenceManyField, AutocompleteInput } from "ra-ui-materialui";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddIcon from '@material-ui/icons/Add';
import { useLocation } from 'react-router';
import { useFormState } from 'react-final-form';
import { useEffect, useState } from "react";
import { ActivityClient, ScheduledActivityClient } from "../services/ohl.virtualCenter.api";
import StarPicker from 'react-star-picker';
import {
    FunctionField,
    RichTextField,
    Pagination,
    Edit,
    SaveButton,
    DateField,
    useRefresh,
    useNotify,
    useCreate,
    FormWithRedirect,
    useUpdate,
    useRecordContext,
    BooleanInput,
    Labeled,
    useInput,
    FieldTitle
} from "react-admin";
import { useGetIdentity } from "ra-core";
import moment from "moment";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ActionDelete from '@material-ui/icons/Delete';
import ReactQuill from "react-quill";

const activityApiClient = new ActivityClient(process?.env?.REACT_APP_PUBLIC_API);
const scheduledActivityapiClient = new ScheduledActivityClient(process?.env?.REACT_APP_PUBLIC_API);

const validateUserInteractions = (values) => {
    const errors = {};
    if (!values?.comment && !values?.technicalComment && !values?.commentForRelative) {
        errors.comment = errors.technicalComment = errors.commentForRelative = 'Mínimo hay que insertar un comentario';
    }
    if (values?.isActivityAssociated && !values?.scheduledActivityId) {
        errors.scheduledActivityId = 'Debes seleccionar una actividad agendada si la interacción está asociada a actividad';
    }

    return errors
}

export const UserInteractionsList = (props) => {
    const { identity, loading } = useGetIdentity();

    return (
        <div>
            <ReferenceManyField
                addLabel={false}
                reference="userInteractions"
                target="id"
                sort={{ field: 'Id', order: 'DESC' }}
                pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            >
                <Datagrid >
                    <DateField label="Fecha" source="creationDate" sortable={false}></DateField>
                    <FunctionField label="Profesional" cellClassName="colum-inherit" render={(record) => record?.professional?.name + ' ' + record?.professional?.surnames} />
                    {(identity && identity?.roles !== "CONTRACTING_AUTHORITY") &&
                        <RichTextField label="Comentario Actividad" source="activityComment" sortable={false}/>}
                    {(identity && identity?.roles !== "CONTRACTING_AUTHORITY")
                        && <RichTextField label="Detalles" source="comment" sortable={false} />}
                    {(identity && identity?.roles !== "CONTRACTING_AUTHORITY") && <RichTextField label="Juicio técnico" source="technicalComment" sortable={false} />}
                    {identity && identity?.roles === "CONTRACTING_AUTHORITY" && <RichTextField label="Terceras partes" source="commentForRelative" sortable={false} />}
                    <EditButtonWrapper {...props} identity={identity} loading={loading} />
                    <DeleteButtonWrapper {...props} identity={identity} loading={loading} />
                </Datagrid>
            </ReferenceManyField>
            {
                props?.permissions?.userInteractions?.create ?
                    <AddNewUserInteractionsButton {...props} />
                    : null
            }
        </div >
    )
}

export const EditButtonWrapper = (props) => {
    const record = useRecordContext(props);
    if (props?.loading) return null
    if ((props?.permissions && props?.permissions?.userInteractions?.edit) || record?.professional?.id === props?.identity?.professionalId) {
        return <EditUserInteractionsButton {...props} />
    } else {
        return null
    }
}

export const DeleteButtonWrapper = (props) => {
    const record = useRecordContext(props);
    if (props?.loading) return null
    if ((props?.permissions && props?.permissions?.userInteractions?.delete) || record?.professional?.id === props?.identity?.professionalId) {
        return <DeleteUserInteractionsButton {...props} />
    } else {
        return null
    }
}

export const UserInteractionsCreate = (props) => {
    const { loading } = useGetIdentity();
    // Read the post_id from the location
    const location = useLocation();
    const post_id =
        location?.state && location?.state?.record
            ? location?.state?.record?.clientId
            : undefined;

    const redirect = post_id ? `/users/${post_id}/5` : false;

    if (loading) return null;
    return (
        <Create mutationMode="pessimistic" {...props}>
            {UserInteractionsForm(redirect)}
        </Create>
    )
};

export const UserInteractionsEdit = (props) => {
    const { identity, loading } = useGetIdentity();
    // Read the post_id from the location
    const location = useLocation();
    const post_id =
        location?.state && location?.state?.record
            ? location?.state?.record?.clientId
            : undefined;

    const redirect = post_id ? `/users/${post_id}/5` : false;

    if (loading) return null;
    return (
        <Edit mutationMode="pessimistic" transform={(values) => TransformUserInteractionsEdit(values, identity)} {...props}>
            {UserInteractionsForm(redirect)}
        </Edit>
    )
};

const StarPickerMod = (props) => {
    const {
        input: { name, onChange, ...rest }
    } = useInput(props);
    return <StarPicker name={name} numberStars={6} onChange={onChange} {...rest} doubleTapResets />;
};

const UserInteractionsForm = (props) => {
    const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ 'color': [] }, { 'background': [] }],]
    const [isActivityAssociated, setIsActivityAssociated] = useState(props?.initialValues ? props?.initialValues?.scheduledActivityId ? true : false : false);

    const toggleAssociated = (value) => {
        setIsActivityAssociated(value);
    };

    const [generalComment, setGeneralComment] = useState();

    
    useEffect( () => {
        if (isActivityAssociated){
            scheduledActivityapiClient?.scheduledActivityGet(props.initialValues?.scheduledActivityId)
            .then(res => setGeneralComment(res?.comments))
        }
        
        // eslint-disable-next-line
    },[])


    return (
        <FormWithRedirect
            resource="userDocuments"
            initialValues={props?.initialValues}
            save={props?.save}
            validate={validateUserInteractions}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                    <DialogContent>
                        {
                            isActivityAssociated ? 
                                <>
                                    <FieldTitle label="Feedback de la Sesión"/>
                                    <ReactQuill label="Comentario" readOnly={true} value={generalComment} /*onChange={(val) => onChangeGeneralComment(val)}*/ className='generalCommentQuill' />
                                </>
                            : null
                        }
                        {(props?.identity && props?.identity?.roles !== "CONTRACTING_AUTHORITY") &&
                            <div className="richText-container">
                                <RichTextInput toolbar={toolbarOptions} label="Detalles" source="comment" />
                                <RichTextInput toolbar={toolbarOptions} label="Juicio técnico" source="technicalComment" />
                            </div>
                        }
                        <div className="lonelyRichText">
                            <RichTextInput fullWidth={true} toolbar={toolbarOptions} label="Información visible por terceras partes" source="commentForRelative" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            {/* <FormControlLabel control={<Switch checked={isActivityAssociated} onChange={toggleAssociated} />} label="Asociado a actividad" /> */}
                            <BooleanInput label="Asociado a actividad" source="isActivityAssociated" onChange={toggleAssociated} defaultValue={props?.initialValues ? props?.initialValues?.scheduledActivityId ? true : false : false} />
                            {isActivityAssociated && <ActivityScheduledSelectorInput />}
                        </div>
                        <ActivityRatingSelectorInput></ActivityRatingSelectorInput>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label="ra.action.cancel"
                            onClick={props?.handleCloseClick}
                            disabled={props?.loading}
                        >
                            <CloseIcon />
                        </Button>
                        <SaveButton
                            handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                            pristine={pristine}
                            saving={saving}
                            disabled={props?.loading}
                        />
                    </DialogActions>
                </>
            )}
        />
    )
}


const ActivityScheduledSelectorInput = props => {
    const { values } = useFormState();
    const record = useRecordContext();
    const [activityListValue, setactivityListValue] = useState();
    const [scheduledActivityListValue, setscheduledActivityListValue] = useState();
    const [disabledMoreThan30, setDisabledMoreThan30] = useState(false);

    useEffect(() => {
        if (values?.client && values?.client?.id) {
            activityApiClient?.list({
                clientId: values?.client?.id,
                filteredForInput: true,
                pageSize: 350
            }).then(res => {
                setactivityListValue(res?.elements);
            })
        } else if (record && record?.id) {
            activityApiClient.list({
                clientId: record?.id,
                filteredForInput: true,
            }).then(res => {
                setactivityListValue(res?.elements);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!values?.activityId) return
        
        
        scheduledActivityapiClient?.list({
            activityId: values?.activityId,
            //filteredForInput: true,
            origin: "BackOffice",
            pageSize: 1000
        }).then(res => {
            let seleccionado = res?.elements?.filter(s => s?.id === values?.scheduledActivityId)
            if (seleccionado && seleccionado?.length > 0){
                if (moment(seleccionado[0]?.startDate).isBefore(moment().add(-30, 'days'))){
                    setDisabledMoreThan30(true)
                }
            }
            setscheduledActivityListValue(res?.elements);
        })
        // eslint-disable-next-line
    }, [values?.activityId])

    // if (!values.scheduledActivityId) return null

    return (
        <div className="simple-container-bottom">
            <AutocompleteInput
                label="Actividad"
                source="activityId"
                choices={activityListValue}
                disabled={disabledMoreThan30}
                optionText="name"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                resettable={true}
            />
            {
                values?.activityId &&
                <AutocompleteInput
                    label="Actividad agendada"
                    source="scheduledActivityId"
                    choices={scheduledActivityListValue}
                    disabled={disabledMoreThan30}
                    optionText={(record) => moment(record?.startDate).calendar(null, {
                        sameElse: 'DD/MM/YYYY HH:mm'
                    })}
                    optionValue="id"
                    options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                    resettable={true}
                    translateChoice={false}
                />
            }

        </div>
    );
};


const ActivityRatingSelectorInput = props => {
    const { values } = useFormState();

    const [moodRating, setMoodRating] = useState(props?.initialValues ? props?.initialValues?.mood : 0);
    const [healthConditionRating, setHealthConditionRating] = useState(props?.initialValues ? props?.initialValues?.healthCondition : 0);
    const [feedbackRating, setFeedbackRating] = useState(props?.initialValues ? props?.initialValues?.feedbackMark : 0);

    const onStarClickMood = (nextValue) => {
        setMoodRating(nextValue);
    };
    const onStarClickHealth = (nextValue) => {
        setHealthConditionRating(nextValue);
    };
    const onStarClickFeedback = (nextValue) => {
        setFeedbackRating(nextValue);
    };

    // if (!values.scheduledActivityId) return null

    return values.scheduledActivityId ?
        <>
            <div style={{ display: 'flex' }}>
                <Labeled label="Estado de ánimo">
                    <StarPickerMod style={{ marginTop: 20 }} onChange={onStarClickMood} value={moodRating} source="mood" />
                </Labeled>
                <Labeled label="Feedback sesión">
                    <StarPickerMod style={{ marginTop: 20 }} onChange={onStarClickFeedback} value={feedbackRating} source="feedbackMark" />
                </Labeled>
            </div>
            <div>
                <Labeled label="Estado físico">
                    <StarPickerMod style={{ marginTop: 20 }} onChange={onStarClickHealth} value={healthConditionRating} source="healthCondition" />
                </Labeled>
            </div>

        </> : null
};

// const PostCreateToolbar = props => {
//     return (
//         <Toolbar {...props} >
//             <SaveButton
//                 label="Guardar"
//                 redirect={props.redirect}
//                 submitOnEnter={true}
//             />
//             <Button
//                 label="Volver"
//                 component={Link}
//                 to={{
//                     pathname: props.redirect,
//                     // Here we specify the initial record for the create view
//                 }}
//             ></Button>
//         </Toolbar>
//     )
// };

const TransformUserInteractionsEdit = (values, identity) => {
    if (!values.isActivityAssociated) {
        values.scheduledActivityId = null;
        values.mood = 0;
        values.feedbackMark = 0;
        values.healthCondition = 0;
    }
    // values.professionalId = values.professional ? values.professional.id : identity.id;
    values.creationDate = values?.creationDate ? moment(values?.creationDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    values.clientId = values?.client?.id;
    return values;
}

// const TransformUserInteractionsBase64 = (values, identity) => {
//     // values.professionalId = values.professional ? values.professional.id : identity.id;
//     values.comment = values.comment ? btoaReplacement(values.comment) : null
//     values.technicalComment = values.technicalComment ? btoaReplacement(values.technicalComment) : null
//     return values;
// }

export const AddNewUserInteractionsButton = (props) => {

    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [create, { loading }] = useCreate('userInteractions');

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            // { payload: { data: TransformUserInteractionsBase64({ ...values, clientId: props.record.id }) } },
            { payload: { data: { ...values, clientId: props?.record?.id } } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar interacción"
            >
                <AddIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar interacciones es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir interacción</DialogTitle>
                <DialogContent>
                    <FormWithRedirect
                        resource="userDocuments"
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving
                        }) => (
                            <>
                                <DialogContent>
                                    <UserInteractionsForm
                                        save={handleSubmit}
                                        mode="create"
                                        loading={loading}
                                        handleCloseClick={handleCloseClick}
                                        identity={identity}
                                        {...props}
                                    ></UserInteractionsForm>
                                </DialogContent>

                            </>
                        )}
                    />
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

const DeleteUserInteractionsButton = ({ record }) => {
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar interacciones es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <DeleteButton
                mutationMode="pessimistic"
                resource="userInteractions"
                redirect={"/users/" + record?.client?.id + '/4'}
                record={record}
                label="Borrar"
            >
                <AccountCircleIcon />
            </DeleteButton>
        )
    }
};

const EditUserInteractionsButton = ({ record, props }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [edit, { loading }] = useUpdate('userInteractions');

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            // { payload: { data: TransformUserInteractionsBase64(TransformUserInteractionsEdit(values)) } },
            { payload: { data: TransformUserInteractionsEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error?.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!record) return null
    return (
        <>
            <Button
                onClick={handleClick}
                label="Editar"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Editar interacción</DialogTitle>
                <DialogContent>
                    <UserInteractionsForm
                        save={handleSubmit}
                        mode="edit"
                        loading={loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                        identity={identity}
                        {...props}
                    ></UserInteractionsForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};
