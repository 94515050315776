import { ContractClient } from "../services/ohl.virtualCenter.api";
import moment from 'moment';
const apiClient = new ContractClient(process.env.REACT_APP_PUBLIC_API);

class ContractsProvider {

    getOne = (resource, params) => {
        return apiClient.contractGet(params.id).then((data) => {
            if (data.extension) {
                let a = moment(data.extensionDuration)
                let b = moment(data.endDate)
                data.extensionDurationMonths = a.diff(b, 'months')
            }
            return { data }
        });
    }

    getLogos = (resource, params) => {
        return apiClient.logos(params.contractId).then((data) => {
            return { data: data }
        });
    }

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if (params.filter && params.filter.initialDate) {
            params.filter.initialDate = moment(params.filter.initialDate).format('yyyy-MM-DDTHH:mm:ss')
        }
        if (params.filter && params.filter.endDate) {
            params.filter.endDate = moment(params.filter.endDate).format('yyyy-MM-DDTHH:mm:ss')
        }

        if(params.filter && params.filter.q) params.filter.contractNumber = params.filter.q;

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    update = (resource, params) => {
        return apiClient.contractPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.contractPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.contractDelete(params.id)
            .then(response => ({
                data: params.previousData
            }))
            .catch(responseError => {
                if (responseError?.error && responseError?.error?.code === 4001) {
                    throw new Error(responseError?.error?.message ? responseError.error.message : "Error de comunicación con el servidor");
                }
            });
    }
};

export default new ContractsProvider();