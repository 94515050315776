
class RolePermission {
    constructor(permisions) {
        Object.entries(resources).forEach((r) => {
            permisions.forEach((p) => {
                if (p instanceof r[1]) {
                    this[r[0]] = p
                }
            })
            if (!this[r[0]]) this[r[0]] = new r[1]()
        })
    }
}

class Permission {
    constructor({ enabled = false, list = false, create = false, edit = false, show = false, del = false } = {}) {
        this.enabled = !!enabled
        this.list = !!list
        this.create = !!create
        this.edit = !!edit
        this.show = !!show
        this.delete = !!del
    }
}

class UsersPermissions extends Permission { }
class HomePermissions extends Permission { }
class AdminsPermissions extends Permission { }
class ProfessionalPermissions extends Permission { }
class ActivitiesPermissions extends Permission { }
class ScheduledActivitiesPermissions extends Permission { }
class CalendarPermissions extends Permission { }
class RecordsPermissions extends Permission { }
class InstallationsPermissions extends Permission { }
class DevicesPermissions extends Permission { }
class ComunicationDevicesPermissions extends Permission { }
class CameraDevicesPermissions extends Permission { }
class ContactsPermissions extends Permission { }
class ContractsPermissions extends Permission { }
class ContractingAuthoritiesPermissions extends Permission { }
class UserHistoryPermissions extends Permission { }
class UserInteractionsPermissions extends Permission { }
class UserDocumentsPermissions extends Permission { }
class ScheduledActivitiesOldPermissions extends Permission { }
class MasterTablePermissions extends Permission { }
class SuperAdminsPermissions extends Permission { }
class HousingPlanDocumentsPermissions extends Permission { }

const resources = {
    'home': HomePermissions,
    'users': UsersPermissions,
    'admins': AdminsPermissions,
    'superAdmins': SuperAdminsPermissions,
    'professional': ProfessionalPermissions,
    'activities': ActivitiesPermissions,
    'scheduledActivities': ScheduledActivitiesPermissions,
    'calendar': CalendarPermissions,
    'records': RecordsPermissions,
    'installations': InstallationsPermissions,
    'devices': DevicesPermissions,
    'comunicationDevices': ComunicationDevicesPermissions,
    'cameraDevices': CameraDevicesPermissions,
    'contacts': ContactsPermissions,
    'userHistory': UserHistoryPermissions,
    'userInteractions': UserInteractionsPermissions,
    'userDocuments': UserDocumentsPermissions,
    'scheduledActivitiesOld': ScheduledActivitiesOldPermissions,
    'masterTable': MasterTablePermissions,
    'contracts': ContractsPermissions,
    'contractingAuthorities': ContractingAuthoritiesPermissions,
    'housingPlanDocuments':HousingPlanDocumentsPermissions
}

export {
    RolePermission, UsersPermissions, AdminsPermissions, ProfessionalPermissions, ActivitiesPermissions,
    ScheduledActivitiesPermissions, DevicesPermissions, ComunicationDevicesPermissions, ContactsPermissions,
    UserHistoryPermissions, UserInteractionsPermissions, UserDocumentsPermissions, ScheduledActivitiesOldPermissions,
    MasterTablePermissions, ContractsPermissions, ContractingAuthoritiesPermissions, SuperAdminsPermissions,
    InstallationsPermissions, HousingPlanDocumentsPermissions, CameraDevicesPermissions, RecordsPermissions,
    CalendarPermissions, HomePermissions
}