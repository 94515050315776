export const iconListMaterial = [
    {
        "value": "ab-testing"
    },
    {
        "value": "abacus"
    },
    {
        "value": "abjad-arabic"
    },
    {
        "value": "abjad-hebrew"
    },
    {
        "value": "abugida-devanagari"
    },
    {
        "value": "abugida-thai"
    },
    {
        "value": "access-point"
    },
    {
        "value": "access-point-check"
    },
    {
        "value": "access-point-minus"
    },
    {
        "value": "access-point-network"
    },
    {
        "value": "access-point-network-off"
    },
    {
        "value": "access-point-off"
    },
    {
        "value": "access-point-plus"
    },
    {
        "value": "access-point-remove"
    },
    {
        "value": "account"
    },
    {
        "value": "account-alert"
    },
    {
        "value": "account-alert-outline"
    },
    {
        "value": "account-arrow-left"
    },
    {
        "value": "account-arrow-left-outline"
    },
    {
        "value": "account-arrow-right"
    },
    {
        "value": "account-arrow-right-outline"
    },
    {
        "value": "account-box"
    },
    {
        "value": "account-box-multiple"
    },
    {
        "value": "account-box-multiple-outline"
    },
    {
        "value": "account-box-outline"
    },
    {
        "value": "account-cancel"
    },
    {
        "value": "account-cancel-outline"
    },
    {
        "value": "account-cash"
    },
    {
        "value": "account-cash-outline"
    },
    {
        "value": "account-check"
    },
    {
        "value": "account-check-outline"
    },
    {
        "value": "account-child"
    },
    {
        "value": "account-child-circle"
    },
    {
        "value": "account-child-outline"
    },
    {
        "value": "account-circle"
    },
    {
        "value": "account-circle-outline"
    },
    {
        "value": "account-clock"
    },
    {
        "value": "account-clock-outline"
    },
    {
        "value": "account-cog"
    },
    {
        "value": "account-cog-outline"
    },
    {
        "value": "account-convert"
    },
    {
        "value": "account-convert-outline"
    },
    {
        "value": "account-cowboy-hat"
    },
    {
        "value": "account-details"
    },
    {
        "value": "account-details-outline"
    },
    {
        "value": "account-edit"
    },
    {
        "value": "account-edit-outline"
    },
    {
        "value": "account-eye"
    },
    {
        "value": "account-eye-outline"
    },
    {
        "value": "account-filter"
    },
    {
        "value": "account-filter-outline"
    },
    {
        "value": "account-group"
    },
    {
        "value": "account-group-outline"
    },
    {
        "value": "account-hard-hat"
    },
    {
        "value": "account-heart"
    },
    {
        "value": "account-heart-outline"
    },
    {
        "value": "account-key"
    },
    {
        "value": "account-key-outline"
    },
    {
        "value": "account-lock"
    },
    {
        "value": "account-lock-outline"
    },
    {
        "value": "account-minus"
    },
    {
        "value": "account-minus-outline"
    },
    {
        "value": "account-multiple"
    },
    {
        "value": "account-multiple-check"
    },
    {
        "value": "account-multiple-check-outline"
    },
    {
        "value": "account-multiple-minus"
    },
    {
        "value": "account-multiple-minus-outline"
    },
    {
        "value": "account-multiple-outline"
    },
    {
        "value": "account-multiple-plus"
    },
    {
        "value": "account-multiple-plus-outline"
    },
    {
        "value": "account-multiple-remove"
    },
    {
        "value": "account-multiple-remove-outline"
    },
    {
        "value": "account-music"
    },
    {
        "value": "account-music-outline"
    },
    {
        "value": "account-network"
    },
    {
        "value": "account-network-outline"
    },
    {
        "value": "account-off"
    },
    {
        "value": "account-off-outline"
    },
    {
        "value": "account-outline"
    },
    {
        "value": "account-plus"
    },
    {
        "value": "account-plus-outline"
    },
    {
        "value": "account-question"
    },
    {
        "value": "account-question-outline"
    },
    {
        "value": "account-reactivate"
    },
    {
        "value": "account-reactivate-outline"
    },
    {
        "value": "account-remove"
    },
    {
        "value": "account-remove-outline"
    },
    {
        "value": "account-search"
    },
    {
        "value": "account-search-outline"
    },
    {
        "value": "account-settings"
    },
    {
        "value": "account-settings-outline"
    },
    {
        "value": "account-star"
    },
    {
        "value": "account-star-outline"
    },
    {
        "value": "account-supervisor"
    },
    {
        "value": "account-supervisor-circle"
    },
    {
        "value": "account-supervisor-circle-outline"
    },
    {
        "value": "account-supervisor-outline"
    },
    {
        "value": "account-switch"
    },
    {
        "value": "account-switch-outline"
    },
    {
        "value": "account-tie"
    },
    {
        "value": "account-tie-outline"
    },
    {
        "value": "account-tie-voice"
    },
    {
        "value": "account-tie-voice-off"
    },
    {
        "value": "account-tie-voice-off-outline"
    },
    {
        "value": "account-tie-voice-outline"
    },
    {
        "value": "account-voice"
    },
    {
        "value": "account-voice-off"
    },
    {
        "value": "adjust"
    },
    {
        "value": "air-conditioner"
    },
    {
        "value": "air-filter"
    },
    {
        "value": "air-horn"
    },
    {
        "value": "air-humidifier"
    },
    {
        "value": "air-humidifier-off"
    },
    {
        "value": "air-purifier"
    },
    {
        "value": "airbag"
    },
    {
        "value": "airballoon"
    },
    {
        "value": "airballoon-outline"
    },
    {
        "value": "airplane"
    },
    {
        "value": "airplane-landing"
    },
    {
        "value": "airplane-off"
    },
    {
        "value": "airplane-takeoff"
    },
    {
        "value": "airport"
    },
    {
        "value": "alarm"
    },
    {
        "value": "alarm-bell"
    },
    {
        "value": "alarm-check"
    },
    {
        "value": "alarm-light"
    },
    {
        "value": "alarm-light-off"
    },
    {
        "value": "alarm-light-off-outline"
    },
    {
        "value": "alarm-light-outline"
    },
    {
        "value": "alarm-multiple"
    },
    {
        "value": "alarm-note"
    },
    {
        "value": "alarm-note-off"
    },
    {
        "value": "alarm-off"
    },
    {
        "value": "alarm-panel"
    },
    {
        "value": "alarm-panel-outline"
    },
    {
        "value": "alarm-plus"
    },
    {
        "value": "alarm-snooze"
    },
    {
        "value": "album"
    },
    {
        "value": "alert"
    },
    {
        "value": "alert-box"
    },
    {
        "value": "alert-box-outline"
    },
    {
        "value": "alert-circle"
    },
    {
        "value": "alert-circle-check"
    },
    {
        "value": "alert-circle-check-outline"
    },
    {
        "value": "alert-circle-outline"
    },
    {
        "value": "alert-decagram"
    },
    {
        "value": "alert-decagram-outline"
    },
    {
        "value": "alert-minus"
    },
    {
        "value": "alert-minus-outline"
    },
    {
        "value": "alert-octagon"
    },
    {
        "value": "alert-octagon-outline"
    },
    {
        "value": "alert-octagram"
    },
    {
        "value": "alert-octagram-outline"
    },
    {
        "value": "alert-outline"
    },
    {
        "value": "alert-plus"
    },
    {
        "value": "alert-plus-outline"
    },
    {
        "value": "alert-remove"
    },
    {
        "value": "alert-remove-outline"
    },
    {
        "value": "alert-rhombus"
    },
    {
        "value": "alert-rhombus-outline"
    },
    {
        "value": "alien"
    },
    {
        "value": "alien-outline"
    },
    {
        "value": "align-horizontal-center"
    },
    {
        "value": "align-horizontal-left"
    },
    {
        "value": "align-horizontal-right"
    },
    {
        "value": "align-vertical-bottom"
    },
    {
        "value": "align-vertical-center"
    },
    {
        "value": "align-vertical-top"
    },
    {
        "value": "all-inclusive"
    },
    {
        "value": "allergy"
    },
    {
        "value": "alpha"
    },
    {
        "value": "alpha-a"
    },
    {
        "value": "alpha-a-box"
    },
    {
        "value": "alpha-a-box-outline"
    },
    {
        "value": "alpha-a-circle"
    },
    {
        "value": "alpha-a-circle-outline"
    },
    {
        "value": "alpha-b"
    },
    {
        "value": "alpha-b-box"
    },
    {
        "value": "alpha-b-box-outline"
    },
    {
        "value": "alpha-b-circle"
    },
    {
        "value": "alpha-b-circle-outline"
    },
    {
        "value": "alpha-c"
    },
    {
        "value": "alpha-c-box"
    },
    {
        "value": "alpha-c-box-outline"
    },
    {
        "value": "alpha-c-circle"
    },
    {
        "value": "alpha-c-circle-outline"
    },
    {
        "value": "alpha-d"
    },
    {
        "value": "alpha-d-box"
    },
    {
        "value": "alpha-d-box-outline"
    },
    {
        "value": "alpha-d-circle"
    },
    {
        "value": "alpha-d-circle-outline"
    },
    {
        "value": "alpha-e"
    },
    {
        "value": "alpha-e-box"
    },
    {
        "value": "alpha-e-box-outline"
    },
    {
        "value": "alpha-e-circle"
    },
    {
        "value": "alpha-e-circle-outline"
    },
    {
        "value": "alpha-f"
    },
    {
        "value": "alpha-f-box"
    },
    {
        "value": "alpha-f-box-outline"
    },
    {
        "value": "alpha-f-circle"
    },
    {
        "value": "alpha-f-circle-outline"
    },
    {
        "value": "alpha-g"
    },
    {
        "value": "alpha-g-box"
    },
    {
        "value": "alpha-g-box-outline"
    },
    {
        "value": "alpha-g-circle"
    },
    {
        "value": "alpha-g-circle-outline"
    },
    {
        "value": "alpha-h"
    },
    {
        "value": "alpha-h-box"
    },
    {
        "value": "alpha-h-box-outline"
    },
    {
        "value": "alpha-h-circle"
    },
    {
        "value": "alpha-h-circle-outline"
    },
    {
        "value": "alpha-i"
    },
    {
        "value": "alpha-i-box"
    },
    {
        "value": "alpha-i-box-outline"
    },
    {
        "value": "alpha-i-circle"
    },
    {
        "value": "alpha-i-circle-outline"
    },
    {
        "value": "alpha-j"
    },
    {
        "value": "alpha-j-box"
    },
    {
        "value": "alpha-j-box-outline"
    },
    {
        "value": "alpha-j-circle"
    },
    {
        "value": "alpha-j-circle-outline"
    },
    {
        "value": "alpha-k"
    },
    {
        "value": "alpha-k-box"
    },
    {
        "value": "alpha-k-box-outline"
    },
    {
        "value": "alpha-k-circle"
    },
    {
        "value": "alpha-k-circle-outline"
    },
    {
        "value": "alpha-l"
    },
    {
        "value": "alpha-l-box"
    },
    {
        "value": "alpha-l-box-outline"
    },
    {
        "value": "alpha-l-circle"
    },
    {
        "value": "alpha-l-circle-outline"
    },
    {
        "value": "alpha-m"
    },
    {
        "value": "alpha-m-box"
    },
    {
        "value": "alpha-m-box-outline"
    },
    {
        "value": "alpha-m-circle"
    },
    {
        "value": "alpha-m-circle-outline"
    },
    {
        "value": "alpha-n"
    },
    {
        "value": "alpha-n-box"
    },
    {
        "value": "alpha-n-box-outline"
    },
    {
        "value": "alpha-n-circle"
    },
    {
        "value": "alpha-n-circle-outline"
    },
    {
        "value": "alpha-o"
    },
    {
        "value": "alpha-o-box"
    },
    {
        "value": "alpha-o-box-outline"
    },
    {
        "value": "alpha-o-circle"
    },
    {
        "value": "alpha-o-circle-outline"
    },
    {
        "value": "alpha-p"
    },
    {
        "value": "alpha-p-box"
    },
    {
        "value": "alpha-p-box-outline"
    },
    {
        "value": "alpha-p-circle"
    },
    {
        "value": "alpha-p-circle-outline"
    },
    {
        "value": "alpha-q"
    },
    {
        "value": "alpha-q-box"
    },
    {
        "value": "alpha-q-box-outline"
    },
    {
        "value": "alpha-q-circle"
    },
    {
        "value": "alpha-q-circle-outline"
    },
    {
        "value": "alpha-r"
    },
    {
        "value": "alpha-r-box"
    },
    {
        "value": "alpha-r-box-outline"
    },
    {
        "value": "alpha-r-circle"
    },
    {
        "value": "alpha-r-circle-outline"
    },
    {
        "value": "alpha-s"
    },
    {
        "value": "alpha-s-box"
    },
    {
        "value": "alpha-s-box-outline"
    },
    {
        "value": "alpha-s-circle"
    },
    {
        "value": "alpha-s-circle-outline"
    },
    {
        "value": "alpha-t"
    },
    {
        "value": "alpha-t-box"
    },
    {
        "value": "alpha-t-box-outline"
    },
    {
        "value": "alpha-t-circle"
    },
    {
        "value": "alpha-t-circle-outline"
    },
    {
        "value": "alpha-u"
    },
    {
        "value": "alpha-u-box"
    },
    {
        "value": "alpha-u-box-outline"
    },
    {
        "value": "alpha-u-circle"
    },
    {
        "value": "alpha-u-circle-outline"
    },
    {
        "value": "alpha-v"
    },
    {
        "value": "alpha-v-box"
    },
    {
        "value": "alpha-v-box-outline"
    },
    {
        "value": "alpha-v-circle"
    },
    {
        "value": "alpha-v-circle-outline"
    },
    {
        "value": "alpha-w"
    },
    {
        "value": "alpha-w-box"
    },
    {
        "value": "alpha-w-box-outline"
    },
    {
        "value": "alpha-w-circle"
    },
    {
        "value": "alpha-w-circle-outline"
    },
    {
        "value": "alpha-x"
    },
    {
        "value": "alpha-x-box"
    },
    {
        "value": "alpha-x-box-outline"
    },
    {
        "value": "alpha-x-circle"
    },
    {
        "value": "alpha-x-circle-outline"
    },
    {
        "value": "alpha-y"
    },
    {
        "value": "alpha-y-box"
    },
    {
        "value": "alpha-y-box-outline"
    },
    {
        "value": "alpha-y-circle"
    },
    {
        "value": "alpha-y-circle-outline"
    },
    {
        "value": "alpha-z"
    },
    {
        "value": "alpha-z-box"
    },
    {
        "value": "alpha-z-box-outline"
    },
    {
        "value": "alpha-z-circle"
    },
    {
        "value": "alpha-z-circle-outline"
    },
    {
        "value": "alphabet-aurebesh"
    },
    {
        "value": "alphabet-cyrillic"
    },
    {
        "value": "alphabet-greek"
    },
    {
        "value": "alphabet-latin"
    },
    {
        "value": "alphabet-piqad"
    },
    {
        "value": "alphabet-tengwar"
    },
    {
        "value": "alphabetical"
    },
    {
        "value": "alphabetical-off"
    },
    {
        "value": "alphabetical-variant"
    },
    {
        "value": "alphabetical-variant-off"
    },
    {
        "value": "altimeter"
    },
    {
        "value": "amazon"
    },
    {
        "value": "amazon-alexa"
    },
    {
        "value": "ambulance"
    },
    {
        "value": "ammunition"
    },
    {
        "value": "ampersand"
    },
    {
        "value": "amplifier"
    },
    {
        "value": "amplifier-off"
    },
    {
        "value": "anchor"
    },
    {
        "value": "android"
    },
    {
        "value": "android-auto"
    },
    {
        "value": "android-debug-bridge"
    },
    {
        "value": "android-messages"
    },
    {
        "value": "android-studio"
    },
    {
        "value": "angle-acute"
    },
    {
        "value": "angle-obtuse"
    },
    {
        "value": "angle-right"
    },
    {
        "value": "angular"
    },
    {
        "value": "angularjs"
    },
    {
        "value": "animation"
    },
    {
        "value": "animation-outline"
    },
    {
        "value": "animation-play"
    },
    {
        "value": "animation-play-outline"
    },
    {
        "value": "ansible"
    },
    {
        "value": "antenna"
    },
    {
        "value": "anvil"
    },
    {
        "value": "apache-kafka"
    },
    {
        "value": "api"
    },
    {
        "value": "api-off"
    },
    {
        "value": "apple"
    },
    {
        "value": "apple-finder"
    },
    {
        "value": "apple-icloud"
    },
    {
        "value": "apple-ios"
    },
    {
        "value": "apple-keyboard-caps"
    },
    {
        "value": "apple-keyboard-command"
    },
    {
        "value": "apple-keyboard-control"
    },
    {
        "value": "apple-keyboard-option"
    },
    {
        "value": "apple-keyboard-shift"
    },
    {
        "value": "apple-safari"
    },
    {
        "value": "application"
    },
    {
        "value": "application-cog"
    },
    {
        "value": "application-export"
    },
    {
        "value": "application-import"
    },
    {
        "value": "application-settings"
    },
    {
        "value": "approximately-equal"
    },
    {
        "value": "approximately-equal-box"
    },
    {
        "value": "apps"
    },
    {
        "value": "apps-box"
    },
    {
        "value": "arch"
    },
    {
        "value": "archive"
    },
    {
        "value": "archive-alert"
    },
    {
        "value": "archive-alert-outline"
    },
    {
        "value": "archive-arrow-down"
    },
    {
        "value": "archive-arrow-down-outline"
    },
    {
        "value": "archive-arrow-up"
    },
    {
        "value": "archive-arrow-up-outline"
    },
    {
        "value": "archive-cancel"
    },
    {
        "value": "archive-cancel-outline"
    },
    {
        "value": "archive-check"
    },
    {
        "value": "archive-check-outline"
    },
    {
        "value": "archive-clock"
    },
    {
        "value": "archive-clock-outline"
    },
    {
        "value": "archive-cog"
    },
    {
        "value": "archive-cog-outline"
    },
    {
        "value": "archive-edit"
    },
    {
        "value": "archive-edit-outline"
    },
    {
        "value": "archive-eye"
    },
    {
        "value": "archive-eye-outline"
    },
    {
        "value": "archive-lock"
    },
    {
        "value": "archive-lock-open"
    },
    {
        "value": "archive-lock-open-outline"
    },
    {
        "value": "archive-lock-outline"
    },
    {
        "value": "archive-marker"
    },
    {
        "value": "archive-marker-outline"
    },
    {
        "value": "archive-minus"
    },
    {
        "value": "archive-minus-outline"
    },
    {
        "value": "archive-music"
    },
    {
        "value": "archive-music-outline"
    },
    {
        "value": "archive-off"
    },
    {
        "value": "archive-off-outline"
    },
    {
        "value": "archive-outline"
    },
    {
        "value": "archive-plus"
    },
    {
        "value": "archive-plus-outline"
    },
    {
        "value": "archive-refresh"
    },
    {
        "value": "archive-refresh-outline"
    },
    {
        "value": "archive-remove"
    },
    {
        "value": "archive-remove-outline"
    },
    {
        "value": "archive-search"
    },
    {
        "value": "archive-search-outline"
    },
    {
        "value": "archive-settings"
    },
    {
        "value": "archive-settings-outline"
    },
    {
        "value": "archive-star"
    },
    {
        "value": "archive-star-outline"
    },
    {
        "value": "archive-sync"
    },
    {
        "value": "archive-sync-outline"
    },
    {
        "value": "arm-flex"
    },
    {
        "value": "arm-flex-outline"
    },
    {
        "value": "arrange-bring-forward"
    },
    {
        "value": "arrange-bring-to-front"
    },
    {
        "value": "arrange-send-backward"
    },
    {
        "value": "arrange-send-to-back"
    },
    {
        "value": "arrow-all"
    },
    {
        "value": "arrow-bottom-left"
    },
    {
        "value": "arrow-bottom-left-bold-outline"
    },
    {
        "value": "arrow-bottom-left-thick"
    },
    {
        "value": "arrow-bottom-left-thin-circle-outline"
    },
    {
        "value": "arrow-bottom-right"
    },
    {
        "value": "arrow-bottom-right-bold-outline"
    },
    {
        "value": "arrow-bottom-right-thick"
    },
    {
        "value": "arrow-bottom-right-thin-circle-outline"
    },
    {
        "value": "arrow-collapse"
    },
    {
        "value": "arrow-collapse-all"
    },
    {
        "value": "arrow-collapse-down"
    },
    {
        "value": "arrow-collapse-horizontal"
    },
    {
        "value": "arrow-collapse-left"
    },
    {
        "value": "arrow-collapse-right"
    },
    {
        "value": "arrow-collapse-up"
    },
    {
        "value": "arrow-collapse-vertical"
    },
    {
        "value": "arrow-decision"
    },
    {
        "value": "arrow-decision-auto"
    },
    {
        "value": "arrow-decision-auto-outline"
    },
    {
        "value": "arrow-decision-outline"
    },
    {
        "value": "arrow-down"
    },
    {
        "value": "arrow-down-bold"
    },
    {
        "value": "arrow-down-bold-box"
    },
    {
        "value": "arrow-down-bold-box-outline"
    },
    {
        "value": "arrow-down-bold-circle"
    },
    {
        "value": "arrow-down-bold-circle-outline"
    },
    {
        "value": "arrow-down-bold-hexagon-outline"
    },
    {
        "value": "arrow-down-bold-outline"
    },
    {
        "value": "arrow-down-box"
    },
    {
        "value": "arrow-down-circle"
    },
    {
        "value": "arrow-down-circle-outline"
    },
    {
        "value": "arrow-down-drop-circle"
    },
    {
        "value": "arrow-down-drop-circle-outline"
    },
    {
        "value": "arrow-down-thick"
    },
    {
        "value": "arrow-down-thin-circle-outline"
    },
    {
        "value": "arrow-expand"
    },
    {
        "value": "arrow-expand-all"
    },
    {
        "value": "arrow-expand-down"
    },
    {
        "value": "arrow-expand-horizontal"
    },
    {
        "value": "arrow-expand-left"
    },
    {
        "value": "arrow-expand-right"
    },
    {
        "value": "arrow-expand-up"
    },
    {
        "value": "arrow-expand-vertical"
    },
    {
        "value": "arrow-horizontal-lock"
    },
    {
        "value": "arrow-left"
    },
    {
        "value": "arrow-left-bold"
    },
    {
        "value": "arrow-left-bold-box"
    },
    {
        "value": "arrow-left-bold-box-outline"
    },
    {
        "value": "arrow-left-bold-circle"
    },
    {
        "value": "arrow-left-bold-circle-outline"
    },
    {
        "value": "arrow-left-bold-hexagon-outline"
    },
    {
        "value": "arrow-left-bold-outline"
    },
    {
        "value": "arrow-left-box"
    },
    {
        "value": "arrow-left-circle"
    },
    {
        "value": "arrow-left-circle-outline"
    },
    {
        "value": "arrow-left-drop-circle"
    },
    {
        "value": "arrow-left-drop-circle-outline"
    },
    {
        "value": "arrow-left-right"
    },
    {
        "value": "arrow-left-right-bold"
    },
    {
        "value": "arrow-left-right-bold-outline"
    },
    {
        "value": "arrow-left-thick"
    },
    {
        "value": "arrow-left-thin-circle-outline"
    },
    {
        "value": "arrow-right"
    },
    {
        "value": "arrow-right-bold"
    },
    {
        "value": "arrow-right-bold-box"
    },
    {
        "value": "arrow-right-bold-box-outline"
    },
    {
        "value": "arrow-right-bold-circle"
    },
    {
        "value": "arrow-right-bold-circle-outline"
    },
    {
        "value": "arrow-right-bold-hexagon-outline"
    },
    {
        "value": "arrow-right-bold-outline"
    },
    {
        "value": "arrow-right-box"
    },
    {
        "value": "arrow-right-circle"
    },
    {
        "value": "arrow-right-circle-outline"
    },
    {
        "value": "arrow-right-drop-circle"
    },
    {
        "value": "arrow-right-drop-circle-outline"
    },
    {
        "value": "arrow-right-thick"
    },
    {
        "value": "arrow-right-thin-circle-outline"
    },
    {
        "value": "arrow-split-horizontal"
    },
    {
        "value": "arrow-split-vertical"
    },
    {
        "value": "arrow-top-left"
    },
    {
        "value": "arrow-top-left-bold-outline"
    },
    {
        "value": "arrow-top-left-bottom-right"
    },
    {
        "value": "arrow-top-left-bottom-right-bold"
    },
    {
        "value": "arrow-top-left-thick"
    },
    {
        "value": "arrow-top-left-thin-circle-outline"
    },
    {
        "value": "arrow-top-right"
    },
    {
        "value": "arrow-top-right-bold-outline"
    },
    {
        "value": "arrow-top-right-bottom-left"
    },
    {
        "value": "arrow-top-right-bottom-left-bold"
    },
    {
        "value": "arrow-top-right-thick"
    },
    {
        "value": "arrow-top-right-thin-circle-outline"
    },
    {
        "value": "arrow-up"
    },
    {
        "value": "arrow-up-bold"
    },
    {
        "value": "arrow-up-bold-box"
    },
    {
        "value": "arrow-up-bold-box-outline"
    },
    {
        "value": "arrow-up-bold-circle"
    },
    {
        "value": "arrow-up-bold-circle-outline"
    },
    {
        "value": "arrow-up-bold-hexagon-outline"
    },
    {
        "value": "arrow-up-bold-outline"
    },
    {
        "value": "arrow-up-box"
    },
    {
        "value": "arrow-up-circle"
    },
    {
        "value": "arrow-up-circle-outline"
    },
    {
        "value": "arrow-up-down"
    },
    {
        "value": "arrow-up-down-bold"
    },
    {
        "value": "arrow-up-down-bold-outline"
    },
    {
        "value": "arrow-up-drop-circle"
    },
    {
        "value": "arrow-up-drop-circle-outline"
    },
    {
        "value": "arrow-up-thick"
    },
    {
        "value": "arrow-up-thin-circle-outline"
    },
    {
        "value": "arrow-vertical-lock"
    },
    {
        "value": "artstation"
    },
    {
        "value": "aspect-ratio"
    },
    {
        "value": "assistant"
    },
    {
        "value": "asterisk"
    },
    {
        "value": "at"
    },
    {
        "value": "atlassian"
    },
    {
        "value": "atm"
    },
    {
        "value": "atom"
    },
    {
        "value": "atom-variant"
    },
    {
        "value": "attachment"
    },
    {
        "value": "audio-video"
    },
    {
        "value": "audio-video-off"
    },
    {
        "value": "augmented-reality"
    },
    {
        "value": "auto-download"
    },
    {
        "value": "auto-fix"
    },
    {
        "value": "auto-upload"
    },
    {
        "value": "autorenew"
    },
    {
        "value": "av-timer"
    },
    {
        "value": "aws"
    },
    {
        "value": "axe"
    },
    {
        "value": "axis"
    },
    {
        "value": "axis-arrow"
    },
    {
        "value": "axis-arrow-info"
    },
    {
        "value": "axis-arrow-lock"
    },
    {
        "value": "axis-lock"
    },
    {
        "value": "axis-x-arrow"
    },
    {
        "value": "axis-x-arrow-lock"
    },
    {
        "value": "axis-x-rotate-clockwise"
    },
    {
        "value": "axis-x-rotate-counterclockwise"
    },
    {
        "value": "axis-x-y-arrow-lock"
    },
    {
        "value": "axis-y-arrow"
    },
    {
        "value": "axis-y-arrow-lock"
    },
    {
        "value": "axis-y-rotate-clockwise"
    },
    {
        "value": "axis-y-rotate-counterclockwise"
    },
    {
        "value": "axis-z-arrow"
    },
    {
        "value": "axis-z-arrow-lock"
    },
    {
        "value": "axis-z-rotate-clockwise"
    },
    {
        "value": "axis-z-rotate-counterclockwise"
    },
    {
        "value": "babel"
    },
    {
        "value": "baby"
    },
    {
        "value": "baby-bottle"
    },
    {
        "value": "baby-bottle-outline"
    },
    {
        "value": "baby-buggy"
    },
    {
        "value": "baby-carriage"
    },
    {
        "value": "baby-carriage-off"
    },
    {
        "value": "baby-face"
    },
    {
        "value": "baby-face-outline"
    },
    {
        "value": "backburger"
    },
    {
        "value": "backspace"
    },
    {
        "value": "backspace-outline"
    },
    {
        "value": "backspace-reverse"
    },
    {
        "value": "backspace-reverse-outline"
    },
    {
        "value": "backup-restore"
    },
    {
        "value": "bacteria"
    },
    {
        "value": "bacteria-outline"
    },
    {
        "value": "badge-account"
    },
    {
        "value": "badge-account-alert"
    },
    {
        "value": "badge-account-alert-outline"
    },
    {
        "value": "badge-account-horizontal"
    },
    {
        "value": "badge-account-horizontal-outline"
    },
    {
        "value": "badge-account-outline"
    },
    {
        "value": "badminton"
    },
    {
        "value": "bag-carry-on"
    },
    {
        "value": "bag-carry-on-check"
    },
    {
        "value": "bag-carry-on-off"
    },
    {
        "value": "bag-checked"
    },
    {
        "value": "bag-personal"
    },
    {
        "value": "bag-personal-off"
    },
    {
        "value": "bag-personal-off-outline"
    },
    {
        "value": "bag-personal-outline"
    },
    {
        "value": "bag-suitcase"
    },
    {
        "value": "bag-suitcase-off"
    },
    {
        "value": "bag-suitcase-off-outline"
    },
    {
        "value": "bag-suitcase-outline"
    },
    {
        "value": "baguette"
    },
    {
        "value": "balloon"
    },
    {
        "value": "ballot"
    },
    {
        "value": "ballot-outline"
    },
    {
        "value": "ballot-recount"
    },
    {
        "value": "ballot-recount-outline"
    },
    {
        "value": "bandage"
    },
    {
        "value": "bandcamp"
    },
    {
        "value": "bank"
    },
    {
        "value": "bank-check"
    },
    {
        "value": "bank-minus"
    },
    {
        "value": "bank-off"
    },
    {
        "value": "bank-off-outline"
    },
    {
        "value": "bank-outline"
    },
    {
        "value": "bank-plus"
    },
    {
        "value": "bank-remove"
    },
    {
        "value": "bank-transfer"
    },
    {
        "value": "bank-transfer-in"
    },
    {
        "value": "bank-transfer-out"
    },
    {
        "value": "barcode"
    },
    {
        "value": "barcode-off"
    },
    {
        "value": "barcode-scan"
    },
    {
        "value": "barley"
    },
    {
        "value": "barley-off"
    },
    {
        "value": "barn"
    },
    {
        "value": "barrel"
    },
    {
        "value": "baseball"
    },
    {
        "value": "baseball-bat"
    },
    {
        "value": "baseball-diamond"
    },
    {
        "value": "baseball-diamond-outline"
    },
    {
        "value": "bash"
    },
    {
        "value": "basket"
    },
    {
        "value": "basket-fill"
    },
    {
        "value": "basket-minus"
    },
    {
        "value": "basket-minus-outline"
    },
    {
        "value": "basket-off"
    },
    {
        "value": "basket-off-outline"
    },
    {
        "value": "basket-outline"
    },
    {
        "value": "basket-plus"
    },
    {
        "value": "basket-plus-outline"
    },
    {
        "value": "basket-remove"
    },
    {
        "value": "basket-remove-outline"
    },
    {
        "value": "basket-unfill"
    },
    {
        "value": "basketball"
    },
    {
        "value": "basketball-hoop"
    },
    {
        "value": "basketball-hoop-outline"
    },
    {
        "value": "bat"
    },
    {
        "value": "battery"
    },
    {
        "value": "battery-10"
    },
    {
        "value": "battery-10-bluetooth"
    },
    {
        "value": "battery-20"
    },
    {
        "value": "battery-20-bluetooth"
    },
    {
        "value": "battery-30"
    },
    {
        "value": "battery-30-bluetooth"
    },
    {
        "value": "battery-40"
    },
    {
        "value": "battery-40-bluetooth"
    },
    {
        "value": "battery-50"
    },
    {
        "value": "battery-50-bluetooth"
    },
    {
        "value": "battery-60"
    },
    {
        "value": "battery-60-bluetooth"
    },
    {
        "value": "battery-70"
    },
    {
        "value": "battery-70-bluetooth"
    },
    {
        "value": "battery-80"
    },
    {
        "value": "battery-80-bluetooth"
    },
    {
        "value": "battery-90"
    },
    {
        "value": "battery-90-bluetooth"
    },
    {
        "value": "battery-alert"
    },
    {
        "value": "battery-alert-bluetooth"
    },
    {
        "value": "battery-alert-variant"
    },
    {
        "value": "battery-alert-variant-outline"
    },
    {
        "value": "battery-bluetooth"
    },
    {
        "value": "battery-bluetooth-variant"
    },
    {
        "value": "battery-charging"
    },
    {
        "value": "battery-charging-10"
    },
    {
        "value": "battery-charging-100"
    },
    {
        "value": "battery-charging-20"
    },
    {
        "value": "battery-charging-30"
    },
    {
        "value": "battery-charging-40"
    },
    {
        "value": "battery-charging-50"
    },
    {
        "value": "battery-charging-60"
    },
    {
        "value": "battery-charging-70"
    },
    {
        "value": "battery-charging-80"
    },
    {
        "value": "battery-charging-90"
    },
    {
        "value": "battery-charging-high"
    },
    {
        "value": "battery-charging-low"
    },
    {
        "value": "battery-charging-medium"
    },
    {
        "value": "battery-charging-outline"
    },
    {
        "value": "battery-charging-wireless"
    },
    {
        "value": "battery-charging-wireless-10"
    },
    {
        "value": "battery-charging-wireless-20"
    },
    {
        "value": "battery-charging-wireless-30"
    },
    {
        "value": "battery-charging-wireless-40"
    },
    {
        "value": "battery-charging-wireless-50"
    },
    {
        "value": "battery-charging-wireless-60"
    },
    {
        "value": "battery-charging-wireless-70"
    },
    {
        "value": "battery-charging-wireless-80"
    },
    {
        "value": "battery-charging-wireless-90"
    },
    {
        "value": "battery-charging-wireless-alert"
    },
    {
        "value": "battery-charging-wireless-outline"
    },
    {
        "value": "battery-heart"
    },
    {
        "value": "battery-heart-outline"
    },
    {
        "value": "battery-heart-variant"
    },
    {
        "value": "battery-high"
    },
    {
        "value": "battery-low"
    },
    {
        "value": "battery-medium"
    },
    {
        "value": "battery-minus"
    },
    {
        "value": "battery-negative"
    },
    {
        "value": "battery-off"
    },
    {
        "value": "battery-off-outline"
    },
    {
        "value": "battery-outline"
    },
    {
        "value": "battery-plus"
    },
    {
        "value": "battery-positive"
    },
    {
        "value": "battery-unknown"
    },
    {
        "value": "battery-unknown-bluetooth"
    },
    {
        "value": "battlenet"
    },
    {
        "value": "beach"
    },
    {
        "value": "beaker"
    },
    {
        "value": "beaker-alert"
    },
    {
        "value": "beaker-alert-outline"
    },
    {
        "value": "beaker-check"
    },
    {
        "value": "beaker-check-outline"
    },
    {
        "value": "beaker-minus"
    },
    {
        "value": "beaker-minus-outline"
    },
    {
        "value": "beaker-outline"
    },
    {
        "value": "beaker-plus"
    },
    {
        "value": "beaker-plus-outline"
    },
    {
        "value": "beaker-question"
    },
    {
        "value": "beaker-question-outline"
    },
    {
        "value": "beaker-remove"
    },
    {
        "value": "beaker-remove-outline"
    },
    {
        "value": "bed"
    },
    {
        "value": "bed-double"
    },
    {
        "value": "bed-double-outline"
    },
    {
        "value": "bed-empty"
    },
    {
        "value": "bed-king"
    },
    {
        "value": "bed-king-outline"
    },
    {
        "value": "bed-outline"
    },
    {
        "value": "bed-queen"
    },
    {
        "value": "bed-queen-outline"
    },
    {
        "value": "bed-single"
    },
    {
        "value": "bed-single-outline"
    },
    {
        "value": "bee"
    },
    {
        "value": "bee-flower"
    },
    {
        "value": "beehive-off-outline"
    },
    {
        "value": "beehive-outline"
    },
    {
        "value": "beekeeper"
    },
    {
        "value": "beer"
    },
    {
        "value": "beer-outline"
    },
    {
        "value": "bell"
    },
    {
        "value": "bell-alert"
    },
    {
        "value": "bell-alert-outline"
    },
    {
        "value": "bell-badge"
    },
    {
        "value": "bell-badge-outline"
    },
    {
        "value": "bell-cancel"
    },
    {
        "value": "bell-cancel-outline"
    },
    {
        "value": "bell-check"
    },
    {
        "value": "bell-check-outline"
    },
    {
        "value": "bell-circle"
    },
    {
        "value": "bell-circle-outline"
    },
    {
        "value": "bell-minus"
    },
    {
        "value": "bell-minus-outline"
    },
    {
        "value": "bell-off"
    },
    {
        "value": "bell-off-outline"
    },
    {
        "value": "bell-outline"
    },
    {
        "value": "bell-plus"
    },
    {
        "value": "bell-plus-outline"
    },
    {
        "value": "bell-remove"
    },
    {
        "value": "bell-remove-outline"
    },
    {
        "value": "bell-ring"
    },
    {
        "value": "bell-ring-outline"
    },
    {
        "value": "bell-sleep"
    },
    {
        "value": "bell-sleep-outline"
    },
    {
        "value": "beta"
    },
    {
        "value": "betamax"
    },
    {
        "value": "biathlon"
    },
    {
        "value": "bicycle"
    },
    {
        "value": "bicycle-basket"
    },
    {
        "value": "bicycle-electric"
    },
    {
        "value": "bicycle-penny-farthing"
    },
    {
        "value": "bike"
    },
    {
        "value": "bike-fast"
    },
    {
        "value": "billboard"
    },
    {
        "value": "billiards"
    },
    {
        "value": "billiards-rack"
    },
    {
        "value": "binoculars"
    },
    {
        "value": "bio"
    },
    {
        "value": "biohazard"
    },
    {
        "value": "bird"
    },
    {
        "value": "bitbucket"
    },
    {
        "value": "bitcoin"
    },
    {
        "value": "black-mesa"
    },
    {
        "value": "blender"
    },
    {
        "value": "blender-software"
    },
    {
        "value": "blinds"
    },
    {
        "value": "blinds-open"
    },
    {
        "value": "block-helper"
    },
    {
        "value": "blogger"
    },
    {
        "value": "blood-bag"
    },
    {
        "value": "bluetooth"
    },
    {
        "value": "bluetooth-audio"
    },
    {
        "value": "bluetooth-connect"
    },
    {
        "value": "bluetooth-off"
    },
    {
        "value": "bluetooth-settings"
    },
    {
        "value": "bluetooth-transfer"
    },
    {
        "value": "blur"
    },
    {
        "value": "blur-linear"
    },
    {
        "value": "blur-off"
    },
    {
        "value": "blur-radial"
    },
    {
        "value": "bolnisi-cross"
    },
    {
        "value": "bolt"
    },
    {
        "value": "bomb"
    },
    {
        "value": "bomb-off"
    },
    {
        "value": "bone"
    },
    {
        "value": "book"
    },
    {
        "value": "book-account"
    },
    {
        "value": "book-account-outline"
    },
    {
        "value": "book-alert"
    },
    {
        "value": "book-alert-outline"
    },
    {
        "value": "book-alphabet"
    },
    {
        "value": "book-arrow-down"
    },
    {
        "value": "book-arrow-down-outline"
    },
    {
        "value": "book-arrow-left"
    },
    {
        "value": "book-arrow-left-outline"
    },
    {
        "value": "book-arrow-right"
    },
    {
        "value": "book-arrow-right-outline"
    },
    {
        "value": "book-arrow-up"
    },
    {
        "value": "book-arrow-up-outline"
    },
    {
        "value": "book-cancel"
    },
    {
        "value": "book-cancel-outline"
    },
    {
        "value": "book-check"
    },
    {
        "value": "book-check-outline"
    },
    {
        "value": "book-clock"
    },
    {
        "value": "book-clock-outline"
    },
    {
        "value": "book-cog"
    },
    {
        "value": "book-cog-outline"
    },
    {
        "value": "book-cross"
    },
    {
        "value": "book-edit"
    },
    {
        "value": "book-edit-outline"
    },
    {
        "value": "book-education"
    },
    {
        "value": "book-education-outline"
    },
    {
        "value": "book-information-variant"
    },
    {
        "value": "book-lock"
    },
    {
        "value": "book-lock-open"
    },
    {
        "value": "book-lock-open-outline"
    },
    {
        "value": "book-lock-outline"
    },
    {
        "value": "book-marker"
    },
    {
        "value": "book-marker-outline"
    },
    {
        "value": "book-minus"
    },
    {
        "value": "book-minus-multiple"
    },
    {
        "value": "book-minus-multiple-outline"
    },
    {
        "value": "book-minus-outline"
    },
    {
        "value": "book-multiple"
    },
    {
        "value": "book-multiple-outline"
    },
    {
        "value": "book-music"
    },
    {
        "value": "book-music-outline"
    },
    {
        "value": "book-off"
    },
    {
        "value": "book-off-outline"
    },
    {
        "value": "book-open"
    },
    {
        "value": "book-open-blank-variant"
    },
    {
        "value": "book-open-outline"
    },
    {
        "value": "book-open-page-variant"
    },
    {
        "value": "book-open-page-variant-outline"
    },
    {
        "value": "book-open-variant"
    },
    {
        "value": "book-outline"
    },
    {
        "value": "book-play"
    },
    {
        "value": "book-play-outline"
    },
    {
        "value": "book-plus"
    },
    {
        "value": "book-plus-multiple"
    },
    {
        "value": "book-plus-multiple-outline"
    },
    {
        "value": "book-plus-outline"
    },
    {
        "value": "book-refresh"
    },
    {
        "value": "book-refresh-outline"
    },
    {
        "value": "book-remove"
    },
    {
        "value": "book-remove-multiple"
    },
    {
        "value": "book-remove-multiple-outline"
    },
    {
        "value": "book-remove-outline"
    },
    {
        "value": "book-search"
    },
    {
        "value": "book-search-outline"
    },
    {
        "value": "book-settings"
    },
    {
        "value": "book-settings-outline"
    },
    {
        "value": "book-sync"
    },
    {
        "value": "book-sync-outline"
    },
    {
        "value": "book-variant"
    },
    {
        "value": "book-variant-multiple"
    },
    {
        "value": "bookmark"
    },
    {
        "value": "bookmark-check"
    },
    {
        "value": "bookmark-check-outline"
    },
    {
        "value": "bookmark-minus"
    },
    {
        "value": "bookmark-minus-outline"
    },
    {
        "value": "bookmark-multiple"
    },
    {
        "value": "bookmark-multiple-outline"
    },
    {
        "value": "bookmark-music"
    },
    {
        "value": "bookmark-music-outline"
    },
    {
        "value": "bookmark-off"
    },
    {
        "value": "bookmark-off-outline"
    },
    {
        "value": "bookmark-outline"
    },
    {
        "value": "bookmark-plus"
    },
    {
        "value": "bookmark-plus-outline"
    },
    {
        "value": "bookmark-remove"
    },
    {
        "value": "bookmark-remove-outline"
    },
    {
        "value": "bookshelf"
    },
    {
        "value": "boom-gate"
    },
    {
        "value": "boom-gate-alert"
    },
    {
        "value": "boom-gate-alert-outline"
    },
    {
        "value": "boom-gate-down"
    },
    {
        "value": "boom-gate-down-outline"
    },
    {
        "value": "boom-gate-outline"
    },
    {
        "value": "boom-gate-up"
    },
    {
        "value": "boom-gate-up-outline"
    },
    {
        "value": "boombox"
    },
    {
        "value": "boomerang"
    },
    {
        "value": "bootstrap"
    },
    {
        "value": "border-all"
    },
    {
        "value": "border-all-variant"
    },
    {
        "value": "border-bottom"
    },
    {
        "value": "border-bottom-variant"
    },
    {
        "value": "border-color"
    },
    {
        "value": "border-horizontal"
    },
    {
        "value": "border-inside"
    },
    {
        "value": "border-left"
    },
    {
        "value": "border-left-variant"
    },
    {
        "value": "border-none"
    },
    {
        "value": "border-none-variant"
    },
    {
        "value": "border-outside"
    },
    {
        "value": "border-right"
    },
    {
        "value": "border-right-variant"
    },
    {
        "value": "border-style"
    },
    {
        "value": "border-top"
    },
    {
        "value": "border-top-variant"
    },
    {
        "value": "border-vertical"
    },
    {
        "value": "bottle-soda"
    },
    {
        "value": "bottle-soda-classic"
    },
    {
        "value": "bottle-soda-classic-outline"
    },
    {
        "value": "bottle-soda-outline"
    },
    {
        "value": "bottle-tonic"
    },
    {
        "value": "bottle-tonic-outline"
    },
    {
        "value": "bottle-tonic-plus"
    },
    {
        "value": "bottle-tonic-plus-outline"
    },
    {
        "value": "bottle-tonic-skull"
    },
    {
        "value": "bottle-tonic-skull-outline"
    },
    {
        "value": "bottle-wine"
    },
    {
        "value": "bottle-wine-outline"
    },
    {
        "value": "bow-tie"
    },
    {
        "value": "bowl"
    },
    {
        "value": "bowl-mix"
    },
    {
        "value": "bowl-mix-outline"
    },
    {
        "value": "bowl-outline"
    },
    {
        "value": "bowling"
    },
    {
        "value": "box"
    },
    {
        "value": "box-cutter"
    },
    {
        "value": "box-cutter-off"
    },
    {
        "value": "box-shadow"
    },
    {
        "value": "boxing-glove"
    },
    {
        "value": "braille"
    },
    {
        "value": "brain"
    },
    {
        "value": "bread-slice"
    },
    {
        "value": "bread-slice-outline"
    },
    {
        "value": "bridge"
    },
    {
        "value": "briefcase"
    },
    {
        "value": "briefcase-account"
    },
    {
        "value": "briefcase-account-outline"
    },
    {
        "value": "briefcase-check"
    },
    {
        "value": "briefcase-check-outline"
    },
    {
        "value": "briefcase-clock"
    },
    {
        "value": "briefcase-clock-outline"
    },
    {
        "value": "briefcase-download"
    },
    {
        "value": "briefcase-download-outline"
    },
    {
        "value": "briefcase-edit"
    },
    {
        "value": "briefcase-edit-outline"
    },
    {
        "value": "briefcase-minus"
    },
    {
        "value": "briefcase-minus-outline"
    },
    {
        "value": "briefcase-off"
    },
    {
        "value": "briefcase-off-outline"
    },
    {
        "value": "briefcase-outline"
    },
    {
        "value": "briefcase-plus"
    },
    {
        "value": "briefcase-plus-outline"
    },
    {
        "value": "briefcase-remove"
    },
    {
        "value": "briefcase-remove-outline"
    },
    {
        "value": "briefcase-search"
    },
    {
        "value": "briefcase-search-outline"
    },
    {
        "value": "briefcase-upload"
    },
    {
        "value": "briefcase-upload-outline"
    },
    {
        "value": "briefcase-variant"
    },
    {
        "value": "briefcase-variant-off"
    },
    {
        "value": "briefcase-variant-off-outline"
    },
    {
        "value": "briefcase-variant-outline"
    },
    {
        "value": "brightness-1"
    },
    {
        "value": "brightness-2"
    },
    {
        "value": "brightness-3"
    },
    {
        "value": "brightness-4"
    },
    {
        "value": "brightness-5"
    },
    {
        "value": "brightness-6"
    },
    {
        "value": "brightness-7"
    },
    {
        "value": "brightness-auto"
    },
    {
        "value": "brightness-percent"
    },
    {
        "value": "broadcast"
    },
    {
        "value": "broadcast-off"
    },
    {
        "value": "broom"
    },
    {
        "value": "brush"
    },
    {
        "value": "brush-off"
    },
    {
        "value": "bucket"
    },
    {
        "value": "bucket-outline"
    },
    {
        "value": "buffer"
    },
    {
        "value": "buffet"
    },
    {
        "value": "bug"
    },
    {
        "value": "bug-check"
    },
    {
        "value": "bug-check-outline"
    },
    {
        "value": "bug-outline"
    },
    {
        "value": "bugle"
    },
    {
        "value": "bulldozer"
    },
    {
        "value": "bullet"
    },
    {
        "value": "bulletin-board"
    },
    {
        "value": "bullhorn"
    },
    {
        "value": "bullhorn-outline"
    },
    {
        "value": "bullseye"
    },
    {
        "value": "bullseye-arrow"
    },
    {
        "value": "bulma"
    },
    {
        "value": "bunk-bed"
    },
    {
        "value": "bunk-bed-outline"
    },
    {
        "value": "bus"
    },
    {
        "value": "bus-alert"
    },
    {
        "value": "bus-articulated-end"
    },
    {
        "value": "bus-articulated-front"
    },
    {
        "value": "bus-clock"
    },
    {
        "value": "bus-double-decker"
    },
    {
        "value": "bus-marker"
    },
    {
        "value": "bus-multiple"
    },
    {
        "value": "bus-school"
    },
    {
        "value": "bus-side"
    },
    {
        "value": "bus-stop"
    },
    {
        "value": "bus-stop-covered"
    },
    {
        "value": "bus-stop-uncovered"
    },
    {
        "value": "butterfly"
    },
    {
        "value": "butterfly-outline"
    },
    {
        "value": "cable-data"
    },
    {
        "value": "cached"
    },
    {
        "value": "cactus"
    },
    {
        "value": "cake"
    },
    {
        "value": "cake-layered"
    },
    {
        "value": "cake-variant"
    },
    {
        "value": "calculator"
    },
    {
        "value": "calculator-variant"
    },
    {
        "value": "calculator-variant-outline"
    },
    {
        "value": "calendar"
    },
    {
        "value": "calendar-account"
    },
    {
        "value": "calendar-account-outline"
    },
    {
        "value": "calendar-alert"
    },
    {
        "value": "calendar-arrow-left"
    },
    {
        "value": "calendar-arrow-right"
    },
    {
        "value": "calendar-blank"
    },
    {
        "value": "calendar-blank-multiple"
    },
    {
        "value": "calendar-blank-outline"
    },
    {
        "value": "calendar-check"
    },
    {
        "value": "calendar-check-outline"
    },
    {
        "value": "calendar-clock"
    },
    {
        "value": "calendar-clock-outline"
    },
    {
        "value": "calendar-cursor"
    },
    {
        "value": "calendar-edit"
    },
    {
        "value": "calendar-end"
    },
    {
        "value": "calendar-export"
    },
    {
        "value": "calendar-heart"
    },
    {
        "value": "calendar-import"
    },
    {
        "value": "calendar-lock"
    },
    {
        "value": "calendar-lock-outline"
    },
    {
        "value": "calendar-minus"
    },
    {
        "value": "calendar-month"
    },
    {
        "value": "calendar-month-outline"
    },
    {
        "value": "calendar-multiple"
    },
    {
        "value": "calendar-multiple-check"
    },
    {
        "value": "calendar-multiselect"
    },
    {
        "value": "calendar-outline"
    },
    {
        "value": "calendar-plus"
    },
    {
        "value": "calendar-question"
    },
    {
        "value": "calendar-range"
    },
    {
        "value": "calendar-range-outline"
    },
    {
        "value": "calendar-refresh"
    },
    {
        "value": "calendar-refresh-outline"
    },
    {
        "value": "calendar-remove"
    },
    {
        "value": "calendar-remove-outline"
    },
    {
        "value": "calendar-search"
    },
    {
        "value": "calendar-star"
    },
    {
        "value": "calendar-start"
    },
    {
        "value": "calendar-sync"
    },
    {
        "value": "calendar-sync-outline"
    },
    {
        "value": "calendar-text"
    },
    {
        "value": "calendar-text-outline"
    },
    {
        "value": "calendar-today"
    },
    {
        "value": "calendar-week"
    },
    {
        "value": "calendar-week-begin"
    },
    {
        "value": "calendar-weekend"
    },
    {
        "value": "calendar-weekend-outline"
    },
    {
        "value": "call-made"
    },
    {
        "value": "call-merge"
    },
    {
        "value": "call-missed"
    },
    {
        "value": "call-received"
    },
    {
        "value": "call-split"
    },
    {
        "value": "camcorder"
    },
    {
        "value": "camcorder-off"
    },
    {
        "value": "camera"
    },
    {
        "value": "camera-account"
    },
    {
        "value": "camera-burst"
    },
    {
        "value": "camera-control"
    },
    {
        "value": "camera-enhance"
    },
    {
        "value": "camera-enhance-outline"
    },
    {
        "value": "camera-flip"
    },
    {
        "value": "camera-flip-outline"
    },
    {
        "value": "camera-front"
    },
    {
        "value": "camera-front-variant"
    },
    {
        "value": "camera-gopro"
    },
    {
        "value": "camera-image"
    },
    {
        "value": "camera-iris"
    },
    {
        "value": "camera-metering-center"
    },
    {
        "value": "camera-metering-matrix"
    },
    {
        "value": "camera-metering-partial"
    },
    {
        "value": "camera-metering-spot"
    },
    {
        "value": "camera-off"
    },
    {
        "value": "camera-outline"
    },
    {
        "value": "camera-party-mode"
    },
    {
        "value": "camera-plus"
    },
    {
        "value": "camera-plus-outline"
    },
    {
        "value": "camera-rear"
    },
    {
        "value": "camera-rear-variant"
    },
    {
        "value": "camera-retake"
    },
    {
        "value": "camera-retake-outline"
    },
    {
        "value": "camera-switch"
    },
    {
        "value": "camera-switch-outline"
    },
    {
        "value": "camera-timer"
    },
    {
        "value": "camera-wireless"
    },
    {
        "value": "camera-wireless-outline"
    },
    {
        "value": "campfire"
    },
    {
        "value": "cancel"
    },
    {
        "value": "candle"
    },
    {
        "value": "candycane"
    },
    {
        "value": "cannabis"
    },
    {
        "value": "cannabis-off"
    },
    {
        "value": "caps-lock"
    },
    {
        "value": "car"
    },
    {
        "value": "car-2-plus"
    },
    {
        "value": "car-3-plus"
    },
    {
        "value": "car-arrow-left"
    },
    {
        "value": "car-arrow-right"
    },
    {
        "value": "car-back"
    },
    {
        "value": "car-battery"
    },
    {
        "value": "car-brake-abs"
    },
    {
        "value": "car-brake-alert"
    },
    {
        "value": "car-brake-hold"
    },
    {
        "value": "car-brake-parking"
    },
    {
        "value": "car-brake-retarder"
    },
    {
        "value": "car-child-seat"
    },
    {
        "value": "car-clutch"
    },
    {
        "value": "car-cog"
    },
    {
        "value": "car-connected"
    },
    {
        "value": "car-convertible"
    },
    {
        "value": "car-coolant-level"
    },
    {
        "value": "car-cruise-control"
    },
    {
        "value": "car-defrost-front"
    },
    {
        "value": "car-defrost-rear"
    },
    {
        "value": "car-door"
    },
    {
        "value": "car-door-lock"
    },
    {
        "value": "car-electric"
    },
    {
        "value": "car-electric-outline"
    },
    {
        "value": "car-emergency"
    },
    {
        "value": "car-esp"
    },
    {
        "value": "car-estate"
    },
    {
        "value": "car-hatchback"
    },
    {
        "value": "car-info"
    },
    {
        "value": "car-key"
    },
    {
        "value": "car-lifted-pickup"
    },
    {
        "value": "car-light-dimmed"
    },
    {
        "value": "car-light-fog"
    },
    {
        "value": "car-light-high"
    },
    {
        "value": "car-limousine"
    },
    {
        "value": "car-multiple"
    },
    {
        "value": "car-off"
    },
    {
        "value": "car-outline"
    },
    {
        "value": "car-parking-lights"
    },
    {
        "value": "car-pickup"
    },
    {
        "value": "car-seat"
    },
    {
        "value": "car-seat-cooler"
    },
    {
        "value": "car-seat-heater"
    },
    {
        "value": "car-settings"
    },
    {
        "value": "car-shift-pattern"
    },
    {
        "value": "car-side"
    },
    {
        "value": "car-sports"
    },
    {
        "value": "car-tire-alert"
    },
    {
        "value": "car-traction-control"
    },
    {
        "value": "car-turbocharger"
    },
    {
        "value": "car-wash"
    },
    {
        "value": "car-windshield"
    },
    {
        "value": "car-windshield-outline"
    },
    {
        "value": "carabiner"
    },
    {
        "value": "caravan"
    },
    {
        "value": "card"
    },
    {
        "value": "card-account-details"
    },
    {
        "value": "card-account-details-outline"
    },
    {
        "value": "card-account-details-star"
    },
    {
        "value": "card-account-details-star-outline"
    },
    {
        "value": "card-account-mail"
    },
    {
        "value": "card-account-mail-outline"
    },
    {
        "value": "card-account-phone"
    },
    {
        "value": "card-account-phone-outline"
    },
    {
        "value": "card-bulleted"
    },
    {
        "value": "card-bulleted-off"
    },
    {
        "value": "card-bulleted-off-outline"
    },
    {
        "value": "card-bulleted-outline"
    },
    {
        "value": "card-bulleted-settings"
    },
    {
        "value": "card-bulleted-settings-outline"
    },
    {
        "value": "card-minus"
    },
    {
        "value": "card-minus-outline"
    },
    {
        "value": "card-off"
    },
    {
        "value": "card-off-outline"
    },
    {
        "value": "card-outline"
    },
    {
        "value": "card-plus"
    },
    {
        "value": "card-plus-outline"
    },
    {
        "value": "card-remove"
    },
    {
        "value": "card-remove-outline"
    },
    {
        "value": "card-search"
    },
    {
        "value": "card-search-outline"
    },
    {
        "value": "card-text"
    },
    {
        "value": "card-text-outline"
    },
    {
        "value": "cards"
    },
    {
        "value": "cards-club"
    },
    {
        "value": "cards-diamond"
    },
    {
        "value": "cards-diamond-outline"
    },
    {
        "value": "cards-heart"
    },
    {
        "value": "cards-outline"
    },
    {
        "value": "cards-playing-outline"
    },
    {
        "value": "cards-spade"
    },
    {
        "value": "cards-variant"
    },
    {
        "value": "carrot"
    },
    {
        "value": "cart"
    },
    {
        "value": "cart-arrow-down"
    },
    {
        "value": "cart-arrow-right"
    },
    {
        "value": "cart-arrow-up"
    },
    {
        "value": "cart-check"
    },
    {
        "value": "cart-minus"
    },
    {
        "value": "cart-off"
    },
    {
        "value": "cart-outline"
    },
    {
        "value": "cart-plus"
    },
    {
        "value": "cart-remove"
    },
    {
        "value": "cart-variant"
    },
    {
        "value": "case-sensitive-alt"
    },
    {
        "value": "cash"
    },
    {
        "value": "cash-100"
    },
    {
        "value": "cash-check"
    },
    {
        "value": "cash-lock"
    },
    {
        "value": "cash-lock-open"
    },
    {
        "value": "cash-marker"
    },
    {
        "value": "cash-minus"
    },
    {
        "value": "cash-multiple"
    },
    {
        "value": "cash-plus"
    },
    {
        "value": "cash-refund"
    },
    {
        "value": "cash-register"
    },
    {
        "value": "cash-remove"
    },
    {
        "value": "cassette"
    },
    {
        "value": "cast"
    },
    {
        "value": "cast-audio"
    },
    {
        "value": "cast-audio-variant"
    },
    {
        "value": "cast-connected"
    },
    {
        "value": "cast-education"
    },
    {
        "value": "cast-off"
    },
    {
        "value": "cast-variant"
    },
    {
        "value": "castle"
    },
    {
        "value": "cat"
    },
    {
        "value": "cctv"
    },
    {
        "value": "ceiling-light"
    },
    {
        "value": "cellphone"
    },
    {
        "value": "cellphone-arrow-down"
    },
    {
        "value": "cellphone-basic"
    },
    {
        "value": "cellphone-charging"
    },
    {
        "value": "cellphone-cog"
    },
    {
        "value": "cellphone-dock"
    },
    {
        "value": "cellphone-erase"
    },
    {
        "value": "cellphone-information"
    },
    {
        "value": "cellphone-key"
    },
    {
        "value": "cellphone-link"
    },
    {
        "value": "cellphone-link-off"
    },
    {
        "value": "cellphone-lock"
    },
    {
        "value": "cellphone-message"
    },
    {
        "value": "cellphone-message-off"
    },
    {
        "value": "cellphone-nfc"
    },
    {
        "value": "cellphone-nfc-off"
    },
    {
        "value": "cellphone-off"
    },
    {
        "value": "cellphone-play"
    },
    {
        "value": "cellphone-screenshot"
    },
    {
        "value": "cellphone-settings"
    },
    {
        "value": "cellphone-sound"
    },
    {
        "value": "cellphone-text"
    },
    {
        "value": "cellphone-wireless"
    },
    {
        "value": "celtic-cross"
    },
    {
        "value": "centos"
    },
    {
        "value": "certificate"
    },
    {
        "value": "certificate-outline"
    },
    {
        "value": "chair-rolling"
    },
    {
        "value": "chair-school"
    },
    {
        "value": "charity"
    },
    {
        "value": "chart-arc"
    },
    {
        "value": "chart-areaspline"
    },
    {
        "value": "chart-areaspline-variant"
    },
    {
        "value": "chart-bar"
    },
    {
        "value": "chart-bar-stacked"
    },
    {
        "value": "chart-bell-curve"
    },
    {
        "value": "chart-bell-curve-cumulative"
    },
    {
        "value": "chart-box"
    },
    {
        "value": "chart-box-outline"
    },
    {
        "value": "chart-box-plus-outline"
    },
    {
        "value": "chart-bubble"
    },
    {
        "value": "chart-donut"
    },
    {
        "value": "chart-donut-variant"
    },
    {
        "value": "chart-gantt"
    },
    {
        "value": "chart-histogram"
    },
    {
        "value": "chart-line"
    },
    {
        "value": "chart-line-stacked"
    },
    {
        "value": "chart-line-variant"
    },
    {
        "value": "chart-multiline"
    },
    {
        "value": "chart-multiple"
    },
    {
        "value": "chart-pie"
    },
    {
        "value": "chart-ppf"
    },
    {
        "value": "chart-sankey"
    },
    {
        "value": "chart-sankey-variant"
    },
    {
        "value": "chart-scatter-plot"
    },
    {
        "value": "chart-scatter-plot-hexbin"
    },
    {
        "value": "chart-timeline"
    },
    {
        "value": "chart-timeline-variant"
    },
    {
        "value": "chart-timeline-variant-shimmer"
    },
    {
        "value": "chart-tree"
    },
    {
        "value": "chat"
    },
    {
        "value": "chat-alert"
    },
    {
        "value": "chat-alert-outline"
    },
    {
        "value": "chat-minus"
    },
    {
        "value": "chat-minus-outline"
    },
    {
        "value": "chat-outline"
    },
    {
        "value": "chat-plus"
    },
    {
        "value": "chat-plus-outline"
    },
    {
        "value": "chat-processing"
    },
    {
        "value": "chat-processing-outline"
    },
    {
        "value": "chat-question"
    },
    {
        "value": "chat-question-outline"
    },
    {
        "value": "chat-remove"
    },
    {
        "value": "chat-remove-outline"
    },
    {
        "value": "chat-sleep"
    },
    {
        "value": "chat-sleep-outline"
    },
    {
        "value": "check"
    },
    {
        "value": "check-all"
    },
    {
        "value": "check-bold"
    },
    {
        "value": "check-circle"
    },
    {
        "value": "check-circle-outline"
    },
    {
        "value": "check-decagram"
    },
    {
        "value": "check-decagram-outline"
    },
    {
        "value": "check-network"
    },
    {
        "value": "check-network-outline"
    },
    {
        "value": "check-outline"
    },
    {
        "value": "check-underline"
    },
    {
        "value": "check-underline-circle"
    },
    {
        "value": "check-underline-circle-outline"
    },
    {
        "value": "checkbook"
    },
    {
        "value": "checkbox-blank"
    },
    {
        "value": "checkbox-blank-badge"
    },
    {
        "value": "checkbox-blank-badge-outline"
    },
    {
        "value": "checkbox-blank-circle"
    },
    {
        "value": "checkbox-blank-circle-outline"
    },
    {
        "value": "checkbox-blank-off"
    },
    {
        "value": "checkbox-blank-off-outline"
    },
    {
        "value": "checkbox-blank-outline"
    },
    {
        "value": "checkbox-intermediate"
    },
    {
        "value": "checkbox-marked"
    },
    {
        "value": "checkbox-marked-circle"
    },
    {
        "value": "checkbox-marked-circle-outline"
    },
    {
        "value": "checkbox-marked-outline"
    },
    {
        "value": "checkbox-multiple-blank"
    },
    {
        "value": "checkbox-multiple-blank-circle"
    },
    {
        "value": "checkbox-multiple-blank-circle-outline"
    },
    {
        "value": "checkbox-multiple-blank-outline"
    },
    {
        "value": "checkbox-multiple-marked"
    },
    {
        "value": "checkbox-multiple-marked-circle"
    },
    {
        "value": "checkbox-multiple-marked-circle-outline"
    },
    {
        "value": "checkbox-multiple-marked-outline"
    },
    {
        "value": "checkbox-multiple-outline"
    },
    {
        "value": "checkbox-outline"
    },
    {
        "value": "checkerboard"
    },
    {
        "value": "checkerboard-minus"
    },
    {
        "value": "checkerboard-plus"
    },
    {
        "value": "checkerboard-remove"
    },
    {
        "value": "cheese"
    },
    {
        "value": "cheese-off"
    },
    {
        "value": "chef-hat"
    },
    {
        "value": "chemical-weapon"
    },
    {
        "value": "chess-bishop"
    },
    {
        "value": "chess-king"
    },
    {
        "value": "chess-knight"
    },
    {
        "value": "chess-pawn"
    },
    {
        "value": "chess-queen"
    },
    {
        "value": "chess-rook"
    },
    {
        "value": "chevron-double-down"
    },
    {
        "value": "chevron-double-left"
    },
    {
        "value": "chevron-double-right"
    },
    {
        "value": "chevron-double-up"
    },
    {
        "value": "chevron-down"
    },
    {
        "value": "chevron-down-box"
    },
    {
        "value": "chevron-down-box-outline"
    },
    {
        "value": "chevron-down-circle"
    },
    {
        "value": "chevron-down-circle-outline"
    },
    {
        "value": "chevron-left"
    },
    {
        "value": "chevron-left-box"
    },
    {
        "value": "chevron-left-box-outline"
    },
    {
        "value": "chevron-left-circle"
    },
    {
        "value": "chevron-left-circle-outline"
    },
    {
        "value": "chevron-right"
    },
    {
        "value": "chevron-right-box"
    },
    {
        "value": "chevron-right-box-outline"
    },
    {
        "value": "chevron-right-circle"
    },
    {
        "value": "chevron-right-circle-outline"
    },
    {
        "value": "chevron-triple-down"
    },
    {
        "value": "chevron-triple-left"
    },
    {
        "value": "chevron-triple-right"
    },
    {
        "value": "chevron-triple-up"
    },
    {
        "value": "chevron-up"
    },
    {
        "value": "chevron-up-box"
    },
    {
        "value": "chevron-up-box-outline"
    },
    {
        "value": "chevron-up-circle"
    },
    {
        "value": "chevron-up-circle-outline"
    },
    {
        "value": "chili-hot"
    },
    {
        "value": "chili-medium"
    },
    {
        "value": "chili-mild"
    },
    {
        "value": "chili-off"
    },
    {
        "value": "chip"
    },
    {
        "value": "church"
    },
    {
        "value": "cigar"
    },
    {
        "value": "cigar-off"
    },
    {
        "value": "circle"
    },
    {
        "value": "circle-box"
    },
    {
        "value": "circle-box-outline"
    },
    {
        "value": "circle-double"
    },
    {
        "value": "circle-edit-outline"
    },
    {
        "value": "circle-expand"
    },
    {
        "value": "circle-half"
    },
    {
        "value": "circle-half-full"
    },
    {
        "value": "circle-medium"
    },
    {
        "value": "circle-multiple"
    },
    {
        "value": "circle-multiple-outline"
    },
    {
        "value": "circle-off-outline"
    },
    {
        "value": "circle-outline"
    },
    {
        "value": "circle-slice-1"
    },
    {
        "value": "circle-slice-2"
    },
    {
        "value": "circle-slice-3"
    },
    {
        "value": "circle-slice-4"
    },
    {
        "value": "circle-slice-5"
    },
    {
        "value": "circle-slice-6"
    },
    {
        "value": "circle-slice-7"
    },
    {
        "value": "circle-slice-8"
    },
    {
        "value": "circle-small"
    },
    {
        "value": "circular-saw"
    },
    {
        "value": "city"
    },
    {
        "value": "city-variant"
    },
    {
        "value": "city-variant-outline"
    },
    {
        "value": "clipboard"
    },
    {
        "value": "clipboard-account"
    },
    {
        "value": "clipboard-account-outline"
    },
    {
        "value": "clipboard-alert"
    },
    {
        "value": "clipboard-alert-outline"
    },
    {
        "value": "clipboard-arrow-down"
    },
    {
        "value": "clipboard-arrow-down-outline"
    },
    {
        "value": "clipboard-arrow-left"
    },
    {
        "value": "clipboard-arrow-left-outline"
    },
    {
        "value": "clipboard-arrow-right"
    },
    {
        "value": "clipboard-arrow-right-outline"
    },
    {
        "value": "clipboard-arrow-up"
    },
    {
        "value": "clipboard-arrow-up-outline"
    },
    {
        "value": "clipboard-check"
    },
    {
        "value": "clipboard-check-multiple"
    },
    {
        "value": "clipboard-check-multiple-outline"
    },
    {
        "value": "clipboard-check-outline"
    },
    {
        "value": "clipboard-clock"
    },
    {
        "value": "clipboard-clock-outline"
    },
    {
        "value": "clipboard-edit"
    },
    {
        "value": "clipboard-edit-outline"
    },
    {
        "value": "clipboard-file"
    },
    {
        "value": "clipboard-file-outline"
    },
    {
        "value": "clipboard-flow"
    },
    {
        "value": "clipboard-flow-outline"
    },
    {
        "value": "clipboard-list"
    },
    {
        "value": "clipboard-list-outline"
    },
    {
        "value": "clipboard-minus"
    },
    {
        "value": "clipboard-minus-outline"
    },
    {
        "value": "clipboard-multiple"
    },
    {
        "value": "clipboard-multiple-outline"
    },
    {
        "value": "clipboard-off"
    },
    {
        "value": "clipboard-off-outline"
    },
    {
        "value": "clipboard-outline"
    },
    {
        "value": "clipboard-play"
    },
    {
        "value": "clipboard-play-multiple"
    },
    {
        "value": "clipboard-play-multiple-outline"
    },
    {
        "value": "clipboard-play-outline"
    },
    {
        "value": "clipboard-plus"
    },
    {
        "value": "clipboard-plus-outline"
    },
    {
        "value": "clipboard-pulse"
    },
    {
        "value": "clipboard-pulse-outline"
    },
    {
        "value": "clipboard-remove"
    },
    {
        "value": "clipboard-remove-outline"
    },
    {
        "value": "clipboard-search"
    },
    {
        "value": "clipboard-search-outline"
    },
    {
        "value": "clipboard-text"
    },
    {
        "value": "clipboard-text-multiple"
    },
    {
        "value": "clipboard-text-multiple-outline"
    },
    {
        "value": "clipboard-text-off"
    },
    {
        "value": "clipboard-text-off-outline"
    },
    {
        "value": "clipboard-text-outline"
    },
    {
        "value": "clipboard-text-play"
    },
    {
        "value": "clipboard-text-play-outline"
    },
    {
        "value": "clipboard-text-search"
    },
    {
        "value": "clipboard-text-search-outline"
    },
    {
        "value": "clippy"
    },
    {
        "value": "clock"
    },
    {
        "value": "clock-alert"
    },
    {
        "value": "clock-alert-outline"
    },
    {
        "value": "clock-check"
    },
    {
        "value": "clock-check-outline"
    },
    {
        "value": "clock-digital"
    },
    {
        "value": "clock-end"
    },
    {
        "value": "clock-fast"
    },
    {
        "value": "clock-in"
    },
    {
        "value": "clock-out"
    },
    {
        "value": "clock-outline"
    },
    {
        "value": "clock-start"
    },
    {
        "value": "clock-time-eight"
    },
    {
        "value": "clock-time-eight-outline"
    },
    {
        "value": "clock-time-eleven"
    },
    {
        "value": "clock-time-eleven-outline"
    },
    {
        "value": "clock-time-five"
    },
    {
        "value": "clock-time-five-outline"
    },
    {
        "value": "clock-time-four"
    },
    {
        "value": "clock-time-four-outline"
    },
    {
        "value": "clock-time-nine"
    },
    {
        "value": "clock-time-nine-outline"
    },
    {
        "value": "clock-time-one"
    },
    {
        "value": "clock-time-one-outline"
    },
    {
        "value": "clock-time-seven"
    },
    {
        "value": "clock-time-seven-outline"
    },
    {
        "value": "clock-time-six"
    },
    {
        "value": "clock-time-six-outline"
    },
    {
        "value": "clock-time-ten"
    },
    {
        "value": "clock-time-ten-outline"
    },
    {
        "value": "clock-time-three"
    },
    {
        "value": "clock-time-three-outline"
    },
    {
        "value": "clock-time-twelve"
    },
    {
        "value": "clock-time-twelve-outline"
    },
    {
        "value": "clock-time-two"
    },
    {
        "value": "clock-time-two-outline"
    },
    {
        "value": "close"
    },
    {
        "value": "close-box"
    },
    {
        "value": "close-box-multiple"
    },
    {
        "value": "close-box-multiple-outline"
    },
    {
        "value": "close-box-outline"
    },
    {
        "value": "close-circle"
    },
    {
        "value": "close-circle-multiple"
    },
    {
        "value": "close-circle-multiple-outline"
    },
    {
        "value": "close-circle-outline"
    },
    {
        "value": "close-network"
    },
    {
        "value": "close-network-outline"
    },
    {
        "value": "close-octagon"
    },
    {
        "value": "close-octagon-outline"
    },
    {
        "value": "close-outline"
    },
    {
        "value": "close-thick"
    },
    {
        "value": "closed-caption"
    },
    {
        "value": "closed-caption-outline"
    },
    {
        "value": "cloud"
    },
    {
        "value": "cloud-alert"
    },
    {
        "value": "cloud-braces"
    },
    {
        "value": "cloud-check"
    },
    {
        "value": "cloud-check-outline"
    },
    {
        "value": "cloud-circle"
    },
    {
        "value": "cloud-download"
    },
    {
        "value": "cloud-download-outline"
    },
    {
        "value": "cloud-lock"
    },
    {
        "value": "cloud-lock-outline"
    },
    {
        "value": "cloud-off-outline"
    },
    {
        "value": "cloud-outline"
    },
    {
        "value": "cloud-print"
    },
    {
        "value": "cloud-print-outline"
    },
    {
        "value": "cloud-question"
    },
    {
        "value": "cloud-refresh"
    },
    {
        "value": "cloud-search"
    },
    {
        "value": "cloud-search-outline"
    },
    {
        "value": "cloud-sync"
    },
    {
        "value": "cloud-sync-outline"
    },
    {
        "value": "cloud-tags"
    },
    {
        "value": "cloud-upload"
    },
    {
        "value": "cloud-upload-outline"
    },
    {
        "value": "clover"
    },
    {
        "value": "coach-lamp"
    },
    {
        "value": "coat-rack"
    },
    {
        "value": "code-array"
    },
    {
        "value": "code-braces"
    },
    {
        "value": "code-braces-box"
    },
    {
        "value": "code-brackets"
    },
    {
        "value": "code-equal"
    },
    {
        "value": "code-greater-than"
    },
    {
        "value": "code-greater-than-or-equal"
    },
    {
        "value": "code-json"
    },
    {
        "value": "code-less-than"
    },
    {
        "value": "code-less-than-or-equal"
    },
    {
        "value": "code-not-equal"
    },
    {
        "value": "code-not-equal-variant"
    },
    {
        "value": "code-parentheses"
    },
    {
        "value": "code-parentheses-box"
    },
    {
        "value": "code-string"
    },
    {
        "value": "code-tags"
    },
    {
        "value": "code-tags-check"
    },
    {
        "value": "codepen"
    },
    {
        "value": "coffee"
    },
    {
        "value": "coffee-maker"
    },
    {
        "value": "coffee-off"
    },
    {
        "value": "coffee-off-outline"
    },
    {
        "value": "coffee-outline"
    },
    {
        "value": "coffee-to-go"
    },
    {
        "value": "coffee-to-go-outline"
    },
    {
        "value": "coffin"
    },
    {
        "value": "cog"
    },
    {
        "value": "cog-box"
    },
    {
        "value": "cog-clockwise"
    },
    {
        "value": "cog-counterclockwise"
    },
    {
        "value": "cog-off"
    },
    {
        "value": "cog-off-outline"
    },
    {
        "value": "cog-outline"
    },
    {
        "value": "cog-refresh"
    },
    {
        "value": "cog-refresh-outline"
    },
    {
        "value": "cog-sync"
    },
    {
        "value": "cog-sync-outline"
    },
    {
        "value": "cog-transfer"
    },
    {
        "value": "cog-transfer-outline"
    },
    {
        "value": "cogs"
    },
    {
        "value": "collage"
    },
    {
        "value": "collapse-all"
    },
    {
        "value": "collapse-all-outline"
    },
    {
        "value": "color-helper"
    },
    {
        "value": "comma"
    },
    {
        "value": "comma-box"
    },
    {
        "value": "comma-box-outline"
    },
    {
        "value": "comma-circle"
    },
    {
        "value": "comma-circle-outline"
    },
    {
        "value": "comment"
    },
    {
        "value": "comment-account"
    },
    {
        "value": "comment-account-outline"
    },
    {
        "value": "comment-alert"
    },
    {
        "value": "comment-alert-outline"
    },
    {
        "value": "comment-arrow-left"
    },
    {
        "value": "comment-arrow-left-outline"
    },
    {
        "value": "comment-arrow-right"
    },
    {
        "value": "comment-arrow-right-outline"
    },
    {
        "value": "comment-bookmark"
    },
    {
        "value": "comment-bookmark-outline"
    },
    {
        "value": "comment-check"
    },
    {
        "value": "comment-check-outline"
    },
    {
        "value": "comment-edit"
    },
    {
        "value": "comment-edit-outline"
    },
    {
        "value": "comment-eye"
    },
    {
        "value": "comment-eye-outline"
    },
    {
        "value": "comment-flash"
    },
    {
        "value": "comment-flash-outline"
    },
    {
        "value": "comment-minus"
    },
    {
        "value": "comment-minus-outline"
    },
    {
        "value": "comment-multiple"
    },
    {
        "value": "comment-multiple-outline"
    },
    {
        "value": "comment-off"
    },
    {
        "value": "comment-off-outline"
    },
    {
        "value": "comment-outline"
    },
    {
        "value": "comment-plus"
    },
    {
        "value": "comment-plus-outline"
    },
    {
        "value": "comment-processing"
    },
    {
        "value": "comment-processing-outline"
    },
    {
        "value": "comment-question"
    },
    {
        "value": "comment-question-outline"
    },
    {
        "value": "comment-quote"
    },
    {
        "value": "comment-quote-outline"
    },
    {
        "value": "comment-remove"
    },
    {
        "value": "comment-remove-outline"
    },
    {
        "value": "comment-search"
    },
    {
        "value": "comment-search-outline"
    },
    {
        "value": "comment-text"
    },
    {
        "value": "comment-text-multiple"
    },
    {
        "value": "comment-text-multiple-outline"
    },
    {
        "value": "comment-text-outline"
    },
    {
        "value": "compare"
    },
    {
        "value": "compare-horizontal"
    },
    {
        "value": "compare-vertical"
    },
    {
        "value": "compass"
    },
    {
        "value": "compass-off"
    },
    {
        "value": "compass-off-outline"
    },
    {
        "value": "compass-outline"
    },
    {
        "value": "compass-rose"
    },
    {
        "value": "concourse-ci"
    },
    {
        "value": "connection"
    },
    {
        "value": "console"
    },
    {
        "value": "console-line"
    },
    {
        "value": "console-network"
    },
    {
        "value": "console-network-outline"
    },
    {
        "value": "consolidate"
    },
    {
        "value": "contactless-payment"
    },
    {
        "value": "contactless-payment-circle"
    },
    {
        "value": "contactless-payment-circle-outline"
    },
    {
        "value": "contacts"
    },
    {
        "value": "contacts-outline"
    },
    {
        "value": "contain"
    },
    {
        "value": "contain-end"
    },
    {
        "value": "contain-start"
    },
    {
        "value": "content-copy"
    },
    {
        "value": "content-cut"
    },
    {
        "value": "content-duplicate"
    },
    {
        "value": "content-paste"
    },
    {
        "value": "content-save"
    },
    {
        "value": "content-save-alert"
    },
    {
        "value": "content-save-alert-outline"
    },
    {
        "value": "content-save-all"
    },
    {
        "value": "content-save-all-outline"
    },
    {
        "value": "content-save-cog"
    },
    {
        "value": "content-save-cog-outline"
    },
    {
        "value": "content-save-edit"
    },
    {
        "value": "content-save-edit-outline"
    },
    {
        "value": "content-save-move"
    },
    {
        "value": "content-save-move-outline"
    },
    {
        "value": "content-save-off"
    },
    {
        "value": "content-save-off-outline"
    },
    {
        "value": "content-save-outline"
    },
    {
        "value": "content-save-settings"
    },
    {
        "value": "content-save-settings-outline"
    },
    {
        "value": "contrast"
    },
    {
        "value": "contrast-box"
    },
    {
        "value": "contrast-circle"
    },
    {
        "value": "controller-classic"
    },
    {
        "value": "controller-classic-outline"
    },
    {
        "value": "cookie"
    },
    {
        "value": "cookie-alert"
    },
    {
        "value": "cookie-alert-outline"
    },
    {
        "value": "cookie-check"
    },
    {
        "value": "cookie-check-outline"
    },
    {
        "value": "cookie-clock"
    },
    {
        "value": "cookie-clock-outline"
    },
    {
        "value": "cookie-cog"
    },
    {
        "value": "cookie-cog-outline"
    },
    {
        "value": "cookie-edit"
    },
    {
        "value": "cookie-edit-outline"
    },
    {
        "value": "cookie-lock"
    },
    {
        "value": "cookie-lock-outline"
    },
    {
        "value": "cookie-minus"
    },
    {
        "value": "cookie-minus-outline"
    },
    {
        "value": "cookie-off"
    },
    {
        "value": "cookie-off-outline"
    },
    {
        "value": "cookie-outline"
    },
    {
        "value": "cookie-plus"
    },
    {
        "value": "cookie-plus-outline"
    },
    {
        "value": "cookie-refresh"
    },
    {
        "value": "cookie-refresh-outline"
    },
    {
        "value": "cookie-remove"
    },
    {
        "value": "cookie-remove-outline"
    },
    {
        "value": "cookie-settings"
    },
    {
        "value": "cookie-settings-outline"
    },
    {
        "value": "coolant-temperature"
    },
    {
        "value": "copyright"
    },
    {
        "value": "cordova"
    },
    {
        "value": "corn"
    },
    {
        "value": "corn-off"
    },
    {
        "value": "cosine-wave"
    },
    {
        "value": "counter"
    },
    {
        "value": "cow"
    },
    {
        "value": "cpu-32-bit"
    },
    {
        "value": "cpu-64-bit"
    },
    {
        "value": "crane"
    },
    {
        "value": "creation"
    },
    {
        "value": "creative-commons"
    },
    {
        "value": "credit-card"
    },
    {
        "value": "credit-card-check"
    },
    {
        "value": "credit-card-check-outline"
    },
    {
        "value": "credit-card-clock"
    },
    {
        "value": "credit-card-clock-outline"
    },
    {
        "value": "credit-card-marker"
    },
    {
        "value": "credit-card-marker-outline"
    },
    {
        "value": "credit-card-minus"
    },
    {
        "value": "credit-card-minus-outline"
    },
    {
        "value": "credit-card-multiple"
    },
    {
        "value": "credit-card-multiple-outline"
    },
    {
        "value": "credit-card-off"
    },
    {
        "value": "credit-card-off-outline"
    },
    {
        "value": "credit-card-outline"
    },
    {
        "value": "credit-card-plus"
    },
    {
        "value": "credit-card-plus-outline"
    },
    {
        "value": "credit-card-refresh"
    },
    {
        "value": "credit-card-refresh-outline"
    },
    {
        "value": "credit-card-refund"
    },
    {
        "value": "credit-card-refund-outline"
    },
    {
        "value": "credit-card-remove"
    },
    {
        "value": "credit-card-remove-outline"
    },
    {
        "value": "credit-card-scan"
    },
    {
        "value": "credit-card-scan-outline"
    },
    {
        "value": "credit-card-search"
    },
    {
        "value": "credit-card-search-outline"
    },
    {
        "value": "credit-card-settings"
    },
    {
        "value": "credit-card-settings-outline"
    },
    {
        "value": "credit-card-sync"
    },
    {
        "value": "credit-card-sync-outline"
    },
    {
        "value": "credit-card-wireless"
    },
    {
        "value": "credit-card-wireless-off"
    },
    {
        "value": "credit-card-wireless-off-outline"
    },
    {
        "value": "credit-card-wireless-outline"
    },
    {
        "value": "cricket"
    },
    {
        "value": "crop"
    },
    {
        "value": "crop-free"
    },
    {
        "value": "crop-landscape"
    },
    {
        "value": "crop-portrait"
    },
    {
        "value": "crop-rotate"
    },
    {
        "value": "crop-square"
    },
    {
        "value": "cross"
    },
    {
        "value": "cross-outline"
    },
    {
        "value": "crosshairs"
    },
    {
        "value": "crosshairs-gps"
    },
    {
        "value": "crosshairs-off"
    },
    {
        "value": "crosshairs-question"
    },
    {
        "value": "crown"
    },
    {
        "value": "crown-outline"
    },
    {
        "value": "cryengine"
    },
    {
        "value": "crystal-ball"
    },
    {
        "value": "cube"
    },
    {
        "value": "cube-off"
    },
    {
        "value": "cube-off-outline"
    },
    {
        "value": "cube-outline"
    },
    {
        "value": "cube-scan"
    },
    {
        "value": "cube-send"
    },
    {
        "value": "cube-unfolded"
    },
    {
        "value": "cup"
    },
    {
        "value": "cup-off"
    },
    {
        "value": "cup-off-outline"
    },
    {
        "value": "cup-outline"
    },
    {
        "value": "cup-water"
    },
    {
        "value": "cupboard"
    },
    {
        "value": "cupboard-outline"
    },
    {
        "value": "cupcake"
    },
    {
        "value": "curling"
    },
    {
        "value": "currency-bdt"
    },
    {
        "value": "currency-brl"
    },
    {
        "value": "currency-btc"
    },
    {
        "value": "currency-cny"
    },
    {
        "value": "currency-eth"
    },
    {
        "value": "currency-eur"
    },
    {
        "value": "currency-eur-off"
    },
    {
        "value": "currency-gbp"
    },
    {
        "value": "currency-ils"
    },
    {
        "value": "currency-inr"
    },
    {
        "value": "currency-jpy"
    },
    {
        "value": "currency-krw"
    },
    {
        "value": "currency-kzt"
    },
    {
        "value": "currency-mnt"
    },
    {
        "value": "currency-ngn"
    },
    {
        "value": "currency-php"
    },
    {
        "value": "currency-rial"
    },
    {
        "value": "currency-rub"
    },
    {
        "value": "currency-sign"
    },
    {
        "value": "currency-try"
    },
    {
        "value": "currency-twd"
    },
    {
        "value": "currency-usd"
    },
    {
        "value": "currency-usd-off"
    },
    {
        "value": "current-ac"
    },
    {
        "value": "current-dc"
    },
    {
        "value": "cursor-default"
    },
    {
        "value": "cursor-default-click"
    },
    {
        "value": "cursor-default-click-outline"
    },
    {
        "value": "cursor-default-gesture"
    },
    {
        "value": "cursor-default-gesture-outline"
    },
    {
        "value": "cursor-default-outline"
    },
    {
        "value": "cursor-move"
    },
    {
        "value": "cursor-pointer"
    },
    {
        "value": "cursor-text"
    },
    {
        "value": "dance-ballroom"
    },
    {
        "value": "dance-pole"
    },
    {
        "value": "data-matrix"
    },
    {
        "value": "data-matrix-edit"
    },
    {
        "value": "data-matrix-minus"
    },
    {
        "value": "data-matrix-plus"
    },
    {
        "value": "data-matrix-remove"
    },
    {
        "value": "data-matrix-scan"
    },
    {
        "value": "database"
    },
    {
        "value": "database-alert"
    },
    {
        "value": "database-alert-outline"
    },
    {
        "value": "database-arrow-down"
    },
    {
        "value": "database-arrow-down-outline"
    },
    {
        "value": "database-arrow-left"
    },
    {
        "value": "database-arrow-left-outline"
    },
    {
        "value": "database-arrow-right"
    },
    {
        "value": "database-arrow-right-outline"
    },
    {
        "value": "database-arrow-up"
    },
    {
        "value": "database-arrow-up-outline"
    },
    {
        "value": "database-check"
    },
    {
        "value": "database-check-outline"
    },
    {
        "value": "database-clock"
    },
    {
        "value": "database-clock-outline"
    },
    {
        "value": "database-cog"
    },
    {
        "value": "database-cog-outline"
    },
    {
        "value": "database-edit"
    },
    {
        "value": "database-edit-outline"
    },
    {
        "value": "database-export"
    },
    {
        "value": "database-export-outline"
    },
    {
        "value": "database-import"
    },
    {
        "value": "database-import-outline"
    },
    {
        "value": "database-lock"
    },
    {
        "value": "database-lock-outline"
    },
    {
        "value": "database-marker"
    },
    {
        "value": "database-marker-outline"
    },
    {
        "value": "database-minus"
    },
    {
        "value": "database-minus-outline"
    },
    {
        "value": "database-off"
    },
    {
        "value": "database-off-outline"
    },
    {
        "value": "database-outline"
    },
    {
        "value": "database-plus"
    },
    {
        "value": "database-plus-outline"
    },
    {
        "value": "database-refresh"
    },
    {
        "value": "database-refresh-outline"
    },
    {
        "value": "database-remove"
    },
    {
        "value": "database-remove-outline"
    },
    {
        "value": "database-search"
    },
    {
        "value": "database-search-outline"
    },
    {
        "value": "database-settings"
    },
    {
        "value": "database-settings-outline"
    },
    {
        "value": "database-sync"
    },
    {
        "value": "database-sync-outline"
    },
    {
        "value": "death-star"
    },
    {
        "value": "death-star-variant"
    },
    {
        "value": "deathly-hallows"
    },
    {
        "value": "debian"
    },
    {
        "value": "debug-step-into"
    },
    {
        "value": "debug-step-out"
    },
    {
        "value": "debug-step-over"
    },
    {
        "value": "decagram"
    },
    {
        "value": "decagram-outline"
    },
    {
        "value": "decimal"
    },
    {
        "value": "decimal-comma"
    },
    {
        "value": "decimal-comma-decrease"
    },
    {
        "value": "decimal-comma-increase"
    },
    {
        "value": "decimal-decrease"
    },
    {
        "value": "decimal-increase"
    },
    {
        "value": "delete"
    },
    {
        "value": "delete-alert"
    },
    {
        "value": "delete-alert-outline"
    },
    {
        "value": "delete-circle"
    },
    {
        "value": "delete-circle-outline"
    },
    {
        "value": "delete-clock"
    },
    {
        "value": "delete-clock-outline"
    },
    {
        "value": "delete-empty"
    },
    {
        "value": "delete-empty-outline"
    },
    {
        "value": "delete-forever"
    },
    {
        "value": "delete-forever-outline"
    },
    {
        "value": "delete-off"
    },
    {
        "value": "delete-off-outline"
    },
    {
        "value": "delete-outline"
    },
    {
        "value": "delete-restore"
    },
    {
        "value": "delete-sweep"
    },
    {
        "value": "delete-sweep-outline"
    },
    {
        "value": "delete-variant"
    },
    {
        "value": "delta"
    },
    {
        "value": "desk"
    },
    {
        "value": "desk-lamp"
    },
    {
        "value": "deskphone"
    },
    {
        "value": "desktop-classic"
    },
    {
        "value": "desktop-mac"
    },
    {
        "value": "desktop-mac-dashboard"
    },
    {
        "value": "desktop-tower"
    },
    {
        "value": "desktop-tower-monitor"
    },
    {
        "value": "details"
    },
    {
        "value": "dev-to"
    },
    {
        "value": "developer-board"
    },
    {
        "value": "deviantart"
    },
    {
        "value": "devices"
    },
    {
        "value": "dharmachakra"
    },
    {
        "value": "diabetes"
    },
    {
        "value": "dialpad"
    },
    {
        "value": "diameter"
    },
    {
        "value": "diameter-outline"
    },
    {
        "value": "diameter-variant"
    },
    {
        "value": "diamond"
    },
    {
        "value": "diamond-outline"
    },
    {
        "value": "diamond-stone"
    },
    {
        "value": "dice-1"
    },
    {
        "value": "dice-1-outline"
    },
    {
        "value": "dice-2"
    },
    {
        "value": "dice-2-outline"
    },
    {
        "value": "dice-3"
    },
    {
        "value": "dice-3-outline"
    },
    {
        "value": "dice-4"
    },
    {
        "value": "dice-4-outline"
    },
    {
        "value": "dice-5"
    },
    {
        "value": "dice-5-outline"
    },
    {
        "value": "dice-6"
    },
    {
        "value": "dice-6-outline"
    },
    {
        "value": "dice-d10"
    },
    {
        "value": "dice-d10-outline"
    },
    {
        "value": "dice-d12"
    },
    {
        "value": "dice-d12-outline"
    },
    {
        "value": "dice-d20"
    },
    {
        "value": "dice-d20-outline"
    },
    {
        "value": "dice-d4"
    },
    {
        "value": "dice-d4-outline"
    },
    {
        "value": "dice-d6"
    },
    {
        "value": "dice-d6-outline"
    },
    {
        "value": "dice-d8"
    },
    {
        "value": "dice-d8-outline"
    },
    {
        "value": "dice-multiple"
    },
    {
        "value": "dice-multiple-outline"
    },
    {
        "value": "digital-ocean"
    },
    {
        "value": "dip-switch"
    },
    {
        "value": "directions"
    },
    {
        "value": "directions-fork"
    },
    {
        "value": "disc"
    },
    {
        "value": "disc-alert"
    },
    {
        "value": "disc-player"
    },
    {
        "value": "discord"
    },
    {
        "value": "dishwasher"
    },
    {
        "value": "dishwasher-alert"
    },
    {
        "value": "dishwasher-off"
    },
    {
        "value": "disqus"
    },
    {
        "value": "distribute-horizontal-center"
    },
    {
        "value": "distribute-horizontal-left"
    },
    {
        "value": "distribute-horizontal-right"
    },
    {
        "value": "distribute-vertical-bottom"
    },
    {
        "value": "distribute-vertical-center"
    },
    {
        "value": "distribute-vertical-top"
    },
    {
        "value": "diving-flippers"
    },
    {
        "value": "diving-helmet"
    },
    {
        "value": "diving-scuba"
    },
    {
        "value": "diving-scuba-flag"
    },
    {
        "value": "diving-scuba-tank"
    },
    {
        "value": "diving-scuba-tank-multiple"
    },
    {
        "value": "diving-snorkel"
    },
    {
        "value": "division"
    },
    {
        "value": "division-box"
    },
    {
        "value": "dlna"
    },
    {
        "value": "dna"
    },
    {
        "value": "dns"
    },
    {
        "value": "dns-outline"
    },
    {
        "value": "dock-bottom"
    },
    {
        "value": "dock-left"
    },
    {
        "value": "dock-right"
    },
    {
        "value": "dock-top"
    },
    {
        "value": "dock-window"
    },
    {
        "value": "docker"
    },
    {
        "value": "doctor"
    },
    {
        "value": "dog"
    },
    {
        "value": "dog-service"
    },
    {
        "value": "dog-side"
    },
    {
        "value": "dog-side-off"
    },
    {
        "value": "dolby"
    },
    {
        "value": "dolly"
    },
    {
        "value": "domain"
    },
    {
        "value": "domain-off"
    },
    {
        "value": "domain-plus"
    },
    {
        "value": "domain-remove"
    },
    {
        "value": "dome-light"
    },
    {
        "value": "domino-mask"
    },
    {
        "value": "donkey"
    },
    {
        "value": "door"
    },
    {
        "value": "door-closed"
    },
    {
        "value": "door-closed-lock"
    },
    {
        "value": "door-open"
    },
    {
        "value": "doorbell"
    },
    {
        "value": "doorbell-video"
    },
    {
        "value": "dot-net"
    },
    {
        "value": "dots-grid"
    },
    {
        "value": "dots-hexagon"
    },
    {
        "value": "dots-horizontal"
    },
    {
        "value": "dots-horizontal-circle"
    },
    {
        "value": "dots-horizontal-circle-outline"
    },
    {
        "value": "dots-square"
    },
    {
        "value": "dots-triangle"
    },
    {
        "value": "dots-vertical"
    },
    {
        "value": "dots-vertical-circle"
    },
    {
        "value": "dots-vertical-circle-outline"
    },
    {
        "value": "douban"
    },
    {
        "value": "download"
    },
    {
        "value": "download-box"
    },
    {
        "value": "download-box-outline"
    },
    {
        "value": "download-circle"
    },
    {
        "value": "download-circle-outline"
    },
    {
        "value": "download-lock"
    },
    {
        "value": "download-lock-outline"
    },
    {
        "value": "download-multiple"
    },
    {
        "value": "download-network"
    },
    {
        "value": "download-network-outline"
    },
    {
        "value": "download-off"
    },
    {
        "value": "download-off-outline"
    },
    {
        "value": "download-outline"
    },
    {
        "value": "drag"
    },
    {
        "value": "drag-horizontal"
    },
    {
        "value": "drag-horizontal-variant"
    },
    {
        "value": "drag-variant"
    },
    {
        "value": "drag-vertical"
    },
    {
        "value": "drag-vertical-variant"
    },
    {
        "value": "drama-masks"
    },
    {
        "value": "draw"
    },
    {
        "value": "drawing"
    },
    {
        "value": "drawing-box"
    },
    {
        "value": "dresser"
    },
    {
        "value": "dresser-outline"
    },
    {
        "value": "drone"
    },
    {
        "value": "dropbox"
    },
    {
        "value": "drupal"
    },
    {
        "value": "duck"
    },
    {
        "value": "dumbbell"
    },
    {
        "value": "dump-truck"
    },
    {
        "value": "ear-hearing"
    },
    {
        "value": "ear-hearing-off"
    },
    {
        "value": "earth"
    },
    {
        "value": "earth-arrow-right"
    },
    {
        "value": "earth-box"
    },
    {
        "value": "earth-box-minus"
    },
    {
        "value": "earth-box-off"
    },
    {
        "value": "earth-box-plus"
    },
    {
        "value": "earth-box-remove"
    },
    {
        "value": "earth-minus"
    },
    {
        "value": "earth-off"
    },
    {
        "value": "earth-plus"
    },
    {
        "value": "earth-remove"
    },
    {
        "value": "egg"
    },
    {
        "value": "egg-easter"
    },
    {
        "value": "egg-off"
    },
    {
        "value": "egg-off-outline"
    },
    {
        "value": "egg-outline"
    },
    {
        "value": "eiffel-tower"
    },
    {
        "value": "eight-track"
    },
    {
        "value": "eject"
    },
    {
        "value": "eject-outline"
    },
    {
        "value": "electric-switch"
    },
    {
        "value": "electric-switch-closed"
    },
    {
        "value": "electron-framework"
    },
    {
        "value": "elephant"
    },
    {
        "value": "elevation-decline"
    },
    {
        "value": "elevation-rise"
    },
    {
        "value": "elevator"
    },
    {
        "value": "elevator-down"
    },
    {
        "value": "elevator-passenger"
    },
    {
        "value": "elevator-up"
    },
    {
        "value": "ellipse"
    },
    {
        "value": "ellipse-outline"
    },
    {
        "value": "email"
    },
    {
        "value": "email-alert"
    },
    {
        "value": "email-alert-outline"
    },
    {
        "value": "email-box"
    },
    {
        "value": "email-check"
    },
    {
        "value": "email-check-outline"
    },
    {
        "value": "email-edit"
    },
    {
        "value": "email-edit-outline"
    },
    {
        "value": "email-lock"
    },
    {
        "value": "email-mark-as-unread"
    },
    {
        "value": "email-minus"
    },
    {
        "value": "email-minus-outline"
    },
    {
        "value": "email-multiple"
    },
    {
        "value": "email-multiple-outline"
    },
    {
        "value": "email-newsletter"
    },
    {
        "value": "email-off"
    },
    {
        "value": "email-off-outline"
    },
    {
        "value": "email-open"
    },
    {
        "value": "email-open-multiple"
    },
    {
        "value": "email-open-multiple-outline"
    },
    {
        "value": "email-open-outline"
    },
    {
        "value": "email-outline"
    },
    {
        "value": "email-plus"
    },
    {
        "value": "email-plus-outline"
    },
    {
        "value": "email-receive"
    },
    {
        "value": "email-receive-outline"
    },
    {
        "value": "email-remove"
    },
    {
        "value": "email-remove-outline"
    },
    {
        "value": "email-search"
    },
    {
        "value": "email-search-outline"
    },
    {
        "value": "email-send"
    },
    {
        "value": "email-send-outline"
    },
    {
        "value": "email-sync"
    },
    {
        "value": "email-sync-outline"
    },
    {
        "value": "email-variant"
    },
    {
        "value": "ember"
    },
    {
        "value": "emby"
    },
    {
        "value": "emoticon"
    },
    {
        "value": "emoticon-angry"
    },
    {
        "value": "emoticon-angry-outline"
    },
    {
        "value": "emoticon-confused"
    },
    {
        "value": "emoticon-confused-outline"
    },
    {
        "value": "emoticon-cool"
    },
    {
        "value": "emoticon-cool-outline"
    },
    {
        "value": "emoticon-cry"
    },
    {
        "value": "emoticon-cry-outline"
    },
    {
        "value": "emoticon-dead"
    },
    {
        "value": "emoticon-dead-outline"
    },
    {
        "value": "emoticon-devil"
    },
    {
        "value": "emoticon-devil-outline"
    },
    {
        "value": "emoticon-excited"
    },
    {
        "value": "emoticon-excited-outline"
    },
    {
        "value": "emoticon-frown"
    },
    {
        "value": "emoticon-frown-outline"
    },
    {
        "value": "emoticon-happy"
    },
    {
        "value": "emoticon-happy-outline"
    },
    {
        "value": "emoticon-kiss"
    },
    {
        "value": "emoticon-kiss-outline"
    },
    {
        "value": "emoticon-lol"
    },
    {
        "value": "emoticon-lol-outline"
    },
    {
        "value": "emoticon-neutral"
    },
    {
        "value": "emoticon-neutral-outline"
    },
    {
        "value": "emoticon-outline"
    },
    {
        "value": "emoticon-poop"
    },
    {
        "value": "emoticon-poop-outline"
    },
    {
        "value": "emoticon-sad"
    },
    {
        "value": "emoticon-sad-outline"
    },
    {
        "value": "emoticon-sick"
    },
    {
        "value": "emoticon-sick-outline"
    },
    {
        "value": "emoticon-tongue"
    },
    {
        "value": "emoticon-tongue-outline"
    },
    {
        "value": "emoticon-wink"
    },
    {
        "value": "emoticon-wink-outline"
    },
    {
        "value": "engine"
    },
    {
        "value": "engine-off"
    },
    {
        "value": "engine-off-outline"
    },
    {
        "value": "engine-outline"
    },
    {
        "value": "epsilon"
    },
    {
        "value": "equal"
    },
    {
        "value": "equal-box"
    },
    {
        "value": "equalizer"
    },
    {
        "value": "equalizer-outline"
    },
    {
        "value": "eraser"
    },
    {
        "value": "eraser-variant"
    },
    {
        "value": "escalator"
    },
    {
        "value": "escalator-box"
    },
    {
        "value": "escalator-down"
    },
    {
        "value": "escalator-up"
    },
    {
        "value": "eslint"
    },
    {
        "value": "et"
    },
    {
        "value": "ethereum"
    },
    {
        "value": "ethernet"
    },
    {
        "value": "ethernet-cable"
    },
    {
        "value": "ethernet-cable-off"
    },
    {
        "value": "ev-plug-ccs1"
    },
    {
        "value": "ev-plug-ccs2"
    },
    {
        "value": "ev-plug-chademo"
    },
    {
        "value": "ev-plug-tesla"
    },
    {
        "value": "ev-plug-type1"
    },
    {
        "value": "ev-plug-type2"
    },
    {
        "value": "ev-station"
    },
    {
        "value": "evernote"
    },
    {
        "value": "excavator"
    },
    {
        "value": "exclamation"
    },
    {
        "value": "exclamation-thick"
    },
    {
        "value": "exit-run"
    },
    {
        "value": "exit-to-app"
    },
    {
        "value": "expand-all"
    },
    {
        "value": "expand-all-outline"
    },
    {
        "value": "expansion-card"
    },
    {
        "value": "expansion-card-variant"
    },
    {
        "value": "exponent"
    },
    {
        "value": "exponent-box"
    },
    {
        "value": "export"
    },
    {
        "value": "export-variant"
    },
    {
        "value": "eye"
    },
    {
        "value": "eye-check"
    },
    {
        "value": "eye-check-outline"
    },
    {
        "value": "eye-circle"
    },
    {
        "value": "eye-circle-outline"
    },
    {
        "value": "eye-minus"
    },
    {
        "value": "eye-minus-outline"
    },
    {
        "value": "eye-off"
    },
    {
        "value": "eye-off-outline"
    },
    {
        "value": "eye-outline"
    },
    {
        "value": "eye-plus"
    },
    {
        "value": "eye-plus-outline"
    },
    {
        "value": "eye-remove"
    },
    {
        "value": "eye-remove-outline"
    },
    {
        "value": "eye-settings"
    },
    {
        "value": "eye-settings-outline"
    },
    {
        "value": "eyedropper"
    },
    {
        "value": "eyedropper-minus"
    },
    {
        "value": "eyedropper-off"
    },
    {
        "value": "eyedropper-plus"
    },
    {
        "value": "eyedropper-remove"
    },
    {
        "value": "eyedropper-variant"
    },
    {
        "value": "face-agent"
    },
    {
        "value": "face-man"
    },
    {
        "value": "face-man-outline"
    },
    {
        "value": "face-man-profile"
    },
    {
        "value": "face-man-shimmer"
    },
    {
        "value": "face-man-shimmer-outline"
    },
    {
        "value": "face-mask"
    },
    {
        "value": "face-mask-outline"
    },
    {
        "value": "face-recognition"
    },
    {
        "value": "face-woman"
    },
    {
        "value": "face-woman-outline"
    },
    {
        "value": "face-woman-profile"
    },
    {
        "value": "face-woman-shimmer"
    },
    {
        "value": "face-woman-shimmer-outline"
    },
    {
        "value": "facebook"
    },
    {
        "value": "facebook-gaming"
    },
    {
        "value": "facebook-messenger"
    },
    {
        "value": "facebook-workplace"
    },
    {
        "value": "factory"
    },
    {
        "value": "family-tree"
    },
    {
        "value": "fan"
    },
    {
        "value": "fan-alert"
    },
    {
        "value": "fan-auto"
    },
    {
        "value": "fan-chevron-down"
    },
    {
        "value": "fan-chevron-up"
    },
    {
        "value": "fan-minus"
    },
    {
        "value": "fan-off"
    },
    {
        "value": "fan-plus"
    },
    {
        "value": "fan-remove"
    },
    {
        "value": "fan-speed-1"
    },
    {
        "value": "fan-speed-2"
    },
    {
        "value": "fan-speed-3"
    },
    {
        "value": "fast-forward"
    },
    {
        "value": "fast-forward-10"
    },
    {
        "value": "fast-forward-30"
    },
    {
        "value": "fast-forward-5"
    },
    {
        "value": "fast-forward-60"
    },
    {
        "value": "fast-forward-outline"
    },
    {
        "value": "fax"
    },
    {
        "value": "feather"
    },
    {
        "value": "feature-search"
    },
    {
        "value": "feature-search-outline"
    },
    {
        "value": "fedora"
    },
    {
        "value": "fencing"
    },
    {
        "value": "ferris-wheel"
    },
    {
        "value": "ferry"
    },
    {
        "value": "file"
    },
    {
        "value": "file-account"
    },
    {
        "value": "file-account-outline"
    },
    {
        "value": "file-alert"
    },
    {
        "value": "file-alert-outline"
    },
    {
        "value": "file-cabinet"
    },
    {
        "value": "file-cad"
    },
    {
        "value": "file-cad-box"
    },
    {
        "value": "file-cancel"
    },
    {
        "value": "file-cancel-outline"
    },
    {
        "value": "file-certificate"
    },
    {
        "value": "file-certificate-outline"
    },
    {
        "value": "file-chart"
    },
    {
        "value": "file-chart-outline"
    },
    {
        "value": "file-check"
    },
    {
        "value": "file-check-outline"
    },
    {
        "value": "file-clock"
    },
    {
        "value": "file-clock-outline"
    },
    {
        "value": "file-cloud"
    },
    {
        "value": "file-cloud-outline"
    },
    {
        "value": "file-code"
    },
    {
        "value": "file-code-outline"
    },
    {
        "value": "file-cog"
    },
    {
        "value": "file-cog-outline"
    },
    {
        "value": "file-compare"
    },
    {
        "value": "file-delimited"
    },
    {
        "value": "file-delimited-outline"
    },
    {
        "value": "file-document"
    },
    {
        "value": "file-document-edit"
    },
    {
        "value": "file-document-edit-outline"
    },
    {
        "value": "file-document-multiple"
    },
    {
        "value": "file-document-multiple-outline"
    },
    {
        "value": "file-document-outline"
    },
    {
        "value": "file-download"
    },
    {
        "value": "file-download-outline"
    },
    {
        "value": "file-edit"
    },
    {
        "value": "file-edit-outline"
    },
    {
        "value": "file-excel"
    },
    {
        "value": "file-excel-box"
    },
    {
        "value": "file-excel-box-outline"
    },
    {
        "value": "file-excel-outline"
    },
    {
        "value": "file-export"
    },
    {
        "value": "file-export-outline"
    },
    {
        "value": "file-eye"
    },
    {
        "value": "file-eye-outline"
    },
    {
        "value": "file-find"
    },
    {
        "value": "file-find-outline"
    },
    {
        "value": "file-hidden"
    },
    {
        "value": "file-image"
    },
    {
        "value": "file-image-marker"
    },
    {
        "value": "file-image-marker-outline"
    },
    {
        "value": "file-image-outline"
    },
    {
        "value": "file-import"
    },
    {
        "value": "file-import-outline"
    },
    {
        "value": "file-key"
    },
    {
        "value": "file-key-outline"
    },
    {
        "value": "file-link"
    },
    {
        "value": "file-link-outline"
    },
    {
        "value": "file-lock"
    },
    {
        "value": "file-lock-outline"
    },
    {
        "value": "file-marker"
    },
    {
        "value": "file-marker-outline"
    },
    {
        "value": "file-move"
    },
    {
        "value": "file-move-outline"
    },
    {
        "value": "file-multiple"
    },
    {
        "value": "file-multiple-outline"
    },
    {
        "value": "file-music"
    },
    {
        "value": "file-music-outline"
    },
    {
        "value": "file-outline"
    },
    {
        "value": "file-pdf"
    },
    {
        "value": "file-pdf-box"
    },
    {
        "value": "file-pdf-box-outline"
    },
    {
        "value": "file-pdf-outline"
    },
    {
        "value": "file-percent"
    },
    {
        "value": "file-percent-outline"
    },
    {
        "value": "file-phone"
    },
    {
        "value": "file-phone-outline"
    },
    {
        "value": "file-plus"
    },
    {
        "value": "file-plus-outline"
    },
    {
        "value": "file-powerpoint"
    },
    {
        "value": "file-powerpoint-box"
    },
    {
        "value": "file-powerpoint-box-outline"
    },
    {
        "value": "file-powerpoint-outline"
    },
    {
        "value": "file-presentation-box"
    },
    {
        "value": "file-question"
    },
    {
        "value": "file-question-outline"
    },
    {
        "value": "file-refresh"
    },
    {
        "value": "file-refresh-outline"
    },
    {
        "value": "file-remove"
    },
    {
        "value": "file-remove-outline"
    },
    {
        "value": "file-replace"
    },
    {
        "value": "file-replace-outline"
    },
    {
        "value": "file-restore"
    },
    {
        "value": "file-restore-outline"
    },
    {
        "value": "file-search"
    },
    {
        "value": "file-search-outline"
    },
    {
        "value": "file-send"
    },
    {
        "value": "file-send-outline"
    },
    {
        "value": "file-settings"
    },
    {
        "value": "file-settings-outline"
    },
    {
        "value": "file-star"
    },
    {
        "value": "file-star-outline"
    },
    {
        "value": "file-swap"
    },
    {
        "value": "file-swap-outline"
    },
    {
        "value": "file-sync"
    },
    {
        "value": "file-sync-outline"
    },
    {
        "value": "file-table"
    },
    {
        "value": "file-table-box"
    },
    {
        "value": "file-table-box-multiple"
    },
    {
        "value": "file-table-box-multiple-outline"
    },
    {
        "value": "file-table-box-outline"
    },
    {
        "value": "file-table-outline"
    },
    {
        "value": "file-tree"
    },
    {
        "value": "file-tree-outline"
    },
    {
        "value": "file-undo"
    },
    {
        "value": "file-undo-outline"
    },
    {
        "value": "file-upload"
    },
    {
        "value": "file-upload-outline"
    },
    {
        "value": "file-video"
    },
    {
        "value": "file-video-outline"
    },
    {
        "value": "file-word"
    },
    {
        "value": "file-word-box"
    },
    {
        "value": "file-word-box-outline"
    },
    {
        "value": "file-word-outline"
    },
    {
        "value": "film"
    },
    {
        "value": "filmstrip"
    },
    {
        "value": "filmstrip-box"
    },
    {
        "value": "filmstrip-box-multiple"
    },
    {
        "value": "filmstrip-off"
    },
    {
        "value": "filter"
    },
    {
        "value": "filter-menu"
    },
    {
        "value": "filter-menu-outline"
    },
    {
        "value": "filter-minus"
    },
    {
        "value": "filter-minus-outline"
    },
    {
        "value": "filter-off"
    },
    {
        "value": "filter-off-outline"
    },
    {
        "value": "filter-outline"
    },
    {
        "value": "filter-plus"
    },
    {
        "value": "filter-plus-outline"
    },
    {
        "value": "filter-remove"
    },
    {
        "value": "filter-remove-outline"
    },
    {
        "value": "filter-variant"
    },
    {
        "value": "filter-variant-minus"
    },
    {
        "value": "filter-variant-plus"
    },
    {
        "value": "filter-variant-remove"
    },
    {
        "value": "finance"
    },
    {
        "value": "find-replace"
    },
    {
        "value": "fingerprint"
    },
    {
        "value": "fingerprint-off"
    },
    {
        "value": "fire"
    },
    {
        "value": "fire-alert"
    },
    {
        "value": "fire-extinguisher"
    },
    {
        "value": "fire-hydrant"
    },
    {
        "value": "fire-hydrant-alert"
    },
    {
        "value": "fire-hydrant-off"
    },
    {
        "value": "fire-off"
    },
    {
        "value": "fire-truck"
    },
    {
        "value": "firebase"
    },
    {
        "value": "firefox"
    },
    {
        "value": "fireplace"
    },
    {
        "value": "fireplace-off"
    },
    {
        "value": "firework"
    },
    {
        "value": "firework-off"
    },
    {
        "value": "fish"
    },
    {
        "value": "fish-off"
    },
    {
        "value": "fishbowl"
    },
    {
        "value": "fishbowl-outline"
    },
    {
        "value": "fit-to-page"
    },
    {
        "value": "fit-to-page-outline"
    },
    {
        "value": "flag"
    },
    {
        "value": "flag-checkered"
    },
    {
        "value": "flag-minus"
    },
    {
        "value": "flag-minus-outline"
    },
    {
        "value": "flag-outline"
    },
    {
        "value": "flag-plus"
    },
    {
        "value": "flag-plus-outline"
    },
    {
        "value": "flag-remove"
    },
    {
        "value": "flag-remove-outline"
    },
    {
        "value": "flag-triangle"
    },
    {
        "value": "flag-variant"
    },
    {
        "value": "flag-variant-outline"
    },
    {
        "value": "flare"
    },
    {
        "value": "flash"
    },
    {
        "value": "flash-alert"
    },
    {
        "value": "flash-alert-outline"
    },
    {
        "value": "flash-auto"
    },
    {
        "value": "flash-circle"
    },
    {
        "value": "flash-off"
    },
    {
        "value": "flash-outline"
    },
    {
        "value": "flash-red-eye"
    },
    {
        "value": "flashlight"
    },
    {
        "value": "flashlight-off"
    },
    {
        "value": "flask"
    },
    {
        "value": "flask-empty"
    },
    {
        "value": "flask-empty-minus"
    },
    {
        "value": "flask-empty-minus-outline"
    },
    {
        "value": "flask-empty-off"
    },
    {
        "value": "flask-empty-off-outline"
    },
    {
        "value": "flask-empty-outline"
    },
    {
        "value": "flask-empty-plus"
    },
    {
        "value": "flask-empty-plus-outline"
    },
    {
        "value": "flask-empty-remove"
    },
    {
        "value": "flask-empty-remove-outline"
    },
    {
        "value": "flask-minus"
    },
    {
        "value": "flask-minus-outline"
    },
    {
        "value": "flask-off"
    },
    {
        "value": "flask-off-outline"
    },
    {
        "value": "flask-outline"
    },
    {
        "value": "flask-plus"
    },
    {
        "value": "flask-plus-outline"
    },
    {
        "value": "flask-remove"
    },
    {
        "value": "flask-remove-outline"
    },
    {
        "value": "flask-round-bottom"
    },
    {
        "value": "flask-round-bottom-empty"
    },
    {
        "value": "flask-round-bottom-empty-outline"
    },
    {
        "value": "flask-round-bottom-outline"
    },
    {
        "value": "fleur-de-lis"
    },
    {
        "value": "flip-horizontal"
    },
    {
        "value": "flip-to-back"
    },
    {
        "value": "flip-to-front"
    },
    {
        "value": "flip-vertical"
    },
    {
        "value": "floor-lamp"
    },
    {
        "value": "floor-lamp-dual"
    },
    {
        "value": "floor-lamp-torchiere"
    },
    {
        "value": "floor-lamp-torchiere-variant"
    },
    {
        "value": "floor-plan"
    },
    {
        "value": "floppy"
    },
    {
        "value": "floppy-variant"
    },
    {
        "value": "flower"
    },
    {
        "value": "flower-outline"
    },
    {
        "value": "flower-poppy"
    },
    {
        "value": "flower-tulip"
    },
    {
        "value": "flower-tulip-outline"
    },
    {
        "value": "focus-auto"
    },
    {
        "value": "focus-field"
    },
    {
        "value": "focus-field-horizontal"
    },
    {
        "value": "focus-field-vertical"
    },
    {
        "value": "folder"
    },
    {
        "value": "folder-account"
    },
    {
        "value": "folder-account-outline"
    },
    {
        "value": "folder-alert"
    },
    {
        "value": "folder-alert-outline"
    },
    {
        "value": "folder-clock"
    },
    {
        "value": "folder-clock-outline"
    },
    {
        "value": "folder-cog"
    },
    {
        "value": "folder-cog-outline"
    },
    {
        "value": "folder-download"
    },
    {
        "value": "folder-download-outline"
    },
    {
        "value": "folder-edit"
    },
    {
        "value": "folder-edit-outline"
    },
    {
        "value": "folder-eye"
    },
    {
        "value": "folder-eye-outline"
    },
    {
        "value": "folder-google-drive"
    },
    {
        "value": "folder-heart"
    },
    {
        "value": "folder-heart-outline"
    },
    {
        "value": "folder-home"
    },
    {
        "value": "folder-home-outline"
    },
    {
        "value": "folder-image"
    },
    {
        "value": "folder-information"
    },
    {
        "value": "folder-information-outline"
    },
    {
        "value": "folder-key"
    },
    {
        "value": "folder-key-network"
    },
    {
        "value": "folder-key-network-outline"
    },
    {
        "value": "folder-key-outline"
    },
    {
        "value": "folder-lock"
    },
    {
        "value": "folder-lock-open"
    },
    {
        "value": "folder-marker"
    },
    {
        "value": "folder-marker-outline"
    },
    {
        "value": "folder-move"
    },
    {
        "value": "folder-move-outline"
    },
    {
        "value": "folder-multiple"
    },
    {
        "value": "folder-multiple-image"
    },
    {
        "value": "folder-multiple-outline"
    },
    {
        "value": "folder-multiple-plus"
    },
    {
        "value": "folder-multiple-plus-outline"
    },
    {
        "value": "folder-music"
    },
    {
        "value": "folder-music-outline"
    },
    {
        "value": "folder-network"
    },
    {
        "value": "folder-network-outline"
    },
    {
        "value": "folder-open"
    },
    {
        "value": "folder-open-outline"
    },
    {
        "value": "folder-outline"
    },
    {
        "value": "folder-plus"
    },
    {
        "value": "folder-plus-outline"
    },
    {
        "value": "folder-pound"
    },
    {
        "value": "folder-pound-outline"
    },
    {
        "value": "folder-refresh"
    },
    {
        "value": "folder-refresh-outline"
    },
    {
        "value": "folder-remove"
    },
    {
        "value": "folder-remove-outline"
    },
    {
        "value": "folder-search"
    },
    {
        "value": "folder-search-outline"
    },
    {
        "value": "folder-settings"
    },
    {
        "value": "folder-settings-outline"
    },
    {
        "value": "folder-star"
    },
    {
        "value": "folder-star-multiple"
    },
    {
        "value": "folder-star-multiple-outline"
    },
    {
        "value": "folder-star-outline"
    },
    {
        "value": "folder-swap"
    },
    {
        "value": "folder-swap-outline"
    },
    {
        "value": "folder-sync"
    },
    {
        "value": "folder-sync-outline"
    },
    {
        "value": "folder-table"
    },
    {
        "value": "folder-table-outline"
    },
    {
        "value": "folder-text"
    },
    {
        "value": "folder-text-outline"
    },
    {
        "value": "folder-upload"
    },
    {
        "value": "folder-upload-outline"
    },
    {
        "value": "folder-zip"
    },
    {
        "value": "folder-zip-outline"
    },
    {
        "value": "font-awesome"
    },
    {
        "value": "food"
    },
    {
        "value": "food-apple"
    },
    {
        "value": "food-apple-outline"
    },
    {
        "value": "food-croissant"
    },
    {
        "value": "food-drumstick"
    },
    {
        "value": "food-drumstick-off"
    },
    {
        "value": "food-drumstick-off-outline"
    },
    {
        "value": "food-drumstick-outline"
    },
    {
        "value": "food-fork-drink"
    },
    {
        "value": "food-halal"
    },
    {
        "value": "food-kosher"
    },
    {
        "value": "food-off"
    },
    {
        "value": "food-steak"
    },
    {
        "value": "food-steak-off"
    },
    {
        "value": "food-turkey"
    },
    {
        "value": "food-variant"
    },
    {
        "value": "food-variant-off"
    },
    {
        "value": "foot-print"
    },
    {
        "value": "football"
    },
    {
        "value": "football-australian"
    },
    {
        "value": "football-helmet"
    },
    {
        "value": "forklift"
    },
    {
        "value": "form-dropdown"
    },
    {
        "value": "form-select"
    },
    {
        "value": "form-textarea"
    },
    {
        "value": "form-textbox"
    },
    {
        "value": "rename"
    },
    {
        "value": "form-textbox-lock"
    },
    {
        "value": "form-textbox-password"
    },
    {
        "value": "format-align-bottom"
    },
    {
        "value": "format-align-center"
    },
    {
        "value": "format-align-justify"
    },
    {
        "value": "format-align-left"
    },
    {
        "value": "format-align-middle"
    },
    {
        "value": "format-align-right"
    },
    {
        "value": "format-align-top"
    },
    {
        "value": "format-annotation-minus"
    },
    {
        "value": "format-annotation-plus"
    },
    {
        "value": "format-bold"
    },
    {
        "value": "format-clear"
    },
    {
        "value": "format-color-fill"
    },
    {
        "value": "format-color-highlight"
    },
    {
        "value": "format-color-marker-cancel"
    },
    {
        "value": "format-color-text"
    },
    {
        "value": "format-columns"
    },
    {
        "value": "format-float-center"
    },
    {
        "value": "format-float-left"
    },
    {
        "value": "format-float-none"
    },
    {
        "value": "format-float-right"
    },
    {
        "value": "format-font"
    },
    {
        "value": "format-font-size-decrease"
    },
    {
        "value": "format-font-size-increase"
    },
    {
        "value": "format-header-1"
    },
    {
        "value": "format-header-2"
    },
    {
        "value": "format-header-3"
    },
    {
        "value": "format-header-4"
    },
    {
        "value": "format-header-5"
    },
    {
        "value": "format-header-6"
    },
    {
        "value": "format-header-decrease"
    },
    {
        "value": "format-header-equal"
    },
    {
        "value": "format-header-increase"
    },
    {
        "value": "format-header-pound"
    },
    {
        "value": "format-horizontal-align-center"
    },
    {
        "value": "format-horizontal-align-left"
    },
    {
        "value": "format-horizontal-align-right"
    },
    {
        "value": "format-indent-decrease"
    },
    {
        "value": "format-indent-increase"
    },
    {
        "value": "format-italic"
    },
    {
        "value": "format-letter-case"
    },
    {
        "value": "format-letter-case-lower"
    },
    {
        "value": "format-letter-case-upper"
    },
    {
        "value": "format-letter-ends-with"
    },
    {
        "value": "format-letter-matches"
    },
    {
        "value": "format-letter-starts-with"
    },
    {
        "value": "format-line-spacing"
    },
    {
        "value": "format-line-style"
    },
    {
        "value": "format-line-weight"
    },
    {
        "value": "format-list-bulleted"
    },
    {
        "value": "format-list-bulleted-square"
    },
    {
        "value": "format-list-bulleted-triangle"
    },
    {
        "value": "format-list-bulleted-type"
    },
    {
        "value": "format-list-checkbox"
    },
    {
        "value": "format-list-checks"
    },
    {
        "value": "format-list-numbered"
    },
    {
        "value": "format-list-numbered-rtl"
    },
    {
        "value": "format-list-text"
    },
    {
        "value": "format-overline"
    },
    {
        "value": "format-page-break"
    },
    {
        "value": "format-paint"
    },
    {
        "value": "format-paragraph"
    },
    {
        "value": "format-pilcrow"
    },
    {
        "value": "format-quote-close"
    },
    {
        "value": "format-quote-close-outline"
    },
    {
        "value": "format-quote-open"
    },
    {
        "value": "format-quote-open-outline"
    },
    {
        "value": "format-rotate-90"
    },
    {
        "value": "format-section"
    },
    {
        "value": "format-size"
    },
    {
        "value": "format-strikethrough"
    },
    {
        "value": "format-strikethrough-variant"
    },
    {
        "value": "format-subscript"
    },
    {
        "value": "format-superscript"
    },
    {
        "value": "format-text"
    },
    {
        "value": "format-text-rotation-angle-down"
    },
    {
        "value": "format-text-rotation-angle-up"
    },
    {
        "value": "format-text-rotation-down"
    },
    {
        "value": "format-text-rotation-down-vertical"
    },
    {
        "value": "format-text-rotation-none"
    },
    {
        "value": "format-text-rotation-up"
    },
    {
        "value": "format-text-rotation-vertical"
    },
    {
        "value": "format-text-variant"
    },
    {
        "value": "format-text-variant-outline"
    },
    {
        "value": "format-text-wrapping-clip"
    },
    {
        "value": "format-text-wrapping-overflow"
    },
    {
        "value": "format-text-wrapping-wrap"
    },
    {
        "value": "format-textbox"
    },
    {
        "value": "format-textdirection-l-to-r"
    },
    {
        "value": "format-textdirection-r-to-l"
    },
    {
        "value": "format-title"
    },
    {
        "value": "format-underline"
    },
    {
        "value": "format-vertical-align-bottom"
    },
    {
        "value": "format-vertical-align-center"
    },
    {
        "value": "format-vertical-align-top"
    },
    {
        "value": "format-wrap-inline"
    },
    {
        "value": "format-wrap-square"
    },
    {
        "value": "format-wrap-tight"
    },
    {
        "value": "format-wrap-top-bottom"
    },
    {
        "value": "forum"
    },
    {
        "value": "forum-outline"
    },
    {
        "value": "forward"
    },
    {
        "value": "forwardburger"
    },
    {
        "value": "fountain"
    },
    {
        "value": "fountain-pen"
    },
    {
        "value": "fountain-pen-tip"
    },
    {
        "value": "freebsd"
    },
    {
        "value": "frequently-asked-questions"
    },
    {
        "value": "fridge"
    },
    {
        "value": "fridge-alert"
    },
    {
        "value": "fridge-alert-outline"
    },
    {
        "value": "fridge-bottom"
    },
    {
        "value": "fridge-industrial"
    },
    {
        "value": "fridge-industrial-alert"
    },
    {
        "value": "fridge-industrial-alert-outline"
    },
    {
        "value": "fridge-industrial-off"
    },
    {
        "value": "fridge-industrial-off-outline"
    },
    {
        "value": "fridge-industrial-outline"
    },
    {
        "value": "fridge-off"
    },
    {
        "value": "fridge-off-outline"
    },
    {
        "value": "fridge-outline"
    },
    {
        "value": "fridge-top"
    },
    {
        "value": "fridge-variant"
    },
    {
        "value": "fridge-variant-alert"
    },
    {
        "value": "fridge-variant-alert-outline"
    },
    {
        "value": "fridge-variant-off"
    },
    {
        "value": "fridge-variant-off-outline"
    },
    {
        "value": "fridge-variant-outline"
    },
    {
        "value": "fruit-cherries"
    },
    {
        "value": "fruit-cherries-off"
    },
    {
        "value": "fruit-citrus"
    },
    {
        "value": "fruit-citrus-off"
    },
    {
        "value": "fruit-grapes"
    },
    {
        "value": "fruit-grapes-outline"
    },
    {
        "value": "fruit-pineapple"
    },
    {
        "value": "fruit-watermelon"
    },
    {
        "value": "fuel"
    },
    {
        "value": "fullscreen"
    },
    {
        "value": "fullscreen-exit"
    },
    {
        "value": "function"
    },
    {
        "value": "function-variant"
    },
    {
        "value": "furigana-horizontal"
    },
    {
        "value": "furigana-vertical"
    },
    {
        "value": "fuse"
    },
    {
        "value": "fuse-alert"
    },
    {
        "value": "fuse-blade"
    },
    {
        "value": "fuse-off"
    },
    {
        "value": "gamepad"
    },
    {
        "value": "gamepad-circle"
    },
    {
        "value": "gamepad-circle-down"
    },
    {
        "value": "gamepad-circle-left"
    },
    {
        "value": "gamepad-circle-outline"
    },
    {
        "value": "gamepad-circle-right"
    },
    {
        "value": "gamepad-circle-up"
    },
    {
        "value": "gamepad-down"
    },
    {
        "value": "gamepad-left"
    },
    {
        "value": "gamepad-right"
    },
    {
        "value": "gamepad-round"
    },
    {
        "value": "gamepad-round-down"
    },
    {
        "value": "gamepad-round-left"
    },
    {
        "value": "gamepad-round-outline"
    },
    {
        "value": "gamepad-round-right"
    },
    {
        "value": "gamepad-round-up"
    },
    {
        "value": "gamepad-square"
    },
    {
        "value": "gamepad-square-outline"
    },
    {
        "value": "gamepad-up"
    },
    {
        "value": "gamepad-variant"
    },
    {
        "value": "gamepad-variant-outline"
    },
    {
        "value": "gamma"
    },
    {
        "value": "gantry-crane"
    },
    {
        "value": "garage"
    },
    {
        "value": "garage-alert"
    },
    {
        "value": "garage-alert-variant"
    },
    {
        "value": "garage-open"
    },
    {
        "value": "garage-open-variant"
    },
    {
        "value": "garage-variant"
    },
    {
        "value": "gas-cylinder"
    },
    {
        "value": "gas-station"
    },
    {
        "value": "gas-station-off"
    },
    {
        "value": "gas-station-off-outline"
    },
    {
        "value": "gas-station-outline"
    },
    {
        "value": "gate"
    },
    {
        "value": "gate-and"
    },
    {
        "value": "gate-arrow-right"
    },
    {
        "value": "gate-nand"
    },
    {
        "value": "gate-nor"
    },
    {
        "value": "gate-not"
    },
    {
        "value": "gate-open"
    },
    {
        "value": "gate-or"
    },
    {
        "value": "gate-xnor"
    },
    {
        "value": "gate-xor"
    },
    {
        "value": "gatsby"
    },
    {
        "value": "gauge"
    },
    {
        "value": "gauge-empty"
    },
    {
        "value": "gauge-full"
    },
    {
        "value": "gauge-low"
    },
    {
        "value": "gavel"
    },
    {
        "value": "gender-female"
    },
    {
        "value": "gender-male"
    },
    {
        "value": "gender-male-female"
    },
    {
        "value": "gender-male-female-variant"
    },
    {
        "value": "gender-non-binary"
    },
    {
        "value": "gender-transgender"
    },
    {
        "value": "gentoo"
    },
    {
        "value": "gesture"
    },
    {
        "value": "gesture-double-tap"
    },
    {
        "value": "gesture-pinch"
    },
    {
        "value": "gesture-spread"
    },
    {
        "value": "gesture-swipe"
    },
    {
        "value": "gesture-swipe-down"
    },
    {
        "value": "gesture-swipe-horizontal"
    },
    {
        "value": "gesture-swipe-left"
    },
    {
        "value": "gesture-swipe-right"
    },
    {
        "value": "gesture-swipe-up"
    },
    {
        "value": "gesture-swipe-vertical"
    },
    {
        "value": "gesture-tap"
    },
    {
        "value": "gesture-tap-box"
    },
    {
        "value": "gesture-tap-button"
    },
    {
        "value": "gesture-tap-hold"
    },
    {
        "value": "gesture-two-double-tap"
    },
    {
        "value": "gesture-two-tap"
    },
    {
        "value": "ghost"
    },
    {
        "value": "ghost-off"
    },
    {
        "value": "ghost-off-outline"
    },
    {
        "value": "ghost-outline"
    },
    {
        "value": "gif"
    },
    {
        "value": "gift"
    },
    {
        "value": "gift-off"
    },
    {
        "value": "gift-off-outline"
    },
    {
        "value": "gift-open"
    },
    {
        "value": "gift-open-outline"
    },
    {
        "value": "gift-outline"
    },
    {
        "value": "git"
    },
    {
        "value": "github"
    },
    {
        "value": "gitlab"
    },
    {
        "value": "glass-cocktail"
    },
    {
        "value": "glass-cocktail-off"
    },
    {
        "value": "glass-flute"
    },
    {
        "value": "glass-mug"
    },
    {
        "value": "glass-mug-off"
    },
    {
        "value": "glass-mug-variant"
    },
    {
        "value": "glass-mug-variant-off"
    },
    {
        "value": "glass-pint-outline"
    },
    {
        "value": "glass-stange"
    },
    {
        "value": "glass-tulip"
    },
    {
        "value": "glass-wine"
    },
    {
        "value": "glasses"
    },
    {
        "value": "globe-light"
    },
    {
        "value": "globe-model"
    },
    {
        "value": "gmail"
    },
    {
        "value": "gnome"
    },
    {
        "value": "go-kart"
    },
    {
        "value": "go-kart-track"
    },
    {
        "value": "gog"
    },
    {
        "value": "gold"
    },
    {
        "value": "golf"
    },
    {
        "value": "golf-cart"
    },
    {
        "value": "golf-tee"
    },
    {
        "value": "gondola"
    },
    {
        "value": "goodreads"
    },
    {
        "value": "google"
    },
    {
        "value": "google-ads"
    },
    {
        "value": "google-analytics"
    },
    {
        "value": "google-assistant"
    },
    {
        "value": "google-cardboard"
    },
    {
        "value": "google-chrome"
    },
    {
        "value": "google-circles"
    },
    {
        "value": "google-circles-communities"
    },
    {
        "value": "google-circles-extended"
    },
    {
        "value": "google-circles-group"
    },
    {
        "value": "google-classroom"
    },
    {
        "value": "google-cloud"
    },
    {
        "value": "google-controller"
    },
    {
        "value": "google-controller-off"
    },
    {
        "value": "google-downasaur"
    },
    {
        "value": "google-drive"
    },
    {
        "value": "google-earth"
    },
    {
        "value": "google-fit"
    },
    {
        "value": "google-glass"
    },
    {
        "value": "google-hangouts"
    },
    {
        "value": "google-home"
    },
    {
        "value": "google-keep"
    },
    {
        "value": "google-lens"
    },
    {
        "value": "google-maps"
    },
    {
        "value": "google-my-business"
    },
    {
        "value": "google-nearby"
    },
    {
        "value": "google-play"
    },
    {
        "value": "google-plus"
    },
    {
        "value": "google-podcast"
    },
    {
        "value": "google-spreadsheet"
    },
    {
        "value": "google-street-view"
    },
    {
        "value": "google-translate"
    },
    {
        "value": "gradient-horizontal"
    },
    {
        "value": "gradient-vertical"
    },
    {
        "value": "grain"
    },
    {
        "value": "graph"
    },
    {
        "value": "graph-outline"
    },
    {
        "value": "graphql"
    },
    {
        "value": "grass"
    },
    {
        "value": "grave-stone"
    },
    {
        "value": "grease-pencil"
    },
    {
        "value": "greater-than"
    },
    {
        "value": "greater-than-or-equal"
    },
    {
        "value": "grid"
    },
    {
        "value": "grid-large"
    },
    {
        "value": "grid-off"
    },
    {
        "value": "grill"
    },
    {
        "value": "grill-outline"
    },
    {
        "value": "group"
    },
    {
        "value": "guitar-acoustic"
    },
    {
        "value": "guitar-electric"
    },
    {
        "value": "guitar-pick"
    },
    {
        "value": "guitar-pick-outline"
    },
    {
        "value": "guy-fawkes-mask"
    },
    {
        "value": "hail"
    },
    {
        "value": "hair-dryer"
    },
    {
        "value": "hair-dryer-outline"
    },
    {
        "value": "halloween"
    },
    {
        "value": "hamburger"
    },
    {
        "value": "hamburger-check"
    },
    {
        "value": "hamburger-minus"
    },
    {
        "value": "hamburger-off"
    },
    {
        "value": "hamburger-plus"
    },
    {
        "value": "hamburger-remove"
    },
    {
        "value": "hammer"
    },
    {
        "value": "hammer-screwdriver"
    },
    {
        "value": "hammer-wrench"
    },
    {
        "value": "hand"
    },
    {
        "value": "hand-heart"
    },
    {
        "value": "hand-heart-outline"
    },
    {
        "value": "hand-left"
    },
    {
        "value": "hand-okay"
    },
    {
        "value": "hand-peace"
    },
    {
        "value": "hand-peace-variant"
    },
    {
        "value": "hand-pointing-down"
    },
    {
        "value": "hand-pointing-left"
    },
    {
        "value": "hand-pointing-right"
    },
    {
        "value": "hand-pointing-up"
    },
    {
        "value": "hand-right"
    },
    {
        "value": "hand-saw"
    },
    {
        "value": "hand-wash"
    },
    {
        "value": "hand-wash-outline"
    },
    {
        "value": "hand-water"
    },
    {
        "value": "handball"
    },
    {
        "value": "handcuffs"
    },
    {
        "value": "handshake"
    },
    {
        "value": "handshake-outline"
    },
    {
        "value": "hanger"
    },
    {
        "value": "hard-hat"
    },
    {
        "value": "harddisk"
    },
    {
        "value": "harddisk-plus"
    },
    {
        "value": "harddisk-remove"
    },
    {
        "value": "hat-fedora"
    },
    {
        "value": "hazard-lights"
    },
    {
        "value": "hdr"
    },
    {
        "value": "hdr-off"
    },
    {
        "value": "head"
    },
    {
        "value": "head-alert"
    },
    {
        "value": "head-alert-outline"
    },
    {
        "value": "head-check"
    },
    {
        "value": "head-check-outline"
    },
    {
        "value": "head-cog"
    },
    {
        "value": "head-cog-outline"
    },
    {
        "value": "head-dots-horizontal"
    },
    {
        "value": "head-dots-horizontal-outline"
    },
    {
        "value": "head-flash"
    },
    {
        "value": "head-flash-outline"
    },
    {
        "value": "head-heart"
    },
    {
        "value": "head-heart-outline"
    },
    {
        "value": "head-lightbulb"
    },
    {
        "value": "head-lightbulb-outline"
    },
    {
        "value": "head-minus"
    },
    {
        "value": "head-minus-outline"
    },
    {
        "value": "head-outline"
    },
    {
        "value": "head-plus"
    },
    {
        "value": "head-plus-outline"
    },
    {
        "value": "head-question"
    },
    {
        "value": "head-question-outline"
    },
    {
        "value": "head-remove"
    },
    {
        "value": "head-remove-outline"
    },
    {
        "value": "head-snowflake"
    },
    {
        "value": "head-snowflake-outline"
    },
    {
        "value": "head-sync"
    },
    {
        "value": "head-sync-outline"
    },
    {
        "value": "headphones"
    },
    {
        "value": "headphones-bluetooth"
    },
    {
        "value": "headphones-box"
    },
    {
        "value": "headphones-off"
    },
    {
        "value": "headphones-settings"
    },
    {
        "value": "headset"
    },
    {
        "value": "headset-dock"
    },
    {
        "value": "headset-off"
    },
    {
        "value": "heart"
    },
    {
        "value": "heart-box"
    },
    {
        "value": "heart-box-outline"
    },
    {
        "value": "heart-broken"
    },
    {
        "value": "heart-broken-outline"
    },
    {
        "value": "heart-circle"
    },
    {
        "value": "heart-circle-outline"
    },
    {
        "value": "heart-cog"
    },
    {
        "value": "heart-cog-outline"
    },
    {
        "value": "heart-flash"
    },
    {
        "value": "heart-half"
    },
    {
        "value": "heart-half-full"
    },
    {
        "value": "heart-half-outline"
    },
    {
        "value": "heart-minus"
    },
    {
        "value": "heart-minus-outline"
    },
    {
        "value": "heart-multiple"
    },
    {
        "value": "heart-multiple-outline"
    },
    {
        "value": "heart-off"
    },
    {
        "value": "heart-off-outline"
    },
    {
        "value": "heart-outline"
    },
    {
        "value": "heart-plus"
    },
    {
        "value": "heart-plus-outline"
    },
    {
        "value": "heart-pulse"
    },
    {
        "value": "heart-remove"
    },
    {
        "value": "heart-remove-outline"
    },
    {
        "value": "heart-settings"
    },
    {
        "value": "heart-settings-outline"
    },
    {
        "value": "helicopter"
    },
    {
        "value": "help"
    },
    {
        "value": "help-box"
    },
    {
        "value": "help-circle"
    },
    {
        "value": "help-circle-outline"
    },
    {
        "value": "help-network"
    },
    {
        "value": "help-network-outline"
    },
    {
        "value": "help-rhombus"
    },
    {
        "value": "help-rhombus-outline"
    },
    {
        "value": "hexadecimal"
    },
    {
        "value": "hexagon"
    },
    {
        "value": "hexagon-multiple"
    },
    {
        "value": "hexagon-multiple-outline"
    },
    {
        "value": "hexagon-outline"
    },
    {
        "value": "hexagon-slice-1"
    },
    {
        "value": "hexagon-slice-2"
    },
    {
        "value": "hexagon-slice-3"
    },
    {
        "value": "hexagon-slice-4"
    },
    {
        "value": "hexagon-slice-5"
    },
    {
        "value": "hexagon-slice-6"
    },
    {
        "value": "hexagram"
    },
    {
        "value": "hexagram-outline"
    },
    {
        "value": "high-definition"
    },
    {
        "value": "high-definition-box"
    },
    {
        "value": "highway"
    },
    {
        "value": "hiking"
    },
    {
        "value": "history"
    },
    {
        "value": "hockey-puck"
    },
    {
        "value": "hockey-sticks"
    },
    {
        "value": "hololens"
    },
    {
        "value": "home"
    },
    {
        "value": "home-account"
    },
    {
        "value": "home-alert"
    },
    {
        "value": "home-alert-outline"
    },
    {
        "value": "home-analytics"
    },
    {
        "value": "home-assistant"
    },
    {
        "value": "home-automation"
    },
    {
        "value": "home-circle"
    },
    {
        "value": "home-circle-outline"
    },
    {
        "value": "home-city"
    },
    {
        "value": "home-city-outline"
    },
    {
        "value": "home-edit"
    },
    {
        "value": "home-edit-outline"
    },
    {
        "value": "home-export-outline"
    },
    {
        "value": "home-flood"
    },
    {
        "value": "home-floor-0"
    },
    {
        "value": "home-floor-1"
    },
    {
        "value": "home-floor-2"
    },
    {
        "value": "home-floor-3"
    },
    {
        "value": "home-floor-a"
    },
    {
        "value": "home-floor-b"
    },
    {
        "value": "home-floor-g"
    },
    {
        "value": "home-floor-l"
    },
    {
        "value": "home-floor-negative-1"
    },
    {
        "value": "home-group"
    },
    {
        "value": "home-heart"
    },
    {
        "value": "home-import-outline"
    },
    {
        "value": "home-lightbulb"
    },
    {
        "value": "home-lightbulb-outline"
    },
    {
        "value": "home-lock"
    },
    {
        "value": "home-lock-open"
    },
    {
        "value": "home-map-marker"
    },
    {
        "value": "home-minus"
    },
    {
        "value": "home-minus-outline"
    },
    {
        "value": "home-modern"
    },
    {
        "value": "home-outline"
    },
    {
        "value": "home-plus"
    },
    {
        "value": "home-plus-outline"
    },
    {
        "value": "home-remove"
    },
    {
        "value": "home-remove-outline"
    },
    {
        "value": "home-roof"
    },
    {
        "value": "home-search"
    },
    {
        "value": "home-search-outline"
    },
    {
        "value": "home-thermometer"
    },
    {
        "value": "home-thermometer-outline"
    },
    {
        "value": "home-variant"
    },
    {
        "value": "home-variant-outline"
    },
    {
        "value": "hook"
    },
    {
        "value": "hook-off"
    },
    {
        "value": "hops"
    },
    {
        "value": "horizontal-rotate-clockwise"
    },
    {
        "value": "horizontal-rotate-counterclockwise"
    },
    {
        "value": "horse"
    },
    {
        "value": "horse-human"
    },
    {
        "value": "horse-variant"
    },
    {
        "value": "horseshoe"
    },
    {
        "value": "hospital"
    },
    {
        "value": "hospital-box"
    },
    {
        "value": "hospital-box-outline"
    },
    {
        "value": "hospital-building"
    },
    {
        "value": "hospital-marker"
    },
    {
        "value": "hot-tub"
    },
    {
        "value": "hours-24"
    },
    {
        "value": "hubspot"
    },
    {
        "value": "hulu"
    },
    {
        "value": "human"
    },
    {
        "value": "human-baby-changing-table"
    },
    {
        "value": "human-cane"
    },
    {
        "value": "human-capacity-decrease"
    },
    {
        "value": "human-capacity-increase"
    },
    {
        "value": "human-child"
    },
    {
        "value": "human-edit"
    },
    {
        "value": "human-female"
    },
    {
        "value": "human-female-boy"
    },
    {
        "value": "human-female-dance"
    },
    {
        "value": "human-female-female"
    },
    {
        "value": "human-female-girl"
    },
    {
        "value": "human-greeting"
    },
    {
        "value": "human-greeting-proximity"
    },
    {
        "value": "human-handsdown"
    },
    {
        "value": "human-handsup"
    },
    {
        "value": "human-male"
    },
    {
        "value": "human-male-board"
    },
    {
        "value": "human-male-boy"
    },
    {
        "value": "human-male-child"
    },
    {
        "value": "human-male-female"
    },
    {
        "value": "human-male-girl"
    },
    {
        "value": "human-male-height"
    },
    {
        "value": "human-male-height-variant"
    },
    {
        "value": "human-male-male"
    },
    {
        "value": "human-pregnant"
    },
    {
        "value": "human-queue"
    },
    {
        "value": "human-scooter"
    },
    {
        "value": "human-wheelchair"
    },
    {
        "value": "humble-bundle"
    },
    {
        "value": "hvac"
    },
    {
        "value": "hvac-off"
    },
    {
        "value": "hydraulic-oil-level"
    },
    {
        "value": "hydraulic-oil-temperature"
    },
    {
        "value": "hydro-power"
    },
    {
        "value": "ice-cream"
    },
    {
        "value": "ice-cream-off"
    },
    {
        "value": "ice-pop"
    },
    {
        "value": "id-card"
    },
    {
        "value": "identifier"
    },
    {
        "value": "ideogram-cjk"
    },
    {
        "value": "ideogram-cjk-variant"
    },
    {
        "value": "iframe"
    },
    {
        "value": "iframe-array"
    },
    {
        "value": "iframe-array-outline"
    },
    {
        "value": "iframe-braces"
    },
    {
        "value": "iframe-braces-outline"
    },
    {
        "value": "iframe-outline"
    },
    {
        "value": "iframe-parentheses"
    },
    {
        "value": "iframe-parentheses-outline"
    },
    {
        "value": "iframe-variable"
    },
    {
        "value": "iframe-variable-outline"
    },
    {
        "value": "image"
    },
    {
        "value": "image-album"
    },
    {
        "value": "image-area"
    },
    {
        "value": "image-area-close"
    },
    {
        "value": "image-auto-adjust"
    },
    {
        "value": "image-broken"
    },
    {
        "value": "image-broken-variant"
    },
    {
        "value": "image-edit"
    },
    {
        "value": "image-edit-outline"
    },
    {
        "value": "image-filter-black-white"
    },
    {
        "value": "image-filter-center-focus"
    },
    {
        "value": "image-filter-center-focus-strong"
    },
    {
        "value": "image-filter-center-focus-strong-outline"
    },
    {
        "value": "image-filter-center-focus-weak"
    },
    {
        "value": "image-filter-drama"
    },
    {
        "value": "image-filter-frames"
    },
    {
        "value": "image-filter-hdr"
    },
    {
        "value": "image-filter-none"
    },
    {
        "value": "image-filter-tilt-shift"
    },
    {
        "value": "image-filter-vintage"
    },
    {
        "value": "image-frame"
    },
    {
        "value": "image-marker"
    },
    {
        "value": "image-marker-outline"
    },
    {
        "value": "image-minus"
    },
    {
        "value": "image-move"
    },
    {
        "value": "image-multiple"
    },
    {
        "value": "image-multiple-outline"
    },
    {
        "value": "image-off"
    },
    {
        "value": "image-off-outline"
    },
    {
        "value": "image-outline"
    },
    {
        "value": "image-plus"
    },
    {
        "value": "image-remove"
    },
    {
        "value": "image-search"
    },
    {
        "value": "image-search-outline"
    },
    {
        "value": "image-size-select-actual"
    },
    {
        "value": "image-size-select-large"
    },
    {
        "value": "image-size-select-small"
    },
    {
        "value": "image-text"
    },
    {
        "value": "import"
    },
    {
        "value": "inbox"
    },
    {
        "value": "inbox-arrow-down"
    },
    {
        "value": "inbox-arrow-down-outline"
    },
    {
        "value": "inbox-arrow-up"
    },
    {
        "value": "inbox-arrow-up-outline"
    },
    {
        "value": "inbox-full"
    },
    {
        "value": "inbox-full-outline"
    },
    {
        "value": "inbox-multiple"
    },
    {
        "value": "inbox-multiple-outline"
    },
    {
        "value": "inbox-outline"
    },
    {
        "value": "inbox-remove"
    },
    {
        "value": "inbox-remove-outline"
    },
    {
        "value": "incognito"
    },
    {
        "value": "incognito-circle"
    },
    {
        "value": "incognito-circle-off"
    },
    {
        "value": "incognito-off"
    },
    {
        "value": "infinity"
    },
    {
        "value": "information"
    },
    {
        "value": "information-off"
    },
    {
        "value": "information-off-outline"
    },
    {
        "value": "information-outline"
    },
    {
        "value": "information-variant"
    },
    {
        "value": "instagram"
    },
    {
        "value": "instrument-triangle"
    },
    {
        "value": "invert-colors"
    },
    {
        "value": "invert-colors-off"
    },
    {
        "value": "iobroker"
    },
    {
        "value": "ip"
    },
    {
        "value": "ip-network"
    },
    {
        "value": "ip-network-outline"
    },
    {
        "value": "ipod"
    },
    {
        "value": "island"
    },
    {
        "value": "iv-bag"
    },
    {
        "value": "jabber"
    },
    {
        "value": "jeepney"
    },
    {
        "value": "jellyfish"
    },
    {
        "value": "jellyfish-outline"
    },
    {
        "value": "jira"
    },
    {
        "value": "jquery"
    },
    {
        "value": "jsfiddle"
    },
    {
        "value": "jump-rope"
    },
    {
        "value": "kabaddi"
    },
    {
        "value": "kangaroo"
    },
    {
        "value": "karate"
    },
    {
        "value": "kayaking"
    },
    {
        "value": "keg"
    },
    {
        "value": "kettle"
    },
    {
        "value": "kettle-alert"
    },
    {
        "value": "kettle-alert-outline"
    },
    {
        "value": "kettle-off"
    },
    {
        "value": "kettle-off-outline"
    },
    {
        "value": "kettle-outline"
    },
    {
        "value": "kettle-pour-over"
    },
    {
        "value": "kettle-steam"
    },
    {
        "value": "kettle-steam-outline"
    },
    {
        "value": "kettlebell"
    },
    {
        "value": "key"
    },
    {
        "value": "key-arrow-right"
    },
    {
        "value": "key-chain"
    },
    {
        "value": "key-chain-variant"
    },
    {
        "value": "key-change"
    },
    {
        "value": "key-link"
    },
    {
        "value": "key-minus"
    },
    {
        "value": "key-outline"
    },
    {
        "value": "key-plus"
    },
    {
        "value": "key-remove"
    },
    {
        "value": "key-star"
    },
    {
        "value": "key-variant"
    },
    {
        "value": "key-wireless"
    },
    {
        "value": "keyboard"
    },
    {
        "value": "keyboard-backspace"
    },
    {
        "value": "keyboard-caps"
    },
    {
        "value": "keyboard-close"
    },
    {
        "value": "keyboard-esc"
    },
    {
        "value": "keyboard-f1"
    },
    {
        "value": "keyboard-f10"
    },
    {
        "value": "keyboard-f11"
    },
    {
        "value": "keyboard-f12"
    },
    {
        "value": "keyboard-f2"
    },
    {
        "value": "keyboard-f3"
    },
    {
        "value": "keyboard-f4"
    },
    {
        "value": "keyboard-f5"
    },
    {
        "value": "keyboard-f6"
    },
    {
        "value": "keyboard-f7"
    },
    {
        "value": "keyboard-f8"
    },
    {
        "value": "keyboard-f9"
    },
    {
        "value": "keyboard-off"
    },
    {
        "value": "keyboard-off-outline"
    },
    {
        "value": "keyboard-outline"
    },
    {
        "value": "keyboard-return"
    },
    {
        "value": "keyboard-settings"
    },
    {
        "value": "keyboard-settings-outline"
    },
    {
        "value": "keyboard-space"
    },
    {
        "value": "keyboard-tab"
    },
    {
        "value": "keyboard-tab-reverse"
    },
    {
        "value": "keyboard-variant"
    },
    {
        "value": "khanda"
    },
    {
        "value": "kickstarter"
    },
    {
        "value": "kitesurfing"
    },
    {
        "value": "klingon"
    },
    {
        "value": "knife"
    },
    {
        "value": "knife-military"
    },
    {
        "value": "koala"
    },
    {
        "value": "kodi"
    },
    {
        "value": "kubernetes"
    },
    {
        "value": "label"
    },
    {
        "value": "label-multiple"
    },
    {
        "value": "label-multiple-outline"
    },
    {
        "value": "label-off"
    },
    {
        "value": "label-off-outline"
    },
    {
        "value": "label-outline"
    },
    {
        "value": "label-percent"
    },
    {
        "value": "label-percent-outline"
    },
    {
        "value": "label-variant"
    },
    {
        "value": "label-variant-outline"
    },
    {
        "value": "ladder"
    },
    {
        "value": "ladybug"
    },
    {
        "value": "lambda"
    },
    {
        "value": "lamp"
    },
    {
        "value": "lamps"
    },
    {
        "value": "lan"
    },
    {
        "value": "lan-check"
    },
    {
        "value": "lan-connect"
    },
    {
        "value": "lan-disconnect"
    },
    {
        "value": "lan-pending"
    },
    {
        "value": "language-c"
    },
    {
        "value": "language-cpp"
    },
    {
        "value": "language-csharp"
    },
    {
        "value": "language-css3"
    },
    {
        "value": "language-fortran"
    },
    {
        "value": "language-go"
    },
    {
        "value": "language-haskell"
    },
    {
        "value": "language-html5"
    },
    {
        "value": "language-java"
    },
    {
        "value": "language-javascript"
    },
    {
        "value": "language-kotlin"
    },
    {
        "value": "language-lua"
    },
    {
        "value": "language-markdown"
    },
    {
        "value": "language-markdown-outline"
    },
    {
        "value": "language-php"
    },
    {
        "value": "language-python"
    },
    {
        "value": "language-r"
    },
    {
        "value": "language-ruby"
    },
    {
        "value": "language-ruby-on-rails"
    },
    {
        "value": "language-rust"
    },
    {
        "value": "language-swift"
    },
    {
        "value": "language-typescript"
    },
    {
        "value": "language-xaml"
    },
    {
        "value": "laptop"
    },
    {
        "value": "laptop-off"
    },
    {
        "value": "laravel"
    },
    {
        "value": "laser-pointer"
    },
    {
        "value": "lasso"
    },
    {
        "value": "lastpass"
    },
    {
        "value": "latitude"
    },
    {
        "value": "launch"
    },
    {
        "value": "lava-lamp"
    },
    {
        "value": "layers"
    },
    {
        "value": "layers-minus"
    },
    {
        "value": "layers-off"
    },
    {
        "value": "layers-off-outline"
    },
    {
        "value": "layers-outline"
    },
    {
        "value": "layers-plus"
    },
    {
        "value": "layers-remove"
    },
    {
        "value": "layers-search"
    },
    {
        "value": "layers-search-outline"
    },
    {
        "value": "layers-triple"
    },
    {
        "value": "layers-triple-outline"
    },
    {
        "value": "lead-pencil"
    },
    {
        "value": "leaf"
    },
    {
        "value": "leaf-maple"
    },
    {
        "value": "leaf-maple-off"
    },
    {
        "value": "leaf-off"
    },
    {
        "value": "leak"
    },
    {
        "value": "leak-off"
    },
    {
        "value": "led-off"
    },
    {
        "value": "led-on"
    },
    {
        "value": "led-outline"
    },
    {
        "value": "led-strip"
    },
    {
        "value": "led-strip-variant"
    },
    {
        "value": "led-variant-off"
    },
    {
        "value": "led-variant-on"
    },
    {
        "value": "led-variant-outline"
    },
    {
        "value": "leek"
    },
    {
        "value": "less-than"
    },
    {
        "value": "less-than-or-equal"
    },
    {
        "value": "library"
    },
    {
        "value": "library-shelves"
    },
    {
        "value": "license"
    },
    {
        "value": "lifebuoy"
    },
    {
        "value": "light-switch"
    },
    {
        "value": "lightbulb"
    },
    {
        "value": "lightbulb-cfl"
    },
    {
        "value": "lightbulb-cfl-off"
    },
    {
        "value": "lightbulb-cfl-spiral"
    },
    {
        "value": "lightbulb-cfl-spiral-off"
    },
    {
        "value": "lightbulb-group"
    },
    {
        "value": "lightbulb-group-off"
    },
    {
        "value": "lightbulb-group-off-outline"
    },
    {
        "value": "lightbulb-group-outline"
    },
    {
        "value": "lightbulb-multiple"
    },
    {
        "value": "lightbulb-multiple-off"
    },
    {
        "value": "lightbulb-multiple-off-outline"
    },
    {
        "value": "lightbulb-multiple-outline"
    },
    {
        "value": "lightbulb-off"
    },
    {
        "value": "lightbulb-off-outline"
    },
    {
        "value": "lightbulb-on"
    },
    {
        "value": "lightbulb-on-outline"
    },
    {
        "value": "lightbulb-outline"
    },
    {
        "value": "lighthouse"
    },
    {
        "value": "lighthouse-on"
    },
    {
        "value": "lightning-bolt"
    },
    {
        "value": "lightning-bolt-outline"
    },
    {
        "value": "lingerie"
    },
    {
        "value": "link"
    },
    {
        "value": "link-box"
    },
    {
        "value": "link-box-outline"
    },
    {
        "value": "link-box-variant"
    },
    {
        "value": "link-box-variant-outline"
    },
    {
        "value": "link-lock"
    },
    {
        "value": "link-off"
    },
    {
        "value": "link-plus"
    },
    {
        "value": "link-variant"
    },
    {
        "value": "link-variant-minus"
    },
    {
        "value": "link-variant-off"
    },
    {
        "value": "link-variant-plus"
    },
    {
        "value": "link-variant-remove"
    },
    {
        "value": "linkedin"
    },
    {
        "value": "linux"
    },
    {
        "value": "linux-mint"
    },
    {
        "value": "lipstick"
    },
    {
        "value": "list-status"
    },
    {
        "value": "litecoin"
    },
    {
        "value": "loading"
    },
    {
        "value": "location-enter"
    },
    {
        "value": "location-exit"
    },
    {
        "value": "lock"
    },
    {
        "value": "lock-alert"
    },
    {
        "value": "lock-alert-outline"
    },
    {
        "value": "lock-check"
    },
    {
        "value": "lock-check-outline"
    },
    {
        "value": "lock-clock"
    },
    {
        "value": "lock-minus"
    },
    {
        "value": "lock-minus-outline"
    },
    {
        "value": "lock-off"
    },
    {
        "value": "lock-off-outline"
    },
    {
        "value": "lock-open"
    },
    {
        "value": "lock-open-alert"
    },
    {
        "value": "lock-open-alert-outline"
    },
    {
        "value": "lock-open-check"
    },
    {
        "value": "lock-open-check-outline"
    },
    {
        "value": "lock-open-minus"
    },
    {
        "value": "lock-open-minus-outline"
    },
    {
        "value": "lock-open-outline"
    },
    {
        "value": "lock-open-plus"
    },
    {
        "value": "lock-open-plus-outline"
    },
    {
        "value": "lock-open-remove"
    },
    {
        "value": "lock-open-remove-outline"
    },
    {
        "value": "lock-open-variant"
    },
    {
        "value": "lock-open-variant-outline"
    },
    {
        "value": "lock-outline"
    },
    {
        "value": "lock-pattern"
    },
    {
        "value": "lock-plus"
    },
    {
        "value": "lock-plus-outline"
    },
    {
        "value": "lock-question"
    },
    {
        "value": "lock-remove"
    },
    {
        "value": "lock-remove-outline"
    },
    {
        "value": "lock-reset"
    },
    {
        "value": "lock-smart"
    },
    {
        "value": "locker"
    },
    {
        "value": "locker-multiple"
    },
    {
        "value": "login"
    },
    {
        "value": "login-variant"
    },
    {
        "value": "logout"
    },
    {
        "value": "logout-variant"
    },
    {
        "value": "longitude"
    },
    {
        "value": "looks"
    },
    {
        "value": "lotion"
    },
    {
        "value": "lotion-outline"
    },
    {
        "value": "lotion-plus"
    },
    {
        "value": "lotion-plus-outline"
    },
    {
        "value": "loupe"
    },
    {
        "value": "lumx"
    },
    {
        "value": "lungs"
    },
    {
        "value": "magazine-pistol"
    },
    {
        "value": "magazine-rifle"
    },
    {
        "value": "magnet"
    },
    {
        "value": "magnet-on"
    },
    {
        "value": "magnify"
    },
    {
        "value": "magnify-close"
    },
    {
        "value": "magnify-minus"
    },
    {
        "value": "magnify-minus-cursor"
    },
    {
        "value": "magnify-minus-outline"
    },
    {
        "value": "magnify-plus"
    },
    {
        "value": "magnify-plus-cursor"
    },
    {
        "value": "magnify-plus-outline"
    },
    {
        "value": "magnify-remove-cursor"
    },
    {
        "value": "magnify-remove-outline"
    },
    {
        "value": "magnify-scan"
    },
    {
        "value": "mail"
    },
    {
        "value": "mailbox"
    },
    {
        "value": "mailbox-open"
    },
    {
        "value": "mailbox-open-outline"
    },
    {
        "value": "mailbox-open-up"
    },
    {
        "value": "mailbox-open-up-outline"
    },
    {
        "value": "mailbox-outline"
    },
    {
        "value": "mailbox-up"
    },
    {
        "value": "mailbox-up-outline"
    },
    {
        "value": "manjaro"
    },
    {
        "value": "map"
    },
    {
        "value": "map-check"
    },
    {
        "value": "map-check-outline"
    },
    {
        "value": "map-clock"
    },
    {
        "value": "map-clock-outline"
    },
    {
        "value": "map-legend"
    },
    {
        "value": "map-marker"
    },
    {
        "value": "map-marker-alert"
    },
    {
        "value": "map-marker-alert-outline"
    },
    {
        "value": "map-marker-check"
    },
    {
        "value": "map-marker-check-outline"
    },
    {
        "value": "map-marker-circle"
    },
    {
        "value": "map-marker-distance"
    },
    {
        "value": "map-marker-down"
    },
    {
        "value": "map-marker-left"
    },
    {
        "value": "map-marker-left-outline"
    },
    {
        "value": "map-marker-minus"
    },
    {
        "value": "map-marker-minus-outline"
    },
    {
        "value": "map-marker-multiple"
    },
    {
        "value": "map-marker-multiple-outline"
    },
    {
        "value": "map-marker-off"
    },
    {
        "value": "map-marker-off-outline"
    },
    {
        "value": "map-marker-outline"
    },
    {
        "value": "map-marker-path"
    },
    {
        "value": "map-marker-plus"
    },
    {
        "value": "map-marker-plus-outline"
    },
    {
        "value": "map-marker-question"
    },
    {
        "value": "map-marker-question-outline"
    },
    {
        "value": "map-marker-radius"
    },
    {
        "value": "map-marker-radius-outline"
    },
    {
        "value": "map-marker-remove"
    },
    {
        "value": "map-marker-remove-outline"
    },
    {
        "value": "map-marker-remove-variant"
    },
    {
        "value": "map-marker-right"
    },
    {
        "value": "map-marker-right-outline"
    },
    {
        "value": "map-marker-star"
    },
    {
        "value": "map-marker-star-outline"
    },
    {
        "value": "map-marker-up"
    },
    {
        "value": "map-minus"
    },
    {
        "value": "map-outline"
    },
    {
        "value": "map-plus"
    },
    {
        "value": "map-search"
    },
    {
        "value": "map-search-outline"
    },
    {
        "value": "mapbox"
    },
    {
        "value": "margin"
    },
    {
        "value": "marker"
    },
    {
        "value": "marker-cancel"
    },
    {
        "value": "marker-check"
    },
    {
        "value": "mastodon"
    },
    {
        "value": "material-design"
    },
    {
        "value": "material-ui"
    },
    {
        "value": "math-compass"
    },
    {
        "value": "math-cos"
    },
    {
        "value": "math-integral"
    },
    {
        "value": "math-integral-box"
    },
    {
        "value": "math-log"
    },
    {
        "value": "math-norm"
    },
    {
        "value": "math-norm-box"
    },
    {
        "value": "math-sin"
    },
    {
        "value": "math-tan"
    },
    {
        "value": "matrix"
    },
    {
        "value": "medal"
    },
    {
        "value": "medal-outline"
    },
    {
        "value": "medical-bag"
    },
    {
        "value": "meditation"
    },
    {
        "value": "memory"
    },
    {
        "value": "menu"
    },
    {
        "value": "menu-down"
    },
    {
        "value": "menu-down-outline"
    },
    {
        "value": "menu-left"
    },
    {
        "value": "menu-left-outline"
    },
    {
        "value": "menu-open"
    },
    {
        "value": "menu-right"
    },
    {
        "value": "menu-right-outline"
    },
    {
        "value": "menu-swap"
    },
    {
        "value": "menu-swap-outline"
    },
    {
        "value": "menu-up"
    },
    {
        "value": "menu-up-outline"
    },
    {
        "value": "merge"
    },
    {
        "value": "message"
    },
    {
        "value": "message-alert"
    },
    {
        "value": "message-alert-outline"
    },
    {
        "value": "message-arrow-left"
    },
    {
        "value": "message-arrow-left-outline"
    },
    {
        "value": "message-arrow-right"
    },
    {
        "value": "message-arrow-right-outline"
    },
    {
        "value": "message-bookmark"
    },
    {
        "value": "message-bookmark-outline"
    },
    {
        "value": "message-bulleted"
    },
    {
        "value": "message-bulleted-off"
    },
    {
        "value": "message-cog"
    },
    {
        "value": "message-cog-outline"
    },
    {
        "value": "message-draw"
    },
    {
        "value": "message-flash"
    },
    {
        "value": "message-flash-outline"
    },
    {
        "value": "message-image"
    },
    {
        "value": "message-image-outline"
    },
    {
        "value": "message-lock"
    },
    {
        "value": "message-lock-outline"
    },
    {
        "value": "message-minus"
    },
    {
        "value": "message-minus-outline"
    },
    {
        "value": "message-off"
    },
    {
        "value": "message-off-outline"
    },
    {
        "value": "message-outline"
    },
    {
        "value": "message-plus"
    },
    {
        "value": "message-plus-outline"
    },
    {
        "value": "message-processing"
    },
    {
        "value": "message-processing-outline"
    },
    {
        "value": "message-question"
    },
    {
        "value": "message-question-outline"
    },
    {
        "value": "message-reply"
    },
    {
        "value": "message-reply-outline"
    },
    {
        "value": "message-reply-text"
    },
    {
        "value": "message-reply-text-outline"
    },
    {
        "value": "message-settings"
    },
    {
        "value": "message-settings-outline"
    },
    {
        "value": "message-text"
    },
    {
        "value": "message-text-clock"
    },
    {
        "value": "message-text-clock-outline"
    },
    {
        "value": "message-text-lock"
    },
    {
        "value": "message-text-lock-outline"
    },
    {
        "value": "message-text-outline"
    },
    {
        "value": "message-video"
    },
    {
        "value": "meteor"
    },
    {
        "value": "metronome"
    },
    {
        "value": "metronome-tick"
    },
    {
        "value": "micro-sd"
    },
    {
        "value": "microphone"
    },
    {
        "value": "microphone-minus"
    },
    {
        "value": "microphone-off"
    },
    {
        "value": "microphone-outline"
    },
    {
        "value": "microphone-plus"
    },
    {
        "value": "microphone-settings"
    },
    {
        "value": "microphone-variant"
    },
    {
        "value": "microphone-variant-off"
    },
    {
        "value": "microscope"
    },
    {
        "value": "microsoft"
    },
    {
        "value": "microsoft-access"
    },
    {
        "value": "microsoft-azure"
    },
    {
        "value": "microsoft-azure-devops"
    },
    {
        "value": "microsoft-bing"
    },
    {
        "value": "microsoft-dynamics-365"
    },
    {
        "value": "microsoft-edge"
    },
    {
        "value": "microsoft-edge-legacy"
    },
    {
        "value": "microsoft-excel"
    },
    {
        "value": "microsoft-internet-explorer"
    },
    {
        "value": "microsoft-office"
    },
    {
        "value": "microsoft-onedrive"
    },
    {
        "value": "microsoft-onenote"
    },
    {
        "value": "microsoft-outlook"
    },
    {
        "value": "microsoft-powerpoint"
    },
    {
        "value": "microsoft-sharepoint"
    },
    {
        "value": "microsoft-teams"
    },
    {
        "value": "microsoft-visual-studio"
    },
    {
        "value": "microsoft-visual-studio-code"
    },
    {
        "value": "microsoft-windows"
    },
    {
        "value": "microsoft-windows-classic"
    },
    {
        "value": "microsoft-word"
    },
    {
        "value": "microsoft-xbox"
    },
    {
        "value": "microsoft-xbox-controller"
    },
    {
        "value": "microsoft-xbox-controller-battery-alert"
    },
    {
        "value": "microsoft-xbox-controller-battery-charging"
    },
    {
        "value": "microsoft-xbox-controller-battery-empty"
    },
    {
        "value": "microsoft-xbox-controller-battery-full"
    },
    {
        "value": "microsoft-xbox-controller-battery-low"
    },
    {
        "value": "microsoft-xbox-controller-battery-medium"
    },
    {
        "value": "microsoft-xbox-controller-battery-unknown"
    },
    {
        "value": "microsoft-xbox-controller-menu"
    },
    {
        "value": "microsoft-xbox-controller-off"
    },
    {
        "value": "microsoft-xbox-controller-view"
    },
    {
        "value": "microsoft-yammer"
    },
    {
        "value": "microwave"
    },
    {
        "value": "microwave-off"
    },
    {
        "value": "middleware"
    },
    {
        "value": "middleware-outline"
    },
    {
        "value": "midi"
    },
    {
        "value": "midi-port"
    },
    {
        "value": "mine"
    },
    {
        "value": "minecraft"
    },
    {
        "value": "mini-sd"
    },
    {
        "value": "minidisc"
    },
    {
        "value": "minus"
    },
    {
        "value": "minus-box"
    },
    {
        "value": "minus-box-multiple"
    },
    {
        "value": "minus-box-multiple-outline"
    },
    {
        "value": "minus-box-outline"
    },
    {
        "value": "minus-circle"
    },
    {
        "value": "minus-circle-multiple"
    },
    {
        "value": "minus-circle-multiple-outline"
    },
    {
        "value": "minus-circle-off"
    },
    {
        "value": "minus-circle-off-outline"
    },
    {
        "value": "minus-circle-outline"
    },
    {
        "value": "minus-network"
    },
    {
        "value": "minus-network-outline"
    },
    {
        "value": "minus-thick"
    },
    {
        "value": "mirror"
    },
    {
        "value": "mixed-martial-arts"
    },
    {
        "value": "mixed-reality"
    },
    {
        "value": "molecule"
    },
    {
        "value": "molecule-co"
    },
    {
        "value": "molecule-co2"
    },
    {
        "value": "monitor"
    },
    {
        "value": "monitor-cellphone"
    },
    {
        "value": "monitor-cellphone-star"
    },
    {
        "value": "monitor-dashboard"
    },
    {
        "value": "monitor-edit"
    },
    {
        "value": "monitor-eye"
    },
    {
        "value": "monitor-lock"
    },
    {
        "value": "monitor-multiple"
    },
    {
        "value": "monitor-off"
    },
    {
        "value": "monitor-screenshot"
    },
    {
        "value": "monitor-share"
    },
    {
        "value": "monitor-shimmer"
    },
    {
        "value": "monitor-speaker"
    },
    {
        "value": "monitor-speaker-off"
    },
    {
        "value": "monitor-star"
    },
    {
        "value": "moon-first-quarter"
    },
    {
        "value": "moon-full"
    },
    {
        "value": "moon-last-quarter"
    },
    {
        "value": "moon-new"
    },
    {
        "value": "moon-waning-crescent"
    },
    {
        "value": "moon-waning-gibbous"
    },
    {
        "value": "moon-waxing-crescent"
    },
    {
        "value": "moon-waxing-gibbous"
    },
    {
        "value": "moped"
    },
    {
        "value": "moped-electric"
    },
    {
        "value": "moped-electric-outline"
    },
    {
        "value": "moped-outline"
    },
    {
        "value": "more"
    },
    {
        "value": "mortar-pestle"
    },
    {
        "value": "mortar-pestle-plus"
    },
    {
        "value": "mother-heart"
    },
    {
        "value": "mother-nurse"
    },
    {
        "value": "motion"
    },
    {
        "value": "motion-outline"
    },
    {
        "value": "motion-pause"
    },
    {
        "value": "motion-pause-outline"
    },
    {
        "value": "motion-play"
    },
    {
        "value": "motion-play-outline"
    },
    {
        "value": "motion-sensor"
    },
    {
        "value": "motion-sensor-off"
    },
    {
        "value": "motorbike"
    },
    {
        "value": "motorbike-electric"
    },
    {
        "value": "mouse"
    },
    {
        "value": "mouse-bluetooth"
    },
    {
        "value": "mouse-move-down"
    },
    {
        "value": "mouse-move-up"
    },
    {
        "value": "mouse-move-vertical"
    },
    {
        "value": "mouse-off"
    },
    {
        "value": "mouse-variant"
    },
    {
        "value": "mouse-variant-off"
    },
    {
        "value": "move-resize"
    },
    {
        "value": "move-resize-variant"
    },
    {
        "value": "movie"
    },
    {
        "value": "movie-check"
    },
    {
        "value": "movie-check-outline"
    },
    {
        "value": "movie-cog"
    },
    {
        "value": "movie-cog-outline"
    },
    {
        "value": "movie-edit"
    },
    {
        "value": "movie-edit-outline"
    },
    {
        "value": "movie-filter"
    },
    {
        "value": "movie-filter-outline"
    },
    {
        "value": "movie-minus"
    },
    {
        "value": "movie-minus-outline"
    },
    {
        "value": "movie-off"
    },
    {
        "value": "movie-off-outline"
    },
    {
        "value": "movie-open"
    },
    {
        "value": "movie-open-check"
    },
    {
        "value": "movie-open-check-outline"
    },
    {
        "value": "movie-open-cog"
    },
    {
        "value": "movie-open-cog-outline"
    },
    {
        "value": "movie-open-edit"
    },
    {
        "value": "movie-open-edit-outline"
    },
    {
        "value": "movie-open-minus"
    },
    {
        "value": "movie-open-minus-outline"
    },
    {
        "value": "movie-open-off"
    },
    {
        "value": "movie-open-off-outline"
    },
    {
        "value": "movie-open-outline"
    },
    {
        "value": "movie-open-play"
    },
    {
        "value": "movie-open-play-outline"
    },
    {
        "value": "movie-open-plus"
    },
    {
        "value": "movie-open-plus-outline"
    },
    {
        "value": "movie-open-remove"
    },
    {
        "value": "movie-open-remove-outline"
    },
    {
        "value": "movie-open-settings"
    },
    {
        "value": "movie-open-settings-outline"
    },
    {
        "value": "movie-open-star"
    },
    {
        "value": "movie-open-star-outline"
    },
    {
        "value": "movie-outline"
    },
    {
        "value": "movie-play"
    },
    {
        "value": "movie-play-outline"
    },
    {
        "value": "movie-plus"
    },
    {
        "value": "movie-plus-outline"
    },
    {
        "value": "movie-remove"
    },
    {
        "value": "movie-remove-outline"
    },
    {
        "value": "movie-roll"
    },
    {
        "value": "movie-search"
    },
    {
        "value": "movie-search-outline"
    },
    {
        "value": "movie-settings"
    },
    {
        "value": "movie-settings-outline"
    },
    {
        "value": "movie-star"
    },
    {
        "value": "movie-star-outline"
    },
    {
        "value": "mower"
    },
    {
        "value": "mower-bag"
    },
    {
        "value": "muffin"
    },
    {
        "value": "multiplication"
    },
    {
        "value": "multiplication-box"
    },
    {
        "value": "mushroom"
    },
    {
        "value": "mushroom-off"
    },
    {
        "value": "mushroom-off-outline"
    },
    {
        "value": "mushroom-outline"
    },
    {
        "value": "music"
    },
    {
        "value": "music-accidental-double-flat"
    },
    {
        "value": "music-accidental-double-sharp"
    },
    {
        "value": "music-accidental-flat"
    },
    {
        "value": "music-accidental-natural"
    },
    {
        "value": "music-accidental-sharp"
    },
    {
        "value": "music-box"
    },
    {
        "value": "music-box-multiple"
    },
    {
        "value": "music-box-multiple-outline"
    },
    {
        "value": "music-box-outline"
    },
    {
        "value": "music-circle"
    },
    {
        "value": "music-circle-outline"
    },
    {
        "value": "music-clef-alto"
    },
    {
        "value": "music-clef-bass"
    },
    {
        "value": "music-clef-treble"
    },
    {
        "value": "music-note"
    },
    {
        "value": "music-note-bluetooth"
    },
    {
        "value": "music-note-bluetooth-off"
    },
    {
        "value": "music-note-eighth"
    },
    {
        "value": "music-note-eighth-dotted"
    },
    {
        "value": "music-note-half"
    },
    {
        "value": "music-note-half-dotted"
    },
    {
        "value": "music-note-off"
    },
    {
        "value": "music-note-off-outline"
    },
    {
        "value": "music-note-outline"
    },
    {
        "value": "music-note-plus"
    },
    {
        "value": "music-note-quarter"
    },
    {
        "value": "music-note-quarter-dotted"
    },
    {
        "value": "music-note-sixteenth"
    },
    {
        "value": "music-note-sixteenth-dotted"
    },
    {
        "value": "music-note-whole"
    },
    {
        "value": "music-note-whole-dotted"
    },
    {
        "value": "music-off"
    },
    {
        "value": "music-rest-eighth"
    },
    {
        "value": "music-rest-half"
    },
    {
        "value": "music-rest-quarter"
    },
    {
        "value": "music-rest-sixteenth"
    },
    {
        "value": "music-rest-whole"
    },
    {
        "value": "mustache"
    },
    {
        "value": "nail"
    },
    {
        "value": "nas"
    },
    {
        "value": "nativescript"
    },
    {
        "value": "nature"
    },
    {
        "value": "nature-people"
    },
    {
        "value": "navigation"
    },
    {
        "value": "navigation-outline"
    },
    {
        "value": "near-me"
    },
    {
        "value": "necklace"
    },
    {
        "value": "needle"
    },
    {
        "value": "netflix"
    },
    {
        "value": "network"
    },
    {
        "value": "network-off"
    },
    {
        "value": "network-off-outline"
    },
    {
        "value": "network-outline"
    },
    {
        "value": "network-strength-1"
    },
    {
        "value": "network-strength-1-alert"
    },
    {
        "value": "network-strength-2"
    },
    {
        "value": "network-strength-2-alert"
    },
    {
        "value": "network-strength-3"
    },
    {
        "value": "network-strength-3-alert"
    },
    {
        "value": "network-strength-4"
    },
    {
        "value": "network-strength-4-alert"
    },
    {
        "value": "network-strength-off"
    },
    {
        "value": "network-strength-off-outline"
    },
    {
        "value": "network-strength-outline"
    },
    {
        "value": "new-box"
    },
    {
        "value": "newspaper"
    },
    {
        "value": "newspaper-minus"
    },
    {
        "value": "newspaper-plus"
    },
    {
        "value": "newspaper-variant"
    },
    {
        "value": "newspaper-variant-multiple"
    },
    {
        "value": "newspaper-variant-multiple-outline"
    },
    {
        "value": "newspaper-variant-outline"
    },
    {
        "value": "nfc"
    },
    {
        "value": "nfc-search-variant"
    },
    {
        "value": "nfc-tap"
    },
    {
        "value": "nfc-variant"
    },
    {
        "value": "nfc-variant-off"
    },
    {
        "value": "ninja"
    },
    {
        "value": "nintendo-game-boy"
    },
    {
        "value": "nintendo-switch"
    },
    {
        "value": "nintendo-wii"
    },
    {
        "value": "nintendo-wiiu"
    },
    {
        "value": "nix"
    },
    {
        "value": "nodejs"
    },
    {
        "value": "noodles"
    },
    {
        "value": "not-equal"
    },
    {
        "value": "not-equal-variant"
    },
    {
        "value": "note"
    },
    {
        "value": "note-alert"
    },
    {
        "value": "note-alert-outline"
    },
    {
        "value": "note-check"
    },
    {
        "value": "note-check-outline"
    },
    {
        "value": "note-edit"
    },
    {
        "value": "note-edit-outline"
    },
    {
        "value": "note-minus"
    },
    {
        "value": "note-minus-outline"
    },
    {
        "value": "note-multiple"
    },
    {
        "value": "note-multiple-outline"
    },
    {
        "value": "note-off"
    },
    {
        "value": "note-off-outline"
    },
    {
        "value": "note-outline"
    },
    {
        "value": "note-plus"
    },
    {
        "value": "note-plus-outline"
    },
    {
        "value": "note-remove"
    },
    {
        "value": "note-remove-outline"
    },
    {
        "value": "note-search"
    },
    {
        "value": "note-search-outline"
    },
    {
        "value": "note-text"
    },
    {
        "value": "note-text-outline"
    },
    {
        "value": "notebook"
    },
    {
        "value": "notebook-check"
    },
    {
        "value": "notebook-check-outline"
    },
    {
        "value": "notebook-edit"
    },
    {
        "value": "notebook-edit-outline"
    },
    {
        "value": "notebook-minus"
    },
    {
        "value": "notebook-minus-outline"
    },
    {
        "value": "notebook-multiple"
    },
    {
        "value": "notebook-outline"
    },
    {
        "value": "notebook-plus"
    },
    {
        "value": "notebook-plus-outline"
    },
    {
        "value": "notebook-remove"
    },
    {
        "value": "notebook-remove-outline"
    },
    {
        "value": "notification-clear-all"
    },
    {
        "value": "npm"
    },
    {
        "value": "nuke"
    },
    {
        "value": "null"
    },
    {
        "value": "numeric"
    },
    {
        "value": "numeric-0"
    },
    {
        "value": "numeric-0-box"
    },
    {
        "value": "numeric-0-box-multiple"
    },
    {
        "value": "numeric-0-box-multiple-outline"
    },
    {
        "value": "numeric-0-box-outline"
    },
    {
        "value": "numeric-0-circle"
    },
    {
        "value": "numeric-0-circle-outline"
    },
    {
        "value": "numeric-1"
    },
    {
        "value": "numeric-1-box"
    },
    {
        "value": "numeric-1-box-multiple"
    },
    {
        "value": "numeric-1-box-multiple-outline"
    },
    {
        "value": "numeric-1-box-outline"
    },
    {
        "value": "numeric-1-circle"
    },
    {
        "value": "numeric-1-circle-outline"
    },
    {
        "value": "numeric-10"
    },
    {
        "value": "numeric-10-box"
    },
    {
        "value": "numeric-10-box-multiple"
    },
    {
        "value": "numeric-10-box-multiple-outline"
    },
    {
        "value": "numeric-10-box-outline"
    },
    {
        "value": "numeric-10-circle"
    },
    {
        "value": "numeric-10-circle-outline"
    },
    {
        "value": "numeric-2"
    },
    {
        "value": "numeric-2-box"
    },
    {
        "value": "numeric-2-box-multiple"
    },
    {
        "value": "numeric-2-box-multiple-outline"
    },
    {
        "value": "numeric-2-box-outline"
    },
    {
        "value": "numeric-2-circle"
    },
    {
        "value": "numeric-2-circle-outline"
    },
    {
        "value": "numeric-3"
    },
    {
        "value": "numeric-3-box"
    },
    {
        "value": "numeric-3-box-multiple"
    },
    {
        "value": "numeric-3-box-multiple-outline"
    },
    {
        "value": "numeric-3-box-outline"
    },
    {
        "value": "numeric-3-circle"
    },
    {
        "value": "numeric-3-circle-outline"
    },
    {
        "value": "numeric-4"
    },
    {
        "value": "numeric-4-box"
    },
    {
        "value": "numeric-4-box-multiple"
    },
    {
        "value": "numeric-4-box-multiple-outline"
    },
    {
        "value": "numeric-4-box-outline"
    },
    {
        "value": "numeric-4-circle"
    },
    {
        "value": "numeric-4-circle-outline"
    },
    {
        "value": "numeric-5"
    },
    {
        "value": "numeric-5-box"
    },
    {
        "value": "numeric-5-box-multiple"
    },
    {
        "value": "numeric-5-box-multiple-outline"
    },
    {
        "value": "numeric-5-box-outline"
    },
    {
        "value": "numeric-5-circle"
    },
    {
        "value": "numeric-5-circle-outline"
    },
    {
        "value": "numeric-6"
    },
    {
        "value": "numeric-6-box"
    },
    {
        "value": "numeric-6-box-multiple"
    },
    {
        "value": "numeric-6-box-multiple-outline"
    },
    {
        "value": "numeric-6-box-outline"
    },
    {
        "value": "numeric-6-circle"
    },
    {
        "value": "numeric-6-circle-outline"
    },
    {
        "value": "numeric-7"
    },
    {
        "value": "numeric-7-box"
    },
    {
        "value": "numeric-7-box-multiple"
    },
    {
        "value": "numeric-7-box-multiple-outline"
    },
    {
        "value": "numeric-7-box-outline"
    },
    {
        "value": "numeric-7-circle"
    },
    {
        "value": "numeric-7-circle-outline"
    },
    {
        "value": "numeric-8"
    },
    {
        "value": "numeric-8-box"
    },
    {
        "value": "numeric-8-box-multiple"
    },
    {
        "value": "numeric-8-box-multiple-outline"
    },
    {
        "value": "numeric-8-box-outline"
    },
    {
        "value": "numeric-8-circle"
    },
    {
        "value": "numeric-8-circle-outline"
    },
    {
        "value": "numeric-9"
    },
    {
        "value": "numeric-9-box"
    },
    {
        "value": "numeric-9-box-multiple"
    },
    {
        "value": "numeric-9-box-multiple-outline"
    },
    {
        "value": "numeric-9-box-outline"
    },
    {
        "value": "numeric-9-circle"
    },
    {
        "value": "numeric-9-circle-outline"
    },
    {
        "value": "numeric-9-plus"
    },
    {
        "value": "numeric-9-plus-box"
    },
    {
        "value": "numeric-9-plus-box-multiple"
    },
    {
        "value": "numeric-9-plus-box-multiple-outline"
    },
    {
        "value": "numeric-9-plus-box-outline"
    },
    {
        "value": "numeric-9-plus-circle"
    },
    {
        "value": "numeric-9-plus-circle-outline"
    },
    {
        "value": "numeric-negative-1"
    },
    {
        "value": "numeric-positive-1"
    },
    {
        "value": "nut"
    },
    {
        "value": "nutrition"
    },
    {
        "value": "nuxt"
    },
    {
        "value": "oar"
    },
    {
        "value": "ocarina"
    },
    {
        "value": "oci"
    },
    {
        "value": "ocr"
    },
    {
        "value": "octagon"
    },
    {
        "value": "octagon-outline"
    },
    {
        "value": "octagram"
    },
    {
        "value": "octagram-outline"
    },
    {
        "value": "odnoklassniki"
    },
    {
        "value": "offer"
    },
    {
        "value": "office-building"
    },
    {
        "value": "office-building-marker"
    },
    {
        "value": "office-building-marker-outline"
    },
    {
        "value": "office-building-outline"
    },
    {
        "value": "oil"
    },
    {
        "value": "oil-lamp"
    },
    {
        "value": "oil-level"
    },
    {
        "value": "oil-temperature"
    },
    {
        "value": "om"
    },
    {
        "value": "omega"
    },
    {
        "value": "one-up"
    },
    {
        "value": "onepassword"
    },
    {
        "value": "opacity"
    },
    {
        "value": "open-in-app"
    },
    {
        "value": "open-in-new"
    },
    {
        "value": "open-source-initiative"
    },
    {
        "value": "openid"
    },
    {
        "value": "opera"
    },
    {
        "value": "orbit"
    },
    {
        "value": "orbit-variant"
    },
    {
        "value": "order-alphabetical-ascending"
    },
    {
        "value": "order-alphabetical-descending"
    },
    {
        "value": "order-bool-ascending"
    },
    {
        "value": "order-bool-ascending-variant"
    },
    {
        "value": "order-bool-descending"
    },
    {
        "value": "order-bool-descending-variant"
    },
    {
        "value": "order-numeric-ascending"
    },
    {
        "value": "order-numeric-descending"
    },
    {
        "value": "origin"
    },
    {
        "value": "ornament"
    },
    {
        "value": "ornament-variant"
    },
    {
        "value": "outdoor-lamp"
    },
    {
        "value": "overscan"
    },
    {
        "value": "owl"
    },
    {
        "value": "pac-man"
    },
    {
        "value": "package"
    },
    {
        "value": "package-down"
    },
    {
        "value": "package-up"
    },
    {
        "value": "package-variant"
    },
    {
        "value": "package-variant-closed"
    },
    {
        "value": "page-first"
    },
    {
        "value": "page-last"
    },
    {
        "value": "page-layout-body"
    },
    {
        "value": "page-layout-footer"
    },
    {
        "value": "page-layout-header"
    },
    {
        "value": "page-layout-header-footer"
    },
    {
        "value": "page-layout-sidebar-left"
    },
    {
        "value": "page-layout-sidebar-right"
    },
    {
        "value": "page-next"
    },
    {
        "value": "page-next-outline"
    },
    {
        "value": "page-previous"
    },
    {
        "value": "page-previous-outline"
    },
    {
        "value": "pail"
    },
    {
        "value": "pail-minus"
    },
    {
        "value": "pail-minus-outline"
    },
    {
        "value": "pail-off"
    },
    {
        "value": "pail-off-outline"
    },
    {
        "value": "pail-outline"
    },
    {
        "value": "pail-plus"
    },
    {
        "value": "pail-plus-outline"
    },
    {
        "value": "pail-remove"
    },
    {
        "value": "pail-remove-outline"
    },
    {
        "value": "palette"
    },
    {
        "value": "palette-advanced"
    },
    {
        "value": "palette-outline"
    },
    {
        "value": "palette-swatch"
    },
    {
        "value": "palette-swatch-outline"
    },
    {
        "value": "palm-tree"
    },
    {
        "value": "pan"
    },
    {
        "value": "pan-bottom-left"
    },
    {
        "value": "pan-bottom-right"
    },
    {
        "value": "pan-down"
    },
    {
        "value": "pan-horizontal"
    },
    {
        "value": "pan-left"
    },
    {
        "value": "pan-right"
    },
    {
        "value": "pan-top-left"
    },
    {
        "value": "pan-top-right"
    },
    {
        "value": "pan-up"
    },
    {
        "value": "pan-vertical"
    },
    {
        "value": "panda"
    },
    {
        "value": "pandora"
    },
    {
        "value": "panorama"
    },
    {
        "value": "panorama-fisheye"
    },
    {
        "value": "panorama-horizontal"
    },
    {
        "value": "panorama-vertical"
    },
    {
        "value": "panorama-wide-angle"
    },
    {
        "value": "paper-cut-vertical"
    },
    {
        "value": "paper-roll"
    },
    {
        "value": "paper-roll-outline"
    },
    {
        "value": "paperclip"
    },
    {
        "value": "parachute"
    },
    {
        "value": "parachute-outline"
    },
    {
        "value": "paragliding"
    },
    {
        "value": "parking"
    },
    {
        "value": "party-popper"
    },
    {
        "value": "passport"
    },
    {
        "value": "passport-biometric"
    },
    {
        "value": "pasta"
    },
    {
        "value": "patio-heater"
    },
    {
        "value": "patreon"
    },
    {
        "value": "pause"
    },
    {
        "value": "pause-circle"
    },
    {
        "value": "pause-circle-outline"
    },
    {
        "value": "pause-octagon"
    },
    {
        "value": "pause-octagon-outline"
    },
    {
        "value": "paw"
    },
    {
        "value": "paw-off"
    },
    {
        "value": "paw-off-outline"
    },
    {
        "value": "paw-outline"
    },
    {
        "value": "pdf-box"
    },
    {
        "value": "peace"
    },
    {
        "value": "peanut"
    },
    {
        "value": "peanut-off"
    },
    {
        "value": "peanut-off-outline"
    },
    {
        "value": "peanut-outline"
    },
    {
        "value": "pen"
    },
    {
        "value": "pen-lock"
    },
    {
        "value": "pen-minus"
    },
    {
        "value": "pen-off"
    },
    {
        "value": "pen-plus"
    },
    {
        "value": "pen-remove"
    },
    {
        "value": "pencil"
    },
    {
        "value": "pencil-box"
    },
    {
        "value": "pencil-box-multiple"
    },
    {
        "value": "pencil-box-multiple-outline"
    },
    {
        "value": "pencil-box-outline"
    },
    {
        "value": "pencil-circle"
    },
    {
        "value": "pencil-circle-outline"
    },
    {
        "value": "pencil-lock"
    },
    {
        "value": "pencil-lock-outline"
    },
    {
        "value": "pencil-minus"
    },
    {
        "value": "pencil-minus-outline"
    },
    {
        "value": "pencil-off"
    },
    {
        "value": "pencil-off-outline"
    },
    {
        "value": "pencil-outline"
    },
    {
        "value": "pencil-plus"
    },
    {
        "value": "pencil-plus-outline"
    },
    {
        "value": "pencil-remove"
    },
    {
        "value": "pencil-remove-outline"
    },
    {
        "value": "pencil-ruler"
    },
    {
        "value": "penguin"
    },
    {
        "value": "pentagon"
    },
    {
        "value": "pentagon-outline"
    },
    {
        "value": "pentagram"
    },
    {
        "value": "percent"
    },
    {
        "value": "percent-outline"
    },
    {
        "value": "periodic-table"
    },
    {
        "value": "perspective-less"
    },
    {
        "value": "perspective-more"
    },
    {
        "value": "phone"
    },
    {
        "value": "phone-alert"
    },
    {
        "value": "phone-alert-outline"
    },
    {
        "value": "phone-bluetooth"
    },
    {
        "value": "phone-bluetooth-outline"
    },
    {
        "value": "phone-cancel"
    },
    {
        "value": "phone-cancel-outline"
    },
    {
        "value": "phone-check"
    },
    {
        "value": "phone-check-outline"
    },
    {
        "value": "phone-classic"
    },
    {
        "value": "phone-classic-off"
    },
    {
        "value": "phone-dial"
    },
    {
        "value": "phone-dial-outline"
    },
    {
        "value": "phone-forward"
    },
    {
        "value": "phone-forward-outline"
    },
    {
        "value": "phone-hangup"
    },
    {
        "value": "phone-hangup-outline"
    },
    {
        "value": "phone-in-talk"
    },
    {
        "value": "phone-in-talk-outline"
    },
    {
        "value": "phone-incoming"
    },
    {
        "value": "phone-incoming-outline"
    },
    {
        "value": "phone-lock"
    },
    {
        "value": "phone-lock-outline"
    },
    {
        "value": "phone-log"
    },
    {
        "value": "phone-log-outline"
    },
    {
        "value": "phone-message"
    },
    {
        "value": "phone-message-outline"
    },
    {
        "value": "phone-minus"
    },
    {
        "value": "phone-minus-outline"
    },
    {
        "value": "phone-missed"
    },
    {
        "value": "phone-missed-outline"
    },
    {
        "value": "phone-off"
    },
    {
        "value": "phone-off-outline"
    },
    {
        "value": "phone-outgoing"
    },
    {
        "value": "phone-outgoing-outline"
    },
    {
        "value": "phone-outline"
    },
    {
        "value": "phone-paused"
    },
    {
        "value": "phone-paused-outline"
    },
    {
        "value": "phone-plus"
    },
    {
        "value": "phone-plus-outline"
    },
    {
        "value": "phone-remove"
    },
    {
        "value": "phone-remove-outline"
    },
    {
        "value": "phone-return"
    },
    {
        "value": "phone-return-outline"
    },
    {
        "value": "phone-ring"
    },
    {
        "value": "phone-ring-outline"
    },
    {
        "value": "phone-rotate-landscape"
    },
    {
        "value": "phone-rotate-portrait"
    },
    {
        "value": "phone-settings"
    },
    {
        "value": "phone-settings-outline"
    },
    {
        "value": "phone-voip"
    },
    {
        "value": "pi"
    },
    {
        "value": "pi-box"
    },
    {
        "value": "pi-hole"
    },
    {
        "value": "piano"
    },
    {
        "value": "piano-off"
    },
    {
        "value": "pickaxe"
    },
    {
        "value": "picture-in-picture-bottom-right"
    },
    {
        "value": "picture-in-picture-bottom-right-outline"
    },
    {
        "value": "picture-in-picture-top-right"
    },
    {
        "value": "picture-in-picture-top-right-outline"
    },
    {
        "value": "pier"
    },
    {
        "value": "pier-crane"
    },
    {
        "value": "pig"
    },
    {
        "value": "pig-variant"
    },
    {
        "value": "pig-variant-outline"
    },
    {
        "value": "piggy-bank"
    },
    {
        "value": "piggy-bank-outline"
    },
    {
        "value": "pill"
    },
    {
        "value": "pillar"
    },
    {
        "value": "pin"
    },
    {
        "value": "pin-off"
    },
    {
        "value": "pin-off-outline"
    },
    {
        "value": "pin-outline"
    },
    {
        "value": "pine-tree"
    },
    {
        "value": "pine-tree-box"
    },
    {
        "value": "pine-tree-fire"
    },
    {
        "value": "pinterest"
    },
    {
        "value": "pinwheel"
    },
    {
        "value": "pinwheel-outline"
    },
    {
        "value": "pipe"
    },
    {
        "value": "pipe-disconnected"
    },
    {
        "value": "pipe-leak"
    },
    {
        "value": "pipe-wrench"
    },
    {
        "value": "pirate"
    },
    {
        "value": "pistol"
    },
    {
        "value": "piston"
    },
    {
        "value": "pitchfork"
    },
    {
        "value": "pizza"
    },
    {
        "value": "play"
    },
    {
        "value": "play-box"
    },
    {
        "value": "play-box-multiple"
    },
    {
        "value": "play-box-multiple-outline"
    },
    {
        "value": "play-box-outline"
    },
    {
        "value": "play-circle"
    },
    {
        "value": "play-circle-outline"
    },
    {
        "value": "play-network"
    },
    {
        "value": "play-network-outline"
    },
    {
        "value": "play-outline"
    },
    {
        "value": "play-pause"
    },
    {
        "value": "play-protected-content"
    },
    {
        "value": "play-speed"
    },
    {
        "value": "playlist-check"
    },
    {
        "value": "playlist-edit"
    },
    {
        "value": "playlist-minus"
    },
    {
        "value": "playlist-music"
    },
    {
        "value": "playlist-music-outline"
    },
    {
        "value": "playlist-play"
    },
    {
        "value": "playlist-plus"
    },
    {
        "value": "playlist-remove"
    },
    {
        "value": "playlist-star"
    },
    {
        "value": "plex"
    },
    {
        "value": "plus"
    },
    {
        "value": "plus-box"
    },
    {
        "value": "plus-box-multiple"
    },
    {
        "value": "plus-box-multiple-outline"
    },
    {
        "value": "plus-box-outline"
    },
    {
        "value": "plus-circle"
    },
    {
        "value": "plus-circle-multiple"
    },
    {
        "value": "plus-circle-multiple-outline"
    },
    {
        "value": "plus-circle-outline"
    },
    {
        "value": "plus-minus"
    },
    {
        "value": "plus-minus-box"
    },
    {
        "value": "plus-minus-variant"
    },
    {
        "value": "plus-network"
    },
    {
        "value": "plus-network-outline"
    },
    {
        "value": "plus-outline"
    },
    {
        "value": "plus-thick"
    },
    {
        "value": "podcast"
    },
    {
        "value": "podium"
    },
    {
        "value": "podium-bronze"
    },
    {
        "value": "podium-gold"
    },
    {
        "value": "podium-silver"
    },
    {
        "value": "point-of-sale"
    },
    {
        "value": "pokeball"
    },
    {
        "value": "pokemon-go"
    },
    {
        "value": "poker-chip"
    },
    {
        "value": "polaroid"
    },
    {
        "value": "police-badge"
    },
    {
        "value": "police-badge-outline"
    },
    {
        "value": "poll"
    },
    {
        "value": "polo"
    },
    {
        "value": "polymer"
    },
    {
        "value": "pool"
    },
    {
        "value": "popcorn"
    },
    {
        "value": "post"
    },
    {
        "value": "post-outline"
    },
    {
        "value": "postage-stamp"
    },
    {
        "value": "pot"
    },
    {
        "value": "pot-mix"
    },
    {
        "value": "pot-mix-outline"
    },
    {
        "value": "pot-outline"
    },
    {
        "value": "pot-steam"
    },
    {
        "value": "pot-steam-outline"
    },
    {
        "value": "pound"
    },
    {
        "value": "pound-box"
    },
    {
        "value": "pound-box-outline"
    },
    {
        "value": "power"
    },
    {
        "value": "power-cycle"
    },
    {
        "value": "power-off"
    },
    {
        "value": "power-on"
    },
    {
        "value": "power-plug"
    },
    {
        "value": "power-plug-off"
    },
    {
        "value": "power-plug-off-outline"
    },
    {
        "value": "power-plug-outline"
    },
    {
        "value": "power-settings"
    },
    {
        "value": "power-sleep"
    },
    {
        "value": "power-socket"
    },
    {
        "value": "power-socket-au"
    },
    {
        "value": "power-socket-de"
    },
    {
        "value": "power-socket-eu"
    },
    {
        "value": "power-socket-fr"
    },
    {
        "value": "power-socket-it"
    },
    {
        "value": "power-socket-jp"
    },
    {
        "value": "power-socket-uk"
    },
    {
        "value": "power-socket-us"
    },
    {
        "value": "power-standby"
    },
    {
        "value": "powershell"
    },
    {
        "value": "prescription"
    },
    {
        "value": "presentation"
    },
    {
        "value": "presentation-play"
    },
    {
        "value": "pretzel"
    },
    {
        "value": "printer"
    },
    {
        "value": "printer-3d"
    },
    {
        "value": "printer-3d-nozzle"
    },
    {
        "value": "printer-3d-nozzle-alert"
    },
    {
        "value": "printer-3d-nozzle-alert-outline"
    },
    {
        "value": "printer-3d-nozzle-outline"
    },
    {
        "value": "printer-alert"
    },
    {
        "value": "printer-check"
    },
    {
        "value": "printer-eye"
    },
    {
        "value": "printer-off"
    },
    {
        "value": "printer-off-outline"
    },
    {
        "value": "printer-outline"
    },
    {
        "value": "printer-pos"
    },
    {
        "value": "printer-search"
    },
    {
        "value": "printer-settings"
    },
    {
        "value": "printer-wireless"
    },
    {
        "value": "priority-high"
    },
    {
        "value": "priority-low"
    },
    {
        "value": "professional-hexagon"
    },
    {
        "value": "progress-alert"
    },
    {
        "value": "progress-check"
    },
    {
        "value": "progress-clock"
    },
    {
        "value": "progress-close"
    },
    {
        "value": "progress-download"
    },
    {
        "value": "progress-pencil"
    },
    {
        "value": "progress-question"
    },
    {
        "value": "progress-star"
    },
    {
        "value": "progress-upload"
    },
    {
        "value": "progress-wrench"
    },
    {
        "value": "projector"
    },
    {
        "value": "projector-screen"
    },
    {
        "value": "projector-screen-outline"
    },
    {
        "value": "propane-tank"
    },
    {
        "value": "propane-tank-outline"
    },
    {
        "value": "protocol"
    },
    {
        "value": "publish"
    },
    {
        "value": "pulse"
    },
    {
        "value": "pump"
    },
    {
        "value": "pumpkin"
    },
    {
        "value": "purse"
    },
    {
        "value": "purse-outline"
    },
    {
        "value": "puzzle"
    },
    {
        "value": "puzzle-check"
    },
    {
        "value": "puzzle-check-outline"
    },
    {
        "value": "puzzle-edit"
    },
    {
        "value": "puzzle-edit-outline"
    },
    {
        "value": "puzzle-heart"
    },
    {
        "value": "puzzle-heart-outline"
    },
    {
        "value": "puzzle-minus"
    },
    {
        "value": "puzzle-minus-outline"
    },
    {
        "value": "puzzle-outline"
    },
    {
        "value": "puzzle-plus"
    },
    {
        "value": "puzzle-plus-outline"
    },
    {
        "value": "puzzle-remove"
    },
    {
        "value": "puzzle-remove-outline"
    },
    {
        "value": "puzzle-star"
    },
    {
        "value": "puzzle-star-outline"
    },
    {
        "value": "qi"
    },
    {
        "value": "qqchat"
    },
    {
        "value": "qrcode"
    },
    {
        "value": "qrcode-edit"
    },
    {
        "value": "qrcode-minus"
    },
    {
        "value": "qrcode-plus"
    },
    {
        "value": "qrcode-remove"
    },
    {
        "value": "qrcode-scan"
    },
    {
        "value": "quadcopter"
    },
    {
        "value": "quality-high"
    },
    {
        "value": "quality-low"
    },
    {
        "value": "quality-medium"
    },
    {
        "value": "quora"
    },
    {
        "value": "rabbit"
    },
    {
        "value": "racing-helmet"
    },
    {
        "value": "racquetball"
    },
    {
        "value": "radar"
    },
    {
        "value": "radiator"
    },
    {
        "value": "radiator-disabled"
    },
    {
        "value": "radiator-off"
    },
    {
        "value": "radio"
    },
    {
        "value": "radio-am"
    },
    {
        "value": "radio-fm"
    },
    {
        "value": "radio-handheld"
    },
    {
        "value": "radio-off"
    },
    {
        "value": "radio-tower"
    },
    {
        "value": "radioactive"
    },
    {
        "value": "radioactive-off"
    },
    {
        "value": "radiobox-blank"
    },
    {
        "value": "radiobox-marked"
    },
    {
        "value": "radiology-box"
    },
    {
        "value": "radiology-box-outline"
    },
    {
        "value": "radius"
    },
    {
        "value": "radius-outline"
    },
    {
        "value": "railroad-light"
    },
    {
        "value": "rake"
    },
    {
        "value": "raspberry-pi"
    },
    {
        "value": "ray-end"
    },
    {
        "value": "ray-end-arrow"
    },
    {
        "value": "ray-start"
    },
    {
        "value": "ray-start-arrow"
    },
    {
        "value": "ray-start-end"
    },
    {
        "value": "ray-start-vertex-end"
    },
    {
        "value": "ray-vertex"
    },
    {
        "value": "react"
    },
    {
        "value": "read"
    },
    {
        "value": "receipt"
    },
    {
        "value": "record"
    },
    {
        "value": "record-circle"
    },
    {
        "value": "record-circle-outline"
    },
    {
        "value": "record-player"
    },
    {
        "value": "record-rec"
    },
    {
        "value": "rectangle"
    },
    {
        "value": "rectangle-outline"
    },
    {
        "value": "recycle"
    },
    {
        "value": "recycle-variant"
    },
    {
        "value": "reddit"
    },
    {
        "value": "redhat"
    },
    {
        "value": "redo"
    },
    {
        "value": "redo-variant"
    },
    {
        "value": "reflect-horizontal"
    },
    {
        "value": "reflect-vertical"
    },
    {
        "value": "refresh"
    },
    {
        "value": "refresh-circle"
    },
    {
        "value": "regex"
    },
    {
        "value": "registered-trademark"
    },
    {
        "value": "reiterate"
    },
    {
        "value": "relation-many-to-many"
    },
    {
        "value": "relation-many-to-one"
    },
    {
        "value": "relation-many-to-one-or-many"
    },
    {
        "value": "relation-many-to-only-one"
    },
    {
        "value": "relation-many-to-zero-or-many"
    },
    {
        "value": "relation-many-to-zero-or-one"
    },
    {
        "value": "relation-one-or-many-to-many"
    },
    {
        "value": "relation-one-or-many-to-one"
    },
    {
        "value": "relation-one-or-many-to-one-or-many"
    },
    {
        "value": "relation-one-or-many-to-only-one"
    },
    {
        "value": "relation-one-or-many-to-zero-or-many"
    },
    {
        "value": "relation-one-or-many-to-zero-or-one"
    },
    {
        "value": "relation-one-to-many"
    },
    {
        "value": "relation-one-to-one"
    },
    {
        "value": "relation-one-to-one-or-many"
    },
    {
        "value": "relation-one-to-only-one"
    },
    {
        "value": "relation-one-to-zero-or-many"
    },
    {
        "value": "relation-one-to-zero-or-one"
    },
    {
        "value": "relation-only-one-to-many"
    },
    {
        "value": "relation-only-one-to-one"
    },
    {
        "value": "relation-only-one-to-one-or-many"
    },
    {
        "value": "relation-only-one-to-only-one"
    },
    {
        "value": "relation-only-one-to-zero-or-many"
    },
    {
        "value": "relation-only-one-to-zero-or-one"
    },
    {
        "value": "relation-zero-or-many-to-many"
    },
    {
        "value": "relation-zero-or-many-to-one"
    },
    {
        "value": "relation-zero-or-many-to-one-or-many"
    },
    {
        "value": "relation-zero-or-many-to-only-one"
    },
    {
        "value": "relation-zero-or-many-to-zero-or-many"
    },
    {
        "value": "relation-zero-or-many-to-zero-or-one"
    },
    {
        "value": "relation-zero-or-one-to-many"
    },
    {
        "value": "relation-zero-or-one-to-one"
    },
    {
        "value": "relation-zero-or-one-to-one-or-many"
    },
    {
        "value": "relation-zero-or-one-to-only-one"
    },
    {
        "value": "relation-zero-or-one-to-zero-or-many"
    },
    {
        "value": "relation-zero-or-one-to-zero-or-one"
    },
    {
        "value": "relative-scale"
    },
    {
        "value": "reload"
    },
    {
        "value": "reload-alert"
    },
    {
        "value": "reminder"
    },
    {
        "value": "remote"
    },
    {
        "value": "remote-desktop"
    },
    {
        "value": "remote-off"
    },
    {
        "value": "remote-tv"
    },
    {
        "value": "remote-tv-off"
    },
    {
        "value": "rename-box"
    },
    {
        "value": "reorder-horizontal"
    },
    {
        "value": "reorder-vertical"
    },
    {
        "value": "repeat"
    },
    {
        "value": "repeat-off"
    },
    {
        "value": "repeat-once"
    },
    {
        "value": "repeat-variant"
    },
    {
        "value": "replay"
    },
    {
        "value": "reply"
    },
    {
        "value": "reply-all"
    },
    {
        "value": "reply-all-outline"
    },
    {
        "value": "reply-circle"
    },
    {
        "value": "reply-outline"
    },
    {
        "value": "reproduction"
    },
    {
        "value": "resistor"
    },
    {
        "value": "resistor-nodes"
    },
    {
        "value": "resize"
    },
    {
        "value": "resize-bottom-right"
    },
    {
        "value": "responsive"
    },
    {
        "value": "restart"
    },
    {
        "value": "restart-alert"
    },
    {
        "value": "restart-off"
    },
    {
        "value": "restore"
    },
    {
        "value": "restore-alert"
    },
    {
        "value": "rewind"
    },
    {
        "value": "rewind-10"
    },
    {
        "value": "rewind-30"
    },
    {
        "value": "rewind-5"
    },
    {
        "value": "rewind-60"
    },
    {
        "value": "rewind-outline"
    },
    {
        "value": "rhombus"
    },
    {
        "value": "rhombus-medium"
    },
    {
        "value": "rhombus-medium-outline"
    },
    {
        "value": "rhombus-outline"
    },
    {
        "value": "rhombus-split"
    },
    {
        "value": "rhombus-split-outline"
    },
    {
        "value": "ribbon"
    },
    {
        "value": "rice"
    },
    {
        "value": "rickshaw"
    },
    {
        "value": "rickshaw-electric"
    },
    {
        "value": "ring"
    },
    {
        "value": "rivet"
    },
    {
        "value": "road"
    },
    {
        "value": "road-variant"
    },
    {
        "value": "robber"
    },
    {
        "value": "robot"
    },
    {
        "value": "robot-angry"
    },
    {
        "value": "robot-angry-outline"
    },
    {
        "value": "robot-confused"
    },
    {
        "value": "robot-confused-outline"
    },
    {
        "value": "robot-dead"
    },
    {
        "value": "robot-dead-outline"
    },
    {
        "value": "robot-excited"
    },
    {
        "value": "robot-excited-outline"
    },
    {
        "value": "robot-happy"
    },
    {
        "value": "robot-happy-outline"
    },
    {
        "value": "robot-industrial"
    },
    {
        "value": "robot-love"
    },
    {
        "value": "robot-love-outline"
    },
    {
        "value": "robot-mower"
    },
    {
        "value": "robot-mower-outline"
    },
    {
        "value": "robot-off"
    },
    {
        "value": "robot-off-outline"
    },
    {
        "value": "robot-outline"
    },
    {
        "value": "robot-vacuum"
    },
    {
        "value": "robot-vacuum-variant"
    },
    {
        "value": "rocket"
    },
    {
        "value": "rocket-launch"
    },
    {
        "value": "rocket-launch-outline"
    },
    {
        "value": "rocket-outline"
    },
    {
        "value": "rodent"
    },
    {
        "value": "roller-skate"
    },
    {
        "value": "roller-skate-off"
    },
    {
        "value": "rollerblade"
    },
    {
        "value": "rollerblade-off"
    },
    {
        "value": "rollupjs"
    },
    {
        "value": "roman-numeral-1"
    },
    {
        "value": "roman-numeral-10"
    },
    {
        "value": "roman-numeral-2"
    },
    {
        "value": "roman-numeral-3"
    },
    {
        "value": "roman-numeral-4"
    },
    {
        "value": "roman-numeral-5"
    },
    {
        "value": "roman-numeral-6"
    },
    {
        "value": "roman-numeral-7"
    },
    {
        "value": "roman-numeral-8"
    },
    {
        "value": "roman-numeral-9"
    },
    {
        "value": "room-service"
    },
    {
        "value": "room-service-outline"
    },
    {
        "value": "rotate-3d"
    },
    {
        "value": "rotate-3d-variant"
    },
    {
        "value": "rotate-left"
    },
    {
        "value": "rotate-left-variant"
    },
    {
        "value": "rotate-orbit"
    },
    {
        "value": "rotate-right"
    },
    {
        "value": "rotate-right-variant"
    },
    {
        "value": "rounded-corner"
    },
    {
        "value": "router"
    },
    {
        "value": "router-network"
    },
    {
        "value": "router-wireless"
    },
    {
        "value": "router-wireless-off"
    },
    {
        "value": "router-wireless-settings"
    },
    {
        "value": "routes"
    },
    {
        "value": "routes-clock"
    },
    {
        "value": "rowing"
    },
    {
        "value": "rss"
    },
    {
        "value": "rss-box"
    },
    {
        "value": "rss-off"
    },
    {
        "value": "rug"
    },
    {
        "value": "rugby"
    },
    {
        "value": "ruler"
    },
    {
        "value": "ruler-square"
    },
    {
        "value": "ruler-square-compass"
    },
    {
        "value": "run"
    },
    {
        "value": "run-fast"
    },
    {
        "value": "rv-truck"
    },
    {
        "value": "sack"
    },
    {
        "value": "sack-percent"
    },
    {
        "value": "safe"
    },
    {
        "value": "safe-square"
    },
    {
        "value": "safe-square-outline"
    },
    {
        "value": "safety-goggles"
    },
    {
        "value": "sail-boat"
    },
    {
        "value": "sale"
    },
    {
        "value": "salesforce"
    },
    {
        "value": "sass"
    },
    {
        "value": "satellite"
    },
    {
        "value": "satellite-uplink"
    },
    {
        "value": "satellite-variant"
    },
    {
        "value": "sausage"
    },
    {
        "value": "sausage-off"
    },
    {
        "value": "saw-blade"
    },
    {
        "value": "sawtooth-wave"
    },
    {
        "value": "saxophone"
    },
    {
        "value": "scale"
    },
    {
        "value": "scale-balance"
    },
    {
        "value": "scale-bathroom"
    },
    {
        "value": "scale-off"
    },
    {
        "value": "scan-helper"
    },
    {
        "value": "scanner"
    },
    {
        "value": "scanner-off"
    },
    {
        "value": "scatter-plot"
    },
    {
        "value": "scatter-plot-outline"
    },
    {
        "value": "school"
    },
    {
        "value": "school-outline"
    },
    {
        "value": "scissors-cutting"
    },
    {
        "value": "scooter"
    },
    {
        "value": "scooter-electric"
    },
    {
        "value": "scoreboard"
    },
    {
        "value": "scoreboard-outline"
    },
    {
        "value": "screen-rotation"
    },
    {
        "value": "screen-rotation-lock"
    },
    {
        "value": "screw-flat-top"
    },
    {
        "value": "screw-lag"
    },
    {
        "value": "screw-machine-flat-top"
    },
    {
        "value": "screw-machine-round-top"
    },
    {
        "value": "screw-round-top"
    },
    {
        "value": "screwdriver"
    },
    {
        "value": "script"
    },
    {
        "value": "script-outline"
    },
    {
        "value": "script-text"
    },
    {
        "value": "script-text-key"
    },
    {
        "value": "script-text-key-outline"
    },
    {
        "value": "script-text-outline"
    },
    {
        "value": "script-text-play"
    },
    {
        "value": "script-text-play-outline"
    },
    {
        "value": "sd"
    },
    {
        "value": "seal"
    },
    {
        "value": "seal-variant"
    },
    {
        "value": "search-web"
    },
    {
        "value": "seat"
    },
    {
        "value": "seat-flat"
    },
    {
        "value": "seat-flat-angled"
    },
    {
        "value": "seat-individual-suite"
    },
    {
        "value": "seat-legroom-extra"
    },
    {
        "value": "seat-legroom-normal"
    },
    {
        "value": "seat-legroom-reduced"
    },
    {
        "value": "seat-outline"
    },
    {
        "value": "seat-passenger"
    },
    {
        "value": "seat-recline-extra"
    },
    {
        "value": "seat-recline-normal"
    },
    {
        "value": "seatbelt"
    },
    {
        "value": "security"
    },
    {
        "value": "security-network"
    },
    {
        "value": "seed"
    },
    {
        "value": "seed-off"
    },
    {
        "value": "seed-off-outline"
    },
    {
        "value": "seed-outline"
    },
    {
        "value": "seesaw"
    },
    {
        "value": "segment"
    },
    {
        "value": "select"
    },
    {
        "value": "select-all"
    },
    {
        "value": "select-color"
    },
    {
        "value": "select-compare"
    },
    {
        "value": "select-drag"
    },
    {
        "value": "select-group"
    },
    {
        "value": "select-inverse"
    },
    {
        "value": "select-marker"
    },
    {
        "value": "select-multiple"
    },
    {
        "value": "select-multiple-marker"
    },
    {
        "value": "select-off"
    },
    {
        "value": "select-place"
    },
    {
        "value": "select-search"
    },
    {
        "value": "selection"
    },
    {
        "value": "selection-drag"
    },
    {
        "value": "selection-ellipse"
    },
    {
        "value": "selection-ellipse-arrow-inside"
    },
    {
        "value": "selection-marker"
    },
    {
        "value": "selection-multiple"
    },
    {
        "value": "selection-multiple-marker"
    },
    {
        "value": "selection-off"
    },
    {
        "value": "selection-search"
    },
    {
        "value": "semantic-web"
    },
    {
        "value": "send"
    },
    {
        "value": "send-check"
    },
    {
        "value": "send-check-outline"
    },
    {
        "value": "send-circle"
    },
    {
        "value": "send-circle-outline"
    },
    {
        "value": "send-clock"
    },
    {
        "value": "send-clock-outline"
    },
    {
        "value": "send-lock"
    },
    {
        "value": "send-lock-outline"
    },
    {
        "value": "send-outline"
    },
    {
        "value": "serial-port"
    },
    {
        "value": "server"
    },
    {
        "value": "server-minus"
    },
    {
        "value": "server-network"
    },
    {
        "value": "server-network-off"
    },
    {
        "value": "server-off"
    },
    {
        "value": "server-plus"
    },
    {
        "value": "server-remove"
    },
    {
        "value": "server-security"
    },
    {
        "value": "set-all"
    },
    {
        "value": "set-center"
    },
    {
        "value": "set-center-right"
    },
    {
        "value": "set-left"
    },
    {
        "value": "set-left-center"
    },
    {
        "value": "set-left-right"
    },
    {
        "value": "set-merge"
    },
    {
        "value": "set-none"
    },
    {
        "value": "set-right"
    },
    {
        "value": "set-split"
    },
    {
        "value": "set-square"
    },
    {
        "value": "set-top-box"
    },
    {
        "value": "settings-helper"
    },
    {
        "value": "shaker"
    },
    {
        "value": "shaker-outline"
    },
    {
        "value": "shape"
    },
    {
        "value": "shape-circle-plus"
    },
    {
        "value": "shape-outline"
    },
    {
        "value": "shape-oval-plus"
    },
    {
        "value": "shape-plus"
    },
    {
        "value": "shape-polygon-plus"
    },
    {
        "value": "shape-rectangle-plus"
    },
    {
        "value": "shape-square-plus"
    },
    {
        "value": "shape-square-rounded-plus"
    },
    {
        "value": "share"
    },
    {
        "value": "share-all"
    },
    {
        "value": "share-all-outline"
    },
    {
        "value": "share-circle"
    },
    {
        "value": "share-off"
    },
    {
        "value": "share-off-outline"
    },
    {
        "value": "share-outline"
    },
    {
        "value": "share-variant"
    },
    {
        "value": "share-variant-outline"
    },
    {
        "value": "shark-fin"
    },
    {
        "value": "shark-fin-outline"
    },
    {
        "value": "sheep"
    },
    {
        "value": "shield"
    },
    {
        "value": "shield-account"
    },
    {
        "value": "shield-account-outline"
    },
    {
        "value": "shield-account-variant"
    },
    {
        "value": "shield-account-variant-outline"
    },
    {
        "value": "shield-airplane"
    },
    {
        "value": "shield-airplane-outline"
    },
    {
        "value": "shield-alert"
    },
    {
        "value": "shield-alert-outline"
    },
    {
        "value": "shield-bug"
    },
    {
        "value": "shield-bug-outline"
    },
    {
        "value": "shield-car"
    },
    {
        "value": "shield-check"
    },
    {
        "value": "shield-check-outline"
    },
    {
        "value": "shield-cross"
    },
    {
        "value": "shield-cross-outline"
    },
    {
        "value": "shield-edit"
    },
    {
        "value": "shield-edit-outline"
    },
    {
        "value": "shield-half"
    },
    {
        "value": "shield-half-full"
    },
    {
        "value": "shield-home"
    },
    {
        "value": "shield-home-outline"
    },
    {
        "value": "shield-key"
    },
    {
        "value": "shield-key-outline"
    },
    {
        "value": "shield-link-variant"
    },
    {
        "value": "shield-link-variant-outline"
    },
    {
        "value": "shield-lock"
    },
    {
        "value": "shield-lock-outline"
    },
    {
        "value": "shield-off"
    },
    {
        "value": "shield-off-outline"
    },
    {
        "value": "shield-outline"
    },
    {
        "value": "shield-plus"
    },
    {
        "value": "shield-plus-outline"
    },
    {
        "value": "shield-refresh"
    },
    {
        "value": "shield-refresh-outline"
    },
    {
        "value": "shield-remove"
    },
    {
        "value": "shield-remove-outline"
    },
    {
        "value": "shield-search"
    },
    {
        "value": "shield-star"
    },
    {
        "value": "shield-star-outline"
    },
    {
        "value": "shield-sun"
    },
    {
        "value": "shield-sun-outline"
    },
    {
        "value": "shield-sync"
    },
    {
        "value": "shield-sync-outline"
    },
    {
        "value": "shimmer"
    },
    {
        "value": "ship-wheel"
    },
    {
        "value": "shoe-ballet"
    },
    {
        "value": "shoe-cleat"
    },
    {
        "value": "shoe-formal"
    },
    {
        "value": "shoe-heel"
    },
    {
        "value": "shoe-print"
    },
    {
        "value": "shoe-sneaker"
    },
    {
        "value": "shopping"
    },
    {
        "value": "shopping-music"
    },
    {
        "value": "shopping-outline"
    },
    {
        "value": "shopping-search"
    },
    {
        "value": "shore"
    },
    {
        "value": "shovel"
    },
    {
        "value": "shovel-off"
    },
    {
        "value": "shower"
    },
    {
        "value": "shower-head"
    },
    {
        "value": "shredder"
    },
    {
        "value": "shuffle"
    },
    {
        "value": "shuffle-disabled"
    },
    {
        "value": "shuffle-variant"
    },
    {
        "value": "shuriken"
    },
    {
        "value": "sigma"
    },
    {
        "value": "sigma-lower"
    },
    {
        "value": "sign-caution"
    },
    {
        "value": "sign-direction"
    },
    {
        "value": "sign-direction-minus"
    },
    {
        "value": "sign-direction-plus"
    },
    {
        "value": "sign-direction-remove"
    },
    {
        "value": "sign-pole"
    },
    {
        "value": "sign-real-estate"
    },
    {
        "value": "sign-text"
    },
    {
        "value": "signal"
    },
    {
        "value": "signal-2g"
    },
    {
        "value": "signal-3g"
    },
    {
        "value": "signal-4g"
    },
    {
        "value": "signal-5g"
    },
    {
        "value": "signal-cellular-1"
    },
    {
        "value": "signal-cellular-2"
    },
    {
        "value": "signal-cellular-3"
    },
    {
        "value": "signal-cellular-outline"
    },
    {
        "value": "signal-distance-variant"
    },
    {
        "value": "signal-hspa"
    },
    {
        "value": "signal-hspa-plus"
    },
    {
        "value": "signal-off"
    },
    {
        "value": "signal-variant"
    },
    {
        "value": "signature"
    },
    {
        "value": "signature-freehand"
    },
    {
        "value": "signature-image"
    },
    {
        "value": "signature-text"
    },
    {
        "value": "silo"
    },
    {
        "value": "silverware"
    },
    {
        "value": "silverware-clean"
    },
    {
        "value": "silverware-fork"
    },
    {
        "value": "silverware-fork-knife"
    },
    {
        "value": "silverware-spoon"
    },
    {
        "value": "silverware-variant"
    },
    {
        "value": "sim"
    },
    {
        "value": "sim-alert"
    },
    {
        "value": "sim-alert-outline"
    },
    {
        "value": "sim-off"
    },
    {
        "value": "sim-off-outline"
    },
    {
        "value": "sim-outline"
    },
    {
        "value": "simple-icons"
    },
    {
        "value": "sina-weibo"
    },
    {
        "value": "sine-wave"
    },
    {
        "value": "sitemap"
    },
    {
        "value": "size-l"
    },
    {
        "value": "size-m"
    },
    {
        "value": "size-s"
    },
    {
        "value": "size-xl"
    },
    {
        "value": "size-xs"
    },
    {
        "value": "size-xxl"
    },
    {
        "value": "size-xxs"
    },
    {
        "value": "size-xxxl"
    },
    {
        "value": "skate"
    },
    {
        "value": "skate-off"
    },
    {
        "value": "skateboard"
    },
    {
        "value": "skateboarding"
    },
    {
        "value": "skew-less"
    },
    {
        "value": "skew-more"
    },
    {
        "value": "ski"
    },
    {
        "value": "ski-cross-country"
    },
    {
        "value": "ski-water"
    },
    {
        "value": "skip-backward"
    },
    {
        "value": "skip-backward-outline"
    },
    {
        "value": "skip-forward"
    },
    {
        "value": "skip-forward-outline"
    },
    {
        "value": "skip-next"
    },
    {
        "value": "skip-next-circle"
    },
    {
        "value": "skip-next-circle-outline"
    },
    {
        "value": "skip-next-outline"
    },
    {
        "value": "skip-previous"
    },
    {
        "value": "skip-previous-circle"
    },
    {
        "value": "skip-previous-circle-outline"
    },
    {
        "value": "skip-previous-outline"
    },
    {
        "value": "skull"
    },
    {
        "value": "skull-crossbones"
    },
    {
        "value": "skull-crossbones-outline"
    },
    {
        "value": "skull-outline"
    },
    {
        "value": "skull-scan"
    },
    {
        "value": "skull-scan-outline"
    },
    {
        "value": "skype"
    },
    {
        "value": "skype-business"
    },
    {
        "value": "slack"
    },
    {
        "value": "slash-forward"
    },
    {
        "value": "slash-forward-box"
    },
    {
        "value": "sledding"
    },
    {
        "value": "sleep"
    },
    {
        "value": "sleep-off"
    },
    {
        "value": "slide"
    },
    {
        "value": "slope-downhill"
    },
    {
        "value": "slope-uphill"
    },
    {
        "value": "slot-machine"
    },
    {
        "value": "slot-machine-outline"
    },
    {
        "value": "smart-card"
    },
    {
        "value": "smart-card-outline"
    },
    {
        "value": "smart-card-reader"
    },
    {
        "value": "smart-card-reader-outline"
    },
    {
        "value": "smog"
    },
    {
        "value": "smoke-detector"
    },
    {
        "value": "smoking"
    },
    {
        "value": "smoking-off"
    },
    {
        "value": "smoking-pipe"
    },
    {
        "value": "smoking-pipe-off"
    },
    {
        "value": "snail"
    },
    {
        "value": "snake"
    },
    {
        "value": "snapchat"
    },
    {
        "value": "snowboard"
    },
    {
        "value": "snowflake"
    },
    {
        "value": "snowflake-alert"
    },
    {
        "value": "snowflake-melt"
    },
    {
        "value": "snowflake-off"
    },
    {
        "value": "snowflake-variant"
    },
    {
        "value": "snowman"
    },
    {
        "value": "snowmobile"
    },
    {
        "value": "soccer"
    },
    {
        "value": "soccer-field"
    },
    {
        "value": "social-distance-2-meters"
    },
    {
        "value": "social-distance-6-feet"
    },
    {
        "value": "sofa"
    },
    {
        "value": "sofa-outline"
    },
    {
        "value": "sofa-single"
    },
    {
        "value": "sofa-single-outline"
    },
    {
        "value": "solar-panel"
    },
    {
        "value": "solar-panel-large"
    },
    {
        "value": "solar-power"
    },
    {
        "value": "soldering-iron"
    },
    {
        "value": "solid"
    },
    {
        "value": "sony-playstation"
    },
    {
        "value": "sort"
    },
    {
        "value": "sort-alphabetical-ascending"
    },
    {
        "value": "sort-alphabetical-ascending-variant"
    },
    {
        "value": "sort-alphabetical-descending"
    },
    {
        "value": "sort-alphabetical-descending-variant"
    },
    {
        "value": "sort-alphabetical-variant"
    },
    {
        "value": "sort-ascending"
    },
    {
        "value": "sort-bool-ascending"
    },
    {
        "value": "sort-bool-ascending-variant"
    },
    {
        "value": "sort-bool-descending"
    },
    {
        "value": "sort-bool-descending-variant"
    },
    {
        "value": "sort-calendar-ascending"
    },
    {
        "value": "sort-calendar-descending"
    },
    {
        "value": "sort-clock-ascending"
    },
    {
        "value": "sort-clock-ascending-outline"
    },
    {
        "value": "sort-clock-descending"
    },
    {
        "value": "sort-clock-descending-outline"
    },
    {
        "value": "sort-descending"
    },
    {
        "value": "sort-numeric-ascending"
    },
    {
        "value": "sort-numeric-ascending-variant"
    },
    {
        "value": "sort-numeric-descending"
    },
    {
        "value": "sort-numeric-descending-variant"
    },
    {
        "value": "sort-numeric-variant"
    },
    {
        "value": "sort-reverse-variant"
    },
    {
        "value": "sort-variant"
    },
    {
        "value": "sort-variant-lock"
    },
    {
        "value": "sort-variant-lock-open"
    },
    {
        "value": "sort-variant-remove"
    },
    {
        "value": "soundcloud"
    },
    {
        "value": "source-branch"
    },
    {
        "value": "source-branch-check"
    },
    {
        "value": "source-branch-minus"
    },
    {
        "value": "source-branch-plus"
    },
    {
        "value": "source-branch-refresh"
    },
    {
        "value": "source-branch-remove"
    },
    {
        "value": "source-branch-sync"
    },
    {
        "value": "source-commit"
    },
    {
        "value": "source-commit-end"
    },
    {
        "value": "source-commit-end-local"
    },
    {
        "value": "source-commit-local"
    },
    {
        "value": "source-commit-next-local"
    },
    {
        "value": "source-commit-start"
    },
    {
        "value": "source-commit-start-next-local"
    },
    {
        "value": "source-fork"
    },
    {
        "value": "source-merge"
    },
    {
        "value": "source-pull"
    },
    {
        "value": "source-repository"
    },
    {
        "value": "source-repository-multiple"
    },
    {
        "value": "soy-sauce"
    },
    {
        "value": "soy-sauce-off"
    },
    {
        "value": "spa"
    },
    {
        "value": "spa-outline"
    },
    {
        "value": "space-invaders"
    },
    {
        "value": "space-station"
    },
    {
        "value": "spade"
    },
    {
        "value": "speaker"
    },
    {
        "value": "speaker-bluetooth"
    },
    {
        "value": "speaker-multiple"
    },
    {
        "value": "speaker-off"
    },
    {
        "value": "speaker-wireless"
    },
    {
        "value": "speedometer"
    },
    {
        "value": "speedometer-medium"
    },
    {
        "value": "speedometer-slow"
    },
    {
        "value": "spellcheck"
    },
    {
        "value": "spider"
    },
    {
        "value": "spider-thread"
    },
    {
        "value": "spider-web"
    },
    {
        "value": "spirit-level"
    },
    {
        "value": "spoon-sugar"
    },
    {
        "value": "spotify"
    },
    {
        "value": "spotlight"
    },
    {
        "value": "spotlight-beam"
    },
    {
        "value": "spray"
    },
    {
        "value": "spray-bottle"
    },
    {
        "value": "sprinkler"
    },
    {
        "value": "sprinkler-variant"
    },
    {
        "value": "sprout"
    },
    {
        "value": "sprout-outline"
    },
    {
        "value": "square"
    },
    {
        "value": "square-circle"
    },
    {
        "value": "square-edit-outline"
    },
    {
        "value": "square-medium"
    },
    {
        "value": "square-medium-outline"
    },
    {
        "value": "square-off"
    },
    {
        "value": "square-off-outline"
    },
    {
        "value": "square-outline"
    },
    {
        "value": "square-root"
    },
    {
        "value": "square-root-box"
    },
    {
        "value": "square-rounded"
    },
    {
        "value": "square-rounded-outline"
    },
    {
        "value": "square-small"
    },
    {
        "value": "square-wave"
    },
    {
        "value": "squeegee"
    },
    {
        "value": "ssh"
    },
    {
        "value": "stack-exchange"
    },
    {
        "value": "stack-overflow"
    },
    {
        "value": "stackpath"
    },
    {
        "value": "stadium"
    },
    {
        "value": "stadium-variant"
    },
    {
        "value": "stairs"
    },
    {
        "value": "stairs-box"
    },
    {
        "value": "stairs-down"
    },
    {
        "value": "stairs-up"
    },
    {
        "value": "stamper"
    },
    {
        "value": "standard-definition"
    },
    {
        "value": "star"
    },
    {
        "value": "star-box"
    },
    {
        "value": "star-box-multiple"
    },
    {
        "value": "star-box-multiple-outline"
    },
    {
        "value": "star-box-outline"
    },
    {
        "value": "star-check"
    },
    {
        "value": "star-check-outline"
    },
    {
        "value": "star-circle"
    },
    {
        "value": "star-circle-outline"
    },
    {
        "value": "star-cog"
    },
    {
        "value": "star-cog-outline"
    },
    {
        "value": "star-crescent"
    },
    {
        "value": "star-david"
    },
    {
        "value": "star-face"
    },
    {
        "value": "star-four-points"
    },
    {
        "value": "star-four-points-outline"
    },
    {
        "value": "star-half"
    },
    {
        "value": "star-half-full"
    },
    {
        "value": "star-minus"
    },
    {
        "value": "star-minus-outline"
    },
    {
        "value": "star-off"
    },
    {
        "value": "star-off-outline"
    },
    {
        "value": "star-outline"
    },
    {
        "value": "star-plus"
    },
    {
        "value": "star-plus-outline"
    },
    {
        "value": "star-remove"
    },
    {
        "value": "star-remove-outline"
    },
    {
        "value": "star-settings"
    },
    {
        "value": "star-settings-outline"
    },
    {
        "value": "star-shooting"
    },
    {
        "value": "star-shooting-outline"
    },
    {
        "value": "star-three-points"
    },
    {
        "value": "star-three-points-outline"
    },
    {
        "value": "state-machine"
    },
    {
        "value": "steam"
    },
    {
        "value": "steering"
    },
    {
        "value": "steering-off"
    },
    {
        "value": "step-backward"
    },
    {
        "value": "step-backward-2"
    },
    {
        "value": "step-forward"
    },
    {
        "value": "step-forward-2"
    },
    {
        "value": "stethoscope"
    },
    {
        "value": "sticker"
    },
    {
        "value": "sticker-alert"
    },
    {
        "value": "sticker-alert-outline"
    },
    {
        "value": "sticker-check"
    },
    {
        "value": "sticker-check-outline"
    },
    {
        "value": "sticker-circle-outline"
    },
    {
        "value": "sticker-emoji"
    },
    {
        "value": "sticker-minus"
    },
    {
        "value": "sticker-minus-outline"
    },
    {
        "value": "sticker-outline"
    },
    {
        "value": "sticker-plus"
    },
    {
        "value": "sticker-plus-outline"
    },
    {
        "value": "sticker-remove"
    },
    {
        "value": "sticker-remove-outline"
    },
    {
        "value": "stocking"
    },
    {
        "value": "stomach"
    },
    {
        "value": "stop"
    },
    {
        "value": "stop-circle"
    },
    {
        "value": "stop-circle-outline"
    },
    {
        "value": "store"
    },
    {
        "value": "store-24-hour"
    },
    {
        "value": "store-minus"
    },
    {
        "value": "store-outline"
    },
    {
        "value": "store-plus"
    },
    {
        "value": "store-remove"
    },
    {
        "value": "storefront"
    },
    {
        "value": "storefront-outline"
    },
    {
        "value": "stove"
    },
    {
        "value": "strategy"
    },
    {
        "value": "stretch-to-page"
    },
    {
        "value": "stretch-to-page-outline"
    },
    {
        "value": "string-lights"
    },
    {
        "value": "string-lights-off"
    },
    {
        "value": "subdirectory-arrow-left"
    },
    {
        "value": "subdirectory-arrow-right"
    },
    {
        "value": "submarine"
    },
    {
        "value": "subtitles"
    },
    {
        "value": "subtitles-outline"
    },
    {
        "value": "subway"
    },
    {
        "value": "subway-alert-variant"
    },
    {
        "value": "subway-variant"
    },
    {
        "value": "summit"
    },
    {
        "value": "sunglasses"
    },
    {
        "value": "surfing"
    },
    {
        "value": "surround-sound"
    },
    {
        "value": "surround-sound-2-0"
    },
    {
        "value": "surround-sound-2-1"
    },
    {
        "value": "surround-sound-3-1"
    },
    {
        "value": "surround-sound-5-1"
    },
    {
        "value": "surround-sound-5-1-2"
    },
    {
        "value": "surround-sound-7-1"
    },
    {
        "value": "svg"
    },
    {
        "value": "swap-horizontal"
    },
    {
        "value": "swap-horizontal-bold"
    },
    {
        "value": "swap-horizontal-circle"
    },
    {
        "value": "swap-horizontal-circle-outline"
    },
    {
        "value": "swap-horizontal-variant"
    },
    {
        "value": "swap-vertical"
    },
    {
        "value": "swap-vertical-bold"
    },
    {
        "value": "swap-vertical-circle"
    },
    {
        "value": "swap-vertical-circle-outline"
    },
    {
        "value": "swap-vertical-variant"
    },
    {
        "value": "swim"
    },
    {
        "value": "switch"
    },
    {
        "value": "sword"
    },
    {
        "value": "sword-cross"
    },
    {
        "value": "syllabary-hangul"
    },
    {
        "value": "syllabary-hiragana"
    },
    {
        "value": "syllabary-katakana"
    },
    {
        "value": "syllabary-katakana-halfwidth"
    },
    {
        "value": "symbol"
    },
    {
        "value": "symfony"
    },
    {
        "value": "sync"
    },
    {
        "value": "sync-alert"
    },
    {
        "value": "sync-circle"
    },
    {
        "value": "sync-off"
    },
    {
        "value": "tab"
    },
    {
        "value": "tab-minus"
    },
    {
        "value": "tab-plus"
    },
    {
        "value": "tab-remove"
    },
    {
        "value": "tab-unselected"
    },
    {
        "value": "table"
    },
    {
        "value": "table-account"
    },
    {
        "value": "table-alert"
    },
    {
        "value": "table-arrow-down"
    },
    {
        "value": "table-arrow-left"
    },
    {
        "value": "table-arrow-right"
    },
    {
        "value": "table-arrow-up"
    },
    {
        "value": "table-border"
    },
    {
        "value": "table-cancel"
    },
    {
        "value": "table-chair"
    },
    {
        "value": "table-check"
    },
    {
        "value": "table-clock"
    },
    {
        "value": "table-cog"
    },
    {
        "value": "table-column"
    },
    {
        "value": "table-column-plus-after"
    },
    {
        "value": "table-column-plus-before"
    },
    {
        "value": "table-column-remove"
    },
    {
        "value": "table-column-width"
    },
    {
        "value": "table-edit"
    },
    {
        "value": "table-eye"
    },
    {
        "value": "table-eye-off"
    },
    {
        "value": "table-furniture"
    },
    {
        "value": "table-headers-eye"
    },
    {
        "value": "table-headers-eye-off"
    },
    {
        "value": "table-heart"
    },
    {
        "value": "table-key"
    },
    {
        "value": "table-large"
    },
    {
        "value": "table-large-plus"
    },
    {
        "value": "table-large-remove"
    },
    {
        "value": "table-lock"
    },
    {
        "value": "table-merge-cells"
    },
    {
        "value": "table-minus"
    },
    {
        "value": "table-multiple"
    },
    {
        "value": "table-network"
    },
    {
        "value": "table-of-contents"
    },
    {
        "value": "table-off"
    },
    {
        "value": "table-picnic"
    },
    {
        "value": "table-plus"
    },
    {
        "value": "table-refresh"
    },
    {
        "value": "table-remove"
    },
    {
        "value": "table-row"
    },
    {
        "value": "table-row-height"
    },
    {
        "value": "table-row-plus-after"
    },
    {
        "value": "table-row-plus-before"
    },
    {
        "value": "table-row-remove"
    },
    {
        "value": "table-search"
    },
    {
        "value": "table-settings"
    },
    {
        "value": "table-split-cell"
    },
    {
        "value": "table-star"
    },
    {
        "value": "table-sync"
    },
    {
        "value": "table-tennis"
    },
    {
        "value": "tablet"
    },
    {
        "value": "tablet-android"
    },
    {
        "value": "tablet-cellphone"
    },
    {
        "value": "tablet-dashboard"
    },
    {
        "value": "taco"
    },
    {
        "value": "tag"
    },
    {
        "value": "tag-arrow-down"
    },
    {
        "value": "tag-arrow-down-outline"
    },
    {
        "value": "tag-arrow-left"
    },
    {
        "value": "tag-arrow-left-outline"
    },
    {
        "value": "tag-arrow-right"
    },
    {
        "value": "tag-arrow-right-outline"
    },
    {
        "value": "tag-arrow-up"
    },
    {
        "value": "tag-arrow-up-outline"
    },
    {
        "value": "tag-faces"
    },
    {
        "value": "tag-heart"
    },
    {
        "value": "tag-heart-outline"
    },
    {
        "value": "tag-minus"
    },
    {
        "value": "tag-minus-outline"
    },
    {
        "value": "tag-multiple"
    },
    {
        "value": "tag-multiple-outline"
    },
    {
        "value": "tag-off"
    },
    {
        "value": "tag-off-outline"
    },
    {
        "value": "tag-outline"
    },
    {
        "value": "tag-plus"
    },
    {
        "value": "tag-plus-outline"
    },
    {
        "value": "tag-remove"
    },
    {
        "value": "tag-remove-outline"
    },
    {
        "value": "tag-text"
    },
    {
        "value": "tag-text-outline"
    },
    {
        "value": "tailwind"
    },
    {
        "value": "tangram"
    },
    {
        "value": "tank"
    },
    {
        "value": "tanker-truck"
    },
    {
        "value": "tape-drive"
    },
    {
        "value": "tape-measure"
    },
    {
        "value": "target"
    },
    {
        "value": "target-account"
    },
    {
        "value": "target-variant"
    },
    {
        "value": "taxi"
    },
    {
        "value": "tea"
    },
    {
        "value": "tea-outline"
    },
    {
        "value": "teamviewer"
    },
    {
        "value": "telescope"
    },
    {
        "value": "television"
    },
    {
        "value": "television-ambient-light"
    },
    {
        "value": "television-box"
    },
    {
        "value": "television-classic"
    },
    {
        "value": "television-classic-off"
    },
    {
        "value": "television-guide"
    },
    {
        "value": "television-off"
    },
    {
        "value": "television-pause"
    },
    {
        "value": "television-play"
    },
    {
        "value": "television-shimmer"
    },
    {
        "value": "television-stop"
    },
    {
        "value": "temperature-celsius"
    },
    {
        "value": "temperature-fahrenheit"
    },
    {
        "value": "temperature-kelvin"
    },
    {
        "value": "tennis"
    },
    {
        "value": "tennis-ball"
    },
    {
        "value": "tent"
    },
    {
        "value": "terraform"
    },
    {
        "value": "terrain"
    },
    {
        "value": "test-tube"
    },
    {
        "value": "test-tube-empty"
    },
    {
        "value": "test-tube-off"
    },
    {
        "value": "text"
    },
    {
        "value": "text-account"
    },
    {
        "value": "text-box"
    },
    {
        "value": "text-box-check"
    },
    {
        "value": "text-box-check-outline"
    },
    {
        "value": "text-box-minus"
    },
    {
        "value": "text-box-minus-outline"
    },
    {
        "value": "text-box-multiple"
    },
    {
        "value": "text-box-multiple-outline"
    },
    {
        "value": "text-box-outline"
    },
    {
        "value": "text-box-plus"
    },
    {
        "value": "text-box-plus-outline"
    },
    {
        "value": "text-box-remove"
    },
    {
        "value": "text-box-remove-outline"
    },
    {
        "value": "text-box-search"
    },
    {
        "value": "text-box-search-outline"
    },
    {
        "value": "text-long"
    },
    {
        "value": "text-recognition"
    },
    {
        "value": "text-search"
    },
    {
        "value": "text-shadow"
    },
    {
        "value": "text-short"
    },
    {
        "value": "text-to-speech"
    },
    {
        "value": "text-to-speech-off"
    },
    {
        "value": "texture"
    },
    {
        "value": "texture-box"
    },
    {
        "value": "theater"
    },
    {
        "value": "theme-light-dark"
    },
    {
        "value": "thermometer"
    },
    {
        "value": "thermometer-alert"
    },
    {
        "value": "thermometer-chevron-down"
    },
    {
        "value": "thermometer-chevron-up"
    },
    {
        "value": "thermometer-high"
    },
    {
        "value": "thermometer-lines"
    },
    {
        "value": "thermometer-low"
    },
    {
        "value": "thermometer-minus"
    },
    {
        "value": "thermometer-off"
    },
    {
        "value": "thermometer-plus"
    },
    {
        "value": "thermostat"
    },
    {
        "value": "thermostat-box"
    },
    {
        "value": "thought-bubble"
    },
    {
        "value": "thought-bubble-outline"
    },
    {
        "value": "thumb-down"
    },
    {
        "value": "thumb-down-outline"
    },
    {
        "value": "thumb-up"
    },
    {
        "value": "thumb-up-outline"
    },
    {
        "value": "thumbs-up-down"
    },
    {
        "value": "ticket"
    },
    {
        "value": "ticket-account"
    },
    {
        "value": "ticket-confirmation"
    },
    {
        "value": "ticket-confirmation-outline"
    },
    {
        "value": "ticket-outline"
    },
    {
        "value": "ticket-percent"
    },
    {
        "value": "ticket-percent-outline"
    },
    {
        "value": "tie"
    },
    {
        "value": "tilde"
    },
    {
        "value": "timelapse"
    },
    {
        "value": "timeline"
    },
    {
        "value": "timeline-alert"
    },
    {
        "value": "timeline-alert-outline"
    },
    {
        "value": "timeline-check"
    },
    {
        "value": "timeline-check-outline"
    },
    {
        "value": "timeline-clock"
    },
    {
        "value": "timeline-clock-outline"
    },
    {
        "value": "timeline-help"
    },
    {
        "value": "timeline-help-outline"
    },
    {
        "value": "timeline-minus"
    },
    {
        "value": "timeline-minus-outline"
    },
    {
        "value": "timeline-outline"
    },
    {
        "value": "timeline-plus"
    },
    {
        "value": "timeline-plus-outline"
    },
    {
        "value": "timeline-remove"
    },
    {
        "value": "timeline-remove-outline"
    },
    {
        "value": "timeline-text"
    },
    {
        "value": "timeline-text-outline"
    },
    {
        "value": "timer"
    },
    {
        "value": "timer-10"
    },
    {
        "value": "timer-3"
    },
    {
        "value": "timer-off"
    },
    {
        "value": "timer-off-outline"
    },
    {
        "value": "timer-outline"
    },
    {
        "value": "timer-sand"
    },
    {
        "value": "timer-sand-empty"
    },
    {
        "value": "timer-sand-full"
    },
    {
        "value": "timetable"
    },
    {
        "value": "toaster"
    },
    {
        "value": "toaster-off"
    },
    {
        "value": "toaster-oven"
    },
    {
        "value": "toggle-switch"
    },
    {
        "value": "toggle-switch-off"
    },
    {
        "value": "toggle-switch-off-outline"
    },
    {
        "value": "toggle-switch-outline"
    },
    {
        "value": "toilet"
    },
    {
        "value": "toolbox"
    },
    {
        "value": "toolbox-outline"
    },
    {
        "value": "tools"
    },
    {
        "value": "tooltip"
    },
    {
        "value": "tooltip-account"
    },
    {
        "value": "tooltip-check"
    },
    {
        "value": "tooltip-check-outline"
    },
    {
        "value": "tooltip-edit"
    },
    {
        "value": "tooltip-edit-outline"
    },
    {
        "value": "tooltip-image"
    },
    {
        "value": "tooltip-image-outline"
    },
    {
        "value": "tooltip-minus"
    },
    {
        "value": "tooltip-minus-outline"
    },
    {
        "value": "tooltip-outline"
    },
    {
        "value": "tooltip-plus"
    },
    {
        "value": "tooltip-plus-outline"
    },
    {
        "value": "tooltip-remove"
    },
    {
        "value": "tooltip-remove-outline"
    },
    {
        "value": "tooltip-text"
    },
    {
        "value": "tooltip-text-outline"
    },
    {
        "value": "tooth"
    },
    {
        "value": "tooth-outline"
    },
    {
        "value": "toothbrush"
    },
    {
        "value": "toothbrush-electric"
    },
    {
        "value": "toothbrush-paste"
    },
    {
        "value": "torch"
    },
    {
        "value": "tortoise"
    },
    {
        "value": "toslink"
    },
    {
        "value": "tournament"
    },
    {
        "value": "tow-truck"
    },
    {
        "value": "tower-beach"
    },
    {
        "value": "tower-fire"
    },
    {
        "value": "toy-brick"
    },
    {
        "value": "toy-brick-marker"
    },
    {
        "value": "toy-brick-marker-outline"
    },
    {
        "value": "toy-brick-minus"
    },
    {
        "value": "toy-brick-minus-outline"
    },
    {
        "value": "toy-brick-outline"
    },
    {
        "value": "toy-brick-plus"
    },
    {
        "value": "toy-brick-plus-outline"
    },
    {
        "value": "toy-brick-remove"
    },
    {
        "value": "toy-brick-remove-outline"
    },
    {
        "value": "toy-brick-search"
    },
    {
        "value": "toy-brick-search-outline"
    },
    {
        "value": "track-light"
    },
    {
        "value": "trackpad"
    },
    {
        "value": "trackpad-lock"
    },
    {
        "value": "tractor"
    },
    {
        "value": "tractor-variant"
    },
    {
        "value": "trademark"
    },
    {
        "value": "traffic-cone"
    },
    {
        "value": "traffic-light"
    },
    {
        "value": "train"
    },
    {
        "value": "train-car"
    },
    {
        "value": "train-car-passenger"
    },
    {
        "value": "train-car-passenger-door"
    },
    {
        "value": "train-car-passenger-door-open"
    },
    {
        "value": "train-car-passenger-variant"
    },
    {
        "value": "train-variant"
    },
    {
        "value": "tram"
    },
    {
        "value": "tram-side"
    },
    {
        "value": "transcribe"
    },
    {
        "value": "transcribe-close"
    },
    {
        "value": "transfer"
    },
    {
        "value": "transfer-down"
    },
    {
        "value": "transfer-left"
    },
    {
        "value": "transfer-right"
    },
    {
        "value": "transfer-up"
    },
    {
        "value": "transit-connection"
    },
    {
        "value": "transit-connection-horizontal"
    },
    {
        "value": "transit-connection-variant"
    },
    {
        "value": "transit-detour"
    },
    {
        "value": "transit-skip"
    },
    {
        "value": "transit-transfer"
    },
    {
        "value": "transition"
    },
    {
        "value": "transition-masked"
    },
    {
        "value": "translate"
    },
    {
        "value": "translate-off"
    },
    {
        "value": "transmission-tower"
    },
    {
        "value": "trash-can"
    },
    {
        "value": "trash-can-outline"
    },
    {
        "value": "tray"
    },
    {
        "value": "tray-alert"
    },
    {
        "value": "tray-arrow-down"
    },
    {
        "value": "tray-arrow-up"
    },
    {
        "value": "tray-full"
    },
    {
        "value": "tray-minus"
    },
    {
        "value": "tray-plus"
    },
    {
        "value": "tray-remove"
    },
    {
        "value": "treasure-chest"
    },
    {
        "value": "tree"
    },
    {
        "value": "tree-outline"
    },
    {
        "value": "trello"
    },
    {
        "value": "trending-down"
    },
    {
        "value": "trending-neutral"
    },
    {
        "value": "trending-up"
    },
    {
        "value": "triangle"
    },
    {
        "value": "triangle-outline"
    },
    {
        "value": "triangle-wave"
    },
    {
        "value": "triforce"
    },
    {
        "value": "trophy"
    },
    {
        "value": "trophy-award"
    },
    {
        "value": "trophy-broken"
    },
    {
        "value": "trophy-outline"
    },
    {
        "value": "trophy-variant"
    },
    {
        "value": "trophy-variant-outline"
    },
    {
        "value": "truck"
    },
    {
        "value": "truck-check"
    },
    {
        "value": "truck-check-outline"
    },
    {
        "value": "truck-delivery"
    },
    {
        "value": "truck-delivery-outline"
    },
    {
        "value": "truck-fast"
    },
    {
        "value": "truck-fast-outline"
    },
    {
        "value": "truck-outline"
    },
    {
        "value": "truck-trailer"
    },
    {
        "value": "trumpet"
    },
    {
        "value": "tshirt-crew"
    },
    {
        "value": "tshirt-crew-outline"
    },
    {
        "value": "tshirt-v"
    },
    {
        "value": "tshirt-v-outline"
    },
    {
        "value": "tumble-dryer"
    },
    {
        "value": "tumble-dryer-alert"
    },
    {
        "value": "tumble-dryer-off"
    },
    {
        "value": "tune"
    },
    {
        "value": "tune-variant"
    },
    {
        "value": "tune-vertical"
    },
    {
        "value": "tune-vertical-variant"
    },
    {
        "value": "turkey"
    },
    {
        "value": "turnstile"
    },
    {
        "value": "turnstile-outline"
    },
    {
        "value": "turtle"
    },
    {
        "value": "twitch"
    },
    {
        "value": "twitter"
    },
    {
        "value": "two-factor-authentication"
    },
    {
        "value": "typewriter"
    },
    {
        "value": "ubisoft"
    },
    {
        "value": "ubuntu"
    },
    {
        "value": "ufo"
    },
    {
        "value": "ufo-outline"
    },
    {
        "value": "ultra-high-definition"
    },
    {
        "value": "umbraco"
    },
    {
        "value": "umbrella"
    },
    {
        "value": "umbrella-closed"
    },
    {
        "value": "umbrella-closed-outline"
    },
    {
        "value": "umbrella-closed-variant"
    },
    {
        "value": "umbrella-outline"
    },
    {
        "value": "undo"
    },
    {
        "value": "undo-variant"
    },
    {
        "value": "unfold-less-horizontal"
    },
    {
        "value": "unfold-less-vertical"
    },
    {
        "value": "unfold-more-horizontal"
    },
    {
        "value": "unfold-more-vertical"
    },
    {
        "value": "ungroup"
    },
    {
        "value": "unicode"
    },
    {
        "value": "unicorn"
    },
    {
        "value": "unicorn-variant"
    },
    {
        "value": "unicycle"
    },
    {
        "value": "unity"
    },
    {
        "value": "unreal"
    },
    {
        "value": "untappd"
    },
    {
        "value": "update"
    },
    {
        "value": "upload"
    },
    {
        "value": "upload-lock"
    },
    {
        "value": "upload-lock-outline"
    },
    {
        "value": "upload-multiple"
    },
    {
        "value": "upload-network"
    },
    {
        "value": "upload-network-outline"
    },
    {
        "value": "upload-off"
    },
    {
        "value": "upload-off-outline"
    },
    {
        "value": "upload-outline"
    },
    {
        "value": "usb"
    },
    {
        "value": "usb-flash-drive"
    },
    {
        "value": "usb-flash-drive-outline"
    },
    {
        "value": "usb-port"
    },
    {
        "value": "valve"
    },
    {
        "value": "valve-closed"
    },
    {
        "value": "valve-open"
    },
    {
        "value": "van-passenger"
    },
    {
        "value": "van-utility"
    },
    {
        "value": "vanish"
    },
    {
        "value": "vanish-quarter"
    },
    {
        "value": "vanity-light"
    },
    {
        "value": "variable"
    },
    {
        "value": "variable-box"
    },
    {
        "value": "vector-arrange-above"
    },
    {
        "value": "vector-arrange-below"
    },
    {
        "value": "vector-bezier"
    },
    {
        "value": "vector-circle"
    },
    {
        "value": "vector-circle-variant"
    },
    {
        "value": "vector-combine"
    },
    {
        "value": "vector-curve"
    },
    {
        "value": "vector-difference"
    },
    {
        "value": "vector-difference-ab"
    },
    {
        "value": "vector-difference-ba"
    },
    {
        "value": "vector-ellipse"
    },
    {
        "value": "vector-intersection"
    },
    {
        "value": "vector-line"
    },
    {
        "value": "vector-link"
    },
    {
        "value": "vector-point"
    },
    {
        "value": "vector-polygon"
    },
    {
        "value": "vector-polyline"
    },
    {
        "value": "vector-polyline-edit"
    },
    {
        "value": "vector-polyline-minus"
    },
    {
        "value": "vector-polyline-plus"
    },
    {
        "value": "vector-polyline-remove"
    },
    {
        "value": "vector-radius"
    },
    {
        "value": "vector-rectangle"
    },
    {
        "value": "vector-selection"
    },
    {
        "value": "vector-square"
    },
    {
        "value": "vector-triangle"
    },
    {
        "value": "vector-union"
    },
    {
        "value": "vhs"
    },
    {
        "value": "vibrate"
    },
    {
        "value": "vibrate-off"
    },
    {
        "value": "video"
    },
    {
        "value": "video-3d"
    },
    {
        "value": "video-3d-off"
    },
    {
        "value": "video-3d-variant"
    },
    {
        "value": "video-4k-box"
    },
    {
        "value": "video-account"
    },
    {
        "value": "video-box"
    },
    {
        "value": "video-box-off"
    },
    {
        "value": "video-check"
    },
    {
        "value": "video-check-outline"
    },
    {
        "value": "video-high-definition"
    },
    {
        "value": "video-image"
    },
    {
        "value": "video-input-antenna"
    },
    {
        "value": "video-input-component"
    },
    {
        "value": "video-input-hdmi"
    },
    {
        "value": "video-input-scart"
    },
    {
        "value": "video-input-svideo"
    },
    {
        "value": "video-minus"
    },
    {
        "value": "video-minus-outline"
    },
    {
        "value": "video-off"
    },
    {
        "value": "video-off-outline"
    },
    {
        "value": "video-outline"
    },
    {
        "value": "video-plus"
    },
    {
        "value": "video-plus-outline"
    },
    {
        "value": "video-stabilization"
    },
    {
        "value": "video-switch"
    },
    {
        "value": "video-switch-outline"
    },
    {
        "value": "video-vintage"
    },
    {
        "value": "video-wireless"
    },
    {
        "value": "video-wireless-outline"
    },
    {
        "value": "view-agenda"
    },
    {
        "value": "view-agenda-outline"
    },
    {
        "value": "view-array"
    },
    {
        "value": "view-array-outline"
    },
    {
        "value": "view-carousel"
    },
    {
        "value": "view-carousel-outline"
    },
    {
        "value": "view-column"
    },
    {
        "value": "view-column-outline"
    },
    {
        "value": "view-comfy"
    },
    {
        "value": "view-comfy-outline"
    },
    {
        "value": "view-compact"
    },
    {
        "value": "view-compact-outline"
    },
    {
        "value": "view-dashboard"
    },
    {
        "value": "view-dashboard-outline"
    },
    {
        "value": "view-dashboard-variant"
    },
    {
        "value": "view-dashboard-variant-outline"
    },
    {
        "value": "view-day"
    },
    {
        "value": "view-day-outline"
    },
    {
        "value": "view-grid"
    },
    {
        "value": "view-grid-outline"
    },
    {
        "value": "view-grid-plus"
    },
    {
        "value": "view-grid-plus-outline"
    },
    {
        "value": "view-headline"
    },
    {
        "value": "view-list"
    },
    {
        "value": "view-list-outline"
    },
    {
        "value": "view-module"
    },
    {
        "value": "view-module-outline"
    },
    {
        "value": "view-parallel"
    },
    {
        "value": "view-parallel-outline"
    },
    {
        "value": "view-quilt"
    },
    {
        "value": "view-quilt-outline"
    },
    {
        "value": "view-sequential"
    },
    {
        "value": "view-sequential-outline"
    },
    {
        "value": "view-split-horizontal"
    },
    {
        "value": "view-split-vertical"
    },
    {
        "value": "view-stream"
    },
    {
        "value": "view-stream-outline"
    },
    {
        "value": "view-week"
    },
    {
        "value": "view-week-outline"
    },
    {
        "value": "vimeo"
    },
    {
        "value": "violin"
    },
    {
        "value": "virtual-reality"
    },
    {
        "value": "virus"
    },
    {
        "value": "virus-outline"
    },
    {
        "value": "vk"
    },
    {
        "value": "vlc"
    },
    {
        "value": "voicemail"
    },
    {
        "value": "volleyball"
    },
    {
        "value": "volume-high"
    },
    {
        "value": "volume-low"
    },
    {
        "value": "volume-medium"
    },
    {
        "value": "volume-minus"
    },
    {
        "value": "volume-mute"
    },
    {
        "value": "volume-off"
    },
    {
        "value": "volume-plus"
    },
    {
        "value": "volume-source"
    },
    {
        "value": "volume-variant-off"
    },
    {
        "value": "volume-vibrate"
    },
    {
        "value": "vote"
    },
    {
        "value": "vote-outline"
    },
    {
        "value": "vpn"
    },
    {
        "value": "vuejs"
    },
    {
        "value": "vuetify"
    },
    {
        "value": "walk"
    },
    {
        "value": "wall"
    },
    {
        "value": "wall-sconce"
    },
    {
        "value": "wall-sconce-flat"
    },
    {
        "value": "wall-sconce-flat-variant"
    },
    {
        "value": "wall-sconce-round"
    },
    {
        "value": "wall-sconce-round-variant"
    },
    {
        "value": "wallet"
    },
    {
        "value": "wallet-giftcard"
    },
    {
        "value": "wallet-membership"
    },
    {
        "value": "wallet-outline"
    },
    {
        "value": "wallet-plus"
    },
    {
        "value": "wallet-plus-outline"
    },
    {
        "value": "wallet-travel"
    },
    {
        "value": "wallpaper"
    },
    {
        "value": "wan"
    },
    {
        "value": "wardrobe"
    },
    {
        "value": "wardrobe-outline"
    },
    {
        "value": "warehouse"
    },
    {
        "value": "washing-machine"
    },
    {
        "value": "washing-machine-alert"
    },
    {
        "value": "washing-machine-off"
    },
    {
        "value": "watch"
    },
    {
        "value": "watch-export"
    },
    {
        "value": "watch-export-variant"
    },
    {
        "value": "watch-import"
    },
    {
        "value": "watch-import-variant"
    },
    {
        "value": "watch-variant"
    },
    {
        "value": "watch-vibrate"
    },
    {
        "value": "watch-vibrate-off"
    },
    {
        "value": "water"
    },
    {
        "value": "water-alert"
    },
    {
        "value": "water-alert-outline"
    },
    {
        "value": "water-boiler"
    },
    {
        "value": "water-boiler-alert"
    },
    {
        "value": "water-boiler-off"
    },
    {
        "value": "water-check"
    },
    {
        "value": "water-check-outline"
    },
    {
        "value": "water-minus"
    },
    {
        "value": "water-minus-outline"
    },
    {
        "value": "water-off"
    },
    {
        "value": "water-off-outline"
    },
    {
        "value": "water-outline"
    },
    {
        "value": "water-percent"
    },
    {
        "value": "water-percent-alert"
    },
    {
        "value": "water-plus"
    },
    {
        "value": "water-plus-outline"
    },
    {
        "value": "water-polo"
    },
    {
        "value": "water-pump"
    },
    {
        "value": "water-pump-off"
    },
    {
        "value": "water-remove"
    },
    {
        "value": "water-remove-outline"
    },
    {
        "value": "water-well"
    },
    {
        "value": "water-well-outline"
    },
    {
        "value": "watering-can"
    },
    {
        "value": "watering-can-outline"
    },
    {
        "value": "watermark"
    },
    {
        "value": "wave"
    },
    {
        "value": "waveform"
    },
    {
        "value": "waves"
    },
    {
        "value": "waze"
    },
    {
        "value": "weather-cloudy"
    },
    {
        "value": "weather-cloudy-alert"
    },
    {
        "value": "weather-cloudy-arrow-right"
    },
    {
        "value": "weather-fog"
    },
    {
        "value": "weather-hail"
    },
    {
        "value": "weather-hazy"
    },
    {
        "value": "weather-hurricane"
    },
    {
        "value": "weather-lightning"
    },
    {
        "value": "weather-lightning-rainy"
    },
    {
        "value": "weather-night"
    },
    {
        "value": "weather-night-partly-cloudy"
    },
    {
        "value": "weather-partly-cloudy"
    },
    {
        "value": "weather-partly-lightning"
    },
    {
        "value": "weather-partly-rainy"
    },
    {
        "value": "weather-partly-snowy"
    },
    {
        "value": "weather-partly-snowy-rainy"
    },
    {
        "value": "weather-pouring"
    },
    {
        "value": "weather-rainy"
    },
    {
        "value": "weather-snowy"
    },
    {
        "value": "weather-snowy-heavy"
    },
    {
        "value": "weather-snowy-rainy"
    },
    {
        "value": "weather-sunny"
    },
    {
        "value": "weather-sunny-alert"
    },
    {
        "value": "weather-sunny-off"
    },
    {
        "value": "weather-sunset"
    },
    {
        "value": "weather-sunset-down"
    },
    {
        "value": "weather-sunset-up"
    },
    {
        "value": "weather-tornado"
    },
    {
        "value": "weather-windy"
    },
    {
        "value": "weather-windy-variant"
    },
    {
        "value": "web"
    },
    {
        "value": "web-box"
    },
    {
        "value": "web-clock"
    },
    {
        "value": "webcam"
    },
    {
        "value": "webcam-off"
    },
    {
        "value": "webhook"
    },
    {
        "value": "webpack"
    },
    {
        "value": "webrtc"
    },
    {
        "value": "wechat"
    },
    {
        "value": "weight"
    },
    {
        "value": "weight-gram"
    },
    {
        "value": "weight-kilogram"
    },
    {
        "value": "weight-lifter"
    },
    {
        "value": "weight-pound"
    },
    {
        "value": "whatsapp"
    },
    {
        "value": "wheel-barrow"
    },
    {
        "value": "wheelchair-accessibility"
    },
    {
        "value": "whistle"
    },
    {
        "value": "whistle-outline"
    },
    {
        "value": "white-balance-auto"
    },
    {
        "value": "white-balance-incandescent"
    },
    {
        "value": "white-balance-iridescent"
    },
    {
        "value": "white-balance-sunny"
    },
    {
        "value": "widgets"
    },
    {
        "value": "widgets-outline"
    },
    {
        "value": "wifi"
    },
    {
        "value": "wifi-alert"
    },
    {
        "value": "wifi-arrow-down"
    },
    {
        "value": "wifi-arrow-left"
    },
    {
        "value": "wifi-arrow-left-right"
    },
    {
        "value": "wifi-arrow-right"
    },
    {
        "value": "wifi-arrow-up"
    },
    {
        "value": "wifi-arrow-up-down"
    },
    {
        "value": "wifi-cancel"
    },
    {
        "value": "wifi-check"
    },
    {
        "value": "wifi-cog"
    },
    {
        "value": "wifi-lock"
    },
    {
        "value": "wifi-lock-open"
    },
    {
        "value": "wifi-marker"
    },
    {
        "value": "wifi-minus"
    },
    {
        "value": "wifi-off"
    },
    {
        "value": "wifi-plus"
    },
    {
        "value": "wifi-refresh"
    },
    {
        "value": "wifi-remove"
    },
    {
        "value": "wifi-settings"
    },
    {
        "value": "wifi-star"
    },
    {
        "value": "wifi-strength-1"
    },
    {
        "value": "wifi-strength-1-alert"
    },
    {
        "value": "wifi-strength-1-lock"
    },
    {
        "value": "wifi-strength-1-lock-open"
    },
    {
        "value": "wifi-strength-2"
    },
    {
        "value": "wifi-strength-2-alert"
    },
    {
        "value": "wifi-strength-2-lock"
    },
    {
        "value": "wifi-strength-2-lock-open"
    },
    {
        "value": "wifi-strength-3"
    },
    {
        "value": "wifi-strength-3-alert"
    },
    {
        "value": "wifi-strength-3-lock"
    },
    {
        "value": "wifi-strength-3-lock-open"
    },
    {
        "value": "wifi-strength-4"
    },
    {
        "value": "wifi-strength-4-alert"
    },
    {
        "value": "wifi-strength-4-lock"
    },
    {
        "value": "wifi-strength-4-lock-open"
    },
    {
        "value": "wifi-strength-alert-outline"
    },
    {
        "value": "wifi-strength-lock-open-outline"
    },
    {
        "value": "wifi-strength-lock-outline"
    },
    {
        "value": "wifi-strength-off"
    },
    {
        "value": "wifi-strength-off-outline"
    },
    {
        "value": "wifi-strength-outline"
    },
    {
        "value": "wifi-sync"
    },
    {
        "value": "wikipedia"
    },
    {
        "value": "wind-turbine"
    },
    {
        "value": "window-close"
    },
    {
        "value": "window-closed"
    },
    {
        "value": "window-closed-variant"
    },
    {
        "value": "window-maximize"
    },
    {
        "value": "window-minimize"
    },
    {
        "value": "window-open"
    },
    {
        "value": "window-open-variant"
    },
    {
        "value": "window-restore"
    },
    {
        "value": "window-shutter"
    },
    {
        "value": "window-shutter-alert"
    },
    {
        "value": "window-shutter-open"
    },
    {
        "value": "windsock"
    },
    {
        "value": "wiper"
    },
    {
        "value": "wiper-wash"
    },
    {
        "value": "wizard-hat"
    },
    {
        "value": "wordpress"
    },
    {
        "value": "wrap"
    },
    {
        "value": "wrap-disabled"
    },
    {
        "value": "wrench"
    },
    {
        "value": "wrench-outline"
    },
    {
        "value": "xamarin"
    },
    {
        "value": "xamarin-outline"
    },
    {
        "value": "xing"
    },
    {
        "value": "xml"
    },
    {
        "value": "xmpp"
    },
    {
        "value": "y-combinator"
    },
    {
        "value": "yahoo"
    },
    {
        "value": "yeast"
    },
    {
        "value": "yin-yang"
    },
    {
        "value": "yoga"
    },
    {
        "value": "youtube"
    },
    {
        "value": "youtube-gaming"
    },
    {
        "value": "youtube-studio"
    },
    {
        "value": "youtube-subscription"
    },
    {
        "value": "youtube-tv"
    },
    {
        "value": "yurt"
    },
    {
        "value": "z-wave"
    },
    {
        "value": "zend"
    },
    {
        "value": "zigbee"
    },
    {
        "value": "zip-box"
    },
    {
        "value": "zip-box-outline"
    },
    {
        "value": "zip-disk"
    },
    {
        "value": "zodiac-aquarius"
    },
    {
        "value": "zodiac-aries"
    },
    {
        "value": "zodiac-cancer"
    },
    {
        "value": "zodiac-capricorn"
    },
    {
        "value": "zodiac-gemini"
    },
    {
        "value": "zodiac-leo"
    },
    {
        "value": "zodiac-libra"
    },
    {
        "value": "zodiac-pisces"
    },
    {
        "value": "zodiac-sagittarius"
    },
    {
        "value": "zodiac-scorpio"
    },
    {
        "value": "zodiac-taurus"
    },
    {
        "value": "zodiac-virgo"
    }
]

export const iconListFont = [
    { "value": "glass" },
    { "value": "music" },
    { "value": "search" },
    { "value": "envelope-o" },
    { "value": "heart" },
    { "value": "star" },
    { "value": "star-o" },
    { "value": "user" },
    { "value": "film" },
    { "value": "th-large" },
    { "value": "th" },
    { "value": "th-list" },
    { "value": "check" },
    { "value": "remove" },
    { "value": "close" },
    { "value": "times" },
    { "value": "search-plus" },
    { "value": "search-minus" },
    { "value": "power-off" },
    { "value": "signal" },
    { "value": "gear" },
    { "value": "cog" },
    { "value": "trash-o" },
    { "value": "home" },
    { "value": "file-o" },
    { "value": "clock-o" },
    { "value": "road" },
    { "value": "download" },
    { "value": "arrow-circle-o-down" },
    { "value": "arrow-circle-o-up" },
    { "value": "inbox" },
    { "value": "play-circle-o" },
    { "value": "rotate-right" },
    { "value": "repeat" },
    { "value": "refresh" },
    { "value": "list-alt" },
    { "value": "lock" },
    { "value": "flag" },
    { "value": "headphones" },
    { "value": "volume-off" },
    { "value": "volume-down" },
    { "value": "volume-up" },
    { "value": "qrcode" },
    { "value": "barcode" },
    { "value": "tag" },
    { "value": "tags" },
    { "value": "book" },
    { "value": "bookmark" },
    { "value": "print" },
    { "value": "camera" },
    { "value": "font" },
    { "value": "bold" },
    { "value": "italic" },
    { "value": "text-height" },
    { "value": "text-width" },
    { "value": "align-left" },
    { "value": "align-center" },
    { "value": "align-right" },
    { "value": "align-justify" },
    { "value": "list" },
    { "value": "dedent" },
    { "value": "outdent" },
    { "value": "indent" },
    { "value": "video-camera" },
    { "value": "photo" },
    { "value": "image" },
    { "value": "picture-o" },
    { "value": "pencil" },
    { "value": "map-marker" },
    { "value": "adjust" },
    { "value": "tint" },
    { "value": "edit" },
    { "value": "pencil-square-o" },
    { "value": "share-square-o" },
    { "value": "check-square-o" },
    { "value": "arrows" },
    { "value": "step-backward" },
    { "value": "fast-backward" },
    { "value": "backward" },
    { "value": "play" },
    { "value": "pause" },
    { "value": "stop" },
    { "value": "forward" },
    { "value": "fast-forward" },
    { "value": "step-forward" },
    { "value": "eject" },
    { "value": "chevron-left" },
    { "value": "chevron-right" },
    { "value": "plus-circle" },
    { "value": "minus-circle" },
    { "value": "times-circle" },
    { "value": "check-circle" },
    { "value": "question-circle" },
    { "value": "info-circle" },
    { "value": "crosshairs" },
    { "value": "times-circle-o" },
    { "value": "check-circle-o" },
    { "value": "ban" },
    { "value": "arrow-left" },
    { "value": "arrow-right" },
    { "value": "arrow-up" },
    { "value": "arrow-down" },
    { "value": "mail-forward" },
    { "value": "share" },
    { "value": "expand" },
    { "value": "compress" },
    { "value": "plus" },
    { "value": "minus" },
    { "value": "asterisk" },
    { "value": "exclamation-circle" },
    { "value": "gift" },
    { "value": "leaf" },
    { "value": "fire" },
    { "value": "eye" },
    { "value": "eye-slash" },
    { "value": "warning" },
    { "value": "exclamation-triangle" },
    { "value": "plane" },
    { "value": "calendar" },
    { "value": "random" },
    { "value": "comment" },
    { "value": "magnet" },
    { "value": "chevron-up" },
    { "value": "chevron-down" },
    { "value": "retweet" },
    { "value": "shopping-cart" },
    { "value": "folder" },
    { "value": "folder-open" },
    { "value": "arrows-v" },
    { "value": "arrows-h" },
    { "value": "bar-chart-o" },
    { "value": "bar-chart" },
    { "value": "twitter-square" },
    { "value": "facebook-square" },
    { "value": "camera-retro" },
    { "value": "key" },
    { "value": "gears" },
    { "value": "cogs" },
    { "value": "comments" },
    { "value": "thumbs-o-up" },
    { "value": "thumbs-o-down" },
    { "value": "star-half" },
    { "value": "heart-o" },
    { "value": "sign-out" },
    { "value": "linkedin-square" },
    { "value": "thumb-tack" },
    { "value": "external-link" },
    { "value": "sign-in" },
    { "value": "trophy" },
    { "value": "github-square" },
    { "value": "upload" },
    { "value": "lemon-o" },
    { "value": "phone" },
    { "value": "square-o" },
    { "value": "bookmark-o" },
    { "value": "phone-square" },
    { "value": "twitter" },
    { "value": "facebook-f" },
    { "value": "facebook" },
    { "value": "github" },
    { "value": "unlock" },
    { "value": "credit-card" },
    { "value": "feed" },
    { "value": "rss" },
    { "value": "hdd-o" },
    { "value": "bullhorn" },
    { "value": "bell" },
    { "value": "certificate" },
    { "value": "hand-o-right" },
    { "value": "hand-o-left" },
    { "value": "hand-o-up" },
    { "value": "hand-o-down" },
    { "value": "arrow-circle-left" },
    { "value": "arrow-circle-right" },
    { "value": "arrow-circle-up" },
    { "value": "arrow-circle-down" },
    { "value": "globe" },
    { "value": "wrench" },
    { "value": "tasks" },
    { "value": "filter" },
    { "value": "briefcase" },
    { "value": "arrows-alt" },
    { "value": "group" },
    { "value": "users" },
    { "value": "chain" },
    { "value": "link" },
    { "value": "cloud" },
    { "value": "flask" },
    { "value": "cut" },
    { "value": "scissors" },
    { "value": "copy" },
    { "value": "files-o" },
    { "value": "paperclip" },
    { "value": "save" },
    { "value": "floppy-o" },
    { "value": "square" },
    { "value": "navicon" },
    { "value": "reorder" },
    { "value": "bars" },
    { "value": "list-ul" },
    { "value": "list-ol" },
    { "value": "strikethrough" },
    { "value": "underline" },
    { "value": "table" },
    { "value": "magic" },
    { "value": "truck" },
    { "value": "pinterest" },
    { "value": "pinterest-square" },
    { "value": "google-plus-square" },
    { "value": "google-plus" },
    { "value": "money" },
    { "value": "caret-down" },
    { "value": "caret-up" },
    { "value": "caret-left" },
    { "value": "caret-right" },
    { "value": "columns" },
    { "value": "unsorted" },
    { "value": "sort" },
    { "value": "sort-down" },
    { "value": "sort-desc" },
    { "value": "sort-up" },
    { "value": "sort-asc" },
    { "value": "envelope" },
    { "value": "linkedin" },
    { "value": "rotate-left" },
    { "value": "undo" },
    { "value": "legal" },
    { "value": "gavel" },
    { "value": "dashboard" },
    { "value": "tachometer" },
    { "value": "comment-o" },
    { "value": "comments-o" },
    { "value": "flash" },
    { "value": "bolt" },
    { "value": "sitemap" },
    { "value": "umbrella" },
    { "value": "paste" },
    { "value": "clipboard" },
    { "value": "lightbulb-o" },
    { "value": "exchange" },
    { "value": "cloud-download" },
    { "value": "cloud-upload" },
    { "value": "user-md" },
    { "value": "stethoscope" },
    { "value": "suitcase" },
    { "value": "bell-o" },
    { "value": "coffee" },
    { "value": "cutlery" },
    { "value": "file-text-o" },
    { "value": "building-o" },
    { "value": "hospital-o" },
    { "value": "ambulance" },
    { "value": "medkit" },
    { "value": "fighter-jet" },
    { "value": "beer" },
    { "value": "h-square" },
    { "value": "plus-square" },
    { "value": "angle-double-left" },
    { "value": "angle-double-right" },
    { "value": "angle-double-up" },
    { "value": "angle-double-down" },
    { "value": "angle-left" },
    { "value": "angle-right" },
    { "value": "angle-up" },
    { "value": "angle-down" },
    { "value": "desktop" },
    { "value": "laptop" },
    { "value": "tablet" },
    { "value": "mobile-phone" },
    { "value": "mobile" },
    { "value": "circle-o" },
    { "value": "quote-left" },
    { "value": "quote-right" },
    { "value": "spinner" },
    { "value": "circle" },
    { "value": "mail-reply" },
    { "value": "reply" },
    { "value": "github-alt" },
    { "value": "folder-o" },
    { "value": "folder-open-o" },
    { "value": "smile-o" },
    { "value": "frown-o" },
    { "value": "meh-o" },
    { "value": "gamepad" },
    { "value": "keyboard-o" },
    { "value": "flag-o" },
    { "value": "flag-checkered" },
    { "value": "terminal" },
    { "value": "code" },
    { "value": "mail-reply-all" },
    { "value": "reply-all" },
    { "value": "star-half-empty" },
    { "value": "star-half-full" },
    { "value": "star-half-o" },
    { "value": "location-arrow" },
    { "value": "crop" },
    { "value": "code-fork" },
    { "value": "unlink" },
    { "value": "chain-broken" },
    { "value": "question" },
    { "value": "info" },
    { "value": "exclamation" },
    { "value": "superscript" },
    { "value": "subscript" },
    { "value": "eraser" },
    { "value": "puzzle-piece" },
    { "value": "microphone" },
    { "value": "microphone-slash" },
    { "value": "shield" },
    { "value": "calendar-o" },
    { "value": "fire-extinguisher" },
    { "value": "rocket" },
    { "value": "maxcdn" },
    { "value": "chevron-circle-left" },
    { "value": "chevron-circle-right" },
    { "value": "chevron-circle-up" },
    { "value": "chevron-circle-down" },
    { "value": "html5" },
    { "value": "css3" },
    { "value": "anchor" },
    { "value": "unlock-alt" },
    { "value": "bullseye" },
    { "value": "ellipsis-h" },
    { "value": "ellipsis-v" },
    { "value": "rss-square" },
    { "value": "play-circle" },
    { "value": "ticket" },
    { "value": "minus-square" },
    { "value": "minus-square-o" },
    { "value": "level-up" },
    { "value": "level-down" },
    { "value": "check-square" },
    { "value": "pencil-square" },
    { "value": "external-link-square" },
    { "value": "share-square" },
    { "value": "compass" },
    { "value": "toggle-down" },
    { "value": "caret-square-o-down" },
    { "value": "toggle-up" },
    { "value": "caret-square-o-up" },
    { "value": "toggle-right" },
    { "value": "caret-square-o-right" },
    { "value": "euro" },
    { "value": "eur" },
    { "value": "gbp" },
    { "value": "dollar" },
    { "value": "usd" },
    { "value": "rupee" },
    { "value": "inr" },
    { "value": "cny" },
    { "value": "rmb" },
    { "value": "yen" },
    { "value": "jpy" },
    { "value": "ruble" },
    { "value": "rouble" },
    { "value": "rub" },
    { "value": "won" },
    { "value": "krw" },
    { "value": "bitcoin" },
    { "value": "btc" },
    { "value": "file" },
    { "value": "file-text" },
    { "value": "sort-alpha-asc" },
    { "value": "sort-alpha-desc" },
    { "value": "sort-amount-asc" },
    { "value": "sort-amount-desc" },
    { "value": "sort-numeric-asc" },
    { "value": "sort-numeric-desc" },
    { "value": "thumbs-up" },
    { "value": "thumbs-down" },
    { "value": "youtube-square" },
    { "value": "youtube" },
    { "value": "xing" },
    { "value": "xing-square" },
    { "value": "youtube-play" },
    { "value": "dropbox" },
    { "value": "stack-overflow" },
    { "value": "instagram" },
    { "value": "flickr" },
    { "value": "adn" },
    { "value": "bitbucket" },
    { "value": "bitbucket-square" },
    { "value": "tumblr" },
    { "value": "tumblr-square" },
    { "value": "long-arrow-down" },
    { "value": "long-arrow-up" },
    { "value": "long-arrow-left" },
    { "value": "long-arrow-right" },
    { "value": "apple" },
    { "value": "windows" },
    { "value": "android" },
    { "value": "linux" },
    { "value": "dribbble" },
    { "value": "skype" },
    { "value": "foursquare" },
    { "value": "trello" },
    { "value": "female" },
    { "value": "male" },
    { "value": "gittip" },
    { "value": "gratipay" },
    { "value": "sun-o" },
    { "value": "moon-o" },
    { "value": "archive" },
    { "value": "bug" },
    { "value": "vk" },
    { "value": "weibo" },
    { "value": "renren" },
    { "value": "pagelines" },
    { "value": "stack-exchange" },
    { "value": "arrow-circle-o-right" },
    { "value": "arrow-circle-o-left" },
    { "value": "toggle-left" },
    { "value": "caret-square-o-left" },
    { "value": "dot-circle-o" },
    { "value": "wheelchair" },
    { "value": "vimeo-square" },
    { "value": "turkish-lira" },
    { "value": "try" },
    { "value": "plus-square-o" },
    { "value": "space-shuttle" },
    { "value": "slack" },
    { "value": "envelope-square" },
    { "value": "wordpress" },
    { "value": "openid" },
    { "value": "institution" },
    { "value": "bank" },
    { "value": "university" },
    { "value": "mortar-board" },
    { "value": "graduation-cap" },
    { "value": "yahoo" },
    { "value": "google" },
    { "value": "reddit" },
    { "value": "reddit-square" },
    { "value": "stumbleupon-circle" },
    { "value": "stumbleupon" },
    { "value": "delicious" },
    { "value": "digg" },
    { "value": "pied-piper-pp" },
    { "value": "pied-piper-alt" },
    { "value": "drupal" },
    { "value": "joomla" },
    { "value": "language" },
    { "value": "fax" },
    { "value": "building" },
    { "value": "child" },
    { "value": "paw" },
    { "value": "spoon" },
    { "value": "cube" },
    { "value": "cubes" },
    { "value": "behance" },
    { "value": "behance-square" },
    { "value": "steam" },
    { "value": "steam-square" },
    { "value": "recycle" },
    { "value": "automobile" },
    { "value": "car" },
    { "value": "cab" },
    { "value": "taxi" },
    { "value": "tree" },
    { "value": "spotify" },
    { "value": "deviantart" },
    { "value": "soundcloud" },
    { "value": "database" },
    { "value": "file-pdf-o" },
    { "value": "file-word-o" },
    { "value": "file-excel-o" },
    { "value": "file-powerpoint-o" },
    { "value": "file-photo-o" },
    { "value": "file-picture-o" },
    { "value": "file-image-o" },
    { "value": "file-zip-o" },
    { "value": "file-archive-o" },
    { "value": "file-sound-o" },
    { "value": "file-audio-o" },
    { "value": "file-movie-o" },
    { "value": "file-video-o" },
    { "value": "file-code-o" },
    { "value": "vine" },
    { "value": "codepen" },
    { "value": "jsfiddle" },
    { "value": "life-bouy" },
    { "value": "life-buoy" },
    { "value": "life-saver" },
    { "value": "support" },
    { "value": "life-ring" },
    { "value": "circle-o-notch" },
    { "value": "ra" },
    { "value": "resistance" },
    { "value": "rebel" },
    { "value": "ge" },
    { "value": "empire" },
    { "value": "git-square" },
    { "value": "git" },
    { "value": "y-combinator-square" },
    { "value": "yc-square" },
    { "value": "hacker-news" },
    { "value": "tencent-weibo" },
    { "value": "qq" },
    { "value": "wechat" },
    { "value": "weixin" },
    { "value": "send" },
    { "value": "paper-plane" },
    { "value": "send-o" },
    { "value": "paper-plane-o" },
    { "value": "history" },
    { "value": "circle-thin" },
    { "value": "header" },
    { "value": "paragraph" },
    { "value": "sliders" },
    { "value": "share-alt" },
    { "value": "share-alt-square" },
    { "value": "bomb" },
    { "value": "soccer-ball-o" },
    { "value": "futbol-o" },
    { "value": "tty" },
    { "value": "binoculars" },
    { "value": "plug" },
    { "value": "slideshare" },
    { "value": "twitch" },
    { "value": "yelp" },
    { "value": "newspaper-o" },
    { "value": "wifi" },
    { "value": "calculator" },
    { "value": "paypal" },
    { "value": "google-wallet" },
    { "value": "cc-visa" },
    { "value": "cc-mastercard" },
    { "value": "cc-discover" },
    { "value": "cc-amex" },
    { "value": "cc-paypal" },
    { "value": "cc-stripe" },
    { "value": "bell-slash" },
    { "value": "bell-slash-o" },
    { "value": "trash" },
    { "value": "copyright" },
    { "value": "at" },
    { "value": "eyedropper" },
    { "value": "paint-brush" },
    { "value": "birthday-cake" },
    { "value": "area-chart" },
    { "value": "pie-chart" },
    { "value": "line-chart" },
    { "value": "lastfm" },
    { "value": "lastfm-square" },
    { "value": "toggle-off" },
    { "value": "toggle-on" },
    { "value": "bicycle" },
    { "value": "bus" },
    { "value": "ioxhost" },
    { "value": "angellist" },
    { "value": "cc" },
    { "value": "shekel" },
    { "value": "sheqel" },
    { "value": "ils" },
    { "value": "meanpath" },
    { "value": "buysellads" },
    { "value": "connectdevelop" },
    { "value": "dashcube" },
    { "value": "forumbee" },
    { "value": "leanpub" },
    { "value": "sellsy" },
    { "value": "shirtsinbulk" },
    { "value": "simplybuilt" },
    { "value": "skyatlas" },
    { "value": "cart-plus" },
    { "value": "cart-arrow-down" },
    { "value": "diamond" },
    { "value": "ship" },
    { "value": "user-secret" },
    { "value": "motorcycle" },
    { "value": "street-view" },
    { "value": "heartbeat" },
    { "value": "venus" },
    { "value": "mars" },
    { "value": "mercury" },
    { "value": "intersex" },
    { "value": "transgender" },
    { "value": "transgender-alt" },
    { "value": "venus-double" },
    { "value": "mars-double" },
    { "value": "venus-mars" },
    { "value": "mars-stroke" },
    { "value": "mars-stroke-v" },
    { "value": "mars-stroke-h" },
    { "value": "neuter" },
    { "value": "genderless" },
    { "value": "facebook-official" },
    { "value": "pinterest-p" },
    { "value": "whatsapp" },
    { "value": "server" },
    { "value": "user-plus" },
    { "value": "user-times" },
    { "value": "hotel" },
    { "value": "bed" },
    { "value": "viacoin" },
    { "value": "train" },
    { "value": "subway" },
    { "value": "medium" },
    { "value": "yc" },
    { "value": "y-combinator" },
    { "value": "optin-monster" },
    { "value": "opencart" },
    { "value": "expeditedssl" },
    { "value": "battery-4" },
    { "value": "battery" },
    { "value": "battery-full" },
    { "value": "battery-3" },
    { "value": "battery-three-quarters" },
    { "value": "battery-2" },
    { "value": "battery-half" },
    { "value": "battery-1" },
    { "value": "battery-quarter" },
    { "value": "battery-0" },
    { "value": "battery-empty" },
    { "value": "mouse-pointer" },
    { "value": "i-cursor" },
    { "value": "object-group" },
    { "value": "object-ungroup" },
    { "value": "sticky-note" },
    { "value": "sticky-note-o" },
    { "value": "cc-jcb" },
    { "value": "cc-diners-club" },
    { "value": "clone" },
    { "value": "balance-scale" },
    { "value": "hourglass-o" },
    { "value": "hourglass-1" },
    { "value": "hourglass-start" },
    { "value": "hourglass-2" },
    { "value": "hourglass-half" },
    { "value": "hourglass-3" },
    { "value": "hourglass-end" },
    { "value": "hourglass" },
    { "value": "hand-grab-o" },
    { "value": "hand-rock-o" },
    { "value": "hand-stop-o" },
    { "value": "hand-paper-o" },
    { "value": "hand-scissors-o" },
    { "value": "hand-lizard-o" },
    { "value": "hand-spock-o" },
    { "value": "hand-pointer-o" },
    { "value": "hand-peace-o" },
    { "value": "trademark" },
    { "value": "registered" },
    { "value": "creative-commons" },
    { "value": "gg" },
    { "value": "gg-circle" },
    { "value": "tripadvisor" },
    { "value": "odnoklassniki" },
    { "value": "odnoklassniki-square" },
    { "value": "get-pocket" },
    { "value": "wikipedia-w" },
    { "value": "safari" },
    { "value": "chrome" },
    { "value": "firefox" },
    { "value": "opera" },
    { "value": "internet-explorer" },
    { "value": "tv" },
    { "value": "television" },
    { "value": "contao" },
    { "value": "500px" },
    { "value": "amazon" },
    { "value": "calendar-plus-o" },
    { "value": "calendar-minus-o" },
    { "value": "calendar-times-o" },
    { "value": "calendar-check-o" },
    { "value": "industry" },
    { "value": "map-pin" },
    { "value": "map-signs" },
    { "value": "map-o" },
    { "value": "map" },
    { "value": "commenting" },
    { "value": "commenting-o" },
    { "value": "houzz" },
    { "value": "vimeo" },
    { "value": "black-tie" },
    { "value": "fonticons" },
    { "value": "reddit-alien" },
    { "value": "edge" },
    { "value": "credit-card-alt" },
    { "value": "codiepie" },
    { "value": "modx" },
    { "value": "fort-awesome" },
    { "value": "usb" },
    { "value": "product-hunt" },
    { "value": "mixcloud" },
    { "value": "scribd" },
    { "value": "pause-circle" },
    { "value": "pause-circle-o" },
    { "value": "stop-circle" },
    { "value": "stop-circle-o" },
    { "value": "shopping-bag" },
    { "value": "shopping-basket" },
    { "value": "hashtag" },
    { "value": "bluetooth" },
    { "value": "bluetooth-b" },
    { "value": "percent" },
    { "value": "gitlab" },
    { "value": "wpbeginner" },
    { "value": "wpforms" },
    { "value": "envira" },
    { "value": "universal-access" },
    { "value": "wheelchair-alt" },
    { "value": "question-circle-o" },
    { "value": "blind" },
    { "value": "audio-description" },
    { "value": "volume-control-phone" },
    { "value": "braille" },
    { "value": "assistive-listening-systems" },
    { "value": "asl-interpreting" },
    { "value": "american-sign-language-interpreting" },
    { "value": "deafness" },
    { "value": "hard-of-hearing" },
    { "value": "deaf" },
    { "value": "glide" },
    { "value": "glide-g" },
    { "value": "signing" },
    { "value": "sign-language" },
    { "value": "low-vision" },
    { "value": "viadeo" },
    { "value": "viadeo-square" },
    { "value": "snapchat" },
    { "value": "snapchat-ghost" },
    { "value": "snapchat-square" },
    { "value": "pied-piper" },
    { "value": "first-order" },
    { "value": "yoast" },
    { "value": "themeisle" },
    { "value": "google-plus-circle" },
    { "value": "google-plus-official" },
    { "value": "fa" },
    { "value": "font-awesome" },
    { "value": "handshake-o" },
    { "value": "envelope-open" },
    { "value": "envelope-open-o" },
    { "value": "linode" },
    { "value": "address-book" },
    { "value": "address-book-o" },
    { "value": "vcard" },
    { "value": "address-card" },
    { "value": "vcard-o" },
    { "value": "address-card-o" },
    { "value": "user-circle" },
    { "value": "user-circle-o" },
    { "value": "user-o" },
    { "value": "id-badge" },
    { "value": "drivers-license" },
    { "value": "id-card" },
    { "value": "drivers-license-o" },
    { "value": "id-card-o" },
    { "value": "quora" },
    { "value": "free-code-camp" },
    { "value": "telegram" },
    { "value": "thermometer-4" },
    { "value": "thermometer" },
    { "value": "thermometer-full" },
    { "value": "thermometer-3" },
    { "value": "thermometer-three-quarters" },
    { "value": "thermometer-2" },
    { "value": "thermometer-half" },
    { "value": "thermometer-1" },
    { "value": "thermometer-quarter" },
    { "value": "thermometer-0" },
    { "value": "thermometer-empty" },
    { "value": "shower" },
    { "value": "bathtub" },
    { "value": "s15" },
    { "value": "bath" },
    { "value": "podcast" },
    { "value": "window-maximize" },
    { "value": "window-minimize" },
    { "value": "window-restore" },
    { "value": "times-rectangle" },
    { "value": "window-close" },
    { "value": "times-rectangle-o" },
    { "value": "window-close-o" },
    { "value": "bandcamp" },
    { "value": "grav" },
    { "value": "etsy" },
    { "value": "imdb" },
    { "value": "ravelry" },
    { "value": "eercast" },
    { "value": "microchip" },
    { "value": "snowflake-o" },
    { "value": "superpowers" },
    { "value": "wpexplorer" },
    { "value": "meetup" }
]

export const iconListFontFinal = [
    { "value": '500px' },
    { "value": 'accessible-icon' },
    { "value": 'accusoft' },
    { "value": 'acquisitions-incorporated' },
    { "value": 'adn' },
    { "value": 'adversal' },
    { "value": 'affiliatetheme' },
    { "value": 'airbnb' },
    { "value": 'algolia' },
    { "value": 'alipay' },
    { "value": 'amazon' },
    { "value": 'amazon-pay' },
    { "value": 'amilia' },
    { "value": 'android' },
    { "value": 'angellist' },
    { "value": 'angrycreative' },
    { "value": 'angular' },
    { "value": 'app-store' },
    { "value": 'app-store-ios' },
    { "value": 'apper' },
    { "value": 'apple' },
    { "value": 'apple-pay' },
    { "value": 'artstation' },
    { "value": 'asymmetrik' },
    { "value": 'atlassian' },
    { "value": 'audible' },
    { "value": 'autoprefixer' },
    { "value": 'avianex' },
    { "value": 'aviato' },
    { "value": 'aws' },
    { "value": 'bandcamp' },
    { "value": 'battle-net' },
    { "value": 'behance' },
    { "value": 'behance-square' },
    { "value": 'bimobject' },
    { "value": 'bitbucket' },
    { "value": 'bitcoin' },
    { "value": 'bity' },
    { "value": 'black-tie' },
    { "value": 'blackberry' },
    { "value": 'blogger' },
    { "value": 'blogger-b' },
    { "value": 'bluetooth' },
    { "value": 'bluetooth-b' },
    { "value": 'bootstrap' },
    { "value": 'btc' },
    { "value": 'buffer' },
    { "value": 'buromobelexperte' },
    { "value": 'buy-n-large' },
    { "value": 'buysellads' },
    { "value": 'canadian-maple-leaf' },
    { "value": 'cc-amazon-pay' },
    { "value": 'cc-amex' },
    { "value": 'cc-apple-pay' },
    { "value": 'cc-diners-club' },
    { "value": 'cc-discover' },
    { "value": 'cc-jcb' },
    { "value": 'cc-mastercard' },
    { "value": 'cc-paypal' },
    { "value": 'cc-stripe' },
    { "value": 'cc-visa' },
    { "value": 'centercode' },
    { "value": 'centos' },
    { "value": 'chrome' },
    { "value": 'chromecast' },
    { "value": 'cloudflare' },
    { "value": 'cloudscale' },
    { "value": 'cloudsmith' },
    { "value": 'cloudversify' },
    { "value": 'codepen' },
    { "value": 'codiepie' },
    { "value": 'confluence' },
    { "value": 'connectdevelop' },
    { "value": 'contao' },
    { "value": 'cotton-bureau' },
    { "value": 'cpanel' },
    { "value": 'creative-commons' },
    { "value": 'creative-commons-by' },
    { "value": 'creative-commons-nc' },
    { "value": 'creative-commons-nc-eu' },
    { "value": 'creative-commons-nc-jp' },
    { "value": 'creative-commons-nd' },
    { "value": 'creative-commons-pd' },
    { "value": 'creative-commons-pd-alt' },
    { "value": 'creative-commons-remix' },
    { "value": 'creative-commons-sa' },
    { "value": 'creative-commons-sampling' },
    { "value": 'creative-commons-sampling-plus' },
    { "value": 'creative-commons-share' },
    { "value": 'creative-commons-zero' },
    { "value": 'critical-role' },
    { "value": 'css3' },
    { "value": 'css3-alt' },
    { "value": 'cuttlefish' },
    { "value": 'd-and-d' },
    { "value": 'd-and-d-beyond' },
    { "value": 'dailymotion' },
    { "value": 'dashcube' },
    { "value": 'deezer' },
    { "value": 'delicious' },
    { "value": 'deploydog' },
    { "value": 'deskpro' },
    { "value": 'dev' },
    { "value": 'deviantart' },
    { "value": 'dhl' },
    { "value": 'diaspora' },
    { "value": 'digg' },
    { "value": 'digital-ocean' },
    { "value": 'discord' },
    { "value": 'discourse' },
    { "value": 'dochub' },
    { "value": 'docker' },
    { "value": 'draft2digital' },
    { "value": 'dribbble' },
    { "value": 'dribbble-square' },
    { "value": 'dropbox' },
    { "value": 'drupal' },
    { "value": 'dyalog' },
    { "value": 'earlybirds' },
    { "value": 'ebay' },
    { "value": 'edge' },
    { "value": 'edge-legacy' },
    { "value": 'elementor' },
    { "value": 'ello' },
    { "value": 'ember' },
    { "value": 'empire' },
    { "value": 'envira' },
    { "value": 'erlang' },
    { "value": 'ethereum' },
    { "value": 'etsy' },
    { "value": 'evernote' },
    { "value": 'expeditedssl' },
    { "value": 'facebook' },
    { "value": 'facebook-f' },
    { "value": 'facebook-messenger' },
    { "value": 'facebook-square' },
    { "value": 'fantasy-flight-games' },
    { "value": 'fedex' },
    { "value": 'fedora' },
    { "value": 'figma' },
    { "value": 'firefox' },
    { "value": 'firefox-browser' },
    { "value": 'first-order' },
    { "value": 'first-order-alt' },
    { "value": 'firstdraft' },
    { "value": 'flickr' },
    { "value": 'flipboard' },
    { "value": 'fly' },
    { "value": 'font-awesome' },
    { "value": 'font-awesome-alt' },
    { "value": 'font-awesome-flag' },
    { "value": 'font-awesome-logo-full' },
    { "value": 'fonticons' },
    { "value": 'fonticons-fi' },
    { "value": 'fort-awesome' },
    { "value": 'fort-awesome-alt' },
    { "value": 'forumbee' },
    { "value": 'foursquare' },
    { "value": 'free-code-camp' },
    { "value": 'freebsd' },
    { "value": 'fulcrum' },
    { "value": 'galactic-republic' },
    { "value": 'galactic-senate' },
    { "value": 'get-pocket' },
    { "value": 'gg' },
    { "value": 'gg-circle' },
    { "value": 'git' },
    { "value": 'git-alt' },
    { "value": 'git-square' },
    { "value": 'github' },
    { "value": 'github-alt' },
    { "value": 'github-square' },
    { "value": 'gitkraken' },
    { "value": 'gitlab' },
    { "value": 'gitter' },
    { "value": 'glide' },
    { "value": 'glide-g' },
    { "value": 'gofore' },
    { "value": 'goodreads' },
    { "value": 'goodreads-g' },
    { "value": 'google' },
    { "value": 'google-drive' },
    { "value": 'google-pay' },
    { "value": 'google-play' },
    { "value": 'google-plus' },
    { "value": 'google-plus-g' },
    { "value": 'google-plus-square' },
    { "value": 'google-wallet' },
    { "value": 'gratipay' },
    { "value": 'grav' },
    { "value": 'gripfire' },
    { "value": 'grunt' },
    { "value": 'guilded' },
    { "value": 'gulp' },
    { "value": 'hacker-news' },
    { "value": 'hacker-news-square' },
    { "value": 'hackerrank' },
    { "value": 'hips' },
    { "value": 'hire-a-helper' },
    { "value": 'hive' },
    { "value": 'hooli' },
    { "value": 'hornbill' },
    { "value": 'hotjar' },
    { "value": 'houzz' },
    { "value": 'html5' },
    { "value": 'hubspot' },
    { "value": 'ideal' },
    { "value": 'imdb' },
    { "value": 'innosoft' },
    { "value": 'instagram' },
    { "value": 'instagram-square' },
    { "value": 'instalod' },
    { "value": 'intercom' },
    { "value": 'internet-explorer' },
    { "value": 'invision' },
    { "value": 'ioxhost' },
    { "value": 'itch-io' },
    { "value": 'itunes' },
    { "value": 'itunes-note' },
    { "value": 'java' },
    { "value": 'jedi-order' },
    { "value": 'jenkins' },
    { "value": 'jira' },
    { "value": 'joget' },
    { "value": 'joomla' },
    { "value": 'js' },
    { "value": 'js-square' },
    { "value": 'jsfiddle' },
    { "value": 'kaggle' },
    { "value": 'keybase' },
    { "value": 'keycdn' },
    { "value": 'kickstarter' },
    { "value": 'kickstarter-k' },
    { "value": 'korvue' },
    { "value": 'laravel' },
    { "value": 'lastfm' },
    { "value": 'lastfm-square' },
    { "value": 'leanpub' },
    { "value": 'less' },
    { "value": 'line' },
    { "value": 'linkedin' },
    { "value": 'linkedin-in' },
    { "value": 'linode' },
    { "value": 'linux' },
    { "value": 'lyft' },
    { "value": 'magento' },
    { "value": 'mailchimp' },
    { "value": 'mandalorian' },
    { "value": 'markdown' },
    { "value": 'mastodon' },
    { "value": 'maxcdn' },
    { "value": 'mdb' },
    { "value": 'medapps' },
    { "value": 'medium' },
    { "value": 'medium-m' },
    { "value": 'medrt' },
    { "value": 'meetup' },
    { "value": 'megaport' },
    { "value": 'mendeley' },
    { "value": 'microblog' },
    { "value": 'microsoft' },
    { "value": 'mix' },
    { "value": 'mixcloud' },
    { "value": 'mixer' },
    { "value": 'mizuni' },
    { "value": 'modx' },
    { "value": 'monero' },
    { "value": 'napster' },
    { "value": 'neos' },
    { "value": 'nimblr' },
    { "value": 'node' },
    { "value": 'node-js' },
    { "value": 'npm' },
    { "value": 'ns8' },
    { "value": 'nutritionix' },
    { "value": 'octopus-deploy' },
    { "value": 'odnoklassniki' },
    { "value": 'odnoklassniki-square' },
    { "value": 'old-republic' },
    { "value": 'opencart' },
    { "value": 'openid' },
    { "value": 'opera' },
    { "value": 'optin-monster' },
    { "value": 'orcid' },
    { "value": 'osi' },
    { "value": 'page4' },
    { "value": 'pagelines' },
    { "value": 'palfed' },
    { "value": 'patreon' },
    { "value": 'paypal' },
    { "value": 'penny-arcade' },
    { "value": 'perbyte' },
    { "value": 'periscope' },
    { "value": 'phabricator' },
    { "value": 'phoenix-framework' },
    { "value": 'phoenix-squadron' },
    { "value": 'php' },
    { "value": 'pied-piper' },
    { "value": 'pied-piper-alt' },
    { "value": 'pied-piper-hat' },
    { "value": 'pied-piper-pp' },
    { "value": 'pied-piper-square' },
    { "value": 'pinterest' },
    { "value": 'pinterest-p' },
    { "value": 'pinterest-square' },
    { "value": 'playstation' },
    { "value": 'product-hunt' },
    { "value": 'pushed' },
    { "value": 'python' },
    { "value": 'qq' },
    { "value": 'quinscape' },
    { "value": 'quora' },
    { "value": 'r-project' },
    { "value": 'raspberry-pi' },
    { "value": 'ravelry' },
    { "value": 'react' },
    { "value": 'reacteurope' },
    { "value": 'readme' },
    { "value": 'rebel' },
    { "value": 'red-river' },
    { "value": 'reddit' },
    { "value": 'reddit-alien' },
    { "value": 'reddit-square' },
    { "value": 'redhat' },
    { "value": 'renren' },
    { "value": 'replyd' },
    { "value": 'researchgate' },
    { "value": 'resolving' },
    { "value": 'rev' },
    { "value": 'rocketchat' },
    { "value": 'rockrms' },
    { "value": 'rust' },
    { "value": 'safari' },
    { "value": 'salesforce' },
    { "value": 'sass' },
    { "value": 'schlix' },
    { "value": 'scribd' },
    { "value": 'searchengin' },
    { "value": 'sellcast' },
    { "value": 'sellsy' },
    { "value": 'servicestack' },
    { "value": 'shirtsinbulk' },
    { "value": 'shopify' },
    { "value": 'shopware' },
    { "value": 'simplybuilt' },
    { "value": 'sistrix' },
    { "value": 'sith' },
    { "value": 'sketch' },
    { "value": 'skyatlas' },
    { "value": 'skype' },
    { "value": 'slack' },
    { "value": 'slack-hash' },
    { "value": 'slideshare' },
    { "value": 'snapchat' },
    { "value": 'snapchat-ghost' },
    { "value": 'snapchat-square' },
    { "value": 'soundcloud' },
    { "value": 'sourcetree' },
    { "value": 'speakap' },
    { "value": 'speaker-deck' },
    { "value": 'spotify' },
    { "value": 'squarespace' },
    { "value": 'stack-exchange' },
    { "value": 'stack-overflow' },
    { "value": 'stackpath' },
    { "value": 'staylinked' },
    { "value": 'steam' },
    { "value": 'steam-square' },
    { "value": 'steam-symbol' },
    { "value": 'sticker-mule' },
    { "value": 'strava' },
    { "value": 'stripe' },
    { "value": 'stripe-s' },
    { "value": 'studiovinari' },
    { "value": 'stumbleupon' },
    { "value": 'stumbleupon-circle' },
    { "value": 'superpowers' },
    { "value": 'supple' },
    { "value": 'suse' },
    { "value": 'swift' },
    { "value": 'symfony' },
    { "value": 'teamspeak' },
    { "value": 'telegram' },
    { "value": 'telegram-plane' },
    { "value": 'tencent-weibo' },
    { "value": 'the-red-yeti' },
    { "value": 'themeco' },
    { "value": 'themeisle' },
    { "value": 'think-peaks' },
    { "value": 'tiktok' },
    { "value": 'trade-federation' },
    { "value": 'trello' },
    { "value": 'tripadvisor' },
    { "value": 'tumblr' },
    { "value": 'tumblr-square' },
    { "value": 'twitch' },
    { "value": 'twitter' },
    { "value": 'twitter-square' },
    { "value": 'typo3' },
    { "value": 'uber' },
    { "value": 'ubuntu' },
    { "value": 'uikit' },
    { "value": 'umbraco' },
    { "value": 'uncharted' },
    { "value": 'uniregistry' },
    { "value": 'unity' },
    { "value": 'unsplash' },
    { "value": 'untappd' },
    { "value": 'ups' },
    { "value": 'usb' },
    { "value": 'usps' },
    { "value": 'ussunnah' },
    { "value": 'vaadin' },
    { "value": 'viacoin' },
    { "value": 'viadeo' },
    { "value": 'viadeo-square' },
    { "value": 'viber' },
    { "value": 'vimeo' },
    { "value": 'vimeo-square' },
    { "value": 'vimeo-v' },
    { "value": 'vine' },
    { "value": 'vk' },
    { "value": 'vnv' },
    { "value": 'vuejs' },
    { "value": 'watchman-monitoring' },
    { "value": 'waze' },
    { "value": 'weebly' },
    { "value": 'weibo' },
    { "value": 'weixin' },
    { "value": 'whatsapp' },
    { "value": 'whatsapp-square' },
    { "value": 'whmcs' },
    { "value": 'wikipedia-w' },
    { "value": 'windows' },
    { "value": 'wix' },
    { "value": 'wizards-of-the-coast' },
    { "value": 'wodu' },
    { "value": 'wolf-pack-battalion' },
    { "value": 'wordpress' },
    { "value": 'wordpress-simple' },
    { "value": 'wpbeginner' },
    { "value": 'wpexplorer' },
    { "value": 'wpforms' },
    { "value": 'wpressr' },
    { "value": 'xbox' },
    { "value": 'xing' },
    { "value": 'xing-square' },
    { "value": 'y-combinator' },
    { "value": 'yahoo' },
    { "value": 'yammer' },
    { "value": 'yandex' },
    { "value": 'yandex-international' },
    { "value": 'yarn' },
    { "value": 'yelp' },
    { "value": 'yoast' },
    { "value": 'youtube' },
    { "value": 'youtube-square' },
    { "value": 'zhihu' },
    { "value": 'abacus' },
    { "value": 'acorn' },
    { "value": 'ad' },
    { "value": 'address-book' },
    { "value": 'address-card' },
    { "value": 'adjust' },
    { "value": 'air-conditioner' },
    { "value": 'air-freshener' },
    { "value": 'alarm-clock' },
    { "value": 'alarm-exclamation' },
    { "value": 'alarm-plus' },
    { "value": 'alarm-snooze' },
    { "value": 'album' },
    { "value": 'album-collection' },
    { "value": 'alicorn' },
    { "value": 'alien' },
    { "value": 'alien-monster' },
    { "value": 'align-center' },
    { "value": 'align-justify' },
    { "value": 'align-left' },
    { "value": 'align-right' },
    { "value": 'align-slash' },
    { "value": 'allergies' },
    { "value": 'ambulance' },
    { "value": 'american-sign-language-interpreting' },
    { "value": 'amp-guitar' },
    { "value": 'analytics' },
    { "value": 'anchor' },
    { "value": 'angel' },
    { "value": 'angle-double-down' },
    { "value": 'angle-double-left' },
    { "value": 'angle-double-right' },
    { "value": 'angle-double-up' },
    { "value": 'angle-down' },
    { "value": 'angle-left' },
    { "value": 'angle-right' },
    { "value": 'angle-up' },
    { "value": 'angry' },
    { "value": 'ankh' },
    { "value": 'apple-alt' },
    { "value": 'apple-crate' },
    { "value": 'archive' },
    { "value": 'archway' },
    { "value": 'arrow-alt-circle-down' },
    { "value": 'arrow-alt-circle-left' },
    { "value": 'arrow-alt-circle-right' },
    { "value": 'arrow-alt-circle-up' },
    { "value": 'arrow-alt-down' },
    { "value": 'arrow-alt-from-bottom' },
    { "value": 'arrow-alt-from-left' },
    { "value": 'arrow-alt-from-right' },
    { "value": 'arrow-alt-from-top' },
    { "value": 'arrow-alt-left' },
    { "value": 'arrow-alt-right' },
    { "value": 'arrow-alt-square-down' },
    { "value": 'arrow-alt-square-left' },
    { "value": 'arrow-alt-square-right' },
    { "value": 'arrow-alt-square-up' },
    { "value": 'arrow-alt-to-bottom' },
    { "value": 'arrow-alt-to-left' },
    { "value": 'arrow-alt-to-right' },
    { "value": 'arrow-alt-to-top' },
    { "value": 'arrow-alt-up' },
    { "value": 'arrow-circle-down' },
    { "value": 'arrow-circle-left' },
    { "value": 'arrow-circle-right' },
    { "value": 'arrow-circle-up' },
    { "value": 'arrow-down' },
    { "value": 'arrow-from-bottom' },
    { "value": 'arrow-from-left' },
    { "value": 'arrow-from-right' },
    { "value": 'arrow-from-top' },
    { "value": 'arrow-left' },
    { "value": 'arrow-right' },
    { "value": 'arrow-square-down' },
    { "value": 'arrow-square-left' },
    { "value": 'arrow-square-right' },
    { "value": 'arrow-square-up' },
    { "value": 'arrow-to-bottom' },
    { "value": 'arrow-to-left' },
    { "value": 'arrow-to-right' },
    { "value": 'arrow-to-top' },
    { "value": 'arrow-up' },
    { "value": 'arrows' },
    { "value": 'arrows-alt' },
    { "value": 'arrows-alt-h' },
    { "value": 'arrows-alt-v' },
    { "value": 'arrows-h' },
    { "value": 'arrows-v' },
    { "value": 'assistive-listening-systems' },
    { "value": 'asterisk' },
    { "value": 'at' },
    { "value": 'atlas' },
    { "value": 'atom' },
    { "value": 'atom-alt' },
    { "value": 'audio-description' },
    { "value": 'award' },
    { "value": 'axe' },
    { "value": 'axe-battle' },
    { "value": 'baby' },
    { "value": 'baby-carriage' },
    { "value": 'backpack' },
    { "value": 'backspace' },
    { "value": 'backward' },
    { "value": 'bacon' },
    { "value": 'bacteria' },
    { "value": 'bacterium' },
    { "value": 'badge' },
    { "value": 'badge-check' },
    { "value": 'badge-dollar' },
    { "value": 'badge-percent' },
    { "value": 'badge-sheriff' },
    { "value": 'badger-honey' },
    { "value": 'bags-shopping' },
    { "value": 'bahai' },
    { "value": 'balance-scale' },
    { "value": 'balance-scale-left' },
    { "value": 'balance-scale-right' },
    { "value": 'ball-pile' },
    { "value": 'ballot' },
    { "value": 'ballot-check' },
    { "value": 'ban' },
    { "value": 'band-aid' },
    { "value": 'banjo' },
    { "value": 'barcode' },
    { "value": 'barcode-alt' },
    { "value": 'barcode-read' },
    { "value": 'barcode-scan' },
    { "value": 'bars' },
    { "value": 'baseball' },
    { "value": 'baseball-ball' },
    { "value": 'basketball-ball' },
    { "value": 'basketball-hoop' },
    { "value": 'bat' },
    { "value": 'bath' },
    { "value": 'battery-bolt' },
    { "value": 'battery-empty' },
    { "value": 'battery-full' },
    { "value": 'battery-half' },
    { "value": 'battery-quarter' },
    { "value": 'battery-slash' },
    { "value": 'battery-three-quarters' },
    { "value": 'bed' },
    { "value": 'bed-alt' },
    { "value": 'bed-bunk' },
    { "value": 'bed-empty' },
    { "value": 'beer' },
    { "value": 'bell' },
    { "value": 'bell-exclamation' },
    { "value": 'bell-on' },
    { "value": 'bell-plus' },
    { "value": 'bell-school' },
    { "value": 'bell-school-slash' },
    { "value": 'bell-slash' },
    { "value": 'bells' },
    { "value": 'betamax' },
    { "value": 'bezier-curve' },
    { "value": 'bible' },
    { "value": 'bicycle' },
    { "value": 'biking' },
    { "value": 'biking-mountain' },
    { "value": 'binoculars' },
    { "value": 'biohazard' },
    { "value": 'birthday-cake' },
    { "value": 'blanket' },
    { "value": 'blender' },
    { "value": 'blender-phone' },
    { "value": 'blind' },
    { "value": 'blinds' },
    { "value": 'blinds-open' },
    { "value": 'blinds-raised' },
    { "value": 'blog' },
    { "value": 'bold' },
    { "value": 'bolt' },
    { "value": 'bomb' },
    { "value": 'bone' },
    { "value": 'bone-break' },
    { "value": 'bong' },
    { "value": 'book' },
    { "value": 'book-alt' },
    { "value": 'book-dead' },
    { "value": 'book-heart' },
    { "value": 'book-medical' },
    { "value": 'book-open' },
    { "value": 'book-reader' },
    { "value": 'book-spells' },
    { "value": 'book-user' },
    { "value": 'bookmark' },
    { "value": 'books' },
    { "value": 'books-medical' },
    { "value": 'boombox' },
    { "value": 'boot' },
    { "value": 'booth-curtain' },
    { "value": 'border-all' },
    { "value": 'border-bottom' },
    { "value": 'border-center-h' },
    { "value": 'border-center-v' },
    { "value": 'border-inner' },
    { "value": 'border-left' },
    { "value": 'border-none' },
    { "value": 'border-outer' },
    { "value": 'border-right' },
    { "value": 'border-style' },
    { "value": 'border-style-alt' },
    { "value": 'border-top' },
    { "value": 'bow-arrow' },
    { "value": 'bowling-ball' },
    { "value": 'bowling-pins' },
    { "value": 'box' },
    { "value": 'box-alt' },
    { "value": 'box-ballot' },
    { "value": 'box-check' },
    { "value": 'box-fragile' },
    { "value": 'box-full' },
    { "value": 'box-heart' },
    { "value": 'box-open' },
    { "value": 'box-tissue' },
    { "value": 'box-up' },
    { "value": 'box-usd' },
    { "value": 'boxes' },
    { "value": 'boxes-alt' },
    { "value": 'boxing-glove' },
    { "value": 'brackets' },
    { "value": 'brackets-curly' },
    { "value": 'braille' },
    { "value": 'brain' },
    { "value": 'bread-loaf' },
    { "value": 'bread-slice' },
    { "value": 'briefcase' },
    { "value": 'briefcase-medical' },
    { "value": 'bring-forward' },
    { "value": 'bring-front' },
    { "value": 'broadcast-tower' },
    { "value": 'broom' },
    { "value": 'browser' },
    { "value": 'brush' },
    { "value": 'bug' },
    { "value": 'building' },
    { "value": 'bullhorn' },
    { "value": 'bullseye' },
    { "value": 'bullseye-arrow' },
    { "value": 'bullseye-pointer' },
    { "value": 'burger-soda' },
    { "value": 'burn' },
    { "value": 'burrito' },
    { "value": 'bus' },
    { "value": 'bus-alt' },
    { "value": 'bus-school' },
    { "value": 'business-time' },
    { "value": 'cabinet-filing' },
    { "value": 'cactus' },
    { "value": 'calculator' },
    { "value": 'calculator-alt' },
    { "value": 'calendar' },
    { "value": 'calendar-alt' },
    { "value": 'calendar-check' },
    { "value": 'calendar-day' },
    { "value": 'calendar-edit' },
    { "value": 'calendar-exclamation' },
    { "value": 'calendar-minus' },
    { "value": 'calendar-plus' },
    { "value": 'calendar-star' },
    { "value": 'calendar-times' },
    { "value": 'calendar-week' },
    { "value": 'camcorder' },
    { "value": 'camera' },
    { "value": 'camera-alt' },
    { "value": 'camera-home' },
    { "value": 'camera-movie' },
    { "value": 'camera-polaroid' },
    { "value": 'camera-retro' },
    { "value": 'campfire' },
    { "value": 'campground' },
    { "value": 'candle-holder' },
    { "value": 'candy-cane' },
    { "value": 'candy-corn' },
    { "value": 'cannabis' },
    { "value": 'capsules' },
    { "value": 'car' },
    { "value": 'car-alt' },
    { "value": 'car-battery' },
    { "value": 'car-building' },
    { "value": 'car-bump' },
    { "value": 'car-bus' },
    { "value": 'car-crash' },
    { "value": 'car-garage' },
    { "value": 'car-mechanic' },
    { "value": 'car-side' },
    { "value": 'car-tilt' },
    { "value": 'car-wash' },
    { "value": 'caravan' },
    { "value": 'caravan-alt' },
    { "value": 'caret-circle-down' },
    { "value": 'caret-circle-left' },
    { "value": 'caret-circle-right' },
    { "value": 'caret-circle-up' },
    { "value": 'caret-down' },
    { "value": 'caret-left' },
    { "value": 'caret-right' },
    { "value": 'caret-square-down' },
    { "value": 'caret-square-left' },
    { "value": 'caret-square-right' },
    { "value": 'caret-square-up' },
    { "value": 'caret-up' },
    { "value": 'carrot' },
    { "value": 'cars' },
    { "value": 'cart-arrow-down' },
    { "value": 'cart-plus' },
    { "value": 'cash-register' },
    { "value": 'cassette-tape' },
    { "value": 'cat' },
    { "value": 'cat-space' },
    { "value": 'cauldron' },
    { "value": 'cctv' },
    { "value": 'certificate' },
    { "value": 'chair' },
    { "value": 'chair-office' },
    { "value": 'chalkboard' },
    { "value": 'chalkboard-teacher' },
    { "value": 'charging-station' },
    { "value": 'chart-area' },
    { "value": 'chart-bar' },
    { "value": 'chart-line' },
    { "value": 'chart-line-down' },
    { "value": 'chart-network' },
    { "value": 'chart-pie' },
    { "value": 'chart-pie-alt' },
    { "value": 'chart-scatter' },
    { "value": 'check' },
    { "value": 'check-circle' },
    { "value": 'check-double' },
    { "value": 'check-square' },
    { "value": 'cheese' },
    { "value": 'cheese-swiss' },
    { "value": 'cheeseburger' },
    { "value": 'chess' },
    { "value": 'chess-bishop' },
    { "value": 'chess-bishop-alt' },
    { "value": 'chess-board' },
    { "value": 'chess-clock' },
    { "value": 'chess-clock-alt' },
    { "value": 'chess-king' },
    { "value": 'chess-king-alt' },
    { "value": 'chess-knight' },
    { "value": 'chess-knight-alt' },
    { "value": 'chess-pawn' },
    { "value": 'chess-pawn-alt' },
    { "value": 'chess-queen' },
    { "value": 'chess-queen-alt' },
    { "value": 'chess-rook' },
    { "value": 'chess-rook-alt' },
    { "value": 'chevron-circle-down' },
    { "value": 'chevron-circle-left' },
    { "value": 'chevron-circle-right' },
    { "value": 'chevron-circle-up' },
    { "value": 'chevron-double-down' },
    { "value": 'chevron-double-left' },
    { "value": 'chevron-double-right' },
    { "value": 'chevron-double-up' },
    { "value": 'chevron-down' },
    { "value": 'chevron-left' },
    { "value": 'chevron-right' },
    { "value": 'chevron-square-down' },
    { "value": 'chevron-square-left' },
    { "value": 'chevron-square-right' },
    { "value": 'chevron-square-up' },
    { "value": 'chevron-up' },
    { "value": 'child' },
    { "value": 'chimney' },
    { "value": 'church' },
    { "value": 'circle' },
    { "value": 'circle-notch' },
    { "value": 'city' },
    { "value": 'clarinet' },
    { "value": 'claw-marks' },
    { "value": 'clinic-medical' },
    { "value": 'clipboard' },
    { "value": 'clipboard-check' },
    { "value": 'clipboard-list' },
    { "value": 'clipboard-list-check' },
    { "value": 'clipboard-prescription' },
    { "value": 'clipboard-user' },
    { "value": 'clock' },
    { "value": 'clone' },
    { "value": 'closed-captioning' },
    { "value": 'cloud' },
    { "value": 'cloud-download' },
    { "value": 'cloud-download-alt' },
    { "value": 'cloud-drizzle' },
    { "value": 'cloud-hail' },
    { "value": 'cloud-hail-mixed' },
    { "value": 'cloud-meatball' },
    { "value": 'cloud-moon' },
    { "value": 'cloud-moon-rain' },
    { "value": 'cloud-music' },
    { "value": 'cloud-rain' },
    { "value": 'cloud-rainbow' },
    { "value": 'cloud-showers' },
    { "value": 'cloud-showers-heavy' },
    { "value": 'cloud-sleet' },
    { "value": 'cloud-snow' },
    { "value": 'cloud-sun' },
    { "value": 'cloud-sun-rain' },
    { "value": 'cloud-upload' },
    { "value": 'cloud-upload-alt' },
    { "value": 'clouds' },
    { "value": 'clouds-moon' },
    { "value": 'clouds-sun' },
    { "value": 'club' },
    { "value": 'cocktail' },
    { "value": 'code' },
    { "value": 'code-branch' },
    { "value": 'code-commit' },
    { "value": 'code-merge' },
    { "value": 'coffee' },
    { "value": 'coffee-pot' },
    { "value": 'coffee-togo' },
    { "value": 'coffin' },
    { "value": 'coffin-cross' },
    { "value": 'cog' },
    { "value": 'cogs' },
    { "value": 'coin' },
    { "value": 'coins' },
    { "value": 'columns' },
    { "value": 'comet' },
    { "value": 'comment' },
    { "value": 'comment-alt' },
    { "value": 'comment-alt-check' },
    { "value": 'comment-alt-dollar' },
    { "value": 'comment-alt-dots' },
    { "value": 'comment-alt-edit' },
    { "value": 'comment-alt-exclamation' },
    { "value": 'comment-alt-lines' },
    { "value": 'comment-alt-medical' },
    { "value": 'comment-alt-minus' },
    { "value": 'comment-alt-music' },
    { "value": 'comment-alt-plus' },
    { "value": 'comment-alt-slash' },
    { "value": 'comment-alt-smile' },
    { "value": 'comment-alt-times' },
    { "value": 'comment-check' },
    { "value": 'comment-dollar' },
    { "value": 'comment-dots' },
    { "value": 'comment-edit' },
    { "value": 'comment-exclamation' },
    { "value": 'comment-lines' },
    { "value": 'comment-medical' },
    { "value": 'comment-minus' },
    { "value": 'comment-music' },
    { "value": 'comment-plus' },
    { "value": 'comment-slash' },
    { "value": 'comment-smile' },
    { "value": 'comment-times' },
    { "value": 'comments' },
    { "value": 'comments-alt' },
    { "value": 'comments-alt-dollar' },
    { "value": 'comments-dollar' },
    { "value": 'compact-disc' },
    { "value": 'compass' },
    { "value": 'compass-slash' },
    { "value": 'compress' },
    { "value": 'compress-alt' },
    { "value": 'compress-arrows-alt' },
    { "value": 'compress-wide' },
    { "value": 'computer-classic' },
    { "value": 'computer-speaker' },
    { "value": 'concierge-bell' },
    { "value": 'construction' },
    { "value": 'container-storage' },
    { "value": 'conveyor-belt' },
    { "value": 'conveyor-belt-alt' },
    { "value": 'cookie' },
    { "value": 'cookie-bite' },
    { "value": 'copy' },
    { "value": 'copyright' },
    { "value": 'corn' },
    { "value": 'couch' },
    { "value": 'cow' },
    { "value": 'cowbell' },
    { "value": 'cowbell-more' },
    { "value": 'credit-card' },
    { "value": 'credit-card-blank' },
    { "value": 'credit-card-front' },
    { "value": 'cricket' },
    { "value": 'croissant' },
    { "value": 'crop' },
    { "value": 'crop-alt' },
    { "value": 'cross' },
    { "value": 'crosshairs' },
    { "value": 'crow' },
    { "value": 'crown' },
    { "value": 'crutch' },
    { "value": 'crutches' },
    { "value": 'cube' },
    { "value": 'cubes' },
    { "value": 'curling' },
    { "value": 'cut' },
    { "value": 'dagger' },
    { "value": 'database' },
    { "value": 'deaf' },
    { "value": 'debug' },
    { "value": 'deer' },
    { "value": 'deer-rudolph' },
    { "value": 'democrat' },
    { "value": 'desktop' },
    { "value": 'desktop-alt' },
    { "value": 'dewpoint' },
    { "value": 'dharmachakra' },
    { "value": 'diagnoses' },
    { "value": 'diamond' },
    { "value": 'dice' },
    { "value": 'dice-d10' },
    { "value": 'dice-d12' },
    { "value": 'dice-d20' },
    { "value": 'dice-d4' },
    { "value": 'dice-d6' },
    { "value": 'dice-d8' },
    { "value": 'dice-five' },
    { "value": 'dice-four' },
    { "value": 'dice-one' },
    { "value": 'dice-six' },
    { "value": 'dice-three' },
    { "value": 'dice-two' },
    { "value": 'digging' },
    { "value": 'digital-tachograph' },
    { "value": 'diploma' },
    { "value": 'directions' },
    { "value": 'disc-drive' },
    { "value": 'disease' },
    { "value": 'divide' },
    { "value": 'dizzy' },
    { "value": 'dna' },
    { "value": 'do-not-enter' },
    { "value": 'dog' },
    { "value": 'dog-leashed' },
    { "value": 'dollar-sign' },
    { "value": 'dolly' },
    { "value": 'dolly-empty' },
    { "value": 'dolly-flatbed' },
    { "value": 'dolly-flatbed-alt' },
    { "value": 'dolly-flatbed-empty' },
    { "value": 'donate' },
    { "value": 'door-closed' },
    { "value": 'door-open' },
    { "value": 'dot-circle' },
    { "value": 'dove' },
    { "value": 'download' },
    { "value": 'drafting-compass' },
    { "value": 'dragon' },
    { "value": 'draw-circle' },
    { "value": 'draw-polygon' },
    { "value": 'draw-square' },
    { "value": 'dreidel' },
    { "value": 'drone' },
    { "value": 'drone-alt' },
    { "value": 'drum' },
    { "value": 'drum-steelpan' },
    { "value": 'drumstick' },
    { "value": 'drumstick-bite' },
    { "value": 'dryer' },
    { "value": 'dryer-alt' },
    { "value": 'duck' },
    { "value": 'dumbbell' },
    { "value": 'dumpster' },
    { "value": 'dumpster-fire' },
    { "value": 'dungeon' },
    { "value": 'ear' },
    { "value": 'ear-muffs' },
    { "value": 'eclipse' },
    { "value": 'eclipse-alt' },
    { "value": 'edit' },
    { "value": 'egg' },
    { "value": 'egg-fried' },
    { "value": 'eject' },
    { "value": 'elephant' },
    { "value": 'ellipsis-h' },
    { "value": 'ellipsis-h-alt' },
    { "value": 'ellipsis-v' },
    { "value": 'ellipsis-v-alt' },
    { "value": 'empty-set' },
    { "value": 'engine-warning' },
    { "value": 'envelope' },
    { "value": 'envelope-open' },
    { "value": 'envelope-open-dollar' },
    { "value": 'envelope-open-text' },
    { "value": 'envelope-square' },
    { "value": 'equals' },
    { "value": 'eraser' },
    { "value": 'ethernet' },
    { "value": 'euro-sign' },
    { "value": 'exchange' },
    { "value": 'exchange-alt' },
    { "value": 'exclamation' },
    { "value": 'exclamation-circle' },
    { "value": 'exclamation-square' },
    { "value": 'exclamation-triangle' },
    { "value": 'expand' },
    { "value": 'expand-alt' },
    { "value": 'expand-arrows' },
    { "value": 'expand-arrows-alt' },
    { "value": 'expand-wide' },
    { "value": 'external-link' },
    { "value": 'external-link-alt' },
    { "value": 'external-link-square' },
    { "value": 'external-link-square-alt' },
    { "value": 'eye' },
    { "value": 'eye-dropper' },
    { "value": 'eye-evil' },
    { "value": 'eye-slash' },
    { "value": 'fan' },
    { "value": 'fan-table' },
    { "value": 'farm' },
    { "value": 'fast-backward' },
    { "value": 'fast-forward' },
    { "value": 'faucet' },
    { "value": 'faucet-drip' },
    { "value": 'fax' },
    { "value": 'feather' },
    { "value": 'feather-alt' },
    { "value": 'female' },
    { "value": 'field-hockey' },
    { "value": 'fighter-jet' },
    { "value": 'file' },
    { "value": 'file-alt' },
    { "value": 'file-archive' },
    { "value": 'file-audio' },
    { "value": 'file-certificate' },
    { "value": 'file-chart-line' },
    { "value": 'file-chart-pie' },
    { "value": 'file-check' },
    { "value": 'file-code' },
    { "value": 'file-contract' },
    { "value": 'file-csv' },
    { "value": 'file-download' },
    { "value": 'file-edit' },
    { "value": 'file-excel' },
    { "value": 'file-exclamation' },
    { "value": 'file-export' },
    { "value": 'file-image' },
    { "value": 'file-import' },
    { "value": 'file-invoice' },
    { "value": 'file-invoice-dollar' },
    { "value": 'file-medical' },
    { "value": 'file-medical-alt' },
    { "value": 'file-minus' },
    { "value": 'file-music' },
    { "value": 'file-pdf' },
    { "value": 'file-plus' },
    { "value": 'file-powerpoint' },
    { "value": 'file-prescription' },
    { "value": 'file-search' },
    { "value": 'file-signature' },
    { "value": 'file-spreadsheet' },
    { "value": 'file-times' },
    { "value": 'file-upload' },
    { "value": 'file-user' },
    { "value": 'file-video' },
    { "value": 'file-word' },
    { "value": 'files-medical' },
    { "value": 'fill' },
    { "value": 'fill-drip' },
    { "value": 'film' },
    { "value": 'film-alt' },
    { "value": 'film-canister' },
    { "value": 'filter' },
    { "value": 'fingerprint' },
    { "value": 'fire' },
    { "value": 'fire-alt' },
    { "value": 'fire-extinguisher' },
    { "value": 'fire-smoke' },
    { "value": 'fireplace' },
    { "value": 'first-aid' },
    { "value": 'fish' },
    { "value": 'fish-cooked' },
    { "value": 'fist-raised' },
    { "value": 'flag' },
    { "value": 'flag-alt' },
    { "value": 'flag-checkered' },
    { "value": 'flag-usa' },
    { "value": 'flame' },
    { "value": 'flashlight' },
    { "value": 'flask' },
    { "value": 'flask-poison' },
    { "value": 'flask-potion' },
    { "value": 'flower' },
    { "value": 'flower-daffodil' },
    { "value": 'flower-tulip' },
    { "value": 'flushed' },
    { "value": 'flute' },
    { "value": 'flux-capacitor' },
    { "value": 'fog' },
    { "value": 'folder' },
    { "value": 'folder-download' },
    { "value": 'folder-minus' },
    { "value": 'folder-open' },
    { "value": 'folder-plus' },
    { "value": 'folder-times' },
    { "value": 'folder-tree' },
    { "value": 'folder-upload' },
    { "value": 'folders' },
    { "value": 'font' },
    { "value": 'font-awesome-logo-full' },
    { "value": 'font-case' },
    { "value": 'football-ball' },
    { "value": 'football-helmet' },
    { "value": 'forklift' },
    { "value": 'forward' },
    { "value": 'fragile' },
    { "value": 'french-fries' },
    { "value": 'frog' },
    { "value": 'frosty-head' },
    { "value": 'frown' },
    { "value": 'frown-open' },
    { "value": 'function' },
    { "value": 'funnel-dollar' },
    { "value": 'futbol' },
    { "value": 'galaxy' },
    { "value": 'game-board' },
    { "value": 'game-board-alt' },
    { "value": 'game-console-handheld' },
    { "value": 'gamepad' },
    { "value": 'gamepad-alt' },
    { "value": 'garage' },
    { "value": 'garage-car' },
    { "value": 'garage-open' },
    { "value": 'gas-pump' },
    { "value": 'gas-pump-slash' },
    { "value": 'gavel' },
    { "value": 'gem' },
    { "value": 'genderless' },
    { "value": 'ghost' },
    { "value": 'gift' },
    { "value": 'gift-card' },
    { "value": 'gifts' },
    { "value": 'gingerbread-man' },
    { "value": 'glass' },
    { "value": 'glass-champagne' },
    { "value": 'glass-cheers' },
    { "value": 'glass-citrus' },
    { "value": 'glass-martini' },
    { "value": 'glass-martini-alt' },
    { "value": 'glass-whiskey' },
    { "value": 'glass-whiskey-rocks' },
    { "value": 'glasses' },
    { "value": 'glasses-alt' },
    { "value": 'globe' },
    { "value": 'globe-africa' },
    { "value": 'globe-americas' },
    { "value": 'globe-asia' },
    { "value": 'globe-europe' },
    { "value": 'globe-snow' },
    { "value": 'globe-stand' },
    { "value": 'golf-ball' },
    { "value": 'golf-club' },
    { "value": 'gopuram' },
    { "value": 'graduation-cap' },
    { "value": 'gramophone' },
    { "value": 'greater-than' },
    { "value": 'greater-than-equal' },
    { "value": 'grimace' },
    { "value": 'grin' },
    { "value": 'grin-alt' },
    { "value": 'grin-beam' },
    { "value": 'grin-beam-sweat' },
    { "value": 'grin-hearts' },
    { "value": 'grin-squint' },
    { "value": 'grin-squint-tears' },
    { "value": 'grin-stars' },
    { "value": 'grin-tears' },
    { "value": 'grin-tongue' },
    { "value": 'grin-tongue-squint' },
    { "value": 'grin-tongue-wink' },
    { "value": 'grin-wink' },
    { "value": 'grip-horizontal' },
    { "value": 'grip-lines' },
    { "value": 'grip-lines-vertical' },
    { "value": 'grip-vertical' },
    { "value": 'guitar' },
    { "value": 'guitar-electric' },
    { "value": 'guitars' },
    { "value": 'h-square' },
    { "value": 'h1' },
    { "value": 'h2' },
    { "value": 'h3' },
    { "value": 'h4' },
    { "value": 'hamburger' },
    { "value": 'hammer' },
    { "value": 'hammer-war' },
    { "value": 'hamsa' },
    { "value": 'hand-heart' },
    { "value": 'hand-holding' },
    { "value": 'hand-holding-box' },
    { "value": 'hand-holding-heart' },
    { "value": 'hand-holding-magic' },
    { "value": 'hand-holding-medical' },
    { "value": 'hand-holding-seedling' },
    { "value": 'hand-holding-usd' },
    { "value": 'hand-holding-water' },
    { "value": 'hand-lizard' },
    { "value": 'hand-middle-finger' },
    { "value": 'hand-paper' },
    { "value": 'hand-peace' },
    { "value": 'hand-point-down' },
    { "value": 'hand-point-left' },
    { "value": 'hand-point-right' },
    { "value": 'hand-point-up' },
    { "value": 'hand-pointer' },
    { "value": 'hand-receiving' },
    { "value": 'hand-rock' },
    { "value": 'hand-scissors' },
    { "value": 'hand-sparkles' },
    { "value": 'hand-spock' },
    { "value": 'hands' },
    { "value": 'hands-heart' },
    { "value": 'hands-helping' },
    { "value": 'hands-usd' },
    { "value": 'hands-wash' },
    { "value": 'handshake' },
    { "value": 'handshake-alt' },
    { "value": 'handshake-alt-slash' },
    { "value": 'handshake-slash' },
    { "value": 'hanukiah' },
    { "value": 'hard-hat' },
    { "value": 'hashtag' },
    { "value": 'hat-chef' },
    { "value": 'hat-cowboy' },
    { "value": 'hat-cowboy-side' },
    { "value": 'hat-santa' },
    { "value": 'hat-winter' },
    { "value": 'hat-witch' },
    { "value": 'hat-wizard' },
    { "value": 'hdd' },
    { "value": 'head-side' },
    { "value": 'head-side-brain' },
    { "value": 'head-side-cough' },
    { "value": 'head-side-cough-slash' },
    { "value": 'head-side-headphones' },
    { "value": 'head-side-mask' },
    { "value": 'head-side-medical' },
    { "value": 'head-side-virus' },
    { "value": 'head-vr' },
    { "value": 'heading' },
    { "value": 'headphones' },
    { "value": 'headphones-alt' },
    { "value": 'headset' },
    { "value": 'heart' },
    { "value": 'heart-broken' },
    { "value": 'heart-circle' },
    { "value": 'heart-rate' },
    { "value": 'heart-square' },
    { "value": 'heartbeat' },
    { "value": 'heat' },
    { "value": 'helicopter' },
    { "value": 'helmet-battle' },
    { "value": 'hexagon' },
    { "value": 'highlighter' },
    { "value": 'hiking' },
    { "value": 'hippo' },
    { "value": 'history' },
    { "value": 'hockey-mask' },
    { "value": 'hockey-puck' },
    { "value": 'hockey-sticks' },
    { "value": 'holly-berry' },
    { "value": 'home' },
    { "value": 'home-alt' },
    { "value": 'home-heart' },
    { "value": 'home-lg' },
    { "value": 'home-lg-alt' },
    { "value": 'hood-cloak' },
    { "value": 'horizontal-rule' },
    { "value": 'horse' },
    { "value": 'horse-head' },
    { "value": 'horse-saddle' },
    { "value": 'hospital' },
    { "value": 'hospital-alt' },
    { "value": 'hospital-symbol' },
    { "value": 'hospital-user' },
    { "value": 'hospitals' },
    { "value": 'hot-tub' },
    { "value": 'hotdog' },
    { "value": 'hotel' },
    { "value": 'hourglass' },
    { "value": 'hourglass-end' },
    { "value": 'hourglass-half' },
    { "value": 'hourglass-start' },
    { "value": 'house' },
    { "value": 'house-damage' },
    { "value": 'house-day' },
    { "value": 'house-flood' },
    { "value": 'house-leave' },
    { "value": 'house-night' },
    { "value": 'house-return' },
    { "value": 'house-signal' },
    { "value": 'house-user' },
    { "value": 'hryvnia' },
    { "value": 'humidity' },
    { "value": 'hurricane' },
    { "value": 'i-cursor' },
    { "value": 'ice-cream' },
    { "value": 'ice-skate' },
    { "value": 'icicles' },
    { "value": 'icons' },
    { "value": 'icons-alt' },
    { "value": 'id-badge' },
    { "value": 'id-card' },
    { "value": 'id-card-alt' },
    { "value": 'igloo' },
    { "value": 'image' },
    { "value": 'image-polaroid' },
    { "value": 'images' },
    { "value": 'inbox' },
    { "value": 'inbox-in' },
    { "value": 'inbox-out' },
    { "value": 'indent' },
    { "value": 'industry' },
    { "value": 'industry-alt' },
    { "value": 'infinity' },
    { "value": 'info' },
    { "value": 'info-circle' },
    { "value": 'info-square' },
    { "value": 'inhaler' },
    { "value": 'integral' },
    { "value": 'intersection' },
    { "value": 'inventory' },
    { "value": 'island-tropical' },
    { "value": 'italic' },
    { "value": 'jack-o-lantern' },
    { "value": 'jedi' },
    { "value": 'joint' },
    { "value": 'journal-whills' },
    { "value": 'joystick' },
    { "value": 'jug' },
    { "value": 'kaaba' },
    { "value": 'kazoo' },
    { "value": 'kerning' },
    { "value": 'key' },
    { "value": 'key-skeleton' },
    { "value": 'keyboard' },
    { "value": 'keynote' },
    { "value": 'khanda' },
    { "value": 'kidneys' },
    { "value": 'kiss' },
    { "value": 'kiss-beam' },
    { "value": 'kiss-wink-heart' },
    { "value": 'kite' },
    { "value": 'kiwi-bird' },
    { "value": 'knife-kitchen' },
    { "value": 'lambda' },
    { "value": 'lamp' },
    { "value": 'lamp-desk' },
    { "value": 'lamp-floor' },
    { "value": 'landmark' },
    { "value": 'landmark-alt' },
    { "value": 'language' },
    { "value": 'laptop' },
    { "value": 'laptop-code' },
    { "value": 'laptop-house' },
    { "value": 'laptop-medical' },
    { "value": 'lasso' },
    { "value": 'laugh' },
    { "value": 'laugh-beam' },
    { "value": 'laugh-squint' },
    { "value": 'laugh-wink' },
    { "value": 'layer-group' },
    { "value": 'layer-minus' },
    { "value": 'layer-plus' },
    { "value": 'leaf' },
    { "value": 'leaf-heart' },
    { "value": 'leaf-maple' },
    { "value": 'leaf-oak' },
    { "value": 'lemon' },
    { "value": 'less-than' },
    { "value": 'less-than-equal' },
    { "value": 'level-down' },
    { "value": 'level-down-alt' },
    { "value": 'level-up' },
    { "value": 'level-up-alt' },
    { "value": 'life-ring' },
    { "value": 'light-ceiling' },
    { "value": 'light-switch' },
    { "value": 'light-switch-off' },
    { "value": 'light-switch-on' },
    { "value": 'lightbulb' },
    { "value": 'lightbulb-dollar' },
    { "value": 'lightbulb-exclamation' },
    { "value": 'lightbulb-on' },
    { "value": 'lightbulb-slash' },
    { "value": 'lights-holiday' },
    { "value": 'line-columns' },
    { "value": 'line-height' },
    { "value": 'link' },
    { "value": 'lips' },
    { "value": 'lira-sign' },
    { "value": 'list' },
    { "value": 'list-alt' },
    { "value": 'list-music' },
    { "value": 'list-ol' },
    { "value": 'list-ul' },
    { "value": 'location' },
    { "value": 'location-arrow' },
    { "value": 'location-circle' },
    { "value": 'location-slash' },
    { "value": 'lock' },
    { "value": 'lock-alt' },
    { "value": 'lock-open' },
    { "value": 'lock-open-alt' },
    { "value": 'long-arrow-alt-down' },
    { "value": 'long-arrow-alt-left' },
    { "value": 'long-arrow-alt-right' },
    { "value": 'long-arrow-alt-up' },
    { "value": 'long-arrow-down' },
    { "value": 'long-arrow-left' },
    { "value": 'long-arrow-right' },
    { "value": 'long-arrow-up' },
    { "value": 'loveseat' },
    { "value": 'low-vision' },
    { "value": 'luchador' },
    { "value": 'luggage-cart' },
    { "value": 'lungs' },
    { "value": 'lungs-virus' },
    { "value": 'mace' },
    { "value": 'magic' },
    { "value": 'magnet' },
    { "value": 'mail-bulk' },
    { "value": 'mailbox' },
    { "value": 'male' },
    { "value": 'mandolin' },
    { "value": 'map' },
    { "value": 'map-marked' },
    { "value": 'map-marked-alt' },
    { "value": 'map-marker' },
    { "value": 'map-marker-alt' },
    { "value": 'map-marker-alt-slash' },
    { "value": 'map-marker-check' },
    { "value": 'map-marker-edit' },
    { "value": 'map-marker-exclamation' },
    { "value": 'map-marker-minus' },
    { "value": 'map-marker-plus' },
    { "value": 'map-marker-question' },
    { "value": 'map-marker-slash' },
    { "value": 'map-marker-smile' },
    { "value": 'map-marker-times' },
    { "value": 'map-pin' },
    { "value": 'map-signs' },
    { "value": 'marker' },
    { "value": 'mars' },
    { "value": 'mars-double' },
    { "value": 'mars-stroke' },
    { "value": 'mars-stroke-h' },
    { "value": 'mars-stroke-v' },
    { "value": 'mask' },
    { "value": 'meat' },
    { "value": 'medal' },
    { "value": 'medkit' },
    { "value": 'megaphone' },
    { "value": 'meh' },
    { "value": 'meh-blank' },
    { "value": 'meh-rolling-eyes' },
    { "value": 'memory' },
    { "value": 'menorah' },
    { "value": 'mercury' },
    { "value": 'meteor' },
    { "value": 'microchip' },
    { "value": 'microphone' },
    { "value": 'microphone-alt' },
    { "value": 'microphone-alt-slash' },
    { "value": 'microphone-slash' },
    { "value": 'microphone-stand' },
    { "value": 'microscope' },
    { "value": 'microwave' },
    { "value": 'mind-share' },
    { "value": 'minus' },
    { "value": 'minus-circle' },
    { "value": 'minus-hexagon' },
    { "value": 'minus-octagon' },
    { "value": 'minus-square' },
    { "value": 'mistletoe' },
    { "value": 'mitten' },
    { "value": 'mobile' },
    { "value": 'mobile-alt' },
    { "value": 'mobile-android' },
    { "value": 'mobile-android-alt' },
    { "value": 'money-bill' },
    { "value": 'money-bill-alt' },
    { "value": 'money-bill-wave' },
    { "value": 'money-bill-wave-alt' },
    { "value": 'money-check' },
    { "value": 'money-check-alt' },
    { "value": 'money-check-edit' },
    { "value": 'money-check-edit-alt' },
    { "value": 'monitor-heart-rate' },
    { "value": 'monkey' },
    { "value": 'monument' },
    { "value": 'moon' },
    { "value": 'moon-cloud' },
    { "value": 'moon-stars' },
    { "value": 'mortar-pestle' },
    { "value": 'mosque' },
    { "value": 'motorcycle' },
    { "value": 'mountain' },
    { "value": 'mountains' },
    { "value": 'mouse' },
    { "value": 'mouse-alt' },
    { "value": 'mouse-pointer' },
    { "value": 'mp3-player' },
    { "value": 'mug' },
    { "value": 'mug-hot' },
    { "value": 'mug-marshmallows' },
    { "value": 'mug-tea' },
    { "value": 'music' },
    { "value": 'music-alt' },
    { "value": 'music-alt-slash' },
    { "value": 'music-slash' },
    { "value": 'narwhal' },
    { "value": 'network-wired' },
    { "value": 'neuter' },
    { "value": 'newspaper' },
    { "value": 'not-equal' },
    { "value": 'notes-medical' },
    { "value": 'object-group' },
    { "value": 'object-ungroup' },
    { "value": 'octagon' },
    { "value": 'oil-can' },
    { "value": 'oil-temp' },
    { "value": 'om' },
    { "value": 'omega' },
    { "value": 'ornament' },
    { "value": 'otter' },
    { "value": 'outdent' },
    { "value": 'outlet' },
    { "value": 'oven' },
    { "value": 'overline' },
    { "value": 'page-break' },
    { "value": 'pager' },
    { "value": 'paint-brush' },
    { "value": 'paint-brush-alt' },
    { "value": 'paint-roller' },
    { "value": 'palette' },
    { "value": 'pallet' },
    { "value": 'pallet-alt' },
    { "value": 'paper-plane' },
    { "value": 'paperclip' },
    { "value": 'parachute-box' },
    { "value": 'paragraph' },
    { "value": 'paragraph-rtl' },
    { "value": 'parking' },
    { "value": 'parking-circle' },
    { "value": 'parking-circle-slash' },
    { "value": 'parking-slash' },
    { "value": 'passport' },
    { "value": 'pastafarianism' },
    { "value": 'paste' },
    { "value": 'pause' },
    { "value": 'pause-circle' },
    { "value": 'paw' },
    { "value": 'paw-alt' },
    { "value": 'paw-claws' },
    { "value": 'peace' },
    { "value": 'pegasus' },
    { "value": 'pen' },
    { "value": 'pen-alt' },
    { "value": 'pen-fancy' },
    { "value": 'pen-nib' },
    { "value": 'pen-square' },
    { "value": 'pencil' },
    { "value": 'pencil-alt' },
    { "value": 'pencil-paintbrush' },
    { "value": 'pencil-ruler' },
    { "value": 'pennant' },
    { "value": 'people-arrows' },
    { "value": 'people-carry' },
    { "value": 'pepper-hot' },
    { "value": 'percent' },
    { "value": 'percentage' },
    { "value": 'person-booth' },
    { "value": 'person-carry' },
    { "value": 'person-dolly' },
    { "value": 'person-dolly-empty' },
    { "value": 'person-sign' },
    { "value": 'phone' },
    { "value": 'phone-alt' },
    { "value": 'phone-laptop' },
    { "value": 'phone-office' },
    { "value": 'phone-plus' },
    { "value": 'phone-rotary' },
    { "value": 'phone-slash' },
    { "value": 'phone-square' },
    { "value": 'phone-square-alt' },
    { "value": 'phone-volume' },
    { "value": 'photo-video' },
    { "value": 'pi' },
    { "value": 'piano' },
    { "value": 'piano-keyboard' },
    { "value": 'pie' },
    { "value": 'pig' },
    { "value": 'piggy-bank' },
    { "value": 'pills' },
    { "value": 'pizza' },
    { "value": 'pizza-slice' },
    { "value": 'place-of-worship' },
    { "value": 'plane' },
    { "value": 'plane-alt' },
    { "value": 'plane-arrival' },
    { "value": 'plane-departure' },
    { "value": 'plane-slash' },
    { "value": 'planet-moon' },
    { "value": 'planet-ringed' },
    { "value": 'play' },
    { "value": 'play-circle' },
    { "value": 'plug' },
    { "value": 'plus' },
    { "value": 'plus-circle' },
    { "value": 'plus-hexagon' },
    { "value": 'plus-octagon' },
    { "value": 'plus-square' },
    { "value": 'podcast' },
    { "value": 'podium' },
    { "value": 'podium-star' },
    { "value": 'police-box' },
    { "value": 'poll' },
    { "value": 'poll-h' },
    { "value": 'poll-people' },
    { "value": 'poo' },
    { "value": 'poo-storm' },
    { "value": 'poop' },
    { "value": 'popcorn' },
    { "value": 'portal-enter' },
    { "value": 'portal-exit' },
    { "value": 'portrait' },
    { "value": 'pound-sign' },
    { "value": 'power-off' },
    { "value": 'pray' },
    { "value": 'praying-hands' },
    { "value": 'prescription' },
    { "value": 'prescription-bottle' },
    { "value": 'prescription-bottle-alt' },
    { "value": 'presentation' },
    { "value": 'print' },
    { "value": 'print-search' },
    { "value": 'print-slash' },
    { "value": 'procedures' },
    { "value": 'project-diagram' },
    { "value": 'projector' },
    { "value": 'pump-medical' },
    { "value": 'pump-soap' },
    { "value": 'pumpkin' },
    { "value": 'puzzle-piece' },
    { "value": 'qrcode' },
    { "value": 'question' },
    { "value": 'question-circle' },
    { "value": 'question-square' },
    { "value": 'quidditch' },
    { "value": 'quote-left' },
    { "value": 'quote-right' },
    { "value": 'quran' },
    { "value": 'rabbit' },
    { "value": 'rabbit-fast' },
    { "value": 'racquet' },
    { "value": 'radar' },
    { "value": 'radiation' },
    { "value": 'radiation-alt' },
    { "value": 'radio' },
    { "value": 'radio-alt' },
    { "value": 'rainbow' },
    { "value": 'raindrops' },
    { "value": 'ram' },
    { "value": 'ramp-loading' },
    { "value": 'random' },
    { "value": 'raygun' },
    { "value": 'receipt' },
    { "value": 'record-vinyl' },
    { "value": 'rectangle-landscape' },
    { "value": 'rectangle-portrait' },
    { "value": 'rectangle-wide' },
    { "value": 'recycle' },
    { "value": 'redo' },
    { "value": 'redo-alt' },
    { "value": 'refrigerator' },
    { "value": 'registered' },
    { "value": 'remove-format' },
    { "value": 'repeat' },
    { "value": 'repeat-1' },
    { "value": 'repeat-1-alt' },
    { "value": 'repeat-alt' },
    { "value": 'reply' },
    { "value": 'reply-all' },
    { "value": 'republican' },
    { "value": 'restroom' },
    { "value": 'retweet' },
    { "value": 'retweet-alt' },
    { "value": 'ribbon' },
    { "value": 'ring' },
    { "value": 'rings-wedding' },
    { "value": 'road' },
    { "value": 'robot' },
    { "value": 'rocket' },
    { "value": 'rocket-launch' },
    { "value": 'route' },
    { "value": 'route-highway' },
    { "value": 'route-interstate' },
    { "value": 'router' },
    { "value": 'rss' },
    { "value": 'rss-square' },
    { "value": 'ruble-sign' },
    { "value": 'ruler' },
    { "value": 'ruler-combined' },
    { "value": 'ruler-horizontal' },
    { "value": 'ruler-triangle' },
    { "value": 'ruler-vertical' },
    { "value": 'running' },
    { "value": 'rupee-sign' },
    { "value": 'rv' },
    { "value": 'sack' },
    { "value": 'sack-dollar' },
    { "value": 'sad-cry' },
    { "value": 'sad-tear' },
    { "value": 'salad' },
    { "value": 'sandwich' },
    { "value": 'satellite' },
    { "value": 'satellite-dish' },
    { "value": 'sausage' },
    { "value": 'save' },
    { "value": 'sax-hot' },
    { "value": 'saxophone' },
    { "value": 'scalpel' },
    { "value": 'scalpel-path' },
    { "value": 'scanner' },
    { "value": 'scanner-image' },
    { "value": 'scanner-keyboard' },
    { "value": 'scanner-touchscreen' },
    { "value": 'scarecrow' },
    { "value": 'scarf' },
    { "value": 'school' },
    { "value": 'screwdriver' },
    { "value": 'scroll' },
    { "value": 'scroll-old' },
    { "value": 'scrubber' },
    { "value": 'scythe' },
    { "value": 'sd-card' },
    { "value": 'search' },
    { "value": 'search-dollar' },
    { "value": 'search-location' },
    { "value": 'search-minus' },
    { "value": 'search-plus' },
    { "value": 'seedling' },
    { "value": 'send-back' },
    { "value": 'send-backward' },
    { "value": 'sensor' },
    { "value": 'sensor-alert' },
    { "value": 'sensor-fire' },
    { "value": 'sensor-on' },
    { "value": 'sensor-smoke' },
    { "value": 'server' },
    { "value": 'shapes' },
    { "value": 'share' },
    { "value": 'share-all' },
    { "value": 'share-alt' },
    { "value": 'share-alt-square' },
    { "value": 'share-square' },
    { "value": 'sheep' },
    { "value": 'shekel-sign' },
    { "value": 'shield' },
    { "value": 'shield-alt' },
    { "value": 'shield-check' },
    { "value": 'shield-cross' },
    { "value": 'shield-virus' },
    { "value": 'ship' },
    { "value": 'shipping-fast' },
    { "value": 'shipping-timed' },
    { "value": 'shish-kebab' },
    { "value": 'shoe-prints' },
    { "value": 'shopping-bag' },
    { "value": 'shopping-basket' },
    { "value": 'shopping-cart' },
    { "value": 'shovel' },
    { "value": 'shovel-snow' },
    { "value": 'shower' },
    { "value": 'shredder' },
    { "value": 'shuttle-van' },
    { "value": 'shuttlecock' },
    { "value": 'sickle' },
    { "value": 'sigma' },
    { "value": 'sign' },
    { "value": 'sign-in' },
    { "value": 'sign-in-alt' },
    { "value": 'sign-language' },
    { "value": 'sign-out' },
    { "value": 'sign-out-alt' },
    { "value": 'signal' },
    { "value": 'signal-1' },
    { "value": 'signal-2' },
    { "value": 'signal-3' },
    { "value": 'signal-4' },
    { "value": 'signal-alt' },
    { "value": 'signal-alt-1' },
    { "value": 'signal-alt-2' },
    { "value": 'signal-alt-3' },
    { "value": 'signal-alt-slash' },
    { "value": 'signal-slash' },
    { "value": 'signal-stream' },
    { "value": 'signature' },
    { "value": 'sim-card' },
    { "value": 'sink' },
    { "value": 'siren' },
    { "value": 'siren-on' },
    { "value": 'sitemap' },
    { "value": 'skating' },
    { "value": 'skeleton' },
    { "value": 'ski-jump' },
    { "value": 'ski-lift' },
    { "value": 'skiing' },
    { "value": 'skiing-nordic' },
    { "value": 'skull' },
    { "value": 'skull-cow' },
    { "value": 'skull-crossbones' },
    { "value": 'slash' },
    { "value": 'sledding' },
    { "value": 'sleigh' },
    { "value": 'sliders-h' },
    { "value": 'sliders-h-square' },
    { "value": 'sliders-v' },
    { "value": 'sliders-v-square' },
    { "value": 'smile' },
    { "value": 'smile-beam' },
    { "value": 'smile-plus' },
    { "value": 'smile-wink' },
    { "value": 'smog' },
    { "value": 'smoke' },
    { "value": 'smoking' },
    { "value": 'smoking-ban' },
    { "value": 'sms' },
    { "value": 'snake' },
    { "value": 'snooze' },
    { "value": 'snow-blowing' },
    { "value": 'snowboarding' },
    { "value": 'snowflake' },
    { "value": 'snowflakes' },
    { "value": 'snowman' },
    { "value": 'snowmobile' },
    { "value": 'snowplow' },
    { "value": 'soap' },
    { "value": 'socks' },
    { "value": 'solar-panel' },
    { "value": 'solar-system' },
    { "value": 'sort' },
    { "value": 'sort-alpha-down' },
    { "value": 'sort-alpha-down-alt' },
    { "value": 'sort-alpha-up' },
    { "value": 'sort-alpha-up-alt' },
    { "value": 'sort-alt' },
    { "value": 'sort-amount-down' },
    { "value": 'sort-amount-down-alt' },
    { "value": 'sort-amount-up' },
    { "value": 'sort-amount-up-alt' },
    { "value": 'sort-circle' },
    { "value": 'sort-circle-down' },
    { "value": 'sort-circle-up' },
    { "value": 'sort-down' },
    { "value": 'sort-numeric-down' },
    { "value": 'sort-numeric-down-alt' },
    { "value": 'sort-numeric-up' },
    { "value": 'sort-numeric-up-alt' },
    { "value": 'sort-shapes-down' },
    { "value": 'sort-shapes-down-alt' },
    { "value": 'sort-shapes-up' },
    { "value": 'sort-shapes-up-alt' },
    { "value": 'sort-size-down' },
    { "value": 'sort-size-down-alt' },
    { "value": 'sort-size-up' },
    { "value": 'sort-size-up-alt' },
    { "value": 'sort-up' },
    { "value": 'soup' },
    { "value": 'spa' },
    { "value": 'space-shuttle' },
    { "value": 'space-station-moon' },
    { "value": 'space-station-moon-alt' },
    { "value": 'spade' },
    { "value": 'sparkles' },
    { "value": 'speaker' },
    { "value": 'speakers' },
    { "value": 'spell-check' },
    { "value": 'spider' },
    { "value": 'spider-black-widow' },
    { "value": 'spider-web' },
    { "value": 'spinner' },
    { "value": 'spinner-third' },
    { "value": 'splotch' },
    { "value": 'spray-can' },
    { "value": 'sprinkler' },
    { "value": 'square' },
    { "value": 'square-full' },
    { "value": 'square-root' },
    { "value": 'square-root-alt' },
    { "value": 'squirrel' },
    { "value": 'staff' },
    { "value": 'stamp' },
    { "value": 'star' },
    { "value": 'star-and-crescent' },
    { "value": 'star-christmas' },
    { "value": 'star-exclamation' },
    { "value": 'star-half' },
    { "value": 'star-half-alt' },
    { "value": 'star-of-david' },
    { "value": 'star-of-life' },
    { "value": 'star-shooting' },
    { "value": 'starfighter' },
    { "value": 'starfighter-alt' },
    { "value": 'stars' },
    { "value": 'starship' },
    { "value": 'starship-freighter' },
    { "value": 'steak' },
    { "value": 'steering-wheel' },
    { "value": 'step-backward' },
    { "value": 'step-forward' },
    { "value": 'stethoscope' },
    { "value": 'sticky-note' },
    { "value": 'stocking' },
    { "value": 'stomach' },
    { "value": 'stop' },
    { "value": 'stop-circle' },
    { "value": 'stopwatch' },
    { "value": 'stopwatch-20' },
    { "value": 'store' },
    { "value": 'store-alt' },
    { "value": 'store-alt-slash' },
    { "value": 'store-slash' },
    { "value": 'stream' },
    { "value": 'street-view' },
    { "value": 'stretcher' },
    { "value": 'strikethrough' },
    { "value": 'stroopwafel' },
    { "value": 'subscript' },
    { "value": 'subway' },
    { "value": 'suitcase' },
    { "value": 'suitcase-rolling' },
    { "value": 'sun' },
    { "value": 'sun-cloud' },
    { "value": 'sun-dust' },
    { "value": 'sun-haze' },
    { "value": 'sunglasses' },
    { "value": 'sunrise' },
    { "value": 'sunset' },
    { "value": 'superscript' },
    { "value": 'surprise' },
    { "value": 'swatchbook' },
    { "value": 'swimmer' },
    { "value": 'swimming-pool' },
    { "value": 'sword' },
    { "value": 'sword-laser' },
    { "value": 'sword-laser-alt' },
    { "value": 'swords' },
    { "value": 'swords-laser' },
    { "value": 'synagogue' },
    { "value": 'sync' },
    { "value": 'sync-alt' },
    { "value": 'syringe' },
    { "value": 'table' },
    { "value": 'table-tennis' },
    { "value": 'tablet' },
    { "value": 'tablet-alt' },
    { "value": 'tablet-android' },
    { "value": 'tablet-android-alt' },
    { "value": 'tablet-rugged' },
    { "value": 'tablets' },
    { "value": 'tachometer' },
    { "value": 'tachometer-alt' },
    { "value": 'tachometer-alt-average' },
    { "value": 'tachometer-alt-fast' },
    { "value": 'tachometer-alt-fastest' },
    { "value": 'tachometer-alt-slow' },
    { "value": 'tachometer-alt-slowest' },
    { "value": 'tachometer-average' },
    { "value": 'tachometer-fast' },
    { "value": 'tachometer-fastest' },
    { "value": 'tachometer-slow' },
    { "value": 'tachometer-slowest' },
    { "value": 'taco' },
    { "value": 'tag' },
    { "value": 'tags' },
    { "value": 'tally' },
    { "value": 'tanakh' },
    { "value": 'tape' },
    { "value": 'tasks' },
    { "value": 'tasks-alt' },
    { "value": 'taxi' },
    { "value": 'teeth' },
    { "value": 'teeth-open' },
    { "value": 'telescope' },
    { "value": 'temperature-down' },
    { "value": 'temperature-frigid' },
    { "value": 'temperature-high' },
    { "value": 'temperature-hot' },
    { "value": 'temperature-low' },
    { "value": 'temperature-up' },
    { "value": 'tenge' },
    { "value": 'tennis-ball' },
    { "value": 'terminal' },
    { "value": 'text' },
    { "value": 'text-height' },
    { "value": 'text-size' },
    { "value": 'text-width' },
    { "value": 'th' },
    { "value": 'th-large' },
    { "value": 'th-list' },
    { "value": 'theater-masks' },
    { "value": 'thermometer' },
    { "value": 'thermometer-empty' },
    { "value": 'thermometer-full' },
    { "value": 'thermometer-half' },
    { "value": 'thermometer-quarter' },
    { "value": 'thermometer-three-quarters' },
    { "value": 'theta' },
    { "value": 'thumbs-down' },
    { "value": 'thumbs-up' },
    { "value": 'thumbtack' },
    { "value": 'thunderstorm' },
    { "value": 'thunderstorm-moon' },
    { "value": 'thunderstorm-sun' },
    { "value": 'ticket' },
    { "value": 'ticket-alt' },
    { "value": 'tilde' },
    { "value": 'times' },
    { "value": 'times-circle' },
    { "value": 'times-hexagon' },
    { "value": 'times-octagon' },
    { "value": 'times-square' },
    { "value": 'tint' },
    { "value": 'tint-slash' },
    { "value": 'tire' },
    { "value": 'tire-flat' },
    { "value": 'tire-pressure-warning' },
    { "value": 'tire-rugged' },
    { "value": 'tired' },
    { "value": 'toggle-off' },
    { "value": 'toggle-on' },
    { "value": 'toilet' },
    { "value": 'toilet-paper' },
    { "value": 'toilet-paper-alt' },
    { "value": 'toilet-paper-slash' },
    { "value": 'tombstone' },
    { "value": 'tombstone-alt' },
    { "value": 'toolbox' },
    { "value": 'tools' },
    { "value": 'tooth' },
    { "value": 'toothbrush' },
    { "value": 'torah' },
    { "value": 'torii-gate' },
    { "value": 'tornado' },
    { "value": 'tractor' },
    { "value": 'trademark' },
    { "value": 'traffic-cone' },
    { "value": 'traffic-light' },
    { "value": 'traffic-light-go' },
    { "value": 'traffic-light-slow' },
    { "value": 'traffic-light-stop' },
    { "value": 'trailer' },
    { "value": 'train' },
    { "value": 'tram' },
    { "value": 'transgender' },
    { "value": 'transgender-alt' },
    { "value": 'transporter' },
    { "value": 'transporter-1' },
    { "value": 'transporter-2' },
    { "value": 'transporter-3' },
    { "value": 'transporter-empty' },
    { "value": 'trash' },
    { "value": 'trash-alt' },
    { "value": 'trash-restore' },
    { "value": 'trash-restore-alt' },
    { "value": 'trash-undo' },
    { "value": 'trash-undo-alt' },
    { "value": 'treasure-chest' },
    { "value": 'tree' },
    { "value": 'tree-alt' },
    { "value": 'tree-christmas' },
    { "value": 'tree-decorated' },
    { "value": 'tree-large' },
    { "value": 'tree-palm' },
    { "value": 'trees' },
    { "value": 'triangle' },
    { "value": 'triangle-music' },
    { "value": 'trophy' },
    { "value": 'trophy-alt' },
    { "value": 'truck' },
    { "value": 'truck-container' },
    { "value": 'truck-couch' },
    { "value": 'truck-loading' },
    { "value": 'truck-monster' },
    { "value": 'truck-moving' },
    { "value": 'truck-pickup' },
    { "value": 'truck-plow' },
    { "value": 'truck-ramp' },
    { "value": 'trumpet' },
    { "value": 'tshirt' },
    { "value": 'tty' },
    { "value": 'turkey' },
    { "value": 'turntable' },
    { "value": 'turtle' },
    { "value": 'tv' },
    { "value": 'tv-alt' },
    { "value": 'tv-music' },
    { "value": 'tv-retro' },
    { "value": 'typewriter' },
    { "value": 'ufo' },
    { "value": 'ufo-beam' },
    { "value": 'umbrella' },
    { "value": 'umbrella-beach' },
    { "value": 'underline' },
    { "value": 'undo' },
    { "value": 'undo-alt' },
    { "value": 'unicorn' },
    { "value": 'union' },
    { "value": 'universal-access' },
    { "value": 'university' },
    { "value": 'unlink' },
    { "value": 'unlock' },
    { "value": 'unlock-alt' },
    { "value": 'upload' },
    { "value": 'usb-drive' },
    { "value": 'usd-circle' },
    { "value": 'usd-square' },
    { "value": 'user' },
    { "value": 'user-alien' },
    { "value": 'user-alt' },
    { "value": 'user-alt-slash' },
    { "value": 'user-astronaut' },
    { "value": 'user-chart' },
    { "value": 'user-check' },
    { "value": 'user-circle' },
    { "value": 'user-clock' },
    { "value": 'user-cog' },
    { "value": 'user-cowboy' },
    { "value": 'user-crown' },
    { "value": 'user-edit' },
    { "value": 'user-friends' },
    { "value": 'user-graduate' },
    { "value": 'user-hard-hat' },
    { "value": 'user-headset' },
    { "value": 'user-injured' },
    { "value": 'user-lock' },
    { "value": 'user-md' },
    { "value": 'user-md-chat' },
    { "value": 'user-minus' },
    { "value": 'user-music' },
    { "value": 'user-ninja' },
    { "value": 'user-nurse' },
    { "value": 'user-plus' },
    { "value": 'user-robot' },
    { "value": 'user-secret' },
    { "value": 'user-shield' },
    { "value": 'user-slash' },
    { "value": 'user-tag' },
    { "value": 'user-tie' },
    { "value": 'user-times' },
    { "value": 'user-unlock' },
    { "value": 'user-visor' },
    { "value": 'users' },
    { "value": 'users-class' },
    { "value": 'users-cog' },
    { "value": 'users-crown' },
    { "value": 'users-medical' },
    { "value": 'users-slash' },
    { "value": 'utensil-fork' },
    { "value": 'utensil-knife' },
    { "value": 'utensil-spoon' },
    { "value": 'utensils' },
    { "value": 'utensils-alt' },
    { "value": 'vacuum' },
    { "value": 'vacuum-robot' },
    { "value": 'value-absolute' },
    { "value": 'vector-square' },
    { "value": 'venus' },
    { "value": 'venus-double' },
    { "value": 'venus-mars' },
    { "value": 'vest' },
    { "value": 'vest-patches' },
    { "value": 'vhs' },
    { "value": 'vial' },
    { "value": 'vials' },
    { "value": 'video' },
    { "value": 'video-plus' },
    { "value": 'video-slash' },
    { "value": 'vihara' },
    { "value": 'violin' },
    { "value": 'virus' },
    { "value": 'virus-slash' },
    { "value": 'viruses' },
    { "value": 'voicemail' },
    { "value": 'volcano' },
    { "value": 'volleyball-ball' },
    { "value": 'volume' },
    { "value": 'volume-down' },
    { "value": 'volume-mute' },
    { "value": 'volume-off' },
    { "value": 'volume-slash' },
    { "value": 'volume-up' },
    { "value": 'vote-nay' },
    { "value": 'vote-yea' },
    { "value": 'vr-cardboard' },
    { "value": 'wagon-covered' },
    { "value": 'walker' },
    { "value": 'walkie-talkie' },
    { "value": 'walking' },
    { "value": 'wallet' },
    { "value": 'wand' },
    { "value": 'wand-magic' },
    { "value": 'warehouse' },
    { "value": 'warehouse-alt' },
    { "value": 'washer' },
    { "value": 'watch' },
    { "value": 'watch-calculator' },
    { "value": 'watch-fitness' },
    { "value": 'water' },
    { "value": 'water-lower' },
    { "value": 'water-rise' },
    { "value": 'wave-sine' },
    { "value": 'wave-square' },
    { "value": 'wave-triangle' },
    { "value": 'waveform' },
    { "value": 'waveform-path' },
    { "value": 'webcam' },
    { "value": 'webcam-slash' },
    { "value": 'weight' },
    { "value": 'weight-hanging' },
    { "value": 'whale' },
    { "value": 'wheat' },
    { "value": 'wheelchair' },
    { "value": 'whistle' },
    { "value": 'wifi' },
    { "value": 'wifi-1' },
    { "value": 'wifi-2' },
    { "value": 'wifi-slash' },
    { "value": 'wind' },
    { "value": 'wind-turbine' },
    { "value": 'wind-warning' },
    { "value": 'window' },
    { "value": 'window-alt' },
    { "value": 'window-close' },
    { "value": 'window-frame' },
    { "value": 'window-frame-open' },
    { "value": 'window-maximize' },
    { "value": 'window-minimize' },
    { "value": 'window-restore' },
    { "value": 'windsock' },
    { "value": 'wine-bottle' },
    { "value": 'wine-glass' },
    { "value": 'wine-glass-alt' },
    { "value": 'won-sign' },
    { "value": 'wreath' },
    { "value": 'wrench' },
    { "value": 'x-ray' },
    { "value": 'yen-sign' },
    { "value": 'yin-yang' },
    { "value": 'abacus' },
    { "value": 'acorn' },
    { "value": 'ad' },
    { "value": 'address-book' },
    { "value": 'address-card' },
    { "value": 'adjust' },
    { "value": 'air-conditioner' },
    { "value": 'air-freshener' },
    { "value": 'alarm-clock' },
    { "value": 'alarm-exclamation' },
    { "value": 'alarm-plus' },
    { "value": 'alarm-snooze' },
    { "value": 'album' },
    { "value": 'album-collection' },
    { "value": 'alicorn' },
    { "value": 'alien' },
    { "value": 'alien-monster' },
    { "value": 'align-center' },
    { "value": 'align-justify' },
    { "value": 'align-left' },
    { "value": 'align-right' },
    { "value": 'align-slash' },
    { "value": 'allergies' },
    { "value": 'ambulance' },
    { "value": 'american-sign-language-interpreting' },
    { "value": 'amp-guitar' },
    { "value": 'analytics' },
    { "value": 'anchor' },
    { "value": 'angel' },
    { "value": 'angle-double-down' },
    { "value": 'angle-double-left' },
    { "value": 'angle-double-right' },
    { "value": 'angle-double-up' },
    { "value": 'angle-down' },
    { "value": 'angle-left' },
    { "value": 'angle-right' },
    { "value": 'angle-up' },
    { "value": 'angry' },
    { "value": 'ankh' },
    { "value": 'apple-alt' },
    { "value": 'apple-crate' },
    { "value": 'archive' },
    { "value": 'archway' },
    { "value": 'arrow-alt-circle-down' },
    { "value": 'arrow-alt-circle-left' },
    { "value": 'arrow-alt-circle-right' },
    { "value": 'arrow-alt-circle-up' },
    { "value": 'arrow-alt-down' },
    { "value": 'arrow-alt-from-bottom' },
    { "value": 'arrow-alt-from-left' },
    { "value": 'arrow-alt-from-right' },
    { "value": 'arrow-alt-from-top' },
    { "value": 'arrow-alt-left' },
    { "value": 'arrow-alt-right' },
    { "value": 'arrow-alt-square-down' },
    { "value": 'arrow-alt-square-left' },
    { "value": 'arrow-alt-square-right' },
    { "value": 'arrow-alt-square-up' },
    { "value": 'arrow-alt-to-bottom' },
    { "value": 'arrow-alt-to-left' },
    { "value": 'arrow-alt-to-right' },
    { "value": 'arrow-alt-to-top' },
    { "value": 'arrow-alt-up' },
    { "value": 'arrow-circle-down' },
    { "value": 'arrow-circle-left' },
    { "value": 'arrow-circle-right' },
    { "value": 'arrow-circle-up' },
    { "value": 'arrow-down' },
    { "value": 'arrow-from-bottom' },
    { "value": 'arrow-from-left' },
    { "value": 'arrow-from-right' },
    { "value": 'arrow-from-top' },
    { "value": 'arrow-left' },
    { "value": 'arrow-right' },
    { "value": 'arrow-square-down' },
    { "value": 'arrow-square-left' },
    { "value": 'arrow-square-right' },
    { "value": 'arrow-square-up' },
    { "value": 'arrow-to-bottom' },
    { "value": 'arrow-to-left' },
    { "value": 'arrow-to-right' },
    { "value": 'arrow-to-top' },
    { "value": 'arrow-up' },
    { "value": 'arrows' },
    { "value": 'arrows-alt' },
    { "value": 'arrows-alt-h' },
    { "value": 'arrows-alt-v' },
    { "value": 'arrows-h' },
    { "value": 'arrows-v' },
    { "value": 'assistive-listening-systems' },
    { "value": 'asterisk' },
    { "value": 'at' },
    { "value": 'atlas' },
    { "value": 'atom' },
    { "value": 'atom-alt' },
    { "value": 'audio-description' },
    { "value": 'award' },
    { "value": 'axe' },
    { "value": 'axe-battle' },
    { "value": 'baby' },
    { "value": 'baby-carriage' },
    { "value": 'backpack' },
    { "value": 'backspace' },
    { "value": 'backward' },
    { "value": 'bacon' },
    { "value": 'bacteria' },
    { "value": 'bacterium' },
    { "value": 'badge' },
    { "value": 'badge-check' },
    { "value": 'badge-dollar' },
    { "value": 'badge-percent' },
    { "value": 'badge-sheriff' },
    { "value": 'badger-honey' },
    { "value": 'bags-shopping' },
    { "value": 'bahai' },
    { "value": 'balance-scale' },
    { "value": 'balance-scale-left' },
    { "value": 'balance-scale-right' },
    { "value": 'ball-pile' },
    { "value": 'ballot' },
    { "value": 'ballot-check' },
    { "value": 'ban' },
    { "value": 'band-aid' },
    { "value": 'banjo' },
    { "value": 'barcode' },
    { "value": 'barcode-alt' },
    { "value": 'barcode-read' },
    { "value": 'barcode-scan' },
    { "value": 'bars' },
    { "value": 'baseball' },
    { "value": 'baseball-ball' },
    { "value": 'basketball-ball' },
    { "value": 'basketball-hoop' },
    { "value": 'bat' },
    { "value": 'bath' },
    { "value": 'battery-bolt' },
    { "value": 'battery-empty' },
    { "value": 'battery-full' },
    { "value": 'battery-half' },
    { "value": 'battery-quarter' },
    { "value": 'battery-slash' },
    { "value": 'battery-three-quarters' },
    { "value": 'bed' },
    { "value": 'bed-alt' },
    { "value": 'bed-bunk' },
    { "value": 'bed-empty' },
    { "value": 'beer' },
    { "value": 'bell' },
    { "value": 'bell-exclamation' },
    { "value": 'bell-on' },
    { "value": 'bell-plus' },
    { "value": 'bell-school' },
    { "value": 'bell-school-slash' },
    { "value": 'bell-slash' },
    { "value": 'bells' },
    { "value": 'betamax' },
    { "value": 'bezier-curve' },
    { "value": 'bible' },
    { "value": 'bicycle' },
    { "value": 'biking' },
    { "value": 'biking-mountain' },
    { "value": 'binoculars' },
    { "value": 'biohazard' },
    { "value": 'birthday-cake' },
    { "value": 'blanket' },
    { "value": 'blender' },
    { "value": 'blender-phone' },
    { "value": 'blind' },
    { "value": 'blinds' },
    { "value": 'blinds-open' },
    { "value": 'blinds-raised' },
    { "value": 'blog' },
    { "value": 'bold' },
    { "value": 'bolt' },
    { "value": 'bomb' },
    { "value": 'bone' },
    { "value": 'bone-break' },
    { "value": 'bong' },
    { "value": 'book' },
    { "value": 'book-alt' },
    { "value": 'book-dead' },
    { "value": 'book-heart' },
    { "value": 'book-medical' },
    { "value": 'book-open' },
    { "value": 'book-reader' },
    { "value": 'book-spells' },
    { "value": 'book-user' },
    { "value": 'bookmark' },
    { "value": 'books' },
    { "value": 'books-medical' },
    { "value": 'boombox' },
    { "value": 'boot' },
    { "value": 'booth-curtain' },
    { "value": 'border-all' },
    { "value": 'border-bottom' },
    { "value": 'border-center-h' },
    { "value": 'border-center-v' },
    { "value": 'border-inner' },
    { "value": 'border-left' },
    { "value": 'border-none' },
    { "value": 'border-outer' },
    { "value": 'border-right' },
    { "value": 'border-style' },
    { "value": 'border-style-alt' },
    { "value": 'border-top' },
    { "value": 'bow-arrow' },
    { "value": 'bowling-ball' },
    { "value": 'bowling-pins' },
    { "value": 'box' },
    { "value": 'box-alt' },
    { "value": 'box-ballot' },
    { "value": 'box-check' },
    { "value": 'box-fragile' },
    { "value": 'box-full' },
    { "value": 'box-heart' },
    { "value": 'box-open' },
    { "value": 'box-tissue' },
    { "value": 'box-up' },
    { "value": 'box-usd' },
    { "value": 'boxes' },
    { "value": 'boxes-alt' },
    { "value": 'boxing-glove' },
    { "value": 'brackets' },
    { "value": 'brackets-curly' },
    { "value": 'braille' },
    { "value": 'brain' },
    { "value": 'bread-loaf' },
    { "value": 'bread-slice' },
    { "value": 'briefcase' },
    { "value": 'briefcase-medical' },
    { "value": 'bring-forward' },
    { "value": 'bring-front' },
    { "value": 'broadcast-tower' },
    { "value": 'broom' },
    { "value": 'browser' },
    { "value": 'brush' },
    { "value": 'bug' },
    { "value": 'building' },
    { "value": 'bullhorn' },
    { "value": 'bullseye' },
    { "value": 'bullseye-arrow' },
    { "value": 'bullseye-pointer' },
    { "value": 'burger-soda' },
    { "value": 'burn' },
    { "value": 'burrito' },
    { "value": 'bus' },
    { "value": 'bus-alt' },
    { "value": 'bus-school' },
    { "value": 'business-time' },
    { "value": 'cabinet-filing' },
    { "value": 'cactus' },
    { "value": 'calculator' },
    { "value": 'calculator-alt' },
    { "value": 'calendar' },
    { "value": 'calendar-alt' },
    { "value": 'calendar-check' },
    { "value": 'calendar-day' },
    { "value": 'calendar-edit' },
    { "value": 'calendar-exclamation' },
    { "value": 'calendar-minus' },
    { "value": 'calendar-plus' },
    { "value": 'calendar-star' },
    { "value": 'calendar-times' },
    { "value": 'calendar-week' },
    { "value": 'camcorder' },
    { "value": 'camera' },
    { "value": 'camera-alt' },
    { "value": 'camera-home' },
    { "value": 'camera-movie' },
    { "value": 'camera-polaroid' },
    { "value": 'camera-retro' },
    { "value": 'campfire' },
    { "value": 'campground' },
    { "value": 'candle-holder' },
    { "value": 'candy-cane' },
    { "value": 'candy-corn' },
    { "value": 'cannabis' },
    { "value": 'capsules' },
    { "value": 'car' },
    { "value": 'car-alt' },
    { "value": 'car-battery' },
    { "value": 'car-building' },
    { "value": 'car-bump' },
    { "value": 'car-bus' },
    { "value": 'car-crash' },
    { "value": 'car-garage' },
    { "value": 'car-mechanic' },
    { "value": 'car-side' },
    { "value": 'car-tilt' },
    { "value": 'car-wash' },
    { "value": 'caravan' },
    { "value": 'caravan-alt' },
    { "value": 'caret-circle-down' },
    { "value": 'caret-circle-left' },
    { "value": 'caret-circle-right' },
    { "value": 'caret-circle-up' },
    { "value": 'caret-down' },
    { "value": 'caret-left' },
    { "value": 'caret-right' },
    { "value": 'caret-square-down' },
    { "value": 'caret-square-left' },
    { "value": 'caret-square-right' },
    { "value": 'caret-square-up' },
    { "value": 'caret-up' },
    { "value": 'carrot' },
    { "value": 'cars' },
    { "value": 'cart-arrow-down' },
    { "value": 'cart-plus' },
    { "value": 'cash-register' },
    { "value": 'cassette-tape' },
    { "value": 'cat' },
    { "value": 'cat-space' },
    { "value": 'cauldron' },
    { "value": 'cctv' },
    { "value": 'certificate' },
    { "value": 'chair' },
    { "value": 'chair-office' },
    { "value": 'chalkboard' },
    { "value": 'chalkboard-teacher' },
    { "value": 'charging-station' },
    { "value": 'chart-area' },
    { "value": 'chart-bar' },
    { "value": 'chart-line' },
    { "value": 'chart-line-down' },
    { "value": 'chart-network' },
    { "value": 'chart-pie' },
    { "value": 'chart-pie-alt' },
    { "value": 'chart-scatter' },
    { "value": 'check' },
    { "value": 'check-circle' },
    { "value": 'check-double' },
    { "value": 'check-square' },
    { "value": 'cheese' },
    { "value": 'cheese-swiss' },
    { "value": 'cheeseburger' },
    { "value": 'chess' },
    { "value": 'chess-bishop' },
    { "value": 'chess-bishop-alt' },
    { "value": 'chess-board' },
    { "value": 'chess-clock' },
    { "value": 'chess-clock-alt' },
    { "value": 'chess-king' },
    { "value": 'chess-king-alt' },
    { "value": 'chess-knight' },
    { "value": 'chess-knight-alt' },
    { "value": 'chess-pawn' },
    { "value": 'chess-pawn-alt' },
    { "value": 'chess-queen' },
    { "value": 'chess-queen-alt' },
    { "value": 'chess-rook' },
    { "value": 'chess-rook-alt' },
    { "value": 'chevron-circle-down' },
    { "value": 'chevron-circle-left' },
    { "value": 'chevron-circle-right' },
    { "value": 'chevron-circle-up' },
    { "value": 'chevron-double-down' },
    { "value": 'chevron-double-left' },
    { "value": 'chevron-double-right' },
    { "value": 'chevron-double-up' },
    { "value": 'chevron-down' },
    { "value": 'chevron-left' },
    { "value": 'chevron-right' },
    { "value": 'chevron-square-down' },
    { "value": 'chevron-square-left' },
    { "value": 'chevron-square-right' },
    { "value": 'chevron-square-up' },
    { "value": 'chevron-up' },
    { "value": 'child' },
    { "value": 'chimney' },
    { "value": 'church' },
    { "value": 'circle' },
    { "value": 'circle-notch' },
    { "value": 'city' },
    { "value": 'clarinet' },
    { "value": 'claw-marks' },
    { "value": 'clinic-medical' },
    { "value": 'clipboard' },
    { "value": 'clipboard-check' },
    { "value": 'clipboard-list' },
    { "value": 'clipboard-list-check' },
    { "value": 'clipboard-prescription' },
    { "value": 'clipboard-user' },
    { "value": 'clock' },
    { "value": 'clone' },
    { "value": 'closed-captioning' },
    { "value": 'cloud' },
    { "value": 'cloud-download' },
    { "value": 'cloud-download-alt' },
    { "value": 'cloud-drizzle' },
    { "value": 'cloud-hail' },
    { "value": 'cloud-hail-mixed' },
    { "value": 'cloud-meatball' },
    { "value": 'cloud-moon' },
    { "value": 'cloud-moon-rain' },
    { "value": 'cloud-music' },
    { "value": 'cloud-rain' },
    { "value": 'cloud-rainbow' },
    { "value": 'cloud-showers' },
    { "value": 'cloud-showers-heavy' },
    { "value": 'cloud-sleet' },
    { "value": 'cloud-snow' },
    { "value": 'cloud-sun' },
    { "value": 'cloud-sun-rain' },
    { "value": 'cloud-upload' },
    { "value": 'cloud-upload-alt' },
    { "value": 'clouds' },
    { "value": 'clouds-moon' },
    { "value": 'clouds-sun' },
    { "value": 'club' },
    { "value": 'cocktail' },
    { "value": 'code' },
    { "value": 'code-branch' },
    { "value": 'code-commit' },
    { "value": 'code-merge' },
    { "value": 'coffee' },
    { "value": 'coffee-pot' },
    { "value": 'coffee-togo' },
    { "value": 'coffin' },
    { "value": 'coffin-cross' },
    { "value": 'cog' },
    { "value": 'cogs' },
    { "value": 'coin' },
    { "value": 'coins' },
    { "value": 'columns' },
    { "value": 'comet' },
    { "value": 'comment' },
    { "value": 'comment-alt' },
    { "value": 'comment-alt-check' },
    { "value": 'comment-alt-dollar' },
    { "value": 'comment-alt-dots' },
    { "value": 'comment-alt-edit' },
    { "value": 'comment-alt-exclamation' },
    { "value": 'comment-alt-lines' },
    { "value": 'comment-alt-medical' },
    { "value": 'comment-alt-minus' },
    { "value": 'comment-alt-music' },
    { "value": 'comment-alt-plus' },
    { "value": 'comment-alt-slash' },
    { "value": 'comment-alt-smile' },
    { "value": 'comment-alt-times' },
    { "value": 'comment-check' },
    { "value": 'comment-dollar' },
    { "value": 'comment-dots' },
    { "value": 'comment-edit' },
    { "value": 'comment-exclamation' },
    { "value": 'comment-lines' },
    { "value": 'comment-medical' },
    { "value": 'comment-minus' },
    { "value": 'comment-music' },
    { "value": 'comment-plus' },
    { "value": 'comment-slash' },
    { "value": 'comment-smile' },
    { "value": 'comment-times' },
    { "value": 'comments' },
    { "value": 'comments-alt' },
    { "value": 'comments-alt-dollar' },
    { "value": 'comments-dollar' },
    { "value": 'compact-disc' },
    { "value": 'compass' },
    { "value": 'compass-slash' },
    { "value": 'compress' },
    { "value": 'compress-alt' },
    { "value": 'compress-arrows-alt' },
    { "value": 'compress-wide' },
    { "value": 'computer-classic' },
    { "value": 'computer-speaker' },
    { "value": 'concierge-bell' },
    { "value": 'construction' },
    { "value": 'container-storage' },
    { "value": 'conveyor-belt' },
    { "value": 'conveyor-belt-alt' },
    { "value": 'cookie' },
    { "value": 'cookie-bite' },
    { "value": 'copy' },
    { "value": 'copyright' },
    { "value": 'corn' },
    { "value": 'couch' },
    { "value": 'cow' },
    { "value": 'cowbell' },
    { "value": 'cowbell-more' },
    { "value": 'credit-card' },
    { "value": 'credit-card-blank' },
    { "value": 'credit-card-front' },
    { "value": 'cricket' },
    { "value": 'croissant' },
    { "value": 'crop' },
    { "value": 'crop-alt' },
    { "value": 'cross' },
    { "value": 'crosshairs' },
    { "value": 'crow' },
    { "value": 'crown' },
    { "value": 'crutch' },
    { "value": 'crutches' },
    { "value": 'cube' },
    { "value": 'cubes' },
    { "value": 'curling' },
    { "value": 'cut' },
    { "value": 'dagger' },
    { "value": 'database' },
    { "value": 'deaf' },
    { "value": 'debug' },
    { "value": 'deer' },
    { "value": 'deer-rudolph' },
    { "value": 'democrat' },
    { "value": 'desktop' },
    { "value": 'desktop-alt' },
    { "value": 'dewpoint' },
    { "value": 'dharmachakra' },
    { "value": 'diagnoses' },
    { "value": 'diamond' },
    { "value": 'dice' },
    { "value": 'dice-d10' },
    { "value": 'dice-d12' },
    { "value": 'dice-d20' },
    { "value": 'dice-d4' },
    { "value": 'dice-d6' },
    { "value": 'dice-d8' },
    { "value": 'dice-five' },
    { "value": 'dice-four' },
    { "value": 'dice-one' },
    { "value": 'dice-six' },
    { "value": 'dice-three' },
    { "value": 'dice-two' },
    { "value": 'digging' },
    { "value": 'digital-tachograph' },
    { "value": 'diploma' },
    { "value": 'directions' },
    { "value": 'disc-drive' },
    { "value": 'disease' },
    { "value": 'divide' },
    { "value": 'dizzy' },
    { "value": 'dna' },
    { "value": 'do-not-enter' },
    { "value": 'dog' },
    { "value": 'dog-leashed' },
    { "value": 'dollar-sign' },
    { "value": 'dolly' },
    { "value": 'dolly-empty' },
    { "value": 'dolly-flatbed' },
    { "value": 'dolly-flatbed-alt' },
    { "value": 'dolly-flatbed-empty' },
    { "value": 'donate' },
    { "value": 'door-closed' },
    { "value": 'door-open' },
    { "value": 'dot-circle' },
    { "value": 'dove' },
    { "value": 'download' },
    { "value": 'drafting-compass' },
    { "value": 'dragon' },
    { "value": 'draw-circle' },
    { "value": 'draw-polygon' },
    { "value": 'draw-square' },
    { "value": 'dreidel' },
    { "value": 'drone' },
    { "value": 'drone-alt' },
    { "value": 'drum' },
    { "value": 'drum-steelpan' },
    { "value": 'drumstick' },
    { "value": 'drumstick-bite' },
    { "value": 'dryer' },
    { "value": 'dryer-alt' },
    { "value": 'duck' },
    { "value": 'dumbbell' },
    { "value": 'dumpster' },
    { "value": 'dumpster-fire' },
    { "value": 'dungeon' },
    { "value": 'ear' },
    { "value": 'ear-muffs' },
    { "value": 'eclipse' },
    { "value": 'eclipse-alt' },
    { "value": 'edit' },
    { "value": 'egg' },
    { "value": 'egg-fried' },
    { "value": 'eject' },
    { "value": 'elephant' },
    { "value": 'ellipsis-h' },
    { "value": 'ellipsis-h-alt' },
    { "value": 'ellipsis-v' },
    { "value": 'ellipsis-v-alt' },
    { "value": 'empty-set' },
    { "value": 'engine-warning' },
    { "value": 'envelope' },
    { "value": 'envelope-open' },
    { "value": 'envelope-open-dollar' },
    { "value": 'envelope-open-text' },
    { "value": 'envelope-square' },
    { "value": 'equals' },
    { "value": 'eraser' },
    { "value": 'ethernet' },
    { "value": 'euro-sign' },
    { "value": 'exchange' },
    { "value": 'exchange-alt' },
    { "value": 'exclamation' },
    { "value": 'exclamation-circle' },
    { "value": 'exclamation-square' },
    { "value": 'exclamation-triangle' },
    { "value": 'expand' },
    { "value": 'expand-alt' },
    { "value": 'expand-arrows' },
    { "value": 'expand-arrows-alt' },
    { "value": 'expand-wide' },
    { "value": 'external-link' },
    { "value": 'external-link-alt' },
    { "value": 'external-link-square' },
    { "value": 'external-link-square-alt' },
    { "value": 'eye' },
    { "value": 'eye-dropper' },
    { "value": 'eye-evil' },
    { "value": 'eye-slash' },
    { "value": 'fan' },
    { "value": 'fan-table' },
    { "value": 'farm' },
    { "value": 'fast-backward' },
    { "value": 'fast-forward' },
    { "value": 'faucet' },
    { "value": 'faucet-drip' },
    { "value": 'fax' },
    { "value": 'feather' },
    { "value": 'feather-alt' },
    { "value": 'female' },
    { "value": 'field-hockey' },
    { "value": 'fighter-jet' },
    { "value": 'file' },
    { "value": 'file-alt' },
    { "value": 'file-archive' },
    { "value": 'file-audio' },
    { "value": 'file-certificate' },
    { "value": 'file-chart-line' },
    { "value": 'file-chart-pie' },
    { "value": 'file-check' },
    { "value": 'file-code' },
    { "value": 'file-contract' },
    { "value": 'file-csv' },
    { "value": 'file-download' },
    { "value": 'file-edit' },
    { "value": 'file-excel' },
    { "value": 'file-exclamation' },
    { "value": 'file-export' },
    { "value": 'file-image' },
    { "value": 'file-import' },
    { "value": 'file-invoice' },
    { "value": 'file-invoice-dollar' },
    { "value": 'file-medical' },
    { "value": 'file-medical-alt' },
    { "value": 'file-minus' },
    { "value": 'file-music' },
    { "value": 'file-pdf' },
    { "value": 'file-plus' },
    { "value": 'file-powerpoint' },
    { "value": 'file-prescription' },
    { "value": 'file-search' },
    { "value": 'file-signature' },
    { "value": 'file-spreadsheet' },
    { "value": 'file-times' },
    { "value": 'file-upload' },
    { "value": 'file-user' },
    { "value": 'file-video' },
    { "value": 'file-word' },
    { "value": 'files-medical' },
    { "value": 'fill' },
    { "value": 'fill-drip' },
    { "value": 'film' },
    { "value": 'film-alt' },
    { "value": 'film-canister' },
    { "value": 'filter' },
    { "value": 'fingerprint' },
    { "value": 'fire' },
    { "value": 'fire-alt' },
    { "value": 'fire-extinguisher' },
    { "value": 'fire-smoke' },
    { "value": 'fireplace' },
    { "value": 'first-aid' },
    { "value": 'fish' },
    { "value": 'fish-cooked' },
    { "value": 'fist-raised' },
    { "value": 'flag' },
    { "value": 'flag-alt' },
    { "value": 'flag-checkered' },
    { "value": 'flag-usa' },
    { "value": 'flame' },
    { "value": 'flashlight' },
    { "value": 'flask' },
    { "value": 'flask-poison' },
    { "value": 'flask-potion' },
    { "value": 'flower' },
    { "value": 'flower-daffodil' },
    { "value": 'flower-tulip' },
    { "value": 'flushed' },
    { "value": 'flute' },
    { "value": 'flux-capacitor' },
    { "value": 'fog' },
    { "value": 'folder' },
    { "value": 'folder-download' },
    { "value": 'folder-minus' },
    { "value": 'folder-open' },
    { "value": 'folder-plus' },
    { "value": 'folder-times' },
    { "value": 'folder-tree' },
    { "value": 'folder-upload' },
    { "value": 'folders' },
    { "value": 'font' },
    { "value": 'font-awesome-logo-full' },
    { "value": 'font-case' },
    { "value": 'football-ball' },
    { "value": 'football-helmet' },
    { "value": 'forklift' },
    { "value": 'forward' },
    { "value": 'fragile' },
    { "value": 'french-fries' },
    { "value": 'frog' },
    { "value": 'frosty-head' },
    { "value": 'frown' },
    { "value": 'frown-open' },
    { "value": 'function' },
    { "value": 'funnel-dollar' },
    { "value": 'futbol' },
    { "value": 'galaxy' },
    { "value": 'game-board' },
    { "value": 'game-board-alt' },
    { "value": 'game-console-handheld' },
    { "value": 'gamepad' },
    { "value": 'gamepad-alt' },
    { "value": 'garage' },
    { "value": 'garage-car' },
    { "value": 'garage-open' },
    { "value": 'gas-pump' },
    { "value": 'gas-pump-slash' },
    { "value": 'gavel' },
    { "value": 'gem' },
    { "value": 'genderless' },
    { "value": 'ghost' },
    { "value": 'gift' },
    { "value": 'gift-card' },
    { "value": 'gifts' },
    { "value": 'gingerbread-man' },
    { "value": 'glass' },
    { "value": 'glass-champagne' },
    { "value": 'glass-cheers' },
    { "value": 'glass-citrus' },
    { "value": 'glass-martini' },
    { "value": 'glass-martini-alt' },
    { "value": 'glass-whiskey' },
    { "value": 'glass-whiskey-rocks' },
    { "value": 'glasses' },
    { "value": 'glasses-alt' },
    { "value": 'globe' },
    { "value": 'globe-africa' },
    { "value": 'globe-americas' },
    { "value": 'globe-asia' },
    { "value": 'globe-europe' },
    { "value": 'globe-snow' },
    { "value": 'globe-stand' },
    { "value": 'golf-ball' },
    { "value": 'golf-club' },
    { "value": 'gopuram' },
    { "value": 'graduation-cap' },
    { "value": 'gramophone' },
    { "value": 'greater-than' },
    { "value": 'greater-than-equal' },
    { "value": 'grimace' },
    { "value": 'grin' },
    { "value": 'grin-alt' },
    { "value": 'grin-beam' },
    { "value": 'grin-beam-sweat' },
    { "value": 'grin-hearts' },
    { "value": 'grin-squint' },
    { "value": 'grin-squint-tears' },
    { "value": 'grin-stars' },
    { "value": 'grin-tears' },
    { "value": 'grin-tongue' },
    { "value": 'grin-tongue-squint' },
    { "value": 'grin-tongue-wink' },
    { "value": 'grin-wink' },
    { "value": 'grip-horizontal' },
    { "value": 'grip-lines' },
    { "value": 'grip-lines-vertical' },
    { "value": 'grip-vertical' },
    { "value": 'guitar' },
    { "value": 'guitar-electric' },
    { "value": 'guitars' },
    { "value": 'h-square' },
    { "value": 'h1' },
    { "value": 'h2' },
    { "value": 'h3' },
    { "value": 'h4' },
    { "value": 'hamburger' },
    { "value": 'hammer' },
    { "value": 'hammer-war' },
    { "value": 'hamsa' },
    { "value": 'hand-heart' },
    { "value": 'hand-holding' },
    { "value": 'hand-holding-box' },
    { "value": 'hand-holding-heart' },
    { "value": 'hand-holding-magic' },
    { "value": 'hand-holding-medical' },
    { "value": 'hand-holding-seedling' },
    { "value": 'hand-holding-usd' },
    { "value": 'hand-holding-water' },
    { "value": 'hand-lizard' },
    { "value": 'hand-middle-finger' },
    { "value": 'hand-paper' },
    { "value": 'hand-peace' },
    { "value": 'hand-point-down' },
    { "value": 'hand-point-left' },
    { "value": 'hand-point-right' },
    { "value": 'hand-point-up' },
    { "value": 'hand-pointer' },
    { "value": 'hand-receiving' },
    { "value": 'hand-rock' },
    { "value": 'hand-scissors' },
    { "value": 'hand-sparkles' },
    { "value": 'hand-spock' },
    { "value": 'hands' },
    { "value": 'hands-heart' },
    { "value": 'hands-helping' },
    { "value": 'hands-usd' },
    { "value": 'hands-wash' },
    { "value": 'handshake' },
    { "value": 'handshake-alt' },
    { "value": 'handshake-alt-slash' },
    { "value": 'handshake-slash' },
    { "value": 'hanukiah' },
    { "value": 'hard-hat' },
    { "value": 'hashtag' },
    { "value": 'hat-chef' },
    { "value": 'hat-cowboy' },
    { "value": 'hat-cowboy-side' },
    { "value": 'hat-santa' },
    { "value": 'hat-winter' },
    { "value": 'hat-witch' },
    { "value": 'hat-wizard' },
    { "value": 'hdd' },
    { "value": 'head-side' },
    { "value": 'head-side-brain' },
    { "value": 'head-side-cough' },
    { "value": 'head-side-cough-slash' },
    { "value": 'head-side-headphones' },
    { "value": 'head-side-mask' },
    { "value": 'head-side-medical' },
    { "value": 'head-side-virus' },
    { "value": 'head-vr' },
    { "value": 'heading' },
    { "value": 'headphones' },
    { "value": 'headphones-alt' },
    { "value": 'headset' },
    { "value": 'heart' },
    { "value": 'heart-broken' },
    { "value": 'heart-circle' },
    { "value": 'heart-rate' },
    { "value": 'heart-square' },
    { "value": 'heartbeat' },
    { "value": 'heat' },
    { "value": 'helicopter' },
    { "value": 'helmet-battle' },
    { "value": 'hexagon' },
    { "value": 'highlighter' },
    { "value": 'hiking' },
    { "value": 'hippo' },
    { "value": 'history' },
    { "value": 'hockey-mask' },
    { "value": 'hockey-puck' },
    { "value": 'hockey-sticks' },
    { "value": 'holly-berry' },
    { "value": 'home' },
    { "value": 'home-alt' },
    { "value": 'home-heart' },
    { "value": 'home-lg' },
    { "value": 'home-lg-alt' },
    { "value": 'hood-cloak' },
    { "value": 'horizontal-rule' },
    { "value": 'horse' },
    { "value": 'horse-head' },
    { "value": 'horse-saddle' },
    { "value": 'hospital' },
    { "value": 'hospital-alt' },
    { "value": 'hospital-symbol' },
    { "value": 'hospital-user' },
    { "value": 'hospitals' },
    { "value": 'hot-tub' },
    { "value": 'hotdog' },
    { "value": 'hotel' },
    { "value": 'hourglass' },
    { "value": 'hourglass-end' },
    { "value": 'hourglass-half' },
    { "value": 'hourglass-start' },
    { "value": 'house' },
    { "value": 'house-damage' },
    { "value": 'house-day' },
    { "value": 'house-flood' },
    { "value": 'house-leave' },
    { "value": 'house-night' },
    { "value": 'house-return' },
    { "value": 'house-signal' },
    { "value": 'house-user' },
    { "value": 'hryvnia' },
    { "value": 'humidity' },
    { "value": 'hurricane' },
    { "value": 'i-cursor' },
    { "value": 'ice-cream' },
    { "value": 'ice-skate' },
    { "value": 'icicles' },
    { "value": 'icons' },
    { "value": 'icons-alt' },
    { "value": 'id-badge' },
    { "value": 'id-card' },
    { "value": 'id-card-alt' },
    { "value": 'igloo' },
    { "value": 'image' },
    { "value": 'image-polaroid' },
    { "value": 'images' },
    { "value": 'inbox' },
    { "value": 'inbox-in' },
    { "value": 'inbox-out' },
    { "value": 'indent' },
    { "value": 'industry' },
    { "value": 'industry-alt' },
    { "value": 'infinity' },
    { "value": 'info' },
    { "value": 'info-circle' },
    { "value": 'info-square' },
    { "value": 'inhaler' },
    { "value": 'integral' },
    { "value": 'intersection' },
    { "value": 'inventory' },
    { "value": 'island-tropical' },
    { "value": 'italic' },
    { "value": 'jack-o-lantern' },
    { "value": 'jedi' },
    { "value": 'joint' },
    { "value": 'journal-whills' },
    { "value": 'joystick' },
    { "value": 'jug' },
    { "value": 'kaaba' },
    { "value": 'kazoo' },
    { "value": 'kerning' },
    { "value": 'key' },
    { "value": 'key-skeleton' },
    { "value": 'keyboard' },
    { "value": 'keynote' },
    { "value": 'khanda' },
    { "value": 'kidneys' },
    { "value": 'kiss' },
    { "value": 'kiss-beam' },
    { "value": 'kiss-wink-heart' },
    { "value": 'kite' },
    { "value": 'kiwi-bird' },
    { "value": 'knife-kitchen' },
    { "value": 'lambda' },
    { "value": 'lamp' },
    { "value": 'lamp-desk' },
    { "value": 'lamp-floor' },
    { "value": 'landmark' },
    { "value": 'landmark-alt' },
    { "value": 'language' },
    { "value": 'laptop' },
    { "value": 'laptop-code' },
    { "value": 'laptop-house' },
    { "value": 'laptop-medical' },
    { "value": 'lasso' },
    { "value": 'laugh' },
    { "value": 'laugh-beam' },
    { "value": 'laugh-squint' },
    { "value": 'laugh-wink' },
    { "value": 'layer-group' },
    { "value": 'layer-minus' },
    { "value": 'layer-plus' },
    { "value": 'leaf' },
    { "value": 'leaf-heart' },
    { "value": 'leaf-maple' },
    { "value": 'leaf-oak' },
    { "value": 'lemon' },
    { "value": 'less-than' },
    { "value": 'less-than-equal' },
    { "value": 'level-down' },
    { "value": 'level-down-alt' },
    { "value": 'level-up' },
    { "value": 'level-up-alt' },
    { "value": 'life-ring' },
    { "value": 'light-ceiling' },
    { "value": 'light-switch' },
    { "value": 'light-switch-off' },
    { "value": 'light-switch-on' },
    { "value": 'lightbulb' },
    { "value": 'lightbulb-dollar' },
    { "value": 'lightbulb-exclamation' },
    { "value": 'lightbulb-on' },
    { "value": 'lightbulb-slash' },
    { "value": 'lights-holiday' },
    { "value": 'line-columns' },
    { "value": 'line-height' },
    { "value": 'link' },
    { "value": 'lips' },
    { "value": 'lira-sign' },
    { "value": 'list' },
    { "value": 'list-alt' },
    { "value": 'list-music' },
    { "value": 'list-ol' },
    { "value": 'list-ul' },
    { "value": 'location' },
    { "value": 'location-arrow' },
    { "value": 'location-circle' },
    { "value": 'location-slash' },
    { "value": 'lock' },
    { "value": 'lock-alt' },
    { "value": 'lock-open' },
    { "value": 'lock-open-alt' },
    { "value": 'long-arrow-alt-down' },
    { "value": 'long-arrow-alt-left' },
    { "value": 'long-arrow-alt-right' },
    { "value": 'long-arrow-alt-up' },
    { "value": 'long-arrow-down' },
    { "value": 'long-arrow-left' },
    { "value": 'long-arrow-right' },
    { "value": 'long-arrow-up' },
    { "value": 'loveseat' },
    { "value": 'low-vision' },
    { "value": 'luchador' },
    { "value": 'luggage-cart' },
    { "value": 'lungs' },
    { "value": 'lungs-virus' },
    { "value": 'mace' },
    { "value": 'magic' },
    { "value": 'magnet' },
    { "value": 'mail-bulk' },
    { "value": 'mailbox' },
    { "value": 'male' },
    { "value": 'mandolin' },
    { "value": 'map' },
    { "value": 'map-marked' },
    { "value": 'map-marked-alt' },
    { "value": 'map-marker' },
    { "value": 'map-marker-alt' },
    { "value": 'map-marker-alt-slash' },
    { "value": 'map-marker-check' },
    { "value": 'map-marker-edit' },
    { "value": 'map-marker-exclamation' },
    { "value": 'map-marker-minus' },
    { "value": 'map-marker-plus' },
    { "value": 'map-marker-question' },
    { "value": 'map-marker-slash' },
    { "value": 'map-marker-smile' },
    { "value": 'map-marker-times' },
    { "value": 'map-pin' },
    { "value": 'map-signs' },
    { "value": 'marker' },
    { "value": 'mars' },
    { "value": 'mars-double' },
    { "value": 'mars-stroke' },
    { "value": 'mars-stroke-h' },
    { "value": 'mars-stroke-v' },
    { "value": 'mask' },
    { "value": 'meat' },
    { "value": 'medal' },
    { "value": 'medkit' },
    { "value": 'megaphone' },
    { "value": 'meh' },
    { "value": 'meh-blank' },
    { "value": 'meh-rolling-eyes' },
    { "value": 'memory' },
    { "value": 'menorah' },
    { "value": 'mercury' },
    { "value": 'meteor' },
    { "value": 'microchip' },
    { "value": 'microphone' },
    { "value": 'microphone-alt' },
    { "value": 'microphone-alt-slash' },
    { "value": 'microphone-slash' },
    { "value": 'microphone-stand' },
    { "value": 'microscope' },
    { "value": 'microwave' },
    { "value": 'mind-share' },
    { "value": 'minus' },
    { "value": 'minus-circle' },
    { "value": 'minus-hexagon' },
    { "value": 'minus-octagon' },
    { "value": 'minus-square' },
    { "value": 'mistletoe' },
    { "value": 'mitten' },
    { "value": 'mobile' },
    { "value": 'mobile-alt' },
    { "value": 'mobile-android' },
    { "value": 'mobile-android-alt' },
    { "value": 'money-bill' },
    { "value": 'money-bill-alt' },
    { "value": 'money-bill-wave' },
    { "value": 'money-bill-wave-alt' },
    { "value": 'money-check' },
    { "value": 'money-check-alt' },
    { "value": 'money-check-edit' },
    { "value": 'money-check-edit-alt' },
    { "value": 'monitor-heart-rate' },
    { "value": 'monkey' },
    { "value": 'monument' },
    { "value": 'moon' },
    { "value": 'moon-cloud' },
    { "value": 'moon-stars' },
    { "value": 'mortar-pestle' },
    { "value": 'mosque' },
    { "value": 'motorcycle' },
    { "value": 'mountain' },
    { "value": 'mountains' },
    { "value": 'mouse' },
    { "value": 'mouse-alt' },
    { "value": 'mouse-pointer' },
    { "value": 'mp3-player' },
    { "value": 'mug' },
    { "value": 'mug-hot' },
    { "value": 'mug-marshmallows' },
    { "value": 'mug-tea' },
    { "value": 'music' },
    { "value": 'music-alt' },
    { "value": 'music-alt-slash' },
    { "value": 'music-slash' },
    { "value": 'narwhal' },
    { "value": 'network-wired' },
    { "value": 'neuter' },
    { "value": 'newspaper' },
    { "value": 'not-equal' },
    { "value": 'notes-medical' },
    { "value": 'object-group' },
    { "value": 'object-ungroup' },
    { "value": 'octagon' },
    { "value": 'oil-can' },
    { "value": 'oil-temp' },
    { "value": 'om' },
    { "value": 'omega' },
    { "value": 'ornament' },
    { "value": 'otter' },
    { "value": 'outdent' },
    { "value": 'outlet' },
    { "value": 'oven' },
    { "value": 'overline' },
    { "value": 'page-break' },
    { "value": 'pager' },
    { "value": 'paint-brush' },
    { "value": 'paint-brush-alt' },
    { "value": 'paint-roller' },
    { "value": 'palette' },
    { "value": 'pallet' },
    { "value": 'pallet-alt' },
    { "value": 'paper-plane' },
    { "value": 'paperclip' },
    { "value": 'parachute-box' },
    { "value": 'paragraph' },
    { "value": 'paragraph-rtl' },
    { "value": 'parking' },
    { "value": 'parking-circle' },
    { "value": 'parking-circle-slash' },
    { "value": 'parking-slash' },
    { "value": 'passport' },
    { "value": 'pastafarianism' },
    { "value": 'paste' },
    { "value": 'pause' },
    { "value": 'pause-circle' },
    { "value": 'paw' },
    { "value": 'paw-alt' },
    { "value": 'paw-claws' },
    { "value": 'peace' },
    { "value": 'pegasus' },
    { "value": 'pen' },
    { "value": 'pen-alt' },
    { "value": 'pen-fancy' },
    { "value": 'pen-nib' },
    { "value": 'pen-square' },
    { "value": 'pencil' },
    { "value": 'pencil-alt' },
    { "value": 'pencil-paintbrush' },
    { "value": 'pencil-ruler' },
    { "value": 'pennant' },
    { "value": 'people-arrows' },
    { "value": 'people-carry' },
    { "value": 'pepper-hot' },
    { "value": 'percent' },
    { "value": 'percentage' },
    { "value": 'person-booth' },
    { "value": 'person-carry' },
    { "value": 'person-dolly' },
    { "value": 'person-dolly-empty' },
    { "value": 'person-sign' },
    { "value": 'phone' },
    { "value": 'phone-alt' },
    { "value": 'phone-laptop' },
    { "value": 'phone-office' },
    { "value": 'phone-plus' },
    { "value": 'phone-rotary' },
    { "value": 'phone-slash' },
    { "value": 'phone-square' },
    { "value": 'phone-square-alt' },
    { "value": 'phone-volume' },
    { "value": 'photo-video' },
    { "value": 'pi' },
    { "value": 'piano' },
    { "value": 'piano-keyboard' },
    { "value": 'pie' },
    { "value": 'pig' },
    { "value": 'piggy-bank' },
    { "value": 'pills' },
    { "value": 'pizza' },
    { "value": 'pizza-slice' },
    { "value": 'place-of-worship' },
    { "value": 'plane' },
    { "value": 'plane-alt' },
    { "value": 'plane-arrival' },
    { "value": 'plane-departure' },
    { "value": 'plane-slash' },
    { "value": 'planet-moon' },
    { "value": 'planet-ringed' },
    { "value": 'play' },
    { "value": 'play-circle' },
    { "value": 'plug' },
    { "value": 'plus' },
    { "value": 'plus-circle' },
    { "value": 'plus-hexagon' },
    { "value": 'plus-octagon' },
    { "value": 'plus-square' },
    { "value": 'podcast' },
    { "value": 'podium' },
    { "value": 'podium-star' },
    { "value": 'police-box' },
    { "value": 'poll' },
    { "value": 'poll-h' },
    { "value": 'poll-people' },
    { "value": 'poo' },
    { "value": 'poo-storm' },
    { "value": 'poop' },
    { "value": 'popcorn' },
    { "value": 'portal-enter' },
    { "value": 'portal-exit' },
    { "value": 'portrait' },
    { "value": 'pound-sign' },
    { "value": 'power-off' },
    { "value": 'pray' },
    { "value": 'praying-hands' },
    { "value": 'prescription' },
    { "value": 'prescription-bottle' },
    { "value": 'prescription-bottle-alt' },
    { "value": 'presentation' },
    { "value": 'print' },
    { "value": 'print-search' },
    { "value": 'print-slash' },
    { "value": 'procedures' },
    { "value": 'project-diagram' },
    { "value": 'projector' },
    { "value": 'pump-medical' },
    { "value": 'pump-soap' },
    { "value": 'pumpkin' },
    { "value": 'puzzle-piece' },
    { "value": 'qrcode' },
    { "value": 'question' },
    { "value": 'question-circle' },
    { "value": 'question-square' },
    { "value": 'quidditch' },
    { "value": 'quote-left' },
    { "value": 'quote-right' },
    { "value": 'quran' },
    { "value": 'rabbit' },
    { "value": 'rabbit-fast' },
    { "value": 'racquet' },
    { "value": 'radar' },
    { "value": 'radiation' },
    { "value": 'radiation-alt' },
    { "value": 'radio' },
    { "value": 'radio-alt' },
    { "value": 'rainbow' },
    { "value": 'raindrops' },
    { "value": 'ram' },
    { "value": 'ramp-loading' },
    { "value": 'random' },
    { "value": 'raygun' },
    { "value": 'receipt' },
    { "value": 'record-vinyl' },
    { "value": 'rectangle-landscape' },
    { "value": 'rectangle-portrait' },
    { "value": 'rectangle-wide' },
    { "value": 'recycle' },
    { "value": 'redo' },
    { "value": 'redo-alt' },
    { "value": 'refrigerator' },
    { "value": 'registered' },
    { "value": 'remove-format' },
    { "value": 'repeat' },
    { "value": 'repeat-1' },
    { "value": 'repeat-1-alt' },
    { "value": 'repeat-alt' },
    { "value": 'reply' },
    { "value": 'reply-all' },
    { "value": 'republican' },
    { "value": 'restroom' },
    { "value": 'retweet' },
    { "value": 'retweet-alt' },
    { "value": 'ribbon' },
    { "value": 'ring' },
    { "value": 'rings-wedding' },
    { "value": 'road' },
    { "value": 'robot' },
    { "value": 'rocket' },
    { "value": 'rocket-launch' },
    { "value": 'route' },
    { "value": 'route-highway' },
    { "value": 'route-interstate' },
    { "value": 'router' },
    { "value": 'rss' },
    { "value": 'rss-square' },
    { "value": 'ruble-sign' },
    { "value": 'ruler' },
    { "value": 'ruler-combined' },
    { "value": 'ruler-horizontal' },
    { "value": 'ruler-triangle' },
    { "value": 'ruler-vertical' },
    { "value": 'running' },
    { "value": 'rupee-sign' },
    { "value": 'rv' },
    { "value": 'sack' },
    { "value": 'sack-dollar' },
    { "value": 'sad-cry' },
    { "value": 'sad-tear' },
    { "value": 'salad' },
    { "value": 'sandwich' },
    { "value": 'satellite' },
    { "value": 'satellite-dish' },
    { "value": 'sausage' },
    { "value": 'save' },
    { "value": 'sax-hot' },
    { "value": 'saxophone' },
    { "value": 'scalpel' },
    { "value": 'scalpel-path' },
    { "value": 'scanner' },
    { "value": 'scanner-image' },
    { "value": 'scanner-keyboard' },
    { "value": 'scanner-touchscreen' },
    { "value": 'scarecrow' },
    { "value": 'scarf' },
    { "value": 'school' },
    { "value": 'screwdriver' },
    { "value": 'scroll' },
    { "value": 'scroll-old' },
    { "value": 'scrubber' },
    { "value": 'scythe' },
    { "value": 'sd-card' },
    { "value": 'search' },
    { "value": 'search-dollar' },
    { "value": 'search-location' },
    { "value": 'search-minus' },
    { "value": 'search-plus' },
    { "value": 'seedling' },
    { "value": 'send-back' },
    { "value": 'send-backward' },
    { "value": 'sensor' },
    { "value": 'sensor-alert' },
    { "value": 'sensor-fire' },
    { "value": 'sensor-on' },
    { "value": 'sensor-smoke' },
    { "value": 'server' },
    { "value": 'shapes' },
    { "value": 'share' },
    { "value": 'share-all' },
    { "value": 'share-alt' },
    { "value": 'share-alt-square' },
    { "value": 'share-square' },
    { "value": 'sheep' },
    { "value": 'shekel-sign' },
    { "value": 'shield' },
    { "value": 'shield-alt' },
    { "value": 'shield-check' },
    { "value": 'shield-cross' },
    { "value": 'shield-virus' },
    { "value": 'ship' },
    { "value": 'shipping-fast' },
    { "value": 'shipping-timed' },
    { "value": 'shish-kebab' },
    { "value": 'shoe-prints' },
    { "value": 'shopping-bag' },
    { "value": 'shopping-basket' },
    { "value": 'shopping-cart' },
    { "value": 'shovel' },
    { "value": 'shovel-snow' },
    { "value": 'shower' },
    { "value": 'shredder' },
    { "value": 'shuttle-van' },
    { "value": 'shuttlecock' },
    { "value": 'sickle' },
    { "value": 'sigma' },
    { "value": 'sign' },
    { "value": 'sign-in' },
    { "value": 'sign-in-alt' },
    { "value": 'sign-language' },
    { "value": 'sign-out' },
    { "value": 'sign-out-alt' },
    { "value": 'signal' },
    { "value": 'signal-1' },
    { "value": 'signal-2' },
    { "value": 'signal-3' },
    { "value": 'signal-4' },
    { "value": 'signal-alt' },
    { "value": 'signal-alt-1' },
    { "value": 'signal-alt-2' },
    { "value": 'signal-alt-3' },
    { "value": 'signal-alt-slash' },
    { "value": 'signal-slash' },
    { "value": 'signal-stream' },
    { "value": 'signature' },
    { "value": 'sim-card' },
    { "value": 'sink' },
    { "value": 'siren' },
    { "value": 'siren-on' },
    { "value": 'sitemap' },
    { "value": 'skating' },
    { "value": 'skeleton' },
    { "value": 'ski-jump' },
    { "value": 'ski-lift' },
    { "value": 'skiing' },
    { "value": 'skiing-nordic' },
    { "value": 'skull' },
    { "value": 'skull-cow' },
    { "value": 'skull-crossbones' },
    { "value": 'slash' },
    { "value": 'sledding' },
    { "value": 'sleigh' },
    { "value": 'sliders-h' },
    { "value": 'sliders-h-square' },
    { "value": 'sliders-v' },
    { "value": 'sliders-v-square' },
    { "value": 'smile' },
    { "value": 'smile-beam' },
    { "value": 'smile-plus' },
    { "value": 'smile-wink' },
    { "value": 'smog' },
    { "value": 'smoke' },
    { "value": 'smoking' },
    { "value": 'smoking-ban' },
    { "value": 'sms' },
    { "value": 'snake' },
    { "value": 'snooze' },
    { "value": 'snow-blowing' },
    { "value": 'snowboarding' },
    { "value": 'snowflake' },
    { "value": 'snowflakes' },
    { "value": 'snowman' },
    { "value": 'snowmobile' },
    { "value": 'snowplow' },
    { "value": 'soap' },
    { "value": 'socks' },
    { "value": 'solar-panel' },
    { "value": 'solar-system' },
    { "value": 'sort' },
    { "value": 'sort-alpha-down' },
    { "value": 'sort-alpha-down-alt' },
    { "value": 'sort-alpha-up' },
    { "value": 'sort-alpha-up-alt' },
    { "value": 'sort-alt' },
    { "value": 'sort-amount-down' },
    { "value": 'sort-amount-down-alt' },
    { "value": 'sort-amount-up' },
    { "value": 'sort-amount-up-alt' },
    { "value": 'sort-circle' },
    { "value": 'sort-circle-down' },
    { "value": 'sort-circle-up' },
    { "value": 'sort-down' },
    { "value": 'sort-numeric-down' },
    { "value": 'sort-numeric-down-alt' },
    { "value": 'sort-numeric-up' },
    { "value": 'sort-numeric-up-alt' },
    { "value": 'sort-shapes-down' },
    { "value": 'sort-shapes-down-alt' },
    { "value": 'sort-shapes-up' },
    { "value": 'sort-shapes-up-alt' },
    { "value": 'sort-size-down' },
    { "value": 'sort-size-down-alt' },
    { "value": 'sort-size-up' },
    { "value": 'sort-size-up-alt' },
    { "value": 'sort-up' },
    { "value": 'soup' },
    { "value": 'spa' },
    { "value": 'space-shuttle' },
    { "value": 'space-station-moon' },
    { "value": 'space-station-moon-alt' },
    { "value": 'spade' },
    { "value": 'sparkles' },
    { "value": 'speaker' },
    { "value": 'speakers' },
    { "value": 'spell-check' },
    { "value": 'spider' },
    { "value": 'spider-black-widow' },
    { "value": 'spider-web' },
    { "value": 'spinner' },
    { "value": 'spinner-third' },
    { "value": 'splotch' },
    { "value": 'spray-can' },
    { "value": 'sprinkler' },
    { "value": 'square' },
    { "value": 'square-full' },
    { "value": 'square-root' },
    { "value": 'square-root-alt' },
    { "value": 'squirrel' },
    { "value": 'staff' },
    { "value": 'stamp' },
    { "value": 'star' },
    { "value": 'star-and-crescent' },
    { "value": 'star-christmas' },
    { "value": 'star-exclamation' },
    { "value": 'star-half' },
    { "value": 'star-half-alt' },
    { "value": 'star-of-david' },
    { "value": 'star-of-life' },
    { "value": 'star-shooting' },
    { "value": 'starfighter' },
    { "value": 'starfighter-alt' },
    { "value": 'stars' },
    { "value": 'starship' },
    { "value": 'starship-freighter' },
    { "value": 'steak' },
    { "value": 'steering-wheel' },
    { "value": 'step-backward' },
    { "value": 'step-forward' },
    { "value": 'stethoscope' },
    { "value": 'sticky-note' },
    { "value": 'stocking' },
    { "value": 'stomach' },
    { "value": 'stop' },
    { "value": 'stop-circle' },
    { "value": 'stopwatch' },
    { "value": 'stopwatch-20' },
    { "value": 'store' },
    { "value": 'store-alt' },
    { "value": 'store-alt-slash' },
    { "value": 'store-slash' },
    { "value": 'stream' },
    { "value": 'street-view' },
    { "value": 'stretcher' },
    { "value": 'strikethrough' },
    { "value": 'stroopwafel' },
    { "value": 'subscript' },
    { "value": 'subway' },
    { "value": 'suitcase' },
    { "value": 'suitcase-rolling' },
    { "value": 'sun' },
    { "value": 'sun-cloud' },
    { "value": 'sun-dust' },
    { "value": 'sun-haze' },
    { "value": 'sunglasses' },
    { "value": 'sunrise' },
    { "value": 'sunset' },
    { "value": 'superscript' },
    { "value": 'surprise' },
    { "value": 'swatchbook' },
    { "value": 'swimmer' },
    { "value": 'swimming-pool' },
    { "value": 'sword' },
    { "value": 'sword-laser' },
    { "value": 'sword-laser-alt' },
    { "value": 'swords' },
    { "value": 'swords-laser' },
    { "value": 'synagogue' },
    { "value": 'sync' },
    { "value": 'sync-alt' },
    { "value": 'syringe' },
    { "value": 'table' },
    { "value": 'table-tennis' },
    { "value": 'tablet' },
    { "value": 'tablet-alt' },
    { "value": 'tablet-android' },
    { "value": 'tablet-android-alt' },
    { "value": 'tablet-rugged' },
    { "value": 'tablets' },
    { "value": 'tachometer' },
    { "value": 'tachometer-alt' },
    { "value": 'tachometer-alt-average' },
    { "value": 'tachometer-alt-fast' },
    { "value": 'tachometer-alt-fastest' },
    { "value": 'tachometer-alt-slow' },
    { "value": 'tachometer-alt-slowest' },
    { "value": 'tachometer-average' },
    { "value": 'tachometer-fast' },
    { "value": 'tachometer-fastest' },
    { "value": 'tachometer-slow' },
    { "value": 'tachometer-slowest' },
    { "value": 'taco' },
    { "value": 'tag' },
    { "value": 'tags' },
    { "value": 'tally' },
    { "value": 'tanakh' },
    { "value": 'tape' },
    { "value": 'tasks' },
    { "value": 'tasks-alt' },
    { "value": 'taxi' },
    { "value": 'teeth' },
    { "value": 'teeth-open' },
    { "value": 'telescope' },
    { "value": 'temperature-down' },
    { "value": 'temperature-frigid' },
    { "value": 'temperature-high' },
    { "value": 'temperature-hot' },
    { "value": 'temperature-low' },
    { "value": 'temperature-up' },
    { "value": 'tenge' },
    { "value": 'tennis-ball' },
    { "value": 'terminal' },
    { "value": 'text' },
    { "value": 'text-height' },
    { "value": 'text-size' },
    { "value": 'text-width' },
    { "value": 'th' },
    { "value": 'th-large' },
    { "value": 'th-list' },
    { "value": 'theater-masks' },
    { "value": 'thermometer' },
    { "value": 'thermometer-empty' },
    { "value": 'thermometer-full' },
    { "value": 'thermometer-half' },
    { "value": 'thermometer-quarter' },
    { "value": 'thermometer-three-quarters' },
    { "value": 'theta' },
    { "value": 'thumbs-down' },
    { "value": 'thumbs-up' },
    { "value": 'thumbtack' },
    { "value": 'thunderstorm' },
    { "value": 'thunderstorm-moon' },
    { "value": 'thunderstorm-sun' },
    { "value": 'ticket' },
    { "value": 'ticket-alt' },
    { "value": 'tilde' },
    { "value": 'times' },
    { "value": 'times-circle' },
    { "value": 'times-hexagon' },
    { "value": 'times-octagon' },
    { "value": 'times-square' },
    { "value": 'tint' },
    { "value": 'tint-slash' },
    { "value": 'tire' },
    { "value": 'tire-flat' },
    { "value": 'tire-pressure-warning' },
    { "value": 'tire-rugged' },
    { "value": 'tired' },
    { "value": 'toggle-off' },
    { "value": 'toggle-on' },
    { "value": 'toilet' },
    { "value": 'toilet-paper' },
    { "value": 'toilet-paper-alt' },
    { "value": 'toilet-paper-slash' },
    { "value": 'tombstone' },
    { "value": 'tombstone-alt' },
    { "value": 'toolbox' },
    { "value": 'tools' },
    { "value": 'tooth' },
    { "value": 'toothbrush' },
    { "value": 'torah' },
    { "value": 'torii-gate' },
    { "value": 'tornado' },
    { "value": 'tractor' },
    { "value": 'trademark' },
    { "value": 'traffic-cone' },
    { "value": 'traffic-light' },
    { "value": 'traffic-light-go' },
    { "value": 'traffic-light-slow' },
    { "value": 'traffic-light-stop' },
    { "value": 'trailer' },
    { "value": 'train' },
    { "value": 'tram' },
    { "value": 'transgender' },
    { "value": 'transgender-alt' },
    { "value": 'transporter' },
    { "value": 'transporter-1' },
    { "value": 'transporter-2' },
    { "value": 'transporter-3' },
    { "value": 'transporter-empty' },
    { "value": 'trash' },
    { "value": 'trash-alt' },
    { "value": 'trash-restore' },
    { "value": 'trash-restore-alt' },
    { "value": 'trash-undo' },
    { "value": 'trash-undo-alt' },
    { "value": 'treasure-chest' },
    { "value": 'tree' },
    { "value": 'tree-alt' },
    { "value": 'tree-christmas' },
    { "value": 'tree-decorated' },
    { "value": 'tree-large' },
    { "value": 'tree-palm' },
    { "value": 'trees' },
    { "value": 'triangle' },
    { "value": 'triangle-music' },
    { "value": 'trophy' },
    { "value": 'trophy-alt' },
    { "value": 'truck' },
    { "value": 'truck-container' },
    { "value": 'truck-couch' },
    { "value": 'truck-loading' },
    { "value": 'truck-monster' },
    { "value": 'truck-moving' },
    { "value": 'truck-pickup' },
    { "value": 'truck-plow' },
    { "value": 'truck-ramp' },
    { "value": 'trumpet' },
    { "value": 'tshirt' },
    { "value": 'tty' },
    { "value": 'turkey' },
    { "value": 'turntable' },
    { "value": 'turtle' },
    { "value": 'tv' },
    { "value": 'tv-alt' },
    { "value": 'tv-music' },
    { "value": 'tv-retro' },
    { "value": 'typewriter' },
    { "value": 'ufo' },
    { "value": 'ufo-beam' },
    { "value": 'umbrella' },
    { "value": 'umbrella-beach' },
    { "value": 'underline' },
    { "value": 'undo' },
    { "value": 'undo-alt' },
    { "value": 'unicorn' },
    { "value": 'union' },
    { "value": 'universal-access' },
    { "value": 'university' },
    { "value": 'unlink' },
    { "value": 'unlock' },
    { "value": 'unlock-alt' },
    { "value": 'upload' },
    { "value": 'usb-drive' },
    { "value": 'usd-circle' },
    { "value": 'usd-square' },
    { "value": 'user' },
    { "value": 'user-alien' },
    { "value": 'user-alt' },
    { "value": 'user-alt-slash' },
    { "value": 'user-astronaut' },
    { "value": 'user-chart' },
    { "value": 'user-check' },
    { "value": 'user-circle' },
    { "value": 'user-clock' },
    { "value": 'user-cog' },
    { "value": 'user-cowboy' },
    { "value": 'user-crown' },
    { "value": 'user-edit' },
    { "value": 'user-friends' },
    { "value": 'user-graduate' },
    { "value": 'user-hard-hat' },
    { "value": 'user-headset' },
    { "value": 'user-injured' },
    { "value": 'user-lock' },
    { "value": 'user-md' },
    { "value": 'user-md-chat' },
    { "value": 'user-minus' },
    { "value": 'user-music' },
    { "value": 'user-ninja' },
    { "value": 'user-nurse' },
    { "value": 'user-plus' },
    { "value": 'user-robot' },
    { "value": 'user-secret' },
    { "value": 'user-shield' },
    { "value": 'user-slash' },
    { "value": 'user-tag' },
    { "value": 'user-tie' },
    { "value": 'user-times' },
    { "value": 'user-unlock' },
    { "value": 'user-visor' },
    { "value": 'users' },
    { "value": 'users-class' },
    { "value": 'users-cog' },
    { "value": 'users-crown' },
    { "value": 'users-medical' },
    { "value": 'users-slash' },
    { "value": 'utensil-fork' },
    { "value": 'utensil-knife' },
    { "value": 'utensil-spoon' },
    { "value": 'utensils' },
    { "value": 'utensils-alt' },
    { "value": 'vacuum' },
    { "value": 'vacuum-robot' },
    { "value": 'value-absolute' },
    { "value": 'vector-square' },
    { "value": 'venus' },
    { "value": 'venus-double' },
    { "value": 'venus-mars' },
    { "value": 'vest' },
    { "value": 'vest-patches' },
    { "value": 'vhs' },
    { "value": 'vial' },
    { "value": 'vials' },
    { "value": 'video' },
    { "value": 'video-plus' },
    { "value": 'video-slash' },
    { "value": 'vihara' },
    { "value": 'violin' },
    { "value": 'virus' },
    { "value": 'virus-slash' },
    { "value": 'viruses' },
    { "value": 'voicemail' },
    { "value": 'volcano' },
    { "value": 'volleyball-ball' },
    { "value": 'volume' },
    { "value": 'volume-down' },
    { "value": 'volume-mute' },
    { "value": 'volume-off' },
    { "value": 'volume-slash' },
    { "value": 'volume-up' },
    { "value": 'vote-nay' },
    { "value": 'vote-yea' },
    { "value": 'vr-cardboard' },
    { "value": 'wagon-covered' },
    { "value": 'walker' },
    { "value": 'walkie-talkie' },
    { "value": 'walking' },
    { "value": 'wallet' },
    { "value": 'wand' },
    { "value": 'wand-magic' },
    { "value": 'warehouse' },
    { "value": 'warehouse-alt' },
    { "value": 'washer' },
    { "value": 'watch' },
    { "value": 'watch-calculator' },
    { "value": 'watch-fitness' },
    { "value": 'water' },
    { "value": 'water-lower' },
    { "value": 'water-rise' },
    { "value": 'wave-sine' },
    { "value": 'wave-square' },
    { "value": 'wave-triangle' },
    { "value": 'waveform' },
    { "value": 'waveform-path' },
    { "value": 'webcam' },
    { "value": 'webcam-slash' },
    { "value": 'weight' },
    { "value": 'weight-hanging' },
    { "value": 'whale' },
    { "value": 'wheat' },
    { "value": 'wheelchair' },
    { "value": 'whistle' },
    { "value": 'wifi' },
    { "value": 'wifi-1' },
    { "value": 'wifi-2' },
    { "value": 'wifi-slash' },
    { "value": 'wind' },
    { "value": 'wind-turbine' },
    { "value": 'wind-warning' },
    { "value": 'window' },
    { "value": 'window-alt' },
    { "value": 'window-close' },
    { "value": 'window-frame' },
    { "value": 'window-frame-open' },
    { "value": 'window-maximize' },
    { "value": 'window-minimize' },
    { "value": 'window-restore' },
    { "value": 'windsock' },
    { "value": 'wine-bottle' },
    { "value": 'wine-glass' },
    { "value": 'wine-glass-alt' },
    { "value": 'won-sign' },
    { "value": 'wreath' },
    { "value": 'wrench' },
    { "value": 'x-ray' },
    { "value": 'yen-sign' },
    { "value": 'yin-yang' },
    { "value": 'abacus' },
    { "value": 'acorn' },
    { "value": 'ad' },
    { "value": 'address-book' },
    { "value": 'address-card' },
    { "value": 'adjust' },
    { "value": 'air-conditioner' },
    { "value": 'air-freshener' },
    { "value": 'alarm-clock' },
    { "value": 'alarm-exclamation' },
    { "value": 'alarm-plus' },
    { "value": 'alarm-snooze' },
    { "value": 'album' },
    { "value": 'album-collection' },
    { "value": 'alicorn' },
    { "value": 'alien' },
    { "value": 'alien-monster' },
    { "value": 'align-center' },
    { "value": 'align-justify' },
    { "value": 'align-left' },
    { "value": 'align-right' },
    { "value": 'align-slash' },
    { "value": 'allergies' },
    { "value": 'ambulance' },
    { "value": 'american-sign-language-interpreting' },
    { "value": 'amp-guitar' },
    { "value": 'analytics' },
    { "value": 'anchor' },
    { "value": 'angel' },
    { "value": 'angle-double-down' },
    { "value": 'angle-double-left' },
    { "value": 'angle-double-right' },
    { "value": 'angle-double-up' },
    { "value": 'angle-down' },
    { "value": 'angle-left' },
    { "value": 'angle-right' },
    { "value": 'angle-up' },
    { "value": 'angry' },
    { "value": 'ankh' },
    { "value": 'apple-alt' },
    { "value": 'apple-crate' },
    { "value": 'archive' },
    { "value": 'archway' },
    { "value": 'arrow-alt-circle-down' },
    { "value": 'arrow-alt-circle-left' },
    { "value": 'arrow-alt-circle-right' },
    { "value": 'arrow-alt-circle-up' },
    { "value": 'arrow-alt-down' },
    { "value": 'arrow-alt-from-bottom' },
    { "value": 'arrow-alt-from-left' },
    { "value": 'arrow-alt-from-right' },
    { "value": 'arrow-alt-from-top' },
    { "value": 'arrow-alt-left' },
    { "value": 'arrow-alt-right' },
    { "value": 'arrow-alt-square-down' },
    { "value": 'arrow-alt-square-left' },
    { "value": 'arrow-alt-square-right' },
    { "value": 'arrow-alt-square-up' },
    { "value": 'arrow-alt-to-bottom' },
    { "value": 'arrow-alt-to-left' },
    { "value": 'arrow-alt-to-right' },
    { "value": 'arrow-alt-to-top' },
    { "value": 'arrow-alt-up' },
    { "value": 'arrow-circle-down' },
    { "value": 'arrow-circle-left' },
    { "value": 'arrow-circle-right' },
    { "value": 'arrow-circle-up' },
    { "value": 'arrow-down' },
    { "value": 'arrow-from-bottom' },
    { "value": 'arrow-from-left' },
    { "value": 'arrow-from-right' },
    { "value": 'arrow-from-top' },
    { "value": 'arrow-left' },
    { "value": 'arrow-right' },
    { "value": 'arrow-square-down' },
    { "value": 'arrow-square-left' },
    { "value": 'arrow-square-right' },
    { "value": 'arrow-square-up' },
    { "value": 'arrow-to-bottom' },
    { "value": 'arrow-to-left' },
    { "value": 'arrow-to-right' },
    { "value": 'arrow-to-top' },
    { "value": 'arrow-up' },
    { "value": 'arrows' },
    { "value": 'arrows-alt' },
    { "value": 'arrows-alt-h' },
    { "value": 'arrows-alt-v' },
    { "value": 'arrows-h' },
    { "value": 'arrows-v' },
    { "value": 'assistive-listening-systems' },
    { "value": 'asterisk' },
    { "value": 'at' },
    { "value": 'atlas' },
    { "value": 'atom' },
    { "value": 'atom-alt' },
    { "value": 'audio-description' },
    { "value": 'award' },
    { "value": 'axe' },
    { "value": 'axe-battle' },
    { "value": 'baby' },
    { "value": 'baby-carriage' },
    { "value": 'backpack' },
    { "value": 'backspace' },
    { "value": 'backward' },
    { "value": 'bacon' },
    { "value": 'bacteria' },
    { "value": 'bacterium' },
    { "value": 'badge' },
    { "value": 'badge-check' },
    { "value": 'badge-dollar' },
    { "value": 'badge-percent' },
    { "value": 'badge-sheriff' },
    { "value": 'badger-honey' },
    { "value": 'bags-shopping' },
    { "value": 'bahai' },
    { "value": 'balance-scale' },
    { "value": 'balance-scale-left' },
    { "value": 'balance-scale-right' },
    { "value": 'ball-pile' },
    { "value": 'ballot' },
    { "value": 'ballot-check' },
    { "value": 'ban' },
    { "value": 'band-aid' },
    { "value": 'banjo' },
    { "value": 'barcode' },
    { "value": 'barcode-alt' },
    { "value": 'barcode-read' },
    { "value": 'barcode-scan' },
    { "value": 'bars' },
    { "value": 'baseball' },
    { "value": 'baseball-ball' },
    { "value": 'basketball-ball' },
    { "value": 'basketball-hoop' },
    { "value": 'bat' },
    { "value": 'bath' },
    { "value": 'battery-bolt' },
    { "value": 'battery-empty' },
    { "value": 'battery-full' },
    { "value": 'battery-half' },
    { "value": 'battery-quarter' },
    { "value": 'battery-slash' },
    { "value": 'battery-three-quarters' },
    { "value": 'bed' },
    { "value": 'bed-alt' },
    { "value": 'bed-bunk' },
    { "value": 'bed-empty' },
    { "value": 'beer' },
    { "value": 'bell' },
    { "value": 'bell-exclamation' },
    { "value": 'bell-on' },
    { "value": 'bell-plus' },
    { "value": 'bell-school' },
    { "value": 'bell-school-slash' },
    { "value": 'bell-slash' },
    { "value": 'bells' },
    { "value": 'betamax' },
    { "value": 'bezier-curve' },
    { "value": 'bible' },
    { "value": 'bicycle' },
    { "value": 'biking' },
    { "value": 'biking-mountain' },
    { "value": 'binoculars' },
    { "value": 'biohazard' },
    { "value": 'birthday-cake' },
    { "value": 'blanket' },
    { "value": 'blender' },
    { "value": 'blender-phone' },
    { "value": 'blind' },
    { "value": 'blinds' },
    { "value": 'blinds-open' },
    { "value": 'blinds-raised' },
    { "value": 'blog' },
    { "value": 'bold' },
    { "value": 'bolt' },
    { "value": 'bomb' },
    { "value": 'bone' },
    { "value": 'bone-break' },
    { "value": 'bong' },
    { "value": 'book' },
    { "value": 'book-alt' },
    { "value": 'book-dead' },
    { "value": 'book-heart' },
    { "value": 'book-medical' },
    { "value": 'book-open' },
    { "value": 'book-reader' },
    { "value": 'book-spells' },
    { "value": 'book-user' },
    { "value": 'bookmark' },
    { "value": 'books' },
    { "value": 'books-medical' },
    { "value": 'boombox' },
    { "value": 'boot' },
    { "value": 'booth-curtain' },
    { "value": 'border-all' },
    { "value": 'border-bottom' },
    { "value": 'border-center-h' },
    { "value": 'border-center-v' },
    { "value": 'border-inner' },
    { "value": 'border-left' },
    { "value": 'border-none' },
    { "value": 'border-outer' },
    { "value": 'border-right' },
    { "value": 'border-style' },
    { "value": 'border-style-alt' },
    { "value": 'border-top' },
    { "value": 'bow-arrow' },
    { "value": 'bowling-ball' },
    { "value": 'bowling-pins' },
    { "value": 'box' },
    { "value": 'box-alt' },
    { "value": 'box-ballot' },
    { "value": 'box-check' },
    { "value": 'box-fragile' },
    { "value": 'box-full' },
    { "value": 'box-heart' },
    { "value": 'box-open' },
    { "value": 'box-tissue' },
    { "value": 'box-up' },
    { "value": 'box-usd' },
    { "value": 'boxes' },
    { "value": 'boxes-alt' },
    { "value": 'boxing-glove' },
    { "value": 'brackets' },
    { "value": 'brackets-curly' },
    { "value": 'braille' },
    { "value": 'brain' },
    { "value": 'bread-loaf' },
    { "value": 'bread-slice' },
    { "value": 'briefcase' },
    { "value": 'briefcase-medical' },
    { "value": 'bring-forward' },
    { "value": 'bring-front' },
    { "value": 'broadcast-tower' },
    { "value": 'broom' },
    { "value": 'browser' },
    { "value": 'brush' },
    { "value": 'bug' },
    { "value": 'building' },
    { "value": 'bullhorn' },
    { "value": 'bullseye' },
    { "value": 'bullseye-arrow' },
    { "value": 'bullseye-pointer' },
    { "value": 'burger-soda' },
    { "value": 'burn' },
    { "value": 'burrito' },
    { "value": 'bus' },
    { "value": 'bus-alt' },
    { "value": 'bus-school' },
    { "value": 'business-time' },
    { "value": 'cabinet-filing' },
    { "value": 'cactus' },
    { "value": 'calculator' },
    { "value": 'calculator-alt' },
    { "value": 'calendar' },
    { "value": 'calendar-alt' },
    { "value": 'calendar-check' },
    { "value": 'calendar-day' },
    { "value": 'calendar-edit' },
    { "value": 'calendar-exclamation' },
    { "value": 'calendar-minus' },
    { "value": 'calendar-plus' },
    { "value": 'calendar-star' },
    { "value": 'calendar-times' },
    { "value": 'calendar-week' },
    { "value": 'camcorder' },
    { "value": 'camera' },
    { "value": 'camera-alt' },
    { "value": 'camera-home' },
    { "value": 'camera-movie' },
    { "value": 'camera-polaroid' },
    { "value": 'camera-retro' },
    { "value": 'campfire' },
    { "value": 'campground' },
    { "value": 'candle-holder' },
    { "value": 'candy-cane' },
    { "value": 'candy-corn' },
    { "value": 'cannabis' },
    { "value": 'capsules' },
    { "value": 'car' },
    { "value": 'car-alt' },
    { "value": 'car-battery' },
    { "value": 'car-building' },
    { "value": 'car-bump' },
    { "value": 'car-bus' },
    { "value": 'car-crash' },
    { "value": 'car-garage' },
    { "value": 'car-mechanic' },
    { "value": 'car-side' },
    { "value": 'car-tilt' },
    { "value": 'car-wash' },
    { "value": 'caravan' },
    { "value": 'caravan-alt' },
    { "value": 'caret-circle-down' },
    { "value": 'caret-circle-left' },
    { "value": 'caret-circle-right' },
    { "value": 'caret-circle-up' },
    { "value": 'caret-down' },
    { "value": 'caret-left' },
    { "value": 'caret-right' },
    { "value": 'caret-square-down' },
    { "value": 'caret-square-left' },
    { "value": 'caret-square-right' },
    { "value": 'caret-square-up' },
    { "value": 'caret-up' },
    { "value": 'carrot' },
    { "value": 'cars' },
    { "value": 'cart-arrow-down' },
    { "value": 'cart-plus' },
    { "value": 'cash-register' },
    { "value": 'cassette-tape' },
    { "value": 'cat' },
    { "value": 'cat-space' },
    { "value": 'cauldron' },
    { "value": 'cctv' },
    { "value": 'certificate' },
    { "value": 'chair' },
    { "value": 'chair-office' },
    { "value": 'chalkboard' },
    { "value": 'chalkboard-teacher' },
    { "value": 'charging-station' },
    { "value": 'chart-area' },
    { "value": 'chart-bar' },
    { "value": 'chart-line' },
    { "value": 'chart-line-down' },
    { "value": 'chart-network' },
    { "value": 'chart-pie' },
    { "value": 'chart-pie-alt' },
    { "value": 'chart-scatter' },
    { "value": 'check' },
    { "value": 'check-circle' },
    { "value": 'check-double' },
    { "value": 'check-square' },
    { "value": 'cheese' },
    { "value": 'cheese-swiss' },
    { "value": 'cheeseburger' },
    { "value": 'chess' },
    { "value": 'chess-bishop' },
    { "value": 'chess-bishop-alt' },
    { "value": 'chess-board' },
    { "value": 'chess-clock' },
    { "value": 'chess-clock-alt' },
    { "value": 'chess-king' },
    { "value": 'chess-king-alt' },
    { "value": 'chess-knight' },
    { "value": 'chess-knight-alt' },
    { "value": 'chess-pawn' },
    { "value": 'chess-pawn-alt' },
    { "value": 'chess-queen' },
    { "value": 'chess-queen-alt' },
    { "value": 'chess-rook' },
    { "value": 'chess-rook-alt' },
    { "value": 'chevron-circle-down' },
    { "value": 'chevron-circle-left' },
    { "value": 'chevron-circle-right' },
    { "value": 'chevron-circle-up' },
    { "value": 'chevron-double-down' },
    { "value": 'chevron-double-left' },
    { "value": 'chevron-double-right' },
    { "value": 'chevron-double-up' },
    { "value": 'chevron-down' },
    { "value": 'chevron-left' },
    { "value": 'chevron-right' },
    { "value": 'chevron-square-down' },
    { "value": 'chevron-square-left' },
    { "value": 'chevron-square-right' },
    { "value": 'chevron-square-up' },
    { "value": 'chevron-up' },
    { "value": 'child' },
    { "value": 'chimney' },
    { "value": 'church' },
    { "value": 'circle' },
    { "value": 'circle-notch' },
    { "value": 'city' },
    { "value": 'clarinet' },
    { "value": 'claw-marks' },
    { "value": 'clinic-medical' },
    { "value": 'clipboard' },
    { "value": 'clipboard-check' },
    { "value": 'clipboard-list' },
    { "value": 'clipboard-list-check' },
    { "value": 'clipboard-prescription' },
    { "value": 'clipboard-user' },
    { "value": 'clock' },
    { "value": 'clone' },
    { "value": 'closed-captioning' },
    { "value": 'cloud' },
    { "value": 'cloud-download' },
    { "value": 'cloud-download-alt' },
    { "value": 'cloud-drizzle' },
    { "value": 'cloud-hail' },
    { "value": 'cloud-hail-mixed' },
    { "value": 'cloud-meatball' },
    { "value": 'cloud-moon' },
    { "value": 'cloud-moon-rain' },
    { "value": 'cloud-music' },
    { "value": 'cloud-rain' },
    { "value": 'cloud-rainbow' },
    { "value": 'cloud-showers' },
    { "value": 'cloud-showers-heavy' },
    { "value": 'cloud-sleet' },
    { "value": 'cloud-snow' },
    { "value": 'cloud-sun' },
    { "value": 'cloud-sun-rain' },
    { "value": 'cloud-upload' },
    { "value": 'cloud-upload-alt' },
    { "value": 'clouds' },
    { "value": 'clouds-moon' },
    { "value": 'clouds-sun' },
    { "value": 'club' },
    { "value": 'cocktail' },
    { "value": 'code' },
    { "value": 'code-branch' },
    { "value": 'code-commit' },
    { "value": 'code-merge' },
    { "value": 'coffee' },
    { "value": 'coffee-pot' },
    { "value": 'coffee-togo' },
    { "value": 'coffin' },
    { "value": 'coffin-cross' },
    { "value": 'cog' },
    { "value": 'cogs' },
    { "value": 'coin' },
    { "value": 'coins' },
    { "value": 'columns' },
    { "value": 'comet' },
    { "value": 'comment' },
    { "value": 'comment-alt' },
    { "value": 'comment-alt-check' },
    { "value": 'comment-alt-dollar' },
    { "value": 'comment-alt-dots' },
    { "value": 'comment-alt-edit' },
    { "value": 'comment-alt-exclamation' },
    { "value": 'comment-alt-lines' },
    { "value": 'comment-alt-medical' },
    { "value": 'comment-alt-minus' },
    { "value": 'comment-alt-music' },
    { "value": 'comment-alt-plus' },
    { "value": 'comment-alt-slash' },
    { "value": 'comment-alt-smile' },
    { "value": 'comment-alt-times' },
    { "value": 'comment-check' },
    { "value": 'comment-dollar' },
    { "value": 'comment-dots' },
    { "value": 'comment-edit' },
    { "value": 'comment-exclamation' },
    { "value": 'comment-lines' },
    { "value": 'comment-medical' },
    { "value": 'comment-minus' },
    { "value": 'comment-music' },
    { "value": 'comment-plus' },
    { "value": 'comment-slash' },
    { "value": 'comment-smile' },
    { "value": 'comment-times' },
    { "value": 'comments' },
    { "value": 'comments-alt' },
    { "value": 'comments-alt-dollar' },
    { "value": 'comments-dollar' },
    { "value": 'compact-disc' },
    { "value": 'compass' },
    { "value": 'compass-slash' },
    { "value": 'compress' },
    { "value": 'compress-alt' },
    { "value": 'compress-arrows-alt' },
    { "value": 'compress-wide' },
    { "value": 'computer-classic' },
    { "value": 'computer-speaker' },
    { "value": 'concierge-bell' },
    { "value": 'construction' },
    { "value": 'container-storage' },
    { "value": 'conveyor-belt' },
    { "value": 'conveyor-belt-alt' },
    { "value": 'cookie' },
    { "value": 'cookie-bite' },
    { "value": 'copy' },
    { "value": 'copyright' },
    { "value": 'corn' },
    { "value": 'couch' },
    { "value": 'cow' },
    { "value": 'cowbell' },
    { "value": 'cowbell-more' },
    { "value": 'credit-card' },
    { "value": 'credit-card-blank' },
    { "value": 'credit-card-front' },
    { "value": 'cricket' },
    { "value": 'croissant' },
    { "value": 'crop' },
    { "value": 'crop-alt' },
    { "value": 'cross' },
    { "value": 'crosshairs' },
    { "value": 'crow' },
    { "value": 'crown' },
    { "value": 'crutch' },
    { "value": 'crutches' },
    { "value": 'cube' },
    { "value": 'cubes' },
    { "value": 'curling' },
    { "value": 'cut' },
    { "value": 'dagger' },
    { "value": 'database' },
    { "value": 'deaf' },
    { "value": 'debug' },
    { "value": 'deer' },
    { "value": 'deer-rudolph' },
    { "value": 'democrat' },
    { "value": 'desktop' },
    { "value": 'desktop-alt' },
    { "value": 'dewpoint' },
    { "value": 'dharmachakra' },
    { "value": 'diagnoses' },
    { "value": 'diamond' },
    { "value": 'dice' },
    { "value": 'dice-d10' },
    { "value": 'dice-d12' },
    { "value": 'dice-d20' },
    { "value": 'dice-d4' },
    { "value": 'dice-d6' },
    { "value": 'dice-d8' },
    { "value": 'dice-five' },
    { "value": 'dice-four' },
    { "value": 'dice-one' },
    { "value": 'dice-six' },
    { "value": 'dice-three' },
    { "value": 'dice-two' },
    { "value": 'digging' },
    { "value": 'digital-tachograph' },
    { "value": 'diploma' },
    { "value": 'directions' },
    { "value": 'disc-drive' },
    { "value": 'disease' },
    { "value": 'divide' },
    { "value": 'dizzy' },
    { "value": 'dna' },
    { "value": 'do-not-enter' },
    { "value": 'dog' },
    { "value": 'dog-leashed' },
    { "value": 'dollar-sign' },
    { "value": 'dolly' },
    { "value": 'dolly-empty' },
    { "value": 'dolly-flatbed' },
    { "value": 'dolly-flatbed-alt' },
    { "value": 'dolly-flatbed-empty' },
    { "value": 'donate' },
    { "value": 'door-closed' },
    { "value": 'door-open' },
    { "value": 'dot-circle' },
    { "value": 'dove' },
    { "value": 'download' },
    { "value": 'drafting-compass' },
    { "value": 'dragon' },
    { "value": 'draw-circle' },
    { "value": 'draw-polygon' },
    { "value": 'draw-square' },
    { "value": 'dreidel' },
    { "value": 'drone' },
    { "value": 'drone-alt' },
    { "value": 'drum' },
    { "value": 'drum-steelpan' },
    { "value": 'drumstick' },
    { "value": 'drumstick-bite' },
    { "value": 'dryer' },
    { "value": 'dryer-alt' },
    { "value": 'duck' },
    { "value": 'dumbbell' },
    { "value": 'dumpster' },
    { "value": 'dumpster-fire' },
    { "value": 'dungeon' },
    { "value": 'ear' },
    { "value": 'ear-muffs' },
    { "value": 'eclipse' },
    { "value": 'eclipse-alt' },
    { "value": 'edit' },
    { "value": 'egg' },
    { "value": 'egg-fried' },
    { "value": 'eject' },
    { "value": 'elephant' },
    { "value": 'ellipsis-h' },
    { "value": 'ellipsis-h-alt' },
    { "value": 'ellipsis-v' },
    { "value": 'ellipsis-v-alt' },
    { "value": 'empty-set' },
    { "value": 'engine-warning' },
    { "value": 'envelope' },
    { "value": 'envelope-open' },
    { "value": 'envelope-open-dollar' },
    { "value": 'envelope-open-text' },
    { "value": 'envelope-square' },
    { "value": 'equals' },
    { "value": 'eraser' },
    { "value": 'ethernet' },
    { "value": 'euro-sign' },
    { "value": 'exchange' },
    { "value": 'exchange-alt' },
    { "value": 'exclamation' },
    { "value": 'exclamation-circle' },
    { "value": 'exclamation-square' },
    { "value": 'exclamation-triangle' },
    { "value": 'expand' },
    { "value": 'expand-alt' },
    { "value": 'expand-arrows' },
    { "value": 'expand-arrows-alt' },
    { "value": 'expand-wide' },
    { "value": 'external-link' },
    { "value": 'external-link-alt' },
    { "value": 'external-link-square' },
    { "value": 'external-link-square-alt' },
    { "value": 'eye' },
    { "value": 'eye-dropper' },
    { "value": 'eye-evil' },
    { "value": 'eye-slash' },
    { "value": 'fan' },
    { "value": 'fan-table' },
    { "value": 'farm' },
    { "value": 'fast-backward' },
    { "value": 'fast-forward' },
    { "value": 'faucet' },
    { "value": 'faucet-drip' },
    { "value": 'fax' },
    { "value": 'feather' },
    { "value": 'feather-alt' },
    { "value": 'female' },
    { "value": 'field-hockey' },
    { "value": 'fighter-jet' },
    { "value": 'file' },
    { "value": 'file-alt' },
    { "value": 'file-archive' },
    { "value": 'file-audio' },
    { "value": 'file-certificate' },
    { "value": 'file-chart-line' },
    { "value": 'file-chart-pie' },
    { "value": 'file-check' },
    { "value": 'file-code' },
    { "value": 'file-contract' },
    { "value": 'file-csv' },
    { "value": 'file-download' },
    { "value": 'file-edit' },
    { "value": 'file-excel' },
    { "value": 'file-exclamation' },
    { "value": 'file-export' },
    { "value": 'file-image' },
    { "value": 'file-import' },
    { "value": 'file-invoice' },
    { "value": 'file-invoice-dollar' },
    { "value": 'file-medical' },
    { "value": 'file-medical-alt' },
    { "value": 'file-minus' },
    { "value": 'file-music' },
    { "value": 'file-pdf' },
    { "value": 'file-plus' },
    { "value": 'file-powerpoint' },
    { "value": 'file-prescription' },
    { "value": 'file-search' },
    { "value": 'file-signature' },
    { "value": 'file-spreadsheet' },
    { "value": 'file-times' },
    { "value": 'file-upload' },
    { "value": 'file-user' },
    { "value": 'file-video' },
    { "value": 'file-word' },
    { "value": 'files-medical' },
    { "value": 'fill' },
    { "value": 'fill-drip' },
    { "value": 'film' },
    { "value": 'film-alt' },
    { "value": 'film-canister' },
    { "value": 'filter' },
    { "value": 'fingerprint' },
    { "value": 'fire' },
    { "value": 'fire-alt' },
    { "value": 'fire-extinguisher' },
    { "value": 'fire-smoke' },
    { "value": 'fireplace' },
    { "value": 'first-aid' },
    { "value": 'fish' },
    { "value": 'fish-cooked' },
    { "value": 'fist-raised' },
    { "value": 'flag' },
    { "value": 'flag-alt' },
    { "value": 'flag-checkered' },
    { "value": 'flag-usa' },
    { "value": 'flame' },
    { "value": 'flashlight' },
    { "value": 'flask' },
    { "value": 'flask-poison' },
    { "value": 'flask-potion' },
    { "value": 'flower' },
    { "value": 'flower-daffodil' },
    { "value": 'flower-tulip' },
    { "value": 'flushed' },
    { "value": 'flute' },
    { "value": 'flux-capacitor' },
    { "value": 'fog' },
    { "value": 'folder' },
    { "value": 'folder-download' },
    { "value": 'folder-minus' },
    { "value": 'folder-open' },
    { "value": 'folder-plus' },
    { "value": 'folder-times' },
    { "value": 'folder-tree' },
    { "value": 'folder-upload' },
    { "value": 'folders' },
    { "value": 'font' },
    { "value": 'font-awesome-logo-full' },
    { "value": 'font-case' },
    { "value": 'football-ball' },
    { "value": 'football-helmet' },
    { "value": 'forklift' },
    { "value": 'forward' },
    { "value": 'fragile' },
    { "value": 'french-fries' },
    { "value": 'frog' },
    { "value": 'frosty-head' },
    { "value": 'frown' },
    { "value": 'frown-open' },
    { "value": 'function' },
    { "value": 'funnel-dollar' },
    { "value": 'futbol' },
    { "value": 'galaxy' },
    { "value": 'game-board' },
    { "value": 'game-board-alt' },
    { "value": 'game-console-handheld' },
    { "value": 'gamepad' },
    { "value": 'gamepad-alt' },
    { "value": 'garage' },
    { "value": 'garage-car' },
    { "value": 'garage-open' },
    { "value": 'gas-pump' },
    { "value": 'gas-pump-slash' },
    { "value": 'gavel' },
    { "value": 'gem' },
    { "value": 'genderless' },
    { "value": 'ghost' },
    { "value": 'gift' },
    { "value": 'gift-card' },
    { "value": 'gifts' },
    { "value": 'gingerbread-man' },
    { "value": 'glass' },
    { "value": 'glass-champagne' },
    { "value": 'glass-cheers' },
    { "value": 'glass-citrus' },
    { "value": 'glass-martini' },
    { "value": 'glass-martini-alt' },
    { "value": 'glass-whiskey' },
    { "value": 'glass-whiskey-rocks' },
    { "value": 'glasses' },
    { "value": 'glasses-alt' },
    { "value": 'globe' },
    { "value": 'globe-africa' },
    { "value": 'globe-americas' },
    { "value": 'globe-asia' },
    { "value": 'globe-europe' },
    { "value": 'globe-snow' },
    { "value": 'globe-stand' },
    { "value": 'golf-ball' },
    { "value": 'golf-club' },
    { "value": 'gopuram' },
    { "value": 'graduation-cap' },
    { "value": 'gramophone' },
    { "value": 'greater-than' },
    { "value": 'greater-than-equal' },
    { "value": 'grimace' },
    { "value": 'grin' },
    { "value": 'grin-alt' },
    { "value": 'grin-beam' },
    { "value": 'grin-beam-sweat' },
    { "value": 'grin-hearts' },
    { "value": 'grin-squint' },
    { "value": 'grin-squint-tears' },
    { "value": 'grin-stars' },
    { "value": 'grin-tears' },
    { "value": 'grin-tongue' },
    { "value": 'grin-tongue-squint' },
    { "value": 'grin-tongue-wink' },
    { "value": 'grin-wink' },
    { "value": 'grip-horizontal' },
    { "value": 'grip-lines' },
    { "value": 'grip-lines-vertical' },
    { "value": 'grip-vertical' },
    { "value": 'guitar' },
    { "value": 'guitar-electric' },
    { "value": 'guitars' },
    { "value": 'h-square' },
    { "value": 'h1' },
    { "value": 'h2' },
    { "value": 'h3' },
    { "value": 'h4' },
    { "value": 'hamburger' },
    { "value": 'hammer' },
    { "value": 'hammer-war' },
    { "value": 'hamsa' },
    { "value": 'hand-heart' },
    { "value": 'hand-holding' },
    { "value": 'hand-holding-box' },
    { "value": 'hand-holding-heart' },
    { "value": 'hand-holding-magic' },
    { "value": 'hand-holding-medical' },
    { "value": 'hand-holding-seedling' },
    { "value": 'hand-holding-usd' },
    { "value": 'hand-holding-water' },
    { "value": 'hand-lizard' },
    { "value": 'hand-middle-finger' },
    { "value": 'hand-paper' },
    { "value": 'hand-peace' },
    { "value": 'hand-point-down' },
    { "value": 'hand-point-left' },
    { "value": 'hand-point-right' },
    { "value": 'hand-point-up' },
    { "value": 'hand-pointer' },
    { "value": 'hand-receiving' },
    { "value": 'hand-rock' },
    { "value": 'hand-scissors' },
    { "value": 'hand-sparkles' },
    { "value": 'hand-spock' },
    { "value": 'hands' },
    { "value": 'hands-heart' },
    { "value": 'hands-helping' },
    { "value": 'hands-usd' },
    { "value": 'hands-wash' },
    { "value": 'handshake' },
    { "value": 'handshake-alt' },
    { "value": 'handshake-alt-slash' },
    { "value": 'handshake-slash' },
    { "value": 'hanukiah' },
    { "value": 'hard-hat' },
    { "value": 'hashtag' },
    { "value": 'hat-chef' },
    { "value": 'hat-cowboy' },
    { "value": 'hat-cowboy-side' },
    { "value": 'hat-santa' },
    { "value": 'hat-winter' },
    { "value": 'hat-witch' },
    { "value": 'hat-wizard' },
    { "value": 'hdd' },
    { "value": 'head-side' },
    { "value": 'head-side-brain' },
    { "value": 'head-side-cough' },
    { "value": 'head-side-cough-slash' },
    { "value": 'head-side-headphones' },
    { "value": 'head-side-mask' },
    { "value": 'head-side-medical' },
    { "value": 'head-side-virus' },
    { "value": 'head-vr' },
    { "value": 'heading' },
    { "value": 'headphones' },
    { "value": 'headphones-alt' },
    { "value": 'headset' },
    { "value": 'heart' },
    { "value": 'heart-broken' },
    { "value": 'heart-circle' },
    { "value": 'heart-rate' },
    { "value": 'heart-square' },
    { "value": 'heartbeat' },
    { "value": 'heat' },
    { "value": 'helicopter' },
    { "value": 'helmet-battle' },
    { "value": 'hexagon' },
    { "value": 'highlighter' },
    { "value": 'hiking' },
    { "value": 'hippo' },
    { "value": 'history' },
    { "value": 'hockey-mask' },
    { "value": 'hockey-puck' },
    { "value": 'hockey-sticks' },
    { "value": 'holly-berry' },
    { "value": 'home' },
    { "value": 'home-alt' },
    { "value": 'home-heart' },
    { "value": 'home-lg' },
    { "value": 'home-lg-alt' },
    { "value": 'hood-cloak' },
    { "value": 'horizontal-rule' },
    { "value": 'horse' },
    { "value": 'horse-head' },
    { "value": 'horse-saddle' },
    { "value": 'hospital' },
    { "value": 'hospital-alt' },
    { "value": 'hospital-symbol' },
    { "value": 'hospital-user' },
    { "value": 'hospitals' },
    { "value": 'hot-tub' },
    { "value": 'hotdog' },
    { "value": 'hotel' },
    { "value": 'hourglass' },
    { "value": 'hourglass-end' },
    { "value": 'hourglass-half' },
    { "value": 'hourglass-start' },
    { "value": 'house' },
    { "value": 'house-damage' },
    { "value": 'house-day' },
    { "value": 'house-flood' },
    { "value": 'house-leave' },
    { "value": 'house-night' },
    { "value": 'house-return' },
    { "value": 'house-signal' },
    { "value": 'house-user' },
    { "value": 'hryvnia' },
    { "value": 'humidity' },
    { "value": 'hurricane' },
    { "value": 'i-cursor' },
    { "value": 'ice-cream' },
    { "value": 'ice-skate' },
    { "value": 'icicles' },
    { "value": 'icons' },
    { "value": 'icons-alt' },
    { "value": 'id-badge' },
    { "value": 'id-card' },
    { "value": 'id-card-alt' },
    { "value": 'igloo' },
    { "value": 'image' },
    { "value": 'image-polaroid' },
    { "value": 'images' },
    { "value": 'inbox' },
    { "value": 'inbox-in' },
    { "value": 'inbox-out' },
    { "value": 'indent' },
    { "value": 'industry' },
    { "value": 'industry-alt' },
    { "value": 'infinity' },
    { "value": 'info' },
    { "value": 'info-circle' },
    { "value": 'info-square' },
    { "value": 'inhaler' },
    { "value": 'integral' },
    { "value": 'intersection' },
    { "value": 'inventory' },
    { "value": 'island-tropical' },
    { "value": 'italic' },
    { "value": 'jack-o-lantern' },
    { "value": 'jedi' },
    { "value": 'joint' },
    { "value": 'journal-whills' },
    { "value": 'joystick' },
    { "value": 'jug' },
    { "value": 'kaaba' },
    { "value": 'kazoo' },
    { "value": 'kerning' },
    { "value": 'key' },
    { "value": 'key-skeleton' },
    { "value": 'keyboard' },
    { "value": 'keynote' },
    { "value": 'khanda' },
    { "value": 'kidneys' },
    { "value": 'kiss' },
    { "value": 'kiss-beam' },
    { "value": 'kiss-wink-heart' },
    { "value": 'kite' },
    { "value": 'kiwi-bird' },
    { "value": 'knife-kitchen' },
    { "value": 'lambda' },
    { "value": 'lamp' },
    { "value": 'lamp-desk' },
    { "value": 'lamp-floor' },
    { "value": 'landmark' },
    { "value": 'landmark-alt' },
    { "value": 'language' },
    { "value": 'laptop' },
    { "value": 'laptop-code' },
    { "value": 'laptop-house' },
    { "value": 'laptop-medical' },
    { "value": 'lasso' },
    { "value": 'laugh' },
    { "value": 'laugh-beam' },
    { "value": 'laugh-squint' },
    { "value": 'laugh-wink' },
    { "value": 'layer-group' },
    { "value": 'layer-minus' },
    { "value": 'layer-plus' },
    { "value": 'leaf' },
    { "value": 'leaf-heart' },
    { "value": 'leaf-maple' },
    { "value": 'leaf-oak' },
    { "value": 'lemon' },
    { "value": 'less-than' },
    { "value": 'less-than-equal' },
    { "value": 'level-down' },
    { "value": 'level-down-alt' },
    { "value": 'level-up' },
    { "value": 'level-up-alt' },
    { "value": 'life-ring' },
    { "value": 'light-ceiling' },
    { "value": 'light-switch' },
    { "value": 'light-switch-off' },
    { "value": 'light-switch-on' },
    { "value": 'lightbulb' },
    { "value": 'lightbulb-dollar' },
    { "value": 'lightbulb-exclamation' },
    { "value": 'lightbulb-on' },
    { "value": 'lightbulb-slash' },
    { "value": 'lights-holiday' },
    { "value": 'line-columns' },
    { "value": 'line-height' },
    { "value": 'link' },
    { "value": 'lips' },
    { "value": 'lira-sign' },
    { "value": 'list' },
    { "value": 'list-alt' },
    { "value": 'list-music' },
    { "value": 'list-ol' },
    { "value": 'list-ul' },
    { "value": 'location' },
    { "value": 'location-arrow' },
    { "value": 'location-circle' },
    { "value": 'location-slash' },
    { "value": 'lock' },
    { "value": 'lock-alt' },
    { "value": 'lock-open' },
    { "value": 'lock-open-alt' },
    { "value": 'long-arrow-alt-down' },
    { "value": 'long-arrow-alt-left' },
    { "value": 'long-arrow-alt-right' },
    { "value": 'long-arrow-alt-up' },
    { "value": 'long-arrow-down' },
    { "value": 'long-arrow-left' },
    { "value": 'long-arrow-right' },
    { "value": 'long-arrow-up' },
    { "value": 'loveseat' },
    { "value": 'low-vision' },
    { "value": 'luchador' },
    { "value": 'luggage-cart' },
    { "value": 'lungs' },
    { "value": 'lungs-virus' },
    { "value": 'mace' },
    { "value": 'magic' },
    { "value": 'magnet' },
    { "value": 'mail-bulk' },
    { "value": 'mailbox' },
    { "value": 'male' },
    { "value": 'mandolin' },
    { "value": 'map' },
    { "value": 'map-marked' },
    { "value": 'map-marked-alt' },
    { "value": 'map-marker' },
    { "value": 'map-marker-alt' },
    { "value": 'map-marker-alt-slash' },
    { "value": 'map-marker-check' },
    { "value": 'map-marker-edit' },
    { "value": 'map-marker-exclamation' },
    { "value": 'map-marker-minus' },
    { "value": 'map-marker-plus' },
    { "value": 'map-marker-question' },
    { "value": 'map-marker-slash' },
    { "value": 'map-marker-smile' },
    { "value": 'map-marker-times' },
    { "value": 'map-pin' },
    { "value": 'map-signs' },
    { "value": 'marker' },
    { "value": 'mars' },
    { "value": 'mars-double' },
    { "value": 'mars-stroke' },
    { "value": 'mars-stroke-h' },
    { "value": 'mars-stroke-v' },
    { "value": 'mask' },
    { "value": 'meat' },
    { "value": 'medal' },
    { "value": 'medkit' },
    { "value": 'megaphone' },
    { "value": 'meh' },
    { "value": 'meh-blank' },
    { "value": 'meh-rolling-eyes' },
    { "value": 'memory' },
    { "value": 'menorah' },
    { "value": 'mercury' },
    { "value": 'meteor' },
    { "value": 'microchip' },
    { "value": 'microphone' },
    { "value": 'microphone-alt' },
    { "value": 'microphone-alt-slash' },
    { "value": 'microphone-slash' },
    { "value": 'microphone-stand' },
    { "value": 'microscope' },
    { "value": 'microwave' },
    { "value": 'mind-share' },
    { "value": 'minus' },
    { "value": 'minus-circle' },
    { "value": 'minus-hexagon' },
    { "value": 'minus-octagon' },
    { "value": 'minus-square' },
    { "value": 'mistletoe' },
    { "value": 'mitten' },
    { "value": 'mobile' },
    { "value": 'mobile-alt' },
    { "value": 'mobile-android' },
    { "value": 'mobile-android-alt' },
    { "value": 'money-bill' },
    { "value": 'money-bill-alt' },
    { "value": 'money-bill-wave' },
    { "value": 'money-bill-wave-alt' },
    { "value": 'money-check' },
    { "value": 'money-check-alt' },
    { "value": 'money-check-edit' },
    { "value": 'money-check-edit-alt' },
    { "value": 'monitor-heart-rate' },
    { "value": 'monkey' },
    { "value": 'monument' },
    { "value": 'moon' },
    { "value": 'moon-cloud' },
    { "value": 'moon-stars' },
    { "value": 'mortar-pestle' },
    { "value": 'mosque' },
    { "value": 'motorcycle' },
    { "value": 'mountain' },
    { "value": 'mountains' },
    { "value": 'mouse' },
    { "value": 'mouse-alt' },
    { "value": 'mouse-pointer' },
    { "value": 'mp3-player' },
    { "value": 'mug' },
    { "value": 'mug-hot' },
    { "value": 'mug-marshmallows' },
    { "value": 'mug-tea' },
    { "value": 'music' },
    { "value": 'music-alt' },
    { "value": 'music-alt-slash' },
    { "value": 'music-slash' },
    { "value": 'narwhal' },
    { "value": 'network-wired' },
    { "value": 'neuter' },
    { "value": 'newspaper' },
    { "value": 'not-equal' },
    { "value": 'notes-medical' },
    { "value": 'object-group' },
    { "value": 'object-ungroup' },
    { "value": 'octagon' },
    { "value": 'oil-can' },
    { "value": 'oil-temp' },
    { "value": 'om' },
    { "value": 'omega' },
    { "value": 'ornament' },
    { "value": 'otter' },
    { "value": 'outdent' },
    { "value": 'outlet' },
    { "value": 'oven' },
    { "value": 'overline' },
    { "value": 'page-break' },
    { "value": 'pager' },
    { "value": 'paint-brush' },
    { "value": 'paint-brush-alt' },
    { "value": 'paint-roller' },
    { "value": 'palette' },
    { "value": 'pallet' },
    { "value": 'pallet-alt' },
    { "value": 'paper-plane' },
    { "value": 'paperclip' },
    { "value": 'parachute-box' },
    { "value": 'paragraph' },
    { "value": 'paragraph-rtl' },
    { "value": 'parking' },
    { "value": 'parking-circle' },
    { "value": 'parking-circle-slash' },
    { "value": 'parking-slash' },
    { "value": 'passport' },
    { "value": 'pastafarianism' },
    { "value": 'paste' },
    { "value": 'pause' },
    { "value": 'pause-circle' },
    { "value": 'paw' },
    { "value": 'paw-alt' },
    { "value": 'paw-claws' },
    { "value": 'peace' },
    { "value": 'pegasus' },
    { "value": 'pen' },
    { "value": 'pen-alt' },
    { "value": 'pen-fancy' },
    { "value": 'pen-nib' },
    { "value": 'pen-square' },
    { "value": 'pencil' },
    { "value": 'pencil-alt' },
    { "value": 'pencil-paintbrush' },
    { "value": 'pencil-ruler' },
    { "value": 'pennant' },
    { "value": 'people-arrows' },
    { "value": 'people-carry' },
    { "value": 'pepper-hot' },
    { "value": 'percent' },
    { "value": 'percentage' },
    { "value": 'person-booth' },
    { "value": 'person-carry' },
    { "value": 'person-dolly' },
    { "value": 'person-dolly-empty' },
    { "value": 'person-sign' },
    { "value": 'phone' },
    { "value": 'phone-alt' },
    { "value": 'phone-laptop' },
    { "value": 'phone-office' },
    { "value": 'phone-plus' },
    { "value": 'phone-rotary' },
    { "value": 'phone-slash' },
    { "value": 'phone-square' },
    { "value": 'phone-square-alt' },
    { "value": 'phone-volume' },
    { "value": 'photo-video' },
    { "value": 'pi' },
    { "value": 'piano' },
    { "value": 'piano-keyboard' },
    { "value": 'pie' },
    { "value": 'pig' },
    { "value": 'piggy-bank' },
    { "value": 'pills' },
    { "value": 'pizza' },
    { "value": 'pizza-slice' },
    { "value": 'place-of-worship' },
    { "value": 'plane' },
    { "value": 'plane-alt' },
    { "value": 'plane-arrival' },
    { "value": 'plane-departure' },
    { "value": 'plane-slash' },
    { "value": 'planet-moon' },
    { "value": 'planet-ringed' },
    { "value": 'play' },
    { "value": 'play-circle' },
    { "value": 'plug' },
    { "value": 'plus' },
    { "value": 'plus-circle' },
    { "value": 'plus-hexagon' },
    { "value": 'plus-octagon' },
    { "value": 'plus-square' },
    { "value": 'podcast' },
    { "value": 'podium' },
    { "value": 'podium-star' },
    { "value": 'police-box' },
    { "value": 'poll' },
    { "value": 'poll-h' },
    { "value": 'poll-people' },
    { "value": 'poo' },
    { "value": 'poo-storm' },
    { "value": 'poop' },
    { "value": 'popcorn' },
    { "value": 'portal-enter' },
    { "value": 'portal-exit' },
    { "value": 'portrait' },
    { "value": 'pound-sign' },
    { "value": 'power-off' },
    { "value": 'pray' },
    { "value": 'praying-hands' },
    { "value": 'prescription' },
    { "value": 'prescription-bottle' },
    { "value": 'prescription-bottle-alt' },
    { "value": 'presentation' },
    { "value": 'print' },
    { "value": 'print-search' },
    { "value": 'print-slash' },
    { "value": 'procedures' },
    { "value": 'project-diagram' },
    { "value": 'projector' },
    { "value": 'pump-medical' },
    { "value": 'pump-soap' },
    { "value": 'pumpkin' },
    { "value": 'puzzle-piece' },
    { "value": 'qrcode' },
    { "value": 'question' },
    { "value": 'question-circle' },
    { "value": 'question-square' },
    { "value": 'quidditch' },
    { "value": 'quote-left' },
    { "value": 'quote-right' },
    { "value": 'quran' },
    { "value": 'rabbit' },
    { "value": 'rabbit-fast' },
    { "value": 'racquet' },
    { "value": 'radar' },
    { "value": 'radiation' },
    { "value": 'radiation-alt' },
    { "value": 'radio' },
    { "value": 'radio-alt' },
    { "value": 'rainbow' },
    { "value": 'raindrops' },
    { "value": 'ram' },
    { "value": 'ramp-loading' },
    { "value": 'random' },
    { "value": 'raygun' },
    { "value": 'receipt' },
    { "value": 'record-vinyl' },
    { "value": 'rectangle-landscape' },
    { "value": 'rectangle-portrait' },
    { "value": 'rectangle-wide' },
    { "value": 'recycle' },
    { "value": 'redo' },
    { "value": 'redo-alt' },
    { "value": 'refrigerator' },
    { "value": 'registered' },
    { "value": 'remove-format' },
    { "value": 'repeat' },
    { "value": 'repeat-1' },
    { "value": 'repeat-1-alt' },
    { "value": 'repeat-alt' },
    { "value": 'reply' },
    { "value": 'reply-all' },
    { "value": 'republican' },
    { "value": 'restroom' },
    { "value": 'retweet' },
    { "value": 'retweet-alt' },
    { "value": 'ribbon' },
    { "value": 'ring' },
    { "value": 'rings-wedding' },
    { "value": 'road' },
    { "value": 'robot' },
    { "value": 'rocket' },
    { "value": 'rocket-launch' },
    { "value": 'route' },
    { "value": 'route-highway' },
    { "value": 'route-interstate' },
    { "value": 'router' },
    { "value": 'rss' },
    { "value": 'rss-square' },
    { "value": 'ruble-sign' },
    { "value": 'ruler' },
    { "value": 'ruler-combined' },
    { "value": 'ruler-horizontal' },
    { "value": 'ruler-triangle' },
    { "value": 'ruler-vertical' },
    { "value": 'running' },
    { "value": 'rupee-sign' },
    { "value": 'rv' },
    { "value": 'sack' },
    { "value": 'sack-dollar' },
    { "value": 'sad-cry' },
    { "value": 'sad-tear' },
    { "value": 'salad' },
    { "value": 'sandwich' },
    { "value": 'satellite' },
    { "value": 'satellite-dish' },
    { "value": 'sausage' },
    { "value": 'save' },
    { "value": 'sax-hot' },
    { "value": 'saxophone' },
    { "value": 'scalpel' },
    { "value": 'scalpel-path' },
    { "value": 'scanner' },
    { "value": 'scanner-image' },
    { "value": 'scanner-keyboard' },
    { "value": 'scanner-touchscreen' },
    { "value": 'scarecrow' },
    { "value": 'scarf' },
    { "value": 'school' },
    { "value": 'screwdriver' },
    { "value": 'scroll' },
    { "value": 'scroll-old' },
    { "value": 'scrubber' },
    { "value": 'scythe' },
    { "value": 'sd-card' },
    { "value": 'search' },
    { "value": 'search-dollar' },
    { "value": 'search-location' },
    { "value": 'search-minus' },
    { "value": 'search-plus' },
    { "value": 'seedling' },
    { "value": 'send-back' },
    { "value": 'send-backward' },
    { "value": 'sensor' },
    { "value": 'sensor-alert' },
    { "value": 'sensor-fire' },
    { "value": 'sensor-on' },
    { "value": 'sensor-smoke' },
    { "value": 'server' },
    { "value": 'shapes' },
    { "value": 'share' },
    { "value": 'share-all' },
    { "value": 'share-alt' },
    { "value": 'share-alt-square' },
    { "value": 'share-square' },
    { "value": 'sheep' },
    { "value": 'shekel-sign' },
    { "value": 'shield' },
    { "value": 'shield-alt' },
    { "value": 'shield-check' },
    { "value": 'shield-cross' },
    { "value": 'shield-virus' },
    { "value": 'ship' },
    { "value": 'shipping-fast' },
    { "value": 'shipping-timed' },
    { "value": 'shish-kebab' },
    { "value": 'shoe-prints' },
    { "value": 'shopping-bag' },
    { "value": 'shopping-basket' },
    { "value": 'shopping-cart' },
    { "value": 'shovel' },
    { "value": 'shovel-snow' },
    { "value": 'shower' },
    { "value": 'shredder' },
    { "value": 'shuttle-van' },
    { "value": 'shuttlecock' },
    { "value": 'sickle' },
    { "value": 'sigma' },
    { "value": 'sign' },
    { "value": 'sign-in' },
    { "value": 'sign-in-alt' },
    { "value": 'sign-language' },
    { "value": 'sign-out' },
    { "value": 'sign-out-alt' },
    { "value": 'signal' },
    { "value": 'signal-1' },
    { "value": 'signal-2' },
    { "value": 'signal-3' },
    { "value": 'signal-4' },
    { "value": 'signal-alt' },
    { "value": 'signal-alt-1' },
    { "value": 'signal-alt-2' },
    { "value": 'signal-alt-3' },
    { "value": 'signal-alt-slash' },
    { "value": 'signal-slash' },
    { "value": 'signal-stream' },
    { "value": 'signature' },
    { "value": 'sim-card' },
    { "value": 'sink' },
    { "value": 'siren' },
    { "value": 'siren-on' },
    { "value": 'sitemap' },
    { "value": 'skating' },
    { "value": 'skeleton' },
    { "value": 'ski-jump' },
    { "value": 'ski-lift' },
    { "value": 'skiing' },
    { "value": 'skiing-nordic' },
    { "value": 'skull' },
    { "value": 'skull-cow' },
    { "value": 'skull-crossbones' },
    { "value": 'slash' },
    { "value": 'sledding' },
    { "value": 'sleigh' },
    { "value": 'sliders-h' },
    { "value": 'sliders-h-square' },
    { "value": 'sliders-v' },
    { "value": 'sliders-v-square' },
    { "value": 'smile' },
    { "value": 'smile-beam' },
    { "value": 'smile-plus' },
    { "value": 'smile-wink' },
    { "value": 'smog' },
    { "value": 'smoke' },
    { "value": 'smoking' },
    { "value": 'smoking-ban' },
    { "value": 'sms' },
    { "value": 'snake' },
    { "value": 'snooze' },
    { "value": 'snow-blowing' },
    { "value": 'snowboarding' },
    { "value": 'snowflake' },
    { "value": 'snowflakes' },
    { "value": 'snowman' },
    { "value": 'snowmobile' },
    { "value": 'snowplow' },
    { "value": 'soap' },
    { "value": 'socks' },
    { "value": 'solar-panel' },
    { "value": 'solar-system' },
    { "value": 'sort' },
    { "value": 'sort-alpha-down' },
    { "value": 'sort-alpha-down-alt' },
    { "value": 'sort-alpha-up' },
    { "value": 'sort-alpha-up-alt' },
    { "value": 'sort-alt' },
    { "value": 'sort-amount-down' },
    { "value": 'sort-amount-down-alt' },
    { "value": 'sort-amount-up' },
    { "value": 'sort-amount-up-alt' },
    { "value": 'sort-circle' },
    { "value": 'sort-circle-down' },
    { "value": 'sort-circle-up' },
    { "value": 'sort-down' },
    { "value": 'sort-numeric-down' },
    { "value": 'sort-numeric-down-alt' },
    { "value": 'sort-numeric-up' },
    { "value": 'sort-numeric-up-alt' },
    { "value": 'sort-shapes-down' },
    { "value": 'sort-shapes-down-alt' },
    { "value": 'sort-shapes-up' },
    { "value": 'sort-shapes-up-alt' },
    { "value": 'sort-size-down' },
    { "value": 'sort-size-down-alt' },
    { "value": 'sort-size-up' },
    { "value": 'sort-size-up-alt' },
    { "value": 'sort-up' },
    { "value": 'soup' },
    { "value": 'spa' },
    { "value": 'space-shuttle' },
    { "value": 'space-station-moon' },
    { "value": 'space-station-moon-alt' },
    { "value": 'spade' },
    { "value": 'sparkles' },
    { "value": 'speaker' },
    { "value": 'speakers' },
    { "value": 'spell-check' },
    { "value": 'spider' },
    { "value": 'spider-black-widow' },
    { "value": 'spider-web' },
    { "value": 'spinner' },
    { "value": 'spinner-third' },
    { "value": 'splotch' },
    { "value": 'spray-can' },
    { "value": 'sprinkler' },
    { "value": 'square' },
    { "value": 'square-full' },
    { "value": 'square-root' },
    { "value": 'square-root-alt' },
    { "value": 'squirrel' },
    { "value": 'staff' },
    { "value": 'stamp' },
    { "value": 'star' },
    { "value": 'star-and-crescent' },
    { "value": 'star-christmas' },
    { "value": 'star-exclamation' },
    { "value": 'star-half' },
    { "value": 'star-half-alt' },
    { "value": 'star-of-david' },
    { "value": 'star-of-life' },
    { "value": 'star-shooting' },
    { "value": 'starfighter' },
    { "value": 'starfighter-alt' },
    { "value": 'stars' },
    { "value": 'starship' },
    { "value": 'starship-freighter' },
    { "value": 'steak' },
    { "value": 'steering-wheel' },
    { "value": 'step-backward' },
    { "value": 'step-forward' },
    { "value": 'stethoscope' },
    { "value": 'sticky-note' },
    { "value": 'stocking' },
    { "value": 'stomach' },
    { "value": 'stop' },
    { "value": 'stop-circle' },
    { "value": 'stopwatch' },
    { "value": 'stopwatch-20' },
    { "value": 'store' },
    { "value": 'store-alt' },
    { "value": 'store-alt-slash' },
    { "value": 'store-slash' },
    { "value": 'stream' },
    { "value": 'street-view' },
    { "value": 'stretcher' },
    { "value": 'strikethrough' },
    { "value": 'stroopwafel' },
    { "value": 'subscript' },
    { "value": 'subway' },
    { "value": 'suitcase' },
    { "value": 'suitcase-rolling' },
    { "value": 'sun' },
    { "value": 'sun-cloud' },
    { "value": 'sun-dust' },
    { "value": 'sun-haze' },
    { "value": 'sunglasses' },
    { "value": 'sunrise' },
    { "value": 'sunset' },
    { "value": 'superscript' },
    { "value": 'surprise' },
    { "value": 'swatchbook' },
    { "value": 'swimmer' },
    { "value": 'swimming-pool' },
    { "value": 'sword' },
    { "value": 'sword-laser' },
    { "value": 'sword-laser-alt' },
    { "value": 'swords' },
    { "value": 'swords-laser' },
    { "value": 'synagogue' },
    { "value": 'sync' },
    { "value": 'sync-alt' },
    { "value": 'syringe' },
    { "value": 'table' },
    { "value": 'table-tennis' },
    { "value": 'tablet' },
    { "value": 'tablet-alt' },
    { "value": 'tablet-android' },
    { "value": 'tablet-android-alt' },
    { "value": 'tablet-rugged' },
    { "value": 'tablets' },
    { "value": 'tachometer' },
    { "value": 'tachometer-alt' },
    { "value": 'tachometer-alt-average' },
    { "value": 'tachometer-alt-fast' },
    { "value": 'tachometer-alt-fastest' },
    { "value": 'tachometer-alt-slow' },
    { "value": 'tachometer-alt-slowest' },
    { "value": 'tachometer-average' },
    { "value": 'tachometer-fast' },
    { "value": 'tachometer-fastest' },
    { "value": 'tachometer-slow' },
    { "value": 'tachometer-slowest' },
    { "value": 'taco' },
    { "value": 'tag' },
    { "value": 'tags' },
    { "value": 'tally' },
    { "value": 'tanakh' },
    { "value": 'tape' },
    { "value": 'tasks' },
    { "value": 'tasks-alt' },
    { "value": 'taxi' },
    { "value": 'teeth' },
    { "value": 'teeth-open' },
    { "value": 'telescope' },
    { "value": 'temperature-down' },
    { "value": 'temperature-frigid' },
    { "value": 'temperature-high' },
    { "value": 'temperature-hot' },
    { "value": 'temperature-low' },
    { "value": 'temperature-up' },
    { "value": 'tenge' },
    { "value": 'tennis-ball' },
    { "value": 'terminal' },
    { "value": 'text' },
    { "value": 'text-height' },
    { "value": 'text-size' },
    { "value": 'text-width' },
    { "value": 'th' },
    { "value": 'th-large' },
    { "value": 'th-list' },
    { "value": 'theater-masks' },
    { "value": 'thermometer' },
    { "value": 'thermometer-empty' },
    { "value": 'thermometer-full' },
    { "value": 'thermometer-half' },
    { "value": 'thermometer-quarter' },
    { "value": 'thermometer-three-quarters' },
    { "value": 'theta' },
    { "value": 'thumbs-down' },
    { "value": 'thumbs-up' },
    { "value": 'thumbtack' },
    { "value": 'thunderstorm' },
    { "value": 'thunderstorm-moon' },
    { "value": 'thunderstorm-sun' },
    { "value": 'ticket' },
    { "value": 'ticket-alt' },
    { "value": 'tilde' },
    { "value": 'times' },
    { "value": 'times-circle' },
    { "value": 'times-hexagon' },
    { "value": 'times-octagon' },
    { "value": 'times-square' },
    { "value": 'tint' },
    { "value": 'tint-slash' },
    { "value": 'tire' },
    { "value": 'tire-flat' },
    { "value": 'tire-pressure-warning' },
    { "value": 'tire-rugged' },
    { "value": 'tired' },
    { "value": 'toggle-off' },
    { "value": 'toggle-on' },
    { "value": 'toilet' },
    { "value": 'toilet-paper' },
    { "value": 'toilet-paper-alt' },
    { "value": 'toilet-paper-slash' },
    { "value": 'tombstone' },
    { "value": 'tombstone-alt' },
    { "value": 'toolbox' },
    { "value": 'tools' },
    { "value": 'tooth' },
    { "value": 'toothbrush' },
    { "value": 'torah' },
    { "value": 'torii-gate' },
    { "value": 'tornado' },
    { "value": 'tractor' },
    { "value": 'trademark' },
    { "value": 'traffic-cone' },
    { "value": 'traffic-light' },
    { "value": 'traffic-light-go' },
    { "value": 'traffic-light-slow' },
    { "value": 'traffic-light-stop' },
    { "value": 'trailer' },
    { "value": 'train' },
    { "value": 'tram' },
    { "value": 'transgender' },
    { "value": 'transgender-alt' },
    { "value": 'transporter' },
    { "value": 'transporter-1' },
    { "value": 'transporter-2' },
    { "value": 'transporter-3' },
    { "value": 'transporter-empty' },
    { "value": 'trash' },
    { "value": 'trash-alt' },
    { "value": 'trash-restore' },
    { "value": 'trash-restore-alt' },
    { "value": 'trash-undo' },
    { "value": 'trash-undo-alt' },
    { "value": 'treasure-chest' },
    { "value": 'tree' },
    { "value": 'tree-alt' },
    { "value": 'tree-christmas' },
    { "value": 'tree-decorated' },
    { "value": 'tree-large' },
    { "value": 'tree-palm' },
    { "value": 'trees' },
    { "value": 'triangle' },
    { "value": 'triangle-music' },
    { "value": 'trophy' },
    { "value": 'trophy-alt' },
    { "value": 'truck' },
    { "value": 'truck-container' },
    { "value": 'truck-couch' },
    { "value": 'truck-loading' },
    { "value": 'truck-monster' },
    { "value": 'truck-moving' },
    { "value": 'truck-pickup' },
    { "value": 'truck-plow' },
    { "value": 'truck-ramp' },
    { "value": 'trumpet' },
    { "value": 'tshirt' },
    { "value": 'tty' },
    { "value": 'turkey' },
    { "value": 'turntable' },
    { "value": 'turtle' },
    { "value": 'tv' },
    { "value": 'tv-alt' },
    { "value": 'tv-music' },
    { "value": 'tv-retro' },
    { "value": 'typewriter' },
    { "value": 'ufo' },
    { "value": 'ufo-beam' },
    { "value": 'umbrella' },
    { "value": 'umbrella-beach' },
    { "value": 'underline' },
    { "value": 'undo' },
    { "value": 'undo-alt' },
    { "value": 'unicorn' },
    { "value": 'union' },
    { "value": 'universal-access' },
    { "value": 'university' },
    { "value": 'unlink' },
    { "value": 'unlock' },
    { "value": 'unlock-alt' },
    { "value": 'upload' },
    { "value": 'usb-drive' },
    { "value": 'usd-circle' },
    { "value": 'usd-square' },
    { "value": 'user' },
    { "value": 'user-alien' },
    { "value": 'user-alt' },
    { "value": 'user-alt-slash' },
    { "value": 'user-astronaut' },
    { "value": 'user-chart' },
    { "value": 'user-check' },
    { "value": 'user-circle' },
    { "value": 'user-clock' },
    { "value": 'user-cog' },
    { "value": 'user-cowboy' },
    { "value": 'user-crown' },
    { "value": 'user-edit' },
    { "value": 'user-friends' },
    { "value": 'user-graduate' },
    { "value": 'user-hard-hat' },
    { "value": 'user-headset' },
    { "value": 'user-injured' },
    { "value": 'user-lock' },
    { "value": 'user-md' },
    { "value": 'user-md-chat' },
    { "value": 'user-minus' },
    { "value": 'user-music' },
    { "value": 'user-ninja' },
    { "value": 'user-nurse' },
    { "value": 'user-plus' },
    { "value": 'user-robot' },
    { "value": 'user-secret' },
    { "value": 'user-shield' },
    { "value": 'user-slash' },
    { "value": 'user-tag' },
    { "value": 'user-tie' },
    { "value": 'user-times' },
    { "value": 'user-unlock' },
    { "value": 'user-visor' },
    { "value": 'users' },
    { "value": 'users-class' },
    { "value": 'users-cog' },
    { "value": 'users-crown' },
    { "value": 'users-medical' },
    { "value": 'users-slash' },
    { "value": 'utensil-fork' },
    { "value": 'utensil-knife' },
    { "value": 'utensil-spoon' },
    { "value": 'utensils' },
    { "value": 'utensils-alt' },
    { "value": 'vacuum' },
    { "value": 'vacuum-robot' },
    { "value": 'value-absolute' },
    { "value": 'vector-square' },
    { "value": 'venus' },
    { "value": 'venus-double' },
    { "value": 'venus-mars' },
    { "value": 'vest' },
    { "value": 'vest-patches' },
    { "value": 'vhs' },
    { "value": 'vial' },
    { "value": 'vials' },
    { "value": 'video' },
    { "value": 'video-plus' },
    { "value": 'video-slash' },
    { "value": 'vihara' },
    { "value": 'violin' },
    { "value": 'virus' },
    { "value": 'virus-slash' },
    { "value": 'viruses' },
    { "value": 'voicemail' },
    { "value": 'volcano' },
    { "value": 'volleyball-ball' },
    { "value": 'volume' },
    { "value": 'volume-down' },
    { "value": 'volume-mute' },
    { "value": 'volume-off' },
    { "value": 'volume-slash' },
    { "value": 'volume-up' },
    { "value": 'vote-nay' },
    { "value": 'vote-yea' },
    { "value": 'vr-cardboard' },
    { "value": 'wagon-covered' },
    { "value": 'walker' },
    { "value": 'walkie-talkie' },
    { "value": 'walking' },
    { "value": 'wallet' },
    { "value": 'wand' },
    { "value": 'wand-magic' },
    { "value": 'warehouse' },
    { "value": 'warehouse-alt' },
    { "value": 'washer' },
    { "value": 'watch' },
    { "value": 'watch-calculator' },
    { "value": 'watch-fitness' },
    { "value": 'water' },
    { "value": 'water-lower' },
    { "value": 'water-rise' },
    { "value": 'wave-sine' },
    { "value": 'wave-square' },
    { "value": 'wave-triangle' },
    { "value": 'waveform' },
    { "value": 'waveform-path' },
    { "value": 'webcam' },
    { "value": 'webcam-slash' },
    { "value": 'weight' },
    { "value": 'weight-hanging' },
    { "value": 'whale' },
    { "value": 'wheat' },
    { "value": 'wheelchair' },
    { "value": 'whistle' },
    { "value": 'wifi' },
    { "value": 'wifi-1' },
    { "value": 'wifi-2' },
    { "value": 'wifi-slash' },
    { "value": 'wind' },
    { "value": 'wind-turbine' },
    { "value": 'wind-warning' },
    { "value": 'window' },
    { "value": 'window-alt' },
    { "value": 'window-close' },
    { "value": 'window-frame' },
    { "value": 'window-frame-open' },
    { "value": 'window-maximize' },
    { "value": 'window-minimize' },
    { "value": 'window-restore' },
    { "value": 'windsock' },
    { "value": 'wine-bottle' },
    { "value": 'wine-glass' },
    { "value": 'wine-glass-alt' },
    { "value": 'won-sign' },
    { "value": 'wreath' },
    { "value": 'wrench' },
    { "value": 'x-ray' },
    { "value": 'yen-sign' },
    { "value": 'yin-yang' },
    { "value": 'abacus' },
    { "value": 'acorn' },
    { "value": 'ad' },
    { "value": 'address-book' },
    { "value": 'address-card' },
    { "value": 'adjust' },
    { "value": 'air-conditioner' },
    { "value": 'air-freshener' },
    { "value": 'alarm-clock' },
    { "value": 'alarm-exclamation' },
    { "value": 'alarm-plus' },
    { "value": 'alarm-snooze' },
    { "value": 'album' },
    { "value": 'album-collection' },
    { "value": 'alicorn' },
    { "value": 'alien' },
    { "value": 'alien-monster' },
    { "value": 'align-center' },
    { "value": 'align-justify' },
    { "value": 'align-left' },
    { "value": 'align-right' },
    { "value": 'align-slash' },
    { "value": 'allergies' },
    { "value": 'ambulance' },
    { "value": 'american-sign-language-interpreting' },
    { "value": 'amp-guitar' },
    { "value": 'analytics' },
    { "value": 'anchor' },
    { "value": 'angel' },
    { "value": 'angle-double-down' },
    { "value": 'angle-double-left' },
    { "value": 'angle-double-right' },
    { "value": 'angle-double-up' },
    { "value": 'angle-down' },
    { "value": 'angle-left' },
    { "value": 'angle-right' },
    { "value": 'angle-up' },
    { "value": 'angry' },
    { "value": 'ankh' },
    { "value": 'apple-alt' },
    { "value": 'apple-crate' },
    { "value": 'archive' },
    { "value": 'archway' },
    { "value": 'arrow-alt-circle-down' },
    { "value": 'arrow-alt-circle-left' },
    { "value": 'arrow-alt-circle-right' },
    { "value": 'arrow-alt-circle-up' },
    { "value": 'arrow-alt-down' },
    { "value": 'arrow-alt-from-bottom' },
    { "value": 'arrow-alt-from-left' },
    { "value": 'arrow-alt-from-right' },
    { "value": 'arrow-alt-from-top' },
    { "value": 'arrow-alt-left' },
    { "value": 'arrow-alt-right' },
    { "value": 'arrow-alt-square-down' },
    { "value": 'arrow-alt-square-left' },
    { "value": 'arrow-alt-square-right' },
    { "value": 'arrow-alt-square-up' },
    { "value": 'arrow-alt-to-bottom' },
    { "value": 'arrow-alt-to-left' },
    { "value": 'arrow-alt-to-right' },
    { "value": 'arrow-alt-to-top' },
    { "value": 'arrow-alt-up' },
    { "value": 'arrow-circle-down' },
    { "value": 'arrow-circle-left' },
    { "value": 'arrow-circle-right' },
    { "value": 'arrow-circle-up' },
    { "value": 'arrow-down' },
    { "value": 'arrow-from-bottom' },
    { "value": 'arrow-from-left' },
    { "value": 'arrow-from-right' },
    { "value": 'arrow-from-top' },
    { "value": 'arrow-left' },
    { "value": 'arrow-right' },
    { "value": 'arrow-square-down' },
    { "value": 'arrow-square-left' },
    { "value": 'arrow-square-right' },
    { "value": 'arrow-square-up' },
    { "value": 'arrow-to-bottom' },
    { "value": 'arrow-to-left' },
    { "value": 'arrow-to-right' },
    { "value": 'arrow-to-top' },
    { "value": 'arrow-up' },
    { "value": 'arrows' },
    { "value": 'arrows-alt' },
    { "value": 'arrows-alt-h' },
    { "value": 'arrows-alt-v' },
    { "value": 'arrows-h' },
    { "value": 'arrows-v' },
    { "value": 'assistive-listening-systems' },
    { "value": 'asterisk' },
    { "value": 'at' },
    { "value": 'atlas' },
    { "value": 'atom' },
    { "value": 'atom-alt' },
    { "value": 'audio-description' },
    { "value": 'award' },
    { "value": 'axe' },
    { "value": 'axe-battle' },
    { "value": 'baby' },
    { "value": 'baby-carriage' },
    { "value": 'backpack' },
    { "value": 'backspace' },
    { "value": 'backward' },
    { "value": 'bacon' },
    { "value": 'bacteria' },
    { "value": 'bacterium' },
    { "value": 'badge' },
    { "value": 'badge-check' },
    { "value": 'badge-dollar' },
    { "value": 'badge-percent' },
    { "value": 'badge-sheriff' },
    { "value": 'badger-honey' },
    { "value": 'bags-shopping' },
    { "value": 'bahai' },
    { "value": 'balance-scale' },
    { "value": 'balance-scale-left' },
    { "value": 'balance-scale-right' },
    { "value": 'ball-pile' },
    { "value": 'ballot' },
    { "value": 'ballot-check' },
    { "value": 'ban' },
    { "value": 'band-aid' },
    { "value": 'banjo' },
    { "value": 'barcode' },
    { "value": 'barcode-alt' },
    { "value": 'barcode-read' },
    { "value": 'barcode-scan' },
    { "value": 'bars' },
    { "value": 'baseball' },
    { "value": 'baseball-ball' },
    { "value": 'basketball-ball' },
    { "value": 'basketball-hoop' },
    { "value": 'bat' },
    { "value": 'bath' },
    { "value": 'battery-bolt' },
    { "value": 'battery-empty' },
    { "value": 'battery-full' },
    { "value": 'battery-half' },
    { "value": 'battery-quarter' },
    { "value": 'battery-slash' },
    { "value": 'battery-three-quarters' },
    { "value": 'bed' },
    { "value": 'bed-alt' },
    { "value": 'bed-bunk' },
    { "value": 'bed-empty' },
    { "value": 'beer' },
    { "value": 'bell' },
    { "value": 'bell-exclamation' },
    { "value": 'bell-on' },
    { "value": 'bell-plus' },
    { "value": 'bell-school' },
    { "value": 'bell-school-slash' },
    { "value": 'bell-slash' },
    { "value": 'bells' },
    { "value": 'betamax' },
    { "value": 'bezier-curve' },
    { "value": 'bible' },
    { "value": 'bicycle' },
    { "value": 'biking' },
    { "value": 'biking-mountain' },
    { "value": 'binoculars' },
    { "value": 'biohazard' },
    { "value": 'birthday-cake' },
    { "value": 'blanket' },
    { "value": 'blender' },
    { "value": 'blender-phone' },
    { "value": 'blind' },
    { "value": 'blinds' },
    { "value": 'blinds-open' },
    { "value": 'blinds-raised' },
    { "value": 'blog' },
    { "value": 'bold' },
    { "value": 'bolt' },
    { "value": 'bomb' },
    { "value": 'bone' },
    { "value": 'bone-break' },
    { "value": 'bong' },
    { "value": 'book' },
    { "value": 'book-alt' },
    { "value": 'book-dead' },
    { "value": 'book-heart' },
    { "value": 'book-medical' },
    { "value": 'book-open' },
    { "value": 'book-reader' },
    { "value": 'book-spells' },
    { "value": 'book-user' },
    { "value": 'bookmark' },
    { "value": 'books' },
    { "value": 'books-medical' },
    { "value": 'boombox' },
    { "value": 'boot' },
    { "value": 'booth-curtain' },
    { "value": 'border-all' },
    { "value": 'border-bottom' },
    { "value": 'border-center-h' },
    { "value": 'border-center-v' },
    { "value": 'border-inner' },
    { "value": 'border-left' },
    { "value": 'border-none' },
    { "value": 'border-outer' },
    { "value": 'border-right' },
    { "value": 'border-style' },
    { "value": 'border-style-alt' },
    { "value": 'border-top' },
    { "value": 'bow-arrow' },
    { "value": 'bowling-ball' },
    { "value": 'bowling-pins' },
    { "value": 'box' },
    { "value": 'box-alt' },
    { "value": 'box-ballot' },
    { "value": 'box-check' },
    { "value": 'box-fragile' },
    { "value": 'box-full' },
    { "value": 'box-heart' },
    { "value": 'box-open' },
    { "value": 'box-tissue' },
    { "value": 'box-up' },
    { "value": 'box-usd' },
    { "value": 'boxes' },
    { "value": 'boxes-alt' },
    { "value": 'boxing-glove' },
    { "value": 'brackets' },
    { "value": 'brackets-curly' },
    { "value": 'braille' },
    { "value": 'brain' },
    { "value": 'bread-loaf' },
    { "value": 'bread-slice' },
    { "value": 'briefcase' },
    { "value": 'briefcase-medical' },
    { "value": 'bring-forward' },
    { "value": 'bring-front' },
    { "value": 'broadcast-tower' },
    { "value": 'broom' },
    { "value": 'browser' },
    { "value": 'brush' },
    { "value": 'bug' },
    { "value": 'building' },
    { "value": 'bullhorn' },
    { "value": 'bullseye' },
    { "value": 'bullseye-arrow' },
    { "value": 'bullseye-pointer' },
    { "value": 'burger-soda' },
    { "value": 'burn' },
    { "value": 'burrito' },
    { "value": 'bus' },
    { "value": 'bus-alt' },
    { "value": 'bus-school' },
    { "value": 'business-time' },
    { "value": 'cabinet-filing' },
    { "value": 'cactus' },
    { "value": 'calculator' },
    { "value": 'calculator-alt' },
    { "value": 'calendar' },
    { "value": 'calendar-alt' },
    { "value": 'calendar-check' },
    { "value": 'calendar-day' },
    { "value": 'calendar-edit' },
    { "value": 'calendar-exclamation' },
    { "value": 'calendar-minus' },
    { "value": 'calendar-plus' },
    { "value": 'calendar-star' },
    { "value": 'calendar-times' },
    { "value": 'calendar-week' },
    { "value": 'camcorder' },
    { "value": 'camera' },
    { "value": 'camera-alt' },
    { "value": 'camera-home' },
    { "value": 'camera-movie' },
    { "value": 'camera-polaroid' },
    { "value": 'camera-retro' },
    { "value": 'campfire' },
    { "value": 'campground' },
    { "value": 'candle-holder' },
    { "value": 'candy-cane' },
    { "value": 'candy-corn' },
    { "value": 'cannabis' },
    { "value": 'capsules' },
    { "value": 'car' },
    { "value": 'car-alt' },
    { "value": 'car-battery' },
    { "value": 'car-building' },
    { "value": 'car-bump' },
    { "value": 'car-bus' },
    { "value": 'car-crash' },
    { "value": 'car-garage' },
    { "value": 'car-mechanic' },
    { "value": 'car-side' },
    { "value": 'car-tilt' },
    { "value": 'car-wash' },
    { "value": 'caravan' },
    { "value": 'caravan-alt' },
    { "value": 'caret-circle-down' },
    { "value": 'caret-circle-left' },
    { "value": 'caret-circle-right' },
    { "value": 'caret-circle-up' },
    { "value": 'caret-down' },
    { "value": 'caret-left' },
    { "value": 'caret-right' },
    { "value": 'caret-square-down' },
    { "value": 'caret-square-left' },
    { "value": 'caret-square-right' },
    { "value": 'caret-square-up' },
    { "value": 'caret-up' },
    { "value": 'carrot' },
    { "value": 'cars' },
    { "value": 'cart-arrow-down' },
    { "value": 'cart-plus' },
    { "value": 'cash-register' },
    { "value": 'cassette-tape' },
    { "value": 'cat' },
    { "value": 'cat-space' },
    { "value": 'cauldron' },
    { "value": 'cctv' },
    { "value": 'certificate' },
    { "value": 'chair' },
    { "value": 'chair-office' },
    { "value": 'chalkboard' },
    { "value": 'chalkboard-teacher' },
    { "value": 'charging-station' },
    { "value": 'chart-area' },
    { "value": 'chart-bar' },
    { "value": 'chart-line' },
    { "value": 'chart-line-down' },
    { "value": 'chart-network' },
    { "value": 'chart-pie' },
    { "value": 'chart-pie-alt' },
    { "value": 'chart-scatter' },
    { "value": 'check' },
    { "value": 'check-circle' },
    { "value": 'check-double' },
    { "value": 'check-square' },
    { "value": 'cheese' },
    { "value": 'cheese-swiss' },
    { "value": 'cheeseburger' },
    { "value": 'chess' },
    { "value": 'chess-bishop' },
    { "value": 'chess-bishop-alt' },
    { "value": 'chess-board' },
    { "value": 'chess-clock' },
    { "value": 'chess-clock-alt' },
    { "value": 'chess-king' },
    { "value": 'chess-king-alt' },
    { "value": 'chess-knight' },
    { "value": 'chess-knight-alt' },
    { "value": 'chess-pawn' },
    { "value": 'chess-pawn-alt' },
    { "value": 'chess-queen' },
    { "value": 'chess-queen-alt' },
    { "value": 'chess-rook' },
    { "value": 'chess-rook-alt' },
    { "value": 'chevron-circle-down' },
    { "value": 'chevron-circle-left' },
    { "value": 'chevron-circle-right' },
    { "value": 'chevron-circle-up' },
    { "value": 'chevron-double-down' },
    { "value": 'chevron-double-left' },
    { "value": 'chevron-double-right' },
    { "value": 'chevron-double-up' },
    { "value": 'chevron-down' },
    { "value": 'chevron-left' },
    { "value": 'chevron-right' },
    { "value": 'chevron-square-down' },
    { "value": 'chevron-square-left' },
    { "value": 'chevron-square-right' },
    { "value": 'chevron-square-up' },
    { "value": 'chevron-up' },
    { "value": 'child' },
    { "value": 'chimney' },
    { "value": 'church' },
    { "value": 'circle' },
    { "value": 'circle-notch' },
    { "value": 'city' },
    { "value": 'clarinet' },
    { "value": 'claw-marks' },
    { "value": 'clinic-medical' },
    { "value": 'clipboard' },
    { "value": 'clipboard-check' },
    { "value": 'clipboard-list' },
    { "value": 'clipboard-list-check' },
    { "value": 'clipboard-prescription' },
    { "value": 'clipboard-user' },
    { "value": 'clock' },
    { "value": 'clone' },
    { "value": 'closed-captioning' },
    { "value": 'cloud' },
    { "value": 'cloud-download' },
    { "value": 'cloud-download-alt' },
    { "value": 'cloud-drizzle' },
    { "value": 'cloud-hail' },
    { "value": 'cloud-hail-mixed' },
    { "value": 'cloud-meatball' },
    { "value": 'cloud-moon' },
    { "value": 'cloud-moon-rain' },
    { "value": 'cloud-music' },
    { "value": 'cloud-rain' },
    { "value": 'cloud-rainbow' },
    { "value": 'cloud-showers' },
    { "value": 'cloud-showers-heavy' },
    { "value": 'cloud-sleet' },
    { "value": 'cloud-snow' },
    { "value": 'cloud-sun' },
    { "value": 'cloud-sun-rain' },
    { "value": 'cloud-upload' },
    { "value": 'cloud-upload-alt' },
    { "value": 'clouds' },
    { "value": 'clouds-moon' },
    { "value": 'clouds-sun' },
    { "value": 'club' },
    { "value": 'cocktail' },
    { "value": 'code' },
    { "value": 'code-branch' },
    { "value": 'code-commit' },
    { "value": 'code-merge' },
    { "value": 'coffee' },
    { "value": 'coffee-pot' },
    { "value": 'coffee-togo' },
    { "value": 'coffin' },
    { "value": 'coffin-cross' },
    { "value": 'cog' },
    { "value": 'cogs' },
    { "value": 'coin' },
    { "value": 'coins' },
    { "value": 'columns' },
    { "value": 'comet' },
    { "value": 'comment' },
    { "value": 'comment-alt' },
    { "value": 'comment-alt-check' },
    { "value": 'comment-alt-dollar' },
    { "value": 'comment-alt-dots' },
    { "value": 'comment-alt-edit' },
    { "value": 'comment-alt-exclamation' },
    { "value": 'comment-alt-lines' },
    { "value": 'comment-alt-medical' },
    { "value": 'comment-alt-minus' },
    { "value": 'comment-alt-music' },
    { "value": 'comment-alt-plus' },
    { "value": 'comment-alt-slash' },
    { "value": 'comment-alt-smile' },
    { "value": 'comment-alt-times' },
    { "value": 'comment-check' },
    { "value": 'comment-dollar' },
    { "value": 'comment-dots' },
    { "value": 'comment-edit' },
    { "value": 'comment-exclamation' },
    { "value": 'comment-lines' },
    { "value": 'comment-medical' },
    { "value": 'comment-minus' },
    { "value": 'comment-music' },
    { "value": 'comment-plus' },
    { "value": 'comment-slash' },
    { "value": 'comment-smile' },
    { "value": 'comment-times' },
    { "value": 'comments' },
    { "value": 'comments-alt' },
    { "value": 'comments-alt-dollar' },
    { "value": 'comments-dollar' },
    { "value": 'compact-disc' },
    { "value": 'compass' },
    { "value": 'compass-slash' },
    { "value": 'compress' },
    { "value": 'compress-alt' },
    { "value": 'compress-arrows-alt' },
    { "value": 'compress-wide' },
    { "value": 'computer-classic' },
    { "value": 'computer-speaker' },
    { "value": 'concierge-bell' },
    { "value": 'construction' },
    { "value": 'container-storage' },
    { "value": 'conveyor-belt' },
    { "value": 'conveyor-belt-alt' },
    { "value": 'cookie' },
    { "value": 'cookie-bite' },
    { "value": 'copy' },
    { "value": 'copyright' },
    { "value": 'corn' },
    { "value": 'couch' },
    { "value": 'cow' },
    { "value": 'cowbell' },
    { "value": 'cowbell-more' },
    { "value": 'credit-card' },
    { "value": 'credit-card-blank' },
    { "value": 'credit-card-front' },
    { "value": 'cricket' },
    { "value": 'croissant' },
    { "value": 'crop' },
    { "value": 'crop-alt' },
    { "value": 'cross' },
    { "value": 'crosshairs' },
    { "value": 'crow' },
    { "value": 'crown' },
    { "value": 'crutch' },
    { "value": 'crutches' },
    { "value": 'cube' },
    { "value": 'cubes' },
    { "value": 'curling' },
    { "value": 'cut' },
    { "value": 'dagger' },
    { "value": 'database' },
    { "value": 'deaf' },
    { "value": 'debug' },
    { "value": 'deer' },
    { "value": 'deer-rudolph' },
    { "value": 'democrat' },
    { "value": 'desktop' },
    { "value": 'desktop-alt' },
    { "value": 'dewpoint' },
    { "value": 'dharmachakra' },
    { "value": 'diagnoses' },
    { "value": 'diamond' },
    { "value": 'dice' },
    { "value": 'dice-d10' },
    { "value": 'dice-d12' },
    { "value": 'dice-d20' },
    { "value": 'dice-d4' },
    { "value": 'dice-d6' },
    { "value": 'dice-d8' },
    { "value": 'dice-five' },
    { "value": 'dice-four' },
    { "value": 'dice-one' },
    { "value": 'dice-six' },
    { "value": 'dice-three' },
    { "value": 'dice-two' },
    { "value": 'digging' },
    { "value": 'digital-tachograph' },
    { "value": 'diploma' },
    { "value": 'directions' },
    { "value": 'disc-drive' },
    { "value": 'disease' },
    { "value": 'divide' },
    { "value": 'dizzy' },
    { "value": 'dna' },
    { "value": 'do-not-enter' },
    { "value": 'dog' },
    { "value": 'dog-leashed' },
    { "value": 'dollar-sign' },
    { "value": 'dolly' },
    { "value": 'dolly-empty' },
    { "value": 'dolly-flatbed' },
    { "value": 'dolly-flatbed-alt' },
    { "value": 'dolly-flatbed-empty' },
    { "value": 'donate' },
    { "value": 'door-closed' },
    { "value": 'door-open' },
    { "value": 'dot-circle' },
    { "value": 'dove' },
    { "value": 'download' },
    { "value": 'drafting-compass' },
    { "value": 'dragon' },
    { "value": 'draw-circle' },
    { "value": 'draw-polygon' },
    { "value": 'draw-square' },
    { "value": 'dreidel' },
    { "value": 'drone' },
    { "value": 'drone-alt' },
    { "value": 'drum' },
    { "value": 'drum-steelpan' },
    { "value": 'drumstick' },
    { "value": 'drumstick-bite' },
    { "value": 'dryer' },
    { "value": 'dryer-alt' },
    { "value": 'duck' },
    { "value": 'dumbbell' },
    { "value": 'dumpster' },
    { "value": 'dumpster-fire' },
    { "value": 'dungeon' },
    { "value": 'ear' },
    { "value": 'ear-muffs' },
    { "value": 'eclipse' },
    { "value": 'eclipse-alt' },
    { "value": 'edit' },
    { "value": 'egg' },
    { "value": 'egg-fried' },
    { "value": 'eject' },
    { "value": 'elephant' },
    { "value": 'ellipsis-h' },
    { "value": 'ellipsis-h-alt' },
    { "value": 'ellipsis-v' },
    { "value": 'ellipsis-v-alt' },
    { "value": 'empty-set' },
    { "value": 'engine-warning' },
    { "value": 'envelope' },
    { "value": 'envelope-open' },
    { "value": 'envelope-open-dollar' },
    { "value": 'envelope-open-text' },
    { "value": 'envelope-square' },
    { "value": 'equals' },
    { "value": 'eraser' },
    { "value": 'ethernet' },
    { "value": 'euro-sign' },
    { "value": 'exchange' },
    { "value": 'exchange-alt' },
    { "value": 'exclamation' },
    { "value": 'exclamation-circle' },
    { "value": 'exclamation-square' },
    { "value": 'exclamation-triangle' },
    { "value": 'expand' },
    { "value": 'expand-alt' },
    { "value": 'expand-arrows' },
    { "value": 'expand-arrows-alt' },
    { "value": 'expand-wide' },
    { "value": 'external-link' },
    { "value": 'external-link-alt' },
    { "value": 'external-link-square' },
    { "value": 'external-link-square-alt' },
    { "value": 'eye' },
    { "value": 'eye-dropper' },
    { "value": 'eye-evil' },
    { "value": 'eye-slash' },
    { "value": 'fan' },
    { "value": 'fan-table' },
    { "value": 'farm' },
    { "value": 'fast-backward' },
    { "value": 'fast-forward' },
    { "value": 'faucet' },
    { "value": 'faucet-drip' },
    { "value": 'fax' },
    { "value": 'feather' },
    { "value": 'feather-alt' },
    { "value": 'female' },
    { "value": 'field-hockey' },
    { "value": 'fighter-jet' },
    { "value": 'file' },
    { "value": 'file-alt' },
    { "value": 'file-archive' },
    { "value": 'file-audio' },
    { "value": 'file-certificate' },
    { "value": 'file-chart-line' },
    { "value": 'file-chart-pie' },
    { "value": 'file-check' },
    { "value": 'file-code' },
    { "value": 'file-contract' },
    { "value": 'file-csv' },
    { "value": 'file-download' },
    { "value": 'file-edit' },
    { "value": 'file-excel' },
    { "value": 'file-exclamation' },
    { "value": 'file-export' },
    { "value": 'file-image' },
    { "value": 'file-import' },
    { "value": 'file-invoice' },
    { "value": 'file-invoice-dollar' },
    { "value": 'file-medical' },
    { "value": 'file-medical-alt' },
    { "value": 'file-minus' },
    { "value": 'file-music' },
    { "value": 'file-pdf' },
    { "value": 'file-plus' },
    { "value": 'file-powerpoint' },
    { "value": 'file-prescription' },
    { "value": 'file-search' },
    { "value": 'file-signature' },
    { "value": 'file-spreadsheet' },
    { "value": 'file-times' },
    { "value": 'file-upload' },
    { "value": 'file-user' },
    { "value": 'file-video' },
    { "value": 'file-word' },
    { "value": 'files-medical' },
    { "value": 'fill' },
    { "value": 'fill-drip' },
    { "value": 'film' },
    { "value": 'film-alt' },
    { "value": 'film-canister' },
    { "value": 'filter' },
    { "value": 'fingerprint' },
    { "value": 'fire' },
    { "value": 'fire-alt' },
    { "value": 'fire-extinguisher' },
    { "value": 'fire-smoke' },
    { "value": 'fireplace' },
    { "value": 'first-aid' },
    { "value": 'fish' },
    { "value": 'fish-cooked' },
    { "value": 'fist-raised' },
    { "value": 'flag' },
    { "value": 'flag-alt' },
    { "value": 'flag-checkered' },
    { "value": 'flag-usa' },
    { "value": 'flame' },
    { "value": 'flashlight' },
    { "value": 'flask' },
    { "value": 'flask-poison' },
    { "value": 'flask-potion' },
    { "value": 'flower' },
    { "value": 'flower-daffodil' },
    { "value": 'flower-tulip' },
    { "value": 'flushed' },
    { "value": 'flute' },
    { "value": 'flux-capacitor' },
    { "value": 'fog' },
    { "value": 'folder' },
    { "value": 'folder-download' },
    { "value": 'folder-minus' },
    { "value": 'folder-open' },
    { "value": 'folder-plus' },
    { "value": 'folder-times' },
    { "value": 'folder-tree' },
    { "value": 'folder-upload' },
    { "value": 'folders' },
    { "value": 'font' },
    { "value": 'font-awesome-logo-full' },
    { "value": 'font-case' },
    { "value": 'football-ball' },
    { "value": 'football-helmet' },
    { "value": 'forklift' },
    { "value": 'forward' },
    { "value": 'fragile' },
    { "value": 'french-fries' },
    { "value": 'frog' },
    { "value": 'frosty-head' },
    { "value": 'frown' },
    { "value": 'frown-open' },
    { "value": 'function' },
    { "value": 'funnel-dollar' },
    { "value": 'futbol' },
    { "value": 'galaxy' },
    { "value": 'game-board' },
    { "value": 'game-board-alt' },
    { "value": 'game-console-handheld' },
    { "value": 'gamepad' },
    { "value": 'gamepad-alt' },
    { "value": 'garage' },
    { "value": 'garage-car' },
    { "value": 'garage-open' },
    { "value": 'gas-pump' },
    { "value": 'gas-pump-slash' },
    { "value": 'gavel' },
    { "value": 'gem' },
    { "value": 'genderless' },
    { "value": 'ghost' },
    { "value": 'gift' },
    { "value": 'gift-card' },
    { "value": 'gifts' },
    { "value": 'gingerbread-man' },
    { "value": 'glass' },
    { "value": 'glass-champagne' },
    { "value": 'glass-cheers' },
    { "value": 'glass-citrus' },
    { "value": 'glass-martini' },
    { "value": 'glass-martini-alt' },
    { "value": 'glass-whiskey' },
    { "value": 'glass-whiskey-rocks' },
    { "value": 'glasses' },
    { "value": 'glasses-alt' },
    { "value": 'globe' },
    { "value": 'globe-africa' },
    { "value": 'globe-americas' },
    { "value": 'globe-asia' },
    { "value": 'globe-europe' },
    { "value": 'globe-snow' },
    { "value": 'globe-stand' },
    { "value": 'golf-ball' },
    { "value": 'golf-club' },
    { "value": 'gopuram' },
    { "value": 'graduation-cap' },
    { "value": 'gramophone' },
    { "value": 'greater-than' },
    { "value": 'greater-than-equal' },
    { "value": 'grimace' },
    { "value": 'grin' },
    { "value": 'grin-alt' },
    { "value": 'grin-beam' },
    { "value": 'grin-beam-sweat' },
    { "value": 'grin-hearts' },
    { "value": 'grin-squint' },
    { "value": 'grin-squint-tears' },
    { "value": 'grin-stars' },
    { "value": 'grin-tears' },
    { "value": 'grin-tongue' },
    { "value": 'grin-tongue-squint' },
    { "value": 'grin-tongue-wink' },
    { "value": 'grin-wink' },
    { "value": 'grip-horizontal' },
    { "value": 'grip-lines' },
    { "value": 'grip-lines-vertical' },
    { "value": 'grip-vertical' },
    { "value": 'guitar' },
    { "value": 'guitar-electric' },
    { "value": 'guitars' },
    { "value": 'h-square' },
    { "value": 'h1' },
    { "value": 'h2' },
    { "value": 'h3' },
    { "value": 'h4' },
    { "value": 'hamburger' },
    { "value": 'hammer' },
    { "value": 'hammer-war' },
    { "value": 'hamsa' },
    { "value": 'hand-heart' },
    { "value": 'hand-holding' },
    { "value": 'hand-holding-box' },
    { "value": 'hand-holding-heart' },
    { "value": 'hand-holding-magic' },
    { "value": 'hand-holding-medical' },
    { "value": 'hand-holding-seedling' },
    { "value": 'hand-holding-usd' },
    { "value": 'hand-holding-water' },
    { "value": 'hand-lizard' },
    { "value": 'hand-middle-finger' },
    { "value": 'hand-paper' },
    { "value": 'hand-peace' },
    { "value": 'hand-point-down' },
    { "value": 'hand-point-left' },
    { "value": 'hand-point-right' },
    { "value": 'hand-point-up' },
    { "value": 'hand-pointer' },
    { "value": 'hand-receiving' },
    { "value": 'hand-rock' },
    { "value": 'hand-scissors' },
    { "value": 'hand-sparkles' },
    { "value": 'hand-spock' },
    { "value": 'hands' },
    { "value": 'hands-heart' },
    { "value": 'hands-helping' },
    { "value": 'hands-usd' },
    { "value": 'hands-wash' },
    { "value": 'handshake' },
    { "value": 'handshake-alt' },
    { "value": 'handshake-alt-slash' },
    { "value": 'handshake-slash' },
    { "value": 'hanukiah' },
    { "value": 'hard-hat' },
    { "value": 'hashtag' },
    { "value": 'hat-chef' },
    { "value": 'hat-cowboy' },
    { "value": 'hat-cowboy-side' },
    { "value": 'hat-santa' },
    { "value": 'hat-winter' },
    { "value": 'hat-witch' },
    { "value": 'hat-wizard' },
    { "value": 'hdd' },
    { "value": 'head-side' },
    { "value": 'head-side-brain' },
    { "value": 'head-side-cough' },
    { "value": 'head-side-cough-slash' },
    { "value": 'head-side-headphones' },
    { "value": 'head-side-mask' },
    { "value": 'head-side-medical' },
    { "value": 'head-side-virus' },
    { "value": 'head-vr' },
    { "value": 'heading' },
    { "value": 'headphones' },
    { "value": 'headphones-alt' },
    { "value": 'headset' },
    { "value": 'heart' },
    { "value": 'heart-broken' },
    { "value": 'heart-circle' },
    { "value": 'heart-rate' },
    { "value": 'heart-square' },
    { "value": 'heartbeat' },
    { "value": 'heat' },
    { "value": 'helicopter' },
    { "value": 'helmet-battle' },
    { "value": 'hexagon' },
    { "value": 'highlighter' },
    { "value": 'hiking' },
    { "value": 'hippo' },
    { "value": 'history' },
    { "value": 'hockey-mask' },
    { "value": 'hockey-puck' },
    { "value": 'hockey-sticks' },
    { "value": 'holly-berry' },
    { "value": 'home' },
    { "value": 'home-alt' },
    { "value": 'home-heart' },
    { "value": 'home-lg' },
    { "value": 'home-lg-alt' },
    { "value": 'hood-cloak' },
    { "value": 'horizontal-rule' },
    { "value": 'horse' },
    { "value": 'horse-head' },
    { "value": 'horse-saddle' },
    { "value": 'hospital' },
    { "value": 'hospital-alt' },
    { "value": 'hospital-symbol' },
    { "value": 'hospital-user' },
    { "value": 'hospitals' },
    { "value": 'hot-tub' },
    { "value": 'hotdog' },
    { "value": 'hotel' },
    { "value": 'hourglass' },
    { "value": 'hourglass-end' },
    { "value": 'hourglass-half' },
    { "value": 'hourglass-start' },
    { "value": 'house' },
    { "value": 'house-damage' },
    { "value": 'house-day' },
    { "value": 'house-flood' },
    { "value": 'house-leave' },
    { "value": 'house-night' },
    { "value": 'house-return' },
    { "value": 'house-signal' },
    { "value": 'house-user' },
    { "value": 'hryvnia' },
    { "value": 'humidity' },
    { "value": 'hurricane' },
    { "value": 'i-cursor' },
    { "value": 'ice-cream' },
    { "value": 'ice-skate' },
    { "value": 'icicles' },
    { "value": 'icons' },
    { "value": 'icons-alt' },
    { "value": 'id-badge' },
    { "value": 'id-card' },
    { "value": 'id-card-alt' },
    { "value": 'igloo' },
    { "value": 'image' },
    { "value": 'image-polaroid' },
    { "value": 'images' },
    { "value": 'inbox' },
    { "value": 'inbox-in' },
    { "value": 'inbox-out' },
    { "value": 'indent' },
    { "value": 'industry' },
    { "value": 'industry-alt' },
    { "value": 'infinity' },
    { "value": 'info' },
    { "value": 'info-circle' },
    { "value": 'info-square' },
    { "value": 'inhaler' },
    { "value": 'integral' },
    { "value": 'intersection' },
    { "value": 'inventory' },
    { "value": 'island-tropical' },
    { "value": 'italic' },
    { "value": 'jack-o-lantern' },
    { "value": 'jedi' },
    { "value": 'joint' },
    { "value": 'journal-whills' },
    { "value": 'joystick' },
    { "value": 'jug' },
    { "value": 'kaaba' },
    { "value": 'kazoo' },
    { "value": 'kerning' },
    { "value": 'key' },
    { "value": 'key-skeleton' },
    { "value": 'keyboard' },
    { "value": 'keynote' },
    { "value": 'khanda' },
    { "value": 'kidneys' },
    { "value": 'kiss' },
    { "value": 'kiss-beam' },
    { "value": 'kiss-wink-heart' },
    { "value": 'kite' },
    { "value": 'kiwi-bird' },
    { "value": 'knife-kitchen' },
    { "value": 'lambda' },
    { "value": 'lamp' },
    { "value": 'lamp-desk' },
    { "value": 'lamp-floor' },
    { "value": 'landmark' },
    { "value": 'landmark-alt' },
    { "value": 'language' },
    { "value": 'laptop' },
    { "value": 'laptop-code' },
    { "value": 'laptop-house' },
    { "value": 'laptop-medical' },
    { "value": 'lasso' },
    { "value": 'laugh' },
    { "value": 'laugh-beam' },
    { "value": 'laugh-squint' },
    { "value": 'laugh-wink' },
    { "value": 'layer-group' },
    { "value": 'layer-minus' },
    { "value": 'layer-plus' },
    { "value": 'leaf' },
    { "value": 'leaf-heart' },
    { "value": 'leaf-maple' },
    { "value": 'leaf-oak' },
    { "value": 'lemon' },
    { "value": 'less-than' },
    { "value": 'less-than-equal' },
    { "value": 'level-down' },
    { "value": 'level-down-alt' },
    { "value": 'level-up' },
    { "value": 'level-up-alt' },
    { "value": 'life-ring' },
    { "value": 'light-ceiling' },
    { "value": 'light-switch' },
    { "value": 'light-switch-off' },
    { "value": 'light-switch-on' },
    { "value": 'lightbulb' },
    { "value": 'lightbulb-dollar' },
    { "value": 'lightbulb-exclamation' },
    { "value": 'lightbulb-on' },
    { "value": 'lightbulb-slash' },
    { "value": 'lights-holiday' },
    { "value": 'line-columns' },
    { "value": 'line-height' },
    { "value": 'link' },
    { "value": 'lips' },
    { "value": 'lira-sign' },
    { "value": 'list' },
    { "value": 'list-alt' },
    { "value": 'list-music' },
    { "value": 'list-ol' },
    { "value": 'list-ul' },
    { "value": 'location' },
    { "value": 'location-arrow' },
    { "value": 'location-circle' },
    { "value": 'location-slash' },
    { "value": 'lock' },
    { "value": 'lock-alt' },
    { "value": 'lock-open' },
    { "value": 'lock-open-alt' },
    { "value": 'long-arrow-alt-down' },
    { "value": 'long-arrow-alt-left' },
    { "value": 'long-arrow-alt-right' },
    { "value": 'long-arrow-alt-up' },
    { "value": 'long-arrow-down' },
    { "value": 'long-arrow-left' },
    { "value": 'long-arrow-right' },
    { "value": 'long-arrow-up' },
    { "value": 'loveseat' },
    { "value": 'low-vision' },
    { "value": 'luchador' },
    { "value": 'luggage-cart' },
    { "value": 'lungs' },
    { "value": 'lungs-virus' },
    { "value": 'mace' },
    { "value": 'magic' },
    { "value": 'magnet' },
    { "value": 'mail-bulk' },
    { "value": 'mailbox' },
    { "value": 'male' },
    { "value": 'mandolin' },
    { "value": 'map' },
    { "value": 'map-marked' },
    { "value": 'map-marked-alt' },
    { "value": 'map-marker' },
    { "value": 'map-marker-alt' },
    { "value": 'map-marker-alt-slash' },
    { "value": 'map-marker-check' },
    { "value": 'map-marker-edit' },
    { "value": 'map-marker-exclamation' },
    { "value": 'map-marker-minus' },
    { "value": 'map-marker-plus' },
    { "value": 'map-marker-question' },
    { "value": 'map-marker-slash' },
    { "value": 'map-marker-smile' },
    { "value": 'map-marker-times' },
    { "value": 'map-pin' },
    { "value": 'map-signs' },
    { "value": 'marker' },
    { "value": 'mars' },
    { "value": 'mars-double' },
    { "value": 'mars-stroke' },
    { "value": 'mars-stroke-h' },
    { "value": 'mars-stroke-v' },
    { "value": 'mask' },
    { "value": 'meat' },
    { "value": 'medal' },
    { "value": 'medkit' },
    { "value": 'megaphone' },
    { "value": 'meh' },
    { "value": 'meh-blank' },
    { "value": 'meh-rolling-eyes' },
    { "value": 'memory' },
    { "value": 'menorah' },
    { "value": 'mercury' },
    { "value": 'meteor' },
    { "value": 'microchip' },
    { "value": 'microphone' },
    { "value": 'microphone-alt' },
    { "value": 'microphone-alt-slash' },
    { "value": 'microphone-slash' },
    { "value": 'microphone-stand' },
    { "value": 'microscope' },
    { "value": 'microwave' },
    { "value": 'mind-share' },
    { "value": 'minus' },
    { "value": 'minus-circle' },
    { "value": 'minus-hexagon' },
    { "value": 'minus-octagon' },
    { "value": 'minus-square' },
    { "value": 'mistletoe' },
    { "value": 'mitten' },
    { "value": 'mobile' },
    { "value": 'mobile-alt' },
    { "value": 'mobile-android' },
    { "value": 'mobile-android-alt' },
    { "value": 'money-bill' },
    { "value": 'money-bill-alt' },
    { "value": 'money-bill-wave' },
    { "value": 'money-bill-wave-alt' },
    { "value": 'money-check' },
    { "value": 'money-check-alt' },
    { "value": 'money-check-edit' },
    { "value": 'money-check-edit-alt' },
    { "value": 'monitor-heart-rate' },
    { "value": 'monkey' },
    { "value": 'monument' },
    { "value": 'moon' },
    { "value": 'moon-cloud' },
    { "value": 'moon-stars' },
    { "value": 'mortar-pestle' },
    { "value": 'mosque' },
    { "value": 'motorcycle' },
    { "value": 'mountain' },
    { "value": 'mountains' },
    { "value": 'mouse' },
    { "value": 'mouse-alt' },
    { "value": 'mouse-pointer' },
    { "value": 'mp3-player' },
    { "value": 'mug' },
    { "value": 'mug-hot' },
    { "value": 'mug-marshmallows' },
    { "value": 'mug-tea' },
    { "value": 'music' },
    { "value": 'music-alt' },
    { "value": 'music-alt-slash' },
    { "value": 'music-slash' },
    { "value": 'narwhal' },
    { "value": 'network-wired' },
    { "value": 'neuter' },
    { "value": 'newspaper' },
    { "value": 'not-equal' },
    { "value": 'notes-medical' },
    { "value": 'object-group' },
    { "value": 'object-ungroup' },
    { "value": 'octagon' },
    { "value": 'oil-can' },
    { "value": 'oil-temp' },
    { "value": 'om' },
    { "value": 'omega' },
    { "value": 'ornament' },
    { "value": 'otter' },
    { "value": 'outdent' },
    { "value": 'outlet' },
    { "value": 'oven' },
    { "value": 'overline' },
    { "value": 'page-break' },
    { "value": 'pager' },
    { "value": 'paint-brush' },
    { "value": 'paint-brush-alt' },
    { "value": 'paint-roller' },
    { "value": 'palette' },
    { "value": 'pallet' },
    { "value": 'pallet-alt' },
    { "value": 'paper-plane' },
    { "value": 'paperclip' },
    { "value": 'parachute-box' },
    { "value": 'paragraph' },
    { "value": 'paragraph-rtl' },
    { "value": 'parking' },
    { "value": 'parking-circle' },
    { "value": 'parking-circle-slash' },
    { "value": 'parking-slash' },
    { "value": 'passport' },
    { "value": 'pastafarianism' },
    { "value": 'paste' },
    { "value": 'pause' },
    { "value": 'pause-circle' },
    { "value": 'paw' },
    { "value": 'paw-alt' },
    { "value": 'paw-claws' },
    { "value": 'peace' },
    { "value": 'pegasus' },
    { "value": 'pen' },
    { "value": 'pen-alt' },
    { "value": 'pen-fancy' },
    { "value": 'pen-nib' },
    { "value": 'pen-square' },
    { "value": 'pencil' },
    { "value": 'pencil-alt' },
    { "value": 'pencil-paintbrush' },
    { "value": 'pencil-ruler' },
    { "value": 'pennant' },
    { "value": 'people-arrows' },
    { "value": 'people-carry' },
    { "value": 'pepper-hot' },
    { "value": 'percent' },
    { "value": 'percentage' },
    { "value": 'person-booth' },
    { "value": 'person-carry' },
    { "value": 'person-dolly' },
    { "value": 'person-dolly-empty' },
    { "value": 'person-sign' },
    { "value": 'phone' },
    { "value": 'phone-alt' },
    { "value": 'phone-laptop' },
    { "value": 'phone-office' },
    { "value": 'phone-plus' },
    { "value": 'phone-rotary' },
    { "value": 'phone-slash' },
    { "value": 'phone-square' },
    { "value": 'phone-square-alt' },
    { "value": 'phone-volume' },
    { "value": 'photo-video' },
    { "value": 'pi' },
    { "value": 'piano' },
    { "value": 'piano-keyboard' },
    { "value": 'pie' },
    { "value": 'pig' },
    { "value": 'piggy-bank' },
    { "value": 'pills' },
    { "value": 'pizza' },
    { "value": 'pizza-slice' },
    { "value": 'place-of-worship' },
    { "value": 'plane' },
    { "value": 'plane-alt' },
    { "value": 'plane-arrival' },
    { "value": 'plane-departure' },
    { "value": 'plane-slash' },
    { "value": 'planet-moon' },
    { "value": 'planet-ringed' },
    { "value": 'play' },
    { "value": 'play-circle' },
    { "value": 'plug' },
    { "value": 'plus' },
    { "value": 'plus-circle' },
    { "value": 'plus-hexagon' },
    { "value": 'plus-octagon' },
    { "value": 'plus-square' },
    { "value": 'podcast' },
    { "value": 'podium' },
    { "value": 'podium-star' },
    { "value": 'police-box' },
    { "value": 'poll' },
    { "value": 'poll-h' },
    { "value": 'poll-people' },
    { "value": 'poo' },
    { "value": 'poo-storm' },
    { "value": 'poop' },
    { "value": 'popcorn' },
    { "value": 'portal-enter' },
    { "value": 'portal-exit' },
    { "value": 'portrait' },
    { "value": 'pound-sign' },
    { "value": 'power-off' },
    { "value": 'pray' },
    { "value": 'praying-hands' },
    { "value": 'prescription' },
    { "value": 'prescription-bottle' },
    { "value": 'prescription-bottle-alt' },
    { "value": 'presentation' },
    { "value": 'print' },
    { "value": 'print-search' },
    { "value": 'print-slash' },
    { "value": 'procedures' },
    { "value": 'project-diagram' },
    { "value": 'projector' },
    { "value": 'pump-medical' },
    { "value": 'pump-soap' },
    { "value": 'pumpkin' },
    { "value": 'puzzle-piece' },
    { "value": 'qrcode' },
    { "value": 'question' },
    { "value": 'question-circle' },
    { "value": 'question-square' },
    { "value": 'quidditch' },
    { "value": 'quote-left' },
    { "value": 'quote-right' },
    { "value": 'quran' },
    { "value": 'rabbit' },
    { "value": 'rabbit-fast' },
    { "value": 'racquet' },
    { "value": 'radar' },
    { "value": 'radiation' },
    { "value": 'radiation-alt' },
    { "value": 'radio' },
    { "value": 'radio-alt' },
    { "value": 'rainbow' },
    { "value": 'raindrops' },
    { "value": 'ram' },
    { "value": 'ramp-loading' },
    { "value": 'random' },
    { "value": 'raygun' },
    { "value": 'receipt' },
    { "value": 'record-vinyl' },
    { "value": 'rectangle-landscape' },
    { "value": 'rectangle-portrait' },
    { "value": 'rectangle-wide' },
    { "value": 'recycle' },
    { "value": 'redo' },
    { "value": 'redo-alt' },
    { "value": 'refrigerator' },
    { "value": 'registered' },
    { "value": 'remove-format' },
    { "value": 'repeat' },
    { "value": 'repeat-1' },
    { "value": 'repeat-1-alt' },
    { "value": 'repeat-alt' },
    { "value": 'reply' },
    { "value": 'reply-all' },
    { "value": 'republican' },
    { "value": 'restroom' },
    { "value": 'retweet' },
    { "value": 'retweet-alt' },
    { "value": 'ribbon' },
    { "value": 'ring' },
    { "value": 'rings-wedding' },
    { "value": 'road' },
    { "value": 'robot' },
    { "value": 'rocket' },
    { "value": 'rocket-launch' },
    { "value": 'route' },
    { "value": 'route-highway' },
    { "value": 'route-interstate' },
    { "value": 'router' },
    { "value": 'rss' },
    { "value": 'rss-square' },
    { "value": 'ruble-sign' },
    { "value": 'ruler' },
    { "value": 'ruler-combined' },
    { "value": 'ruler-horizontal' },
    { "value": 'ruler-triangle' },
    { "value": 'ruler-vertical' },
    { "value": 'running' },
    { "value": 'rupee-sign' },
    { "value": 'rv' },
    { "value": 'sack' },
    { "value": 'sack-dollar' },
    { "value": 'sad-cry' },
    { "value": 'sad-tear' },
    { "value": 'salad' },
    { "value": 'sandwich' },
    { "value": 'satellite' },
    { "value": 'satellite-dish' },
    { "value": 'sausage' },
    { "value": 'save' },
    { "value": 'sax-hot' },
    { "value": 'saxophone' },
    { "value": 'scalpel' },
    { "value": 'scalpel-path' },
    { "value": 'scanner' },
    { "value": 'scanner-image' },
    { "value": 'scanner-keyboard' },
    { "value": 'scanner-touchscreen' },
    { "value": 'scarecrow' },
    { "value": 'scarf' },
    { "value": 'school' },
    { "value": 'screwdriver' },
    { "value": 'scroll' },
    { "value": 'scroll-old' },
    { "value": 'scrubber' },
    { "value": 'scythe' },
    { "value": 'sd-card' },
    { "value": 'search' },
    { "value": 'search-dollar' },
    { "value": 'search-location' },
    { "value": 'search-minus' },
    { "value": 'search-plus' },
    { "value": 'seedling' },
    { "value": 'send-back' },
    { "value": 'send-backward' },
    { "value": 'sensor' },
    { "value": 'sensor-alert' },
    { "value": 'sensor-fire' },
    { "value": 'sensor-on' },
    { "value": 'sensor-smoke' },
    { "value": 'server' },
    { "value": 'shapes' },
    { "value": 'share' },
    { "value": 'share-all' },
    { "value": 'share-alt' },
    { "value": 'share-alt-square' },
    { "value": 'share-square' },
    { "value": 'sheep' },
    { "value": 'shekel-sign' },
    { "value": 'shield' },
    { "value": 'shield-alt' },
    { "value": 'shield-check' },
    { "value": 'shield-cross' },
    { "value": 'shield-virus' },
    { "value": 'ship' },
    { "value": 'shipping-fast' },
    { "value": 'shipping-timed' },
    { "value": 'shish-kebab' },
    { "value": 'shoe-prints' },
    { "value": 'shopping-bag' },
    { "value": 'shopping-basket' },
    { "value": 'shopping-cart' },
    { "value": 'shovel' },
    { "value": 'shovel-snow' },
    { "value": 'shower' },
    { "value": 'shredder' },
    { "value": 'shuttle-van' },
    { "value": 'shuttlecock' },
    { "value": 'sickle' },
    { "value": 'sigma' },
    { "value": 'sign' },
    { "value": 'sign-in' },
    { "value": 'sign-in-alt' },
    { "value": 'sign-language' },
    { "value": 'sign-out' },
    { "value": 'sign-out-alt' },
    { "value": 'signal' },
    { "value": 'signal-1' },
    { "value": 'signal-2' },
    { "value": 'signal-3' },
    { "value": 'signal-4' },
    { "value": 'signal-alt' },
    { "value": 'signal-alt-1' },
    { "value": 'signal-alt-2' },
    { "value": 'signal-alt-3' },
    { "value": 'signal-alt-slash' },
    { "value": 'signal-slash' },
    { "value": 'signal-stream' },
    { "value": 'signature' },
    { "value": 'sim-card' },
    { "value": 'sink' },
    { "value": 'siren' },
    { "value": 'siren-on' },
    { "value": 'sitemap' },
    { "value": 'skating' },
    { "value": 'skeleton' },
    { "value": 'ski-jump' },
    { "value": 'ski-lift' },
    { "value": 'skiing' },
    { "value": 'skiing-nordic' },
    { "value": 'skull' },
    { "value": 'skull-cow' },
    { "value": 'skull-crossbones' },
    { "value": 'slash' },
    { "value": 'sledding' },
    { "value": 'sleigh' },
    { "value": 'sliders-h' },
    { "value": 'sliders-h-square' },
    { "value": 'sliders-v' },
    { "value": 'sliders-v-square' },
    { "value": 'smile' },
    { "value": 'smile-beam' },
    { "value": 'smile-plus' },
    { "value": 'smile-wink' },
    { "value": 'smog' },
    { "value": 'smoke' },
    { "value": 'smoking' },
    { "value": 'smoking-ban' },
    { "value": 'sms' },
    { "value": 'snake' },
    { "value": 'snooze' },
    { "value": 'snow-blowing' },
    { "value": 'snowboarding' },
    { "value": 'snowflake' },
    { "value": 'snowflakes' },
    { "value": 'snowman' },
    { "value": 'snowmobile' },
    { "value": 'snowplow' },
    { "value": 'soap' },
    { "value": 'socks' },
    { "value": 'solar-panel' },
    { "value": 'solar-system' },
    { "value": 'sort' },
    { "value": 'sort-alpha-down' },
    { "value": 'sort-alpha-down-alt' },
    { "value": 'sort-alpha-up' },
    { "value": 'sort-alpha-up-alt' },
    { "value": 'sort-alt' },
    { "value": 'sort-amount-down' },
    { "value": 'sort-amount-down-alt' },
    { "value": 'sort-amount-up' },
    { "value": 'sort-amount-up-alt' },
    { "value": 'sort-circle' },
    { "value": 'sort-circle-down' },
    { "value": 'sort-circle-up' },
    { "value": 'sort-down' },
    { "value": 'sort-numeric-down' },
    { "value": 'sort-numeric-down-alt' },
    { "value": 'sort-numeric-up' },
    { "value": 'sort-numeric-up-alt' },
    { "value": 'sort-shapes-down' },
    { "value": 'sort-shapes-down-alt' },
    { "value": 'sort-shapes-up' },
    { "value": 'sort-shapes-up-alt' },
    { "value": 'sort-size-down' },
    { "value": 'sort-size-down-alt' },
    { "value": 'sort-size-up' },
    { "value": 'sort-size-up-alt' },
    { "value": 'sort-up' },
    { "value": 'soup' },
    { "value": 'spa' },
    { "value": 'space-shuttle' },
    { "value": 'space-station-moon' },
    { "value": 'space-station-moon-alt' },
    { "value": 'spade' },
    { "value": 'sparkles' },
    { "value": 'speaker' },
    { "value": 'speakers' },
    { "value": 'spell-check' },
    { "value": 'spider' },
    { "value": 'spider-black-widow' },
    { "value": 'spider-web' },
    { "value": 'spinner' },
    { "value": 'spinner-third' },
    { "value": 'splotch' },
    { "value": 'spray-can' },
    { "value": 'sprinkler' },
    { "value": 'square' },
    { "value": 'square-full' },
    { "value": 'square-root' },
    { "value": 'square-root-alt' },
    { "value": 'squirrel' },
    { "value": 'staff' },
    { "value": 'stamp' },
    { "value": 'star' },
    { "value": 'star-and-crescent' },
    { "value": 'star-christmas' },
    { "value": 'star-exclamation' },
    { "value": 'star-half' },
    { "value": 'star-half-alt' },
    { "value": 'star-of-david' },
    { "value": 'star-of-life' },
    { "value": 'star-shooting' },
    { "value": 'starfighter' },
    { "value": 'starfighter-alt' },
    { "value": 'stars' },
    { "value": 'starship' },
    { "value": 'starship-freighter' },
    { "value": 'steak' },
    { "value": 'steering-wheel' },
    { "value": 'step-backward' },
    { "value": 'step-forward' },
    { "value": 'stethoscope' },
    { "value": 'sticky-note' },
    { "value": 'stocking' },
    { "value": 'stomach' },
    { "value": 'stop' },
    { "value": 'stop-circle' },
    { "value": 'stopwatch' },
    { "value": 'stopwatch-20' },
    { "value": 'store' },
    { "value": 'store-alt' },
    { "value": 'store-alt-slash' },
    { "value": 'store-slash' },
    { "value": 'stream' },
    { "value": 'street-view' },
    { "value": 'stretcher' },
    { "value": 'strikethrough' },
    { "value": 'stroopwafel' },
    { "value": 'subscript' },
    { "value": 'subway' },
    { "value": 'suitcase' },
    { "value": 'suitcase-rolling' },
    { "value": 'sun' },
    { "value": 'sun-cloud' },
    { "value": 'sun-dust' },
    { "value": 'sun-haze' },
    { "value": 'sunglasses' },
    { "value": 'sunrise' },
    { "value": 'sunset' },
    { "value": 'superscript' },
    { "value": 'surprise' },
    { "value": 'swatchbook' },
    { "value": 'swimmer' },
    { "value": 'swimming-pool' },
    { "value": 'sword' },
    { "value": 'sword-laser' },
    { "value": 'sword-laser-alt' },
    { "value": 'swords' },
    { "value": 'swords-laser' },
    { "value": 'synagogue' },
    { "value": 'sync' },
    { "value": 'sync-alt' },
    { "value": 'syringe' },
    { "value": 'table' },
    { "value": 'table-tennis' },
    { "value": 'tablet' },
    { "value": 'tablet-alt' },
    { "value": 'tablet-android' },
    { "value": 'tablet-android-alt' },
    { "value": 'tablet-rugged' },
    { "value": 'tablets' },
    { "value": 'tachometer' },
    { "value": 'tachometer-alt' },
    { "value": 'tachometer-alt-average' },
    { "value": 'tachometer-alt-fast' },
    { "value": 'tachometer-alt-fastest' },
    { "value": 'tachometer-alt-slow' },
    { "value": 'tachometer-alt-slowest' },
    { "value": 'tachometer-average' },
    { "value": 'tachometer-fast' },
    { "value": 'tachometer-fastest' },
    { "value": 'tachometer-slow' },
    { "value": 'tachometer-slowest' },
    { "value": 'taco' },
    { "value": 'tag' },
    { "value": 'tags' },
    { "value": 'tally' },
    { "value": 'tanakh' },
    { "value": 'tape' },
    { "value": 'tasks' },
    { "value": 'tasks-alt' },
    { "value": 'taxi' },
    { "value": 'teeth' },
    { "value": 'teeth-open' },
    { "value": 'telescope' },
    { "value": 'temperature-down' },
    { "value": 'temperature-frigid' },
    { "value": 'temperature-high' },
    { "value": 'temperature-hot' },
    { "value": 'temperature-low' },
    { "value": 'temperature-up' },
    { "value": 'tenge' },
    { "value": 'tennis-ball' },
    { "value": 'terminal' },
    { "value": 'text' },
    { "value": 'text-height' },
    { "value": 'text-size' },
    { "value": 'text-width' },
    { "value": 'th' },
    { "value": 'th-large' },
    { "value": 'th-list' },
    { "value": 'theater-masks' },
    { "value": 'thermometer' },
    { "value": 'thermometer-empty' },
    { "value": 'thermometer-full' },
    { "value": 'thermometer-half' },
    { "value": 'thermometer-quarter' },
    { "value": 'thermometer-three-quarters' },
    { "value": 'theta' },
    { "value": 'thumbs-down' },
    { "value": 'thumbs-up' },
    { "value": 'thumbtack' },
    { "value": 'thunderstorm' },
    { "value": 'thunderstorm-moon' },
    { "value": 'thunderstorm-sun' },
    { "value": 'ticket' },
    { "value": 'ticket-alt' },
    { "value": 'tilde' },
    { "value": 'times' },
    { "value": 'times-circle' },
    { "value": 'times-hexagon' },
    { "value": 'times-octagon' },
    { "value": 'times-square' },
    { "value": 'tint' },
    { "value": 'tint-slash' },
    { "value": 'tire' },
    { "value": 'tire-flat' },
    { "value": 'tire-pressure-warning' },
    { "value": 'tire-rugged' },
    { "value": 'tired' },
    { "value": 'toggle-off' },
    { "value": 'toggle-on' },
    { "value": 'toilet' },
    { "value": 'toilet-paper' },
    { "value": 'toilet-paper-alt' },
    { "value": 'toilet-paper-slash' },
    { "value": 'tombstone' },
    { "value": 'tombstone-alt' },
    { "value": 'toolbox' },
    { "value": 'tools' },
    { "value": 'tooth' },
    { "value": 'toothbrush' },
    { "value": 'torah' },
    { "value": 'torii-gate' },
    { "value": 'tornado' },
    { "value": 'tractor' },
    { "value": 'trademark' },
    { "value": 'traffic-cone' },
    { "value": 'traffic-light' },
    { "value": 'traffic-light-go' },
    { "value": 'traffic-light-slow' },
    { "value": 'traffic-light-stop' },
    { "value": 'trailer' },
    { "value": 'train' },
    { "value": 'tram' },
    { "value": 'transgender' },
    { "value": 'transgender-alt' },
    { "value": 'transporter' },
    { "value": 'transporter-1' },
    { "value": 'transporter-2' },
    { "value": 'transporter-3' },
    { "value": 'transporter-empty' },
    { "value": 'trash' },
    { "value": 'trash-alt' },
    { "value": 'trash-restore' },
    { "value": 'trash-restore-alt' },
    { "value": 'trash-undo' },
    { "value": 'trash-undo-alt' },
    { "value": 'treasure-chest' },
    { "value": 'tree' },
    { "value": 'tree-alt' },
    { "value": 'tree-christmas' },
    { "value": 'tree-decorated' },
    { "value": 'tree-large' },
    { "value": 'tree-palm' },
    { "value": 'trees' },
    { "value": 'triangle' },
    { "value": 'triangle-music' },
    { "value": 'trophy' },
    { "value": 'trophy-alt' },
    { "value": 'truck' },
    { "value": 'truck-container' },
    { "value": 'truck-couch' },
    { "value": 'truck-loading' },
    { "value": 'truck-monster' },
    { "value": 'truck-moving' },
    { "value": 'truck-pickup' },
    { "value": 'truck-plow' },
    { "value": 'truck-ramp' },
    { "value": 'trumpet' },
    { "value": 'tshirt' },
    { "value": 'tty' },
    { "value": 'turkey' },
    { "value": 'turntable' },
    { "value": 'turtle' },
    { "value": 'tv' },
    { "value": 'tv-alt' },
    { "value": 'tv-music' },
    { "value": 'tv-retro' },
    { "value": 'typewriter' },
    { "value": 'ufo' },
    { "value": 'ufo-beam' },
    { "value": 'umbrella' },
    { "value": 'umbrella-beach' },
    { "value": 'underline' },
    { "value": 'undo' },
    { "value": 'undo-alt' },
    { "value": 'unicorn' },
    { "value": 'union' },
    { "value": 'universal-access' },
    { "value": 'university' },
    { "value": 'unlink' },
    { "value": 'unlock' },
    { "value": 'unlock-alt' },
    { "value": 'upload' },
    { "value": 'usb-drive' },
    { "value": 'usd-circle' },
    { "value": 'usd-square' },
    { "value": 'user' },
    { "value": 'user-alien' },
    { "value": 'user-alt' },
    { "value": 'user-alt-slash' },
    { "value": 'user-astronaut' },
    { "value": 'user-chart' },
    { "value": 'user-check' },
    { "value": 'user-circle' },
    { "value": 'user-clock' },
    { "value": 'user-cog' },
    { "value": 'user-cowboy' },
    { "value": 'user-crown' },
    { "value": 'user-edit' },
    { "value": 'user-friends' },
    { "value": 'user-graduate' },
    { "value": 'user-hard-hat' },
    { "value": 'user-headset' },
    { "value": 'user-injured' },
    { "value": 'user-lock' },
    { "value": 'user-md' },
    { "value": 'user-md-chat' },
    { "value": 'user-minus' },
    { "value": 'user-music' },
    { "value": 'user-ninja' },
    { "value": 'user-nurse' },
    { "value": 'user-plus' },
    { "value": 'user-robot' },
    { "value": 'user-secret' },
    { "value": 'user-shield' },
    { "value": 'user-slash' },
    { "value": 'user-tag' },
    { "value": 'user-tie' },
    { "value": 'user-times' },
    { "value": 'user-unlock' },
    { "value": 'user-visor' },
    { "value": 'users' },
    { "value": 'users-class' },
    { "value": 'users-cog' },
    { "value": 'users-crown' },
    { "value": 'users-medical' },
    { "value": 'users-slash' },
    { "value": 'utensil-fork' },
    { "value": 'utensil-knife' },
    { "value": 'utensil-spoon' },
    { "value": 'utensils' },
    { "value": 'utensils-alt' },
    { "value": 'vacuum' },
    { "value": 'vacuum-robot' },
    { "value": 'value-absolute' },
    { "value": 'vector-square' },
    { "value": 'venus' },
    { "value": 'venus-double' },
    { "value": 'venus-mars' },
    { "value": 'vest' },
    { "value": 'vest-patches' },
    { "value": 'vhs' },
    { "value": 'vial' },
    { "value": 'vials' },
    { "value": 'video' },
    { "value": 'video-plus' },
    { "value": 'video-slash' },
    { "value": 'vihara' },
    { "value": 'violin' },
    { "value": 'virus' },
    { "value": 'virus-slash' },
    { "value": 'viruses' },
    { "value": 'voicemail' },
    { "value": 'volcano' },
    { "value": 'volleyball-ball' },
    { "value": 'volume' },
    { "value": 'volume-down' },
    { "value": 'volume-mute' },
    { "value": 'volume-off' },
    { "value": 'volume-slash' },
    { "value": 'volume-up' },
    { "value": 'vote-nay' },
    { "value": 'vote-yea' },
    { "value": 'vr-cardboard' },
    { "value": 'wagon-covered' },
    { "value": 'walker' },
    { "value": 'walkie-talkie' },
    { "value": 'walking' },
    { "value": 'wallet' },
    { "value": 'wand' },
    { "value": 'wand-magic' },
    { "value": 'warehouse' },
    { "value": 'warehouse-alt' },
    { "value": 'washer' },
    { "value": 'watch' },
    { "value": 'watch-calculator' },
    { "value": 'watch-fitness' },
    { "value": 'water' },
    { "value": 'water-lower' },
    { "value": 'water-rise' },
    { "value": 'wave-sine' },
    { "value": 'wave-square' },
    { "value": 'wave-triangle' },
    { "value": 'waveform' },
    { "value": 'waveform-path' },
    { "value": 'webcam' },
    { "value": 'webcam-slash' },
    { "value": 'weight' },
    { "value": 'weight-hanging' },
    { "value": 'whale' },
    { "value": 'wheat' },
    { "value": 'wheelchair' },
    { "value": 'whistle' },
    { "value": 'wifi' },
    { "value": 'wifi-1' },
    { "value": 'wifi-2' },
    { "value": 'wifi-slash' },
    { "value": 'wind' },
    { "value": 'wind-turbine' },
    { "value": 'wind-warning' },
    { "value": 'window' },
    { "value": 'window-alt' },
    { "value": 'window-close' },
    { "value": 'window-frame' },
    { "value": 'window-frame-open' },
    { "value": 'window-maximize' },
    { "value": 'window-minimize' },
    { "value": 'window-restore' },
    { "value": 'windsock' },
    { "value": 'wine-bottle' },
    { "value": 'wine-glass' },
    { "value": 'wine-glass-alt' },
    { "value": 'won-sign' },
    { "value": 'wreath' },
    { "value": 'wrench' },
    { "value": 'x-ray' },
    { "value": 'yen-sign' },
    { "value": 'yin-yang' }
]