import { maxValue, number, useMutation, useNotify, useRedirect, required } from "ra-core";
import { ArrayInput, SimpleFormIterator, ReferenceInput, Datagrid, List, TextField, EmailField, Edit, TextInput, TabbedForm, FormTab, Create, Filter, AutocompleteInput, AutocompleteArrayInput } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { ChipArrayUseStyles, TabsUseStyles } from "../App";
import { WorkingCenterField } from "../components/Fields/WorkingCenterField";
import { getLanguajes, getProfessionalType, getWorkingCenter } from "../services/master-tables.service";
import { PostBulkActionButtons, CustomToolbarPermissions } from "./utils";
import {email} from 'react-admin';
import moment from 'moment';

const validateAdminCreation = (values) => {
    const errors = {};
    if (!values.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values.identificationNumber && !validarDNIoNIE(values.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values.surnames?.trim()) {
        errors.surnames = 'Requerido';
    }
    if (!values.password) {
        errors.password = 'Requerido';
    }
    if (!values.email?.trim()) {
        errors.email = 'Requerido';
    }
    return errors
};



const validarDNIoNIE = (dni) => {
    var numero, letr, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

const validateAdminEdit = (values) => {
    const errors = {};
    if (!values.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values.identificationNumber && !validarDNIoNIE(values.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values.surnames?.trim()) {
        errors.surnames = 'Requerido';
    }
    if (!values.email?.trim()) {
        errors.email = 'Requerido';
    }
    return errors
};

const AdminTitle = ({ record }) => {
    return <span>Administrador {record ? `${record.name}` : ''}</span>;
};

const AdminFilter = (props) => {
    const [workingCenter, setworkingCenter] = useState()

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
    }, []);

    return (
        <Filter {...props}>
            <TextInput label="NIF/NIE" source="identificationNumber" alwaysOn />
            <TextInput label="Nombre" source="name" alwaysOn />
            {
                workingCenter ?
                    <AutocompleteInput
                        label="Centro de Trabajo"
                        source="associatedWorkingCenterId"
                        choices={workingCenter}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
        </Filter>
    )
};

export const AdminList = props => {

    const [professionalType, setprofessionalType] = useState()

    useEffect(() => {
        getProfessionalType().then(
            res => {
                setprofessionalType(res.elements)
            }
        )
    }, []);

    if (!professionalType) return null

    return (
        <List bulkActionButtons={props.permissions.admins.delete ? <PostBulkActionButtons /> : false} {...props} filters={<AdminFilter />} exporter={false}
            filter={{ professionalTypeId: [professionalType.find(e => e.value === 'ADMIN').id] }}>
            <Datagrid rowClick="edit" isRowSelectable={record => props.permissions.admins.delete}>
                <TextField label="Nombre" source="name" />
                <TextField label="Apellidos" source="surnames" />
                <EmailField label="Email" source="email" sortBy="User.Email" />
                <TextField label="NIF/NIE" source="identificationNumber" sortBy="User.IdentificationNumber" />
                <TextField label="Teléfono Móvil" source="cellPhone" />
                <TextField label="Teléfono" source="homePhone" />
                {/* <TextField label="Idioma" source="languages" /> */}
                {/* <TextField label="Tipo" source="type" /> */}
                <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={false} />
            </Datagrid>
        </List>
    )
};

export const AdminEdit = props => (
    <Edit title={<AdminTitle />} mutationMode="pessimistic" {...props} transform={transformAdmin}>
        {AdminForm(props, 'edit')}
    </Edit>
);

export const AdminCreate = props => (
    <Create mutationMode="pessimistic" {...props} transform={transformAdmin}>
        {AdminForm(props)}
    </Create>
);

export const AdminForm = (props, mode = 'create') => {
    const [workingCenter, setworkingCenter] = useState();
    const [languajeList, setlanguajeList] = useState();
    const [professionalType, setprofessionalType] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const validateEmail = email();

    const redirect = useRedirect();
    const notify = useNotify()

    const [mutate] = useMutation();

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'admins',
                    payload: { data: transformAdmin(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4002) {
                    notify(error.error.message, 'error')
                    return {
                        password: error.error.message
                    };
                }
                if (error.error && error.error.code === 4003) {
                    notify(error.error.message, 'error')
                    return {
                        identificationNumber: error.error.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        // eslint-disable-next-line
        [mutate, mode, notify, props.basePath, redirect],
    );

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalType(res.elements.find(e => e.value === 'ADMIN').id)
            }
        )
    }, [])

    if (!professionalType) return <></>

    return (
        <TabbedForm classes={classes} save={save} toolbar={<CustomToolbarPermissions perm="admins.delete" />}
            validate={mode === 'create' ? validateAdminCreation : validateAdminEdit} initialValues={{ professionalTypeId: professionalType }}>
            <FormTab label="Administrador" contentClassName="tab-container">
                <TextInput label="Nombre" source="name" />
                <TextInput label="Apellidos" source="surnames" />
                <TextInput label="Email" source="email" validate={validateEmail} />
                <TextInput label="NIF/NIE" source="identificationNumber" />
                {
                    mode === 'create' ? <TextInput label="Contraseña" source="password" /> : null
                }
            </FormTab>
            <FormTab label="Contacto" contentClassName="tab-container">
                <TextInput label="Teléfono Móvil" source="cellPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} />
                <TextInput label="Teléfono fijo" source="homePhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} />
            </FormTab>
            <FormTab label="Misceláneo" contentClassName="tab-container">
                {
                    languajeList ?
                        <AutocompleteArrayInput
                            label="Idioma"
                            source="languagesId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
            </FormTab>
            {
                mode !== 'create' ?
                    <FormTab label="Contratos">
                        <ArrayInput label="Contratos" source="contracts">
                            <SimpleFormIterator disableRemove disableAdd>
                                <ReferenceInput label="Contrato" source="id" disabled reference="contracts" filterToQuery={(value) => {
                                    return { nameINumber: value }
                                }}>
                                    <AutocompleteInput
                                        optionText={(record) => record.contractNumber}
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <TextInput style={{ display: 'none' }} source="contractsValidation" />
                    </FormTab>
                    : null
            }

        </TabbedForm>
    )
}

const transformAdmin = (data) => {
    const activeDate = data.activeDate ? moment(data.activeDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const leavingDate = data.leavingDate ? moment(data.leavingDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        roleId: 2,
        contracts: data.contracts?.map(e => e.id),
        activeDate,
        leavingDate,
    }
}