import {
    DeleteButton, SimpleForm, Button, Datagrid, TextField, BooleanField, Edit, 
    TextInput, DateInput, DateTimeInput, SelectInput, NumberInput, TabbedForm, FormTab, 
    ReferenceManyField, Pagination, Create, FunctionField
} from "ra-ui-materialui";
import { useState, useCallback } from "react";
import { InteractionDto, ScheduledActivityClient, ExternalInformationClient } from "../services/ohl.virtualCenter.api";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import { parseDate } from "./scheduledActivities";
import { CustomToolbarPermissions } from "./utils";
import { FormDataConsumer, required, useRedirect, useMutation, useGetIdentity } from "ra-core";
import { TabsUseStyles } from "../App";

import ActionDelete from '@material-ui/icons/Delete';
import { useLocation } from 'react-router';
import {
    useRefresh,
    useNotify,
    useUpdate,
    useRecordContext,
    DatagridBody,
    Labeled
} from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import StarPicker from 'react-star-picker';
import ReactQuill from 'react-quill';
import MUIButton from '@material-ui/core/Button';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { btoaReplacement } from "../common/utils";

const interactionsClient = new ExternalInformationClient(process?.env?.REACT_APP_PUBLIC_API);
const scheduledActivityClient = new ScheduledActivityClient(process?.env?.REACT_APP_PUBLIC_API);

library.add(fas)

const validateRecordCreate = (values) => {
    const errors = {};
    if (!values?.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values?.initDate?.trim()) {
        errors.initDate = 'Requerido';
    }
    if (moment(values?.initDate).isBefore((new Date()).toDateString())) {
        errors.initDate = 'La fecha de inicio no puede ser anterior a la actual';
    }
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values?.initHour)) {
        errors.initHour = 'Formato incorrecto';
    }
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values?.endHour)) {
        errors.endHour = 'Formato incorrecto';
    }
    if (!values?.initHour?.trim()) {
        errors.initHour = 'Requerido';
    }
    if (!values?.endHour?.trim()) {
        errors.endHour = 'Requerido';
    }
    // Check initHour < endHour
    if (values.initHour && values.endHour) {
        const initDate = moment();
        const endDate = moment();
        initDate?.set({ hour: values?.initHour?.split(':')[0], minute: values?.initHour?.split(':')[1] })
        endDate?.set({ hour: values?.endHour.split(':')[0], minute: values?.endHour?.split(':')[1] })
        if (initDate >= endDate) {
            errors.endHour = 'No puede ser inferior a la fecha de inicio';
        }
    }
    if (!values?.periodicy?.trim()) {
        errors.periodicy = 'Requerido';
    }
    if (!values?.amount && values?.periodicy !== 'Once') {
        errors.amount = 'Requerido';
    }
    if (values?.amount <= 1 && values?.periodicy !== 'Once') {
        errors.amount = 'Tiene que ser mayor que 1';
    }

    return errors
}
const ValidateEditAgenda = (values) => {
    const errors = {};

    let now = moment(new Date())
    let selected = moment(values?.startDate)
    if (selected < now) {
        errors.startDate = "La fecha no puede ser anterior a la actual"
    }

    return errors
}
const validateRecordEdit = (values) => {
    const errors = {};
    if (!values?.name?.trim()) {
        errors.name = 'Requerido';
    }

    return errors
}

const RecordsTitle = ({ record }) => {
    return <span>Registro {record ? `${record?.name}` : ''}</span>;
};

export const RecordCreate = (props) => {

    return (
        <Create mutationMode="pessimistic" {...props} transform={transformActivitiesCreate}>
            {RecordForm(props)}
        </Create>
    )
};

export const RecordEdit = (props) => {

    return (
        <Edit title={<RecordsTitle />} mutationMode="pessimistic" {...props} transform={transformActivitiesUpdate}>
            {RecordForm(props, 'edit')}
        </Edit>
    )
};

const RecordForm = (props, mode = 'create') => {
    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();
    const { identity, loading } = useGetIdentity();
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'records',
                    payload: { data: mode === 'create' ? transformActivitiesCreate(values) : transformActivitiesUpdate(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props?.basePath, res?.data?.id, res?.data)
                } else {
                    redirect('edit', props?.basePath, res?.data?.id, res?.data)
                }
            } catch (error) {
                if (error?.error && error?.error?.code === 4003) {
                    notify(error?.error?.message, 'error')
                    // return {
                    //     professional: { id: error.error.message }
                    // };
                }
                if (error?.error && error?.error?.code === 4001) {
                    notify(error?.error?.message, 'error')
                    // return {
                    //     contractNumber: error.error.message
                    // };
                }
                //notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );


    const CustomDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, field => (
                <TableCell style={{ width: field?.props?.width }} key={`${id}-${field?.props?.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            ))}
        </TableRow>
    );

    const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow />} />;
    const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;
    if (loading && !identity) {
        return <div></div>;
    }
    return (
        <TabbedForm toolbar={<CustomToolbarPermissions perm="records.delete" />} classes={classes} save={save} validate={mode === 'create' ? validateRecordCreate : validateRecordEdit}>
            <FormTab label="Registro" contentClassName="tab-container">
                <TextInput label="Titulo Registro" source="name" required inputProps={{ maxLength: 120 }} />
                <TextInput label="Ubicación" source="location" />
                <TextInput label="Comentarios" source="comments" multiline rows={5} inputProps={{ maxLength: 800 }}/>
            </FormTab>
            {
                mode === 'edit' ?
                    <FormTab label="Agenda" contentClassName="">
                        <ReferenceManyField
                            className="full"
                            pagination={<Pagination />}
                            addLabel={false}
                            target="id"
                            reference="scheduledActivities"
                            filter={{ includeUsers: true }}
                            perPage={10}
                        >
                            <CustomDatagrid className="full">
                                <TextField width="25%" label="Nombre registro" source="activity.name" />
                                {/* <DateField label="Fecha inicio" source="startDate" /> */}
                                <FunctionField width="15%" label="Fecha inicio" className="capitalize" render={record => parseDate(record?.startDate)} />
                                <FunctionField width="15%" label="Fecha Fin" className="capitalize" render={record => parseDate(record?.endDate)} />
                                <span></span>
                                <span></span>
                                {/* <FunctionField width="15%" label="Estado" className="state" render={record => {
                                    return record.state === record?.isHeld ? "En curso" : record?.isCancel ? "Cancelada" : !!record?.realEndDate ? "Finalizada" : "Pendiente"
                                }} /> */}
                                {
                                    identity.roles === "SUPER_ADMIN" ?
                                        <BooleanField width="2rem" label="Eliminada" source="isDeleted" /> :
                                        <span></span>
                                }

                                {/* <BooleanField label="Cancelada" source="isCancel" />
                                <BooleanField label="Comenzada" source="isHeld" />
                                <FunctionField label="Finalizada" render={record => {
                                    return !!record.realEndDate ? <Done color="primary" /> : <Clear />
                                }} /> */}
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.records?.edit ?
                                        <>
                                            <EditScheduledActivityButton record={record} identity={identity} loading={loading} />
                                        </> : null
                                }} />
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && record?.isHeld && record?.realEndDate && props?.permissions && props?.permissions?.records?.edit ?
                                        <>
                                            < EditActivityCommentButton record={record} identity={identity} loading={loading} />
                                        </> : null
                                }} />
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.records?.delete ? <DeleteScheduledActivityButton record={record} identity={identity} loading={loading} /> : null
                                }} />
                            </CustomDatagrid>
                        </ReferenceManyField >
                    </FormTab>
                : // Modo Create
                    <FormTab label="Periodicidad" contentClassName="tab-container">
                        <DateInput label="Fecha de inicio" source="initDate" validate={[required()]}></DateInput>
                        <div style={{ width: '98%' }}>
                            <TextInput style={{ marginRight: 5 }} label="Hora inicio (HH:MM)" source="initHour" validate={[required()]} ></TextInput>
                            <TextInput style={{ marginLeft: 5 }} label="Hora fin (HH:MM)" source="endHour" validate={[required()]} ></TextInput>
                        </div>
                        <SelectInput label="Periodicidad" source="periodicy" choices={[
                            { id: 'Once', name: 'Una vez' },
                            { id: 'EveryDay', name: 'Cada día' },
                            { id: 'EveryWeek', name: 'Cada semana' },
                            { id: 'EveryMonth', name: 'Cada Mes' },
                            { id: 'EveryYear', name: 'Cada año' },
                        ]} required />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData?.periodicy !== 'Once' &&
                                <NumberInput label="Cantidad de eventos" source="amount" required></NumberInput>
                            }
                        </FormDataConsumer>
                    </FormTab>
            }
        </TabbedForm >
    )
}

const EditScheduledActivityButton = (props) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const record = useRecordContext(props);

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [edit] = useUpdate('scheduledActivities');


    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form?.getState()?.dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { data: transformScheduledActivityEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error?.message, 'error');
                }
            }
        );
    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme?.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme?.spacing(1),
            top: theme?.spacing(1),
            color: theme?.palette?.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes?.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        !props.identity ? null : <>
            <Button
                onClick={handleClick}
                label="Editar agenda"
                icon="card-account-mail"
                disabled={props?.identity?.roles === "CONTRACTING_AUTHORITY"}
            >
                <CalendarIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle onClose={handleCloseClick}>Editar Agenda</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        save={handleSubmit}
                        validate={ValidateEditAgenda}
                        redirect={location?.pathname}
                        mode="edit"
                        loading={props?.loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                    >
                        <DateTimeInput label="Fecha de Inicio" source="startDate"></DateTimeInput>
                        <DateTimeInput label="Fecha de Fin" source="endDate"></DateTimeInput>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

const EditActivityCommentButton = (props) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const record = useRecordContext(props);
    const form = useForm();

    const [interactionList, setInteractionList] = useState(record?.interactions);
    const [generalComment, setGeneralComment] = useState();
    const [tabValue, setTabValue] = useState(0);
    const [databaseOperationInProgress, setDataBaseOperationInProgress] = useState(false);

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event, record) => {
        if (form?.getState().dirty) {
            handlePopOverClick(event)
        } else {
            record.interactions.forEach(element => {
                try {
                    element.comment = element?.comment ? atob(element?.comment) : null;
                    element.technicalComment = element?.technicalComment ? atob(element?.technicalComment) : null;
                    element.commentForRelative = element?.commentForRelative ? atob(element?.commentForRelative) : null;
                } catch (ex) { }
            });
            setInteractionList(record?.interactions);
            setGeneralComment(record?.comments);
            setshowDialog(true);
        }
    };

    const onChangeGeneralComment = (value) => {
        setGeneralComment(value);
    };

    const onChangeComment = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].comment = value;
        record.interactions[index].comment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeTechComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].technicalComment = value;
        record.interactions[index].technicalComment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeRelativesComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].commentForRelative = value;
        record.interactions[index].commentForRelative = value;
        setInteractionList(interactionListClone);
    };

    const onStarClickMood = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].mood = nextValue;
        record.interactions[index].mood = nextValue;
        setInteractionList(interactionListClone);
    };

    const onStarClickHealth = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].healthCondition = nextValue;
        record.interactions[index].healthCondition = nextValue;
        setInteractionList(interactionListClone);
    };
    const onStarClickFeedback = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].feedbackMark = nextValue;
        record.interactions[index].feedbackMark = nextValue;
        setInteractionList(interactionListClone);
    };

    // const handleSubmit = async values => {
    //     edit(
    //         {payload: {data: transformScheduledActivityEdit(values) } },
    //         {
    //             onSuccess: ({data}) => {
    //                 setshowDialog(false);
    //                 // Update the comment form to target the newly created post
    //                 // Updating the ReferenceInput value will force it to reload the available posts
    //                 // refresh();
    //                 refresh();
    //             },
    //             onFailure: ({error}) => {
    //                 notify(error.message, 'error');
    //             }
    //         }
    //     );
    // };

    const handleCloseClick = async () => {

        var updatedInteractionList = [];
        interactionList.forEach(element => {
            element.comment = element?.comment ? btoaReplacement(element?.comment) : null;
            element.technicalComment = element?.technicalComment ? btoaReplacement(element?.technicalComment) : null;
            element.commentForRelative = element?.commentForRelative ? btoaReplacement(element?.commentForRelative) : null;
            if (!element?.mood) {
                element.mood = 0;
            }
            if (!element?.feedbackMark) {
                element.feedbackMark = 0;
            }
            if (!element?.healthCondition) {
                element.healthCondition = 0;
            }
            var updatedInteraction = new InteractionDto();
            Object.keys(element).forEach(e => {
                if (e === "creationDate") {
                    updatedInteraction[e] = null;
                } else {
                    updatedInteraction[e] = element[e];
                }
            });
            updatedInteractionList.push(updatedInteraction);
        });
        if (generalComment) {
            record.comments = generalComment;
        }
        setDataBaseOperationInProgress(true);
        //await scheduledActivityClient.scheduledActivityPut(transformScheduledActivityEdit(record));
        var success = false
        Promise.all([
            scheduledActivityClient?.comments({ id: record?.id, comments: generalComment }),
            interactionsClient?.many(updatedInteractionList)
        ]).then(() => {
            success = true
        }).finally(() => {
            setDataBaseOperationInProgress(false);
            updatedInteractionList.forEach((element) => {
                element.comment = element?.comment ? atob(element?.comment) : null;
                element.technicalComment = element?.technicalComment ? atob(element?.technicalComment) : null;
                element.commentForRelative = element?.commentForRelative ? atob(element?.commentForRelative) : null;
            })
            setInteractionList(updatedInteractionList)
            if (success) {
                record.interactions = updatedInteractionList
                setshowDialog(false);
            }
        })
    };
    function changeTabValue(index) {
        setTabValue(index);
        console.warn("changeTab " + index);
    }

    const handleCloseDialog = () => {
        setshowDialog(false);
    }

    if (props?.loading && !props?.identity) {
        return <div></div>;
    }

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme?.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme?.spacing(1),
            top: theme?.spacing(1),
            color: theme?.palette?.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes?.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        !props?.identity ? null :
            <>
                <Button
                    onClick={(event) => handleClick(event, record)}
                    label="Editar feedback"
                    icon="card-account-mail"
                    disabled={props?.identity?.roles === "CONTRACTING_AUTHORITY"}
                >
                    <AccountCircleIcon />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
                {/* <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                >
                    <DialogTitle onClose={handleCloseClick}>Editar Comentario</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            save={handleSubmit}
                            validate={false}
                            redirect={location.pathname}
                            mode="edit"
                            loading={props.loading}
                            handleCloseClick={handleCloseClick}
                            initialValues={record}>
                            <RichTextInput label="" source="comments" validate={validateComment} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> */}
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseDialog}
                    scroll="body"
                >
                    <DialogTitle>Feedback de la Sesión</DialogTitle>
                    <DialogContent>
                        {(interactionList && interactionList?.length > 0) ?
                            <div>
                                <label style={{ fontSize: '0.7rem' }}>Comentario</label>
                                <ReactQuill label="Comentario" value={generalComment} onChange={(val) => onChangeGeneralComment(val)} className='generalCommentQuill' />
                                <MUITabs value={tabValue}>
                                    {interactionList.map((interaction, index) =>
                                        <MUITab label={interaction?.client?.name + " " + interaction?.client?.surnames} onClick={() => changeTabValue(index)} />
                                    )}
                                </MUITabs>
                                {interactionList.map((interaction, index) =>
                                    <div
                                        role="tabpanel"
                                        hidden={tabValue !== index}>
                                        <div style={{ display: 'flex', marginTop: "0.5rem" }}>
                                            <label style={{ fontSize: '0.7rem', alignSelf: 'flex-start' }}>Detalles
                                            </label>
                                            <label style={{ fontSize: '0.7rem', position: 'relative', left: '29.5rem' }}>Juicio técnico
                                            </label>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <ReactQuill className="commentQuill" value={interaction?.comment} onChange={(val) => onChangeComment(val, index)} style={{ width: '47%' }} />
                                            <ReactQuill className="commentQuill" value={interaction?.technicalComment} onChange={(val) => onChangeTechComm(val, index)} style={{ width: '47%' }} />
                                        </div>
                                        {/* <div style={{ display: 'flex', marginTop: "0.5rem" }}> */}
                                        <label style={{ fontSize: '0.7rem', marginTop: '0.5rem', alignSelf: 'flex-start' }}>Información visible por terceras partes
                                        </label>
                                        {/* </div> */}
                                        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                                        <ReactQuill label="Información visible por terceras partes" className="generalCommentQuill" value={interaction?.commentForRelative} onChange={(val) => onChangeRelativesComm(val, index)} style={{ width: '100%' }} />
                                        {/* </div> */}
                                        <div style={{ display: 'flex' }}>
                                            <Labeled label="Estado de ánimo">
                                                <StarPicker style={{ marginTop: 10 }} onChange={(val) => onStarClickMood(val, index)} value={interaction?.mood}
                                                    numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Feedback sesión">
                                                <StarPicker style={{ marginTop: 10, margingLeft: '3 rem' }} onChange={(val) => onStarClickFeedback(val, index)}
                                                    value={interaction?.feedbackMark} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Estado físico">
                                                <StarPicker style={{ marginTop: 20, margingLeft: '3 rem' }} onChange={(val) => onStarClickHealth(val, index)}
                                                    value={interaction?.healthCondition} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                        </div>
                                    </div>
                                )}
                            </div> : <p>No ha habido asistentes a esta reunión</p>
                        }
                        {databaseOperationInProgress ? <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}><CircularProgress /> Por favor espere</Box> : null}
                    </DialogContent>
                    <DialogActions>
                        <MUIButton
                            style={{ backgroundColor: '#2196f3' }}
                            label="Cancelar"
                            onClick={handleCloseDialog}
                        >
                            Cancelar
                        </MUIButton>
                        <MUIButton
                            style={{ backgroundColor: '#2196f3' }}
                            label="Guardar"
                            onClick={handleCloseClick}
                        >
                            Guardar
                        </MUIButton>
                    </DialogActions >
                </Dialog >
            </>
    )
}

const DeleteScheduledActivityButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar actividades de la agenda es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="scheduledActivities"
                    record={record}
                    redirect={location?.pathname}
                    label="borrar agenda"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

const transformScheduledActivityEdit = (data) => {
    const startDate = data?.startDate ? moment(data?.startDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const endDate = data?.endDate ? moment(data?.endDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const activity = { id: data?.activity?.id };
    const realStartDate = data?.realStartDate ? moment(data?.realStartDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const realEndDate = data?.realEndDate ? moment(data?.realEndDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        startDate,
        endDate,
        activity: activity,
        interactions: null,
        realStartDate: realStartDate,
        realEndDate: realEndDate
    }
}

const transformActivitiesCreate = (data) => {
    // Horas a fecha null setteando horas
    let initHour = data.initHour + ':00';
    let endHour = data.endHour + ':00';
    // Pasamos fechas a formato UTC Z
    const initDate = data?.initDate ? moment(data?.initDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    if(!data?.primaryTypeId) data.secondaryTypeId = null;

    return {
        ...data,
        participants: data?.participants?.map(e => e?.id),
        activityId: data?.id,
        initHour,
        endHour,
        initDate,
        professionalId: data?.professional?.id,
        relatedContractId: data?.relatedContract?.id
    }
}

const transformActivitiesUpdate = (data) => {
    if(!data?.primaryTypeId) data.secondaryTypeId = null;
    return {
        ...data,
        participants: data?.participants?.map(e => e?.id),
        activityId: data?.id,
        professionalId: data?.professional?.id,
        relatedContractId: data?.relatedContract?.id
    }
}
