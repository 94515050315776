import { useFormState, useForm } from 'react-final-form';
import { BooleanInput } from "ra-ui-materialui";

export const BooleanInputCustom = (props) => {
    const { source, isDisabled, identity, contractId  } = props;
    const form = useForm();
    const { values } = useFormState();

    const position = values?.contracts?.findIndex(contract => contract?.id === contractId);
    var result = values?.contracts?.find(contract => contract?.isMain === true) || false;
    const isAnyMain = result ? true : false;

    var isDisable = (values?.mainContractId !== contractId && values?.mainContractId !== null && values?.mainContractId !== undefined) || isDisabled || (identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN");

    if (!isAnyMain) isDisable = false;

    
    const handleChange = (event) => {

        if (event) {
            if (contractId !== undefined) {
                form.change('mainContractId', contractId);
            }
        } else {
            form.change('mainContractId', null);
        }
    };


    return (
        <BooleanInput
            key={contractId}
            label={`Es principal`}
            source={`contracts[${position}].${source}`}
            disabled={isDisable || (identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN")}
            //validate={[validateMainContract !== undefined ? validateMainContract : ""]}
            onChange={(newValue) => handleChange(newValue)}
            //validate={(value) => values?.mainContractId !== null && values?.mainContractId === record?.id ? 'Este campo es requerido' :  undefined}
            style={{ paddingLeft: 40 + 'px' }} />
    )

}