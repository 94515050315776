import {
    Datagrid, List, TextField, NumberField, Edit, TextInput, AutocompleteInput, Create, Button, DeleteButton,
    BooleanInput
} from "ra-ui-materialui";
import { useEffect, useState } from "react";
import { getContactType } from "../services/master-tables.service";
import { useLocation } from 'react-router';
import { useField } from 'react-final-form';
import {
    SaveButton,
    useRefresh,
    useNotify,
    useCreate,
    useUpdate,
    SimpleForm,
    useInput,
    regex,
    email,
    Toolbar,
    useRecordContext,
} from "react-admin";
import { PostBulkActionButtons } from "./utils";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ActionDelete from '@material-ui/icons/Delete';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm, useFormState } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField as MuiTextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ContactClient, CreateContactDto } from "../services/ohl.virtualCenter.api";

const apiClient = new ContactClient(process.env.REACT_APP_PUBLIC_API);
const regexJustLetters = /[0-9`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]+/;
const regexNif = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;
const regexPhone = /^\+?([0-9\s]){9,20}$/;
const regexMail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const validarDNIoNIE = (dni) => {
    var numero, letr, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        } else {
            //alert('Dni correcto');
            return true;
        }
    } else {
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

export const ContactList = props => (
    <List bulkActionButtons={props.permissions.contacts.delete ? <PostBulkActionButtons /> : false} exporter={false} {...props}>
        <Datagrid rowClick="edit" isRowSelectable={record => props.permissions.contacts.delete}>
            <TextField label="Nombre" source="name" />
            <TextField label="Descripción" source="description" />
            <NumberField label="Profesional" source="professional.name" />
        </Datagrid>
    </List>
);

export const ContactEdit = (props) => {

    // Read the post_id from the location
    const location = useLocation();
    const post_id =
        location.state && location.state.record
            ? location.state.record.clientId
            : undefined;

    const redirect = post_id ? `/users/${post_id}/3` : false;

    return (
        // <Edit mutationMode="pessimistic" {...props} transform={transformContactEdit}>
        <Edit mutationMode="pessimistic" {...props}>
            {ContactForm(redirect)}
        </Edit>
    )
};

export const ContactCreate = (props) => {

    // Read the post_id from the location
    const location = useLocation();
    const post_id =
        location.state && location.state.record
            ? location.state.record.clientId
            : undefined;

    const redirect = post_id ? `/users/${post_id}/3` : false;

    return (
        <Create mutationMode="pessimistic" {...props}>
            {ContactForm(redirect)}
        </Create>
    )
};

const ContactForm = (props) => {

    const [disableInputs, setDisableInputs] = useState(false);
    const [formSaved, setFormSaved] = useState(false);

    const validateContact = (values) => {
        const errors = {};

        if (values.canUseAppFamiliares){
            if (!disableInputs){
                if(!values.identificationNumber?.trim()){
                    errors.identificationNumber = 'Requerido';
                } 
                if(!values.name?.trim()){
                    errors.name = 'Requerido';
                } 
                if(!values.surnames?.trim()){
                    errors.surnames = 'Requerido';
                } 
                if(!values.email?.trim()){
                    errors.email = 'Requerido';
                } 
                if(!values.phone1?.trim()){
                    errors.phone1 = 'Requerido';
                }
            }
        }else{
            if (!values.identificationNumber?.trim() && !values.name?.trim() && 
                !values.surnames?.trim() && !values.email?.trim() && !values.phone1?.trim() && 
                !values.phone2?.trim()){
                errors.identificationNumber = 'Uno de estos campos es requerido';
                errors.name = 'Uno de estos campos es requerido';
                errors.surnames = 'Uno de estos campos es requerido';
                errors.phone1 = 'Uno de estos campos es requerido';
                errors.phone2 = 'Uno de estos campos es requerido';
                errors.email = 'Uno de estos campos es requerido';
            }
        }
        if (!values.typeId){
            errors.typeId = 'Requerido'
        }
        if (props.user.identificationNumber === values.identificationNumber) {
            errors.identificationNumber = 'El NIF/NIE del contacto no puede ser igual al del usuario';
        }
        if (values.email && !disableInputs && !regexMail.test(values.email)) {
            errors.email = 'Debe ser un email válido';
        }
        if (values.name && !disableInputs && regexJustLetters.test(values.name)) {
            errors.name = "Debe contener sólo letras";
        }
        if (values.surnames && !disableInputs && regexJustLetters.test(values.surnames)) {
            errors.surnames = "Debe contener sólo letras";
        }
        if (values.identificationNumber && !disableInputs && (!regexNif.test(values.identificationNumber) || !validarDNIoNIE(values.identificationNumber))) {
            errors.identificationNumber = "No es un NIF/NIE válido";
        }
        if (values.phone1 && !disableInputs && !regexPhone.test(values.phone1)) {
            errors.phone1 = "No es un teléfono válido";
        }
        if (values.phone2 && !disableInputs && !regexPhone.test(values.phone2)) {
            errors.phone2 = "No es un teléfono válido";
        }

        return errors
    }

    const handleSave = async (values) => {
        await props.save(values); // Guarda el formulario
        setFormSaved(true); // Actualiza el estado para indicar que el formulario ha sido guardado
    }

    return (
        <DialogContent>
            <SimpleForm
                save={handleSave}
                validate={validateContact}
                toolbar={<ContactFormToolbar formSaved={formSaved} handleCloseClick={props.handleCloseClick} />}
                handleCloseClick={props.handleCloseClick}
                initialValues={props.initialValues}>
                    <ChildContactForm {...props} disableInputs={disableInputs} setDisableInputs={setDisableInputs}/>
            </SimpleForm>
        </DialogContent>
    )
}

const ChildContactForm = (props) => {
        const validateNameSurnames = !props.disableInputs ? !regex(regexJustLetters, 'Debe contener sólo letras') : null;
        const validatePhones = !props.disableInputs ? regex(regexJustLetters, 'No es un teléfono válido') : null;
        const validateEmail = !props.disableInputs ? email() : null;
        const [contactTypes, setcontactTypes] = useState([]);
        const record = useRecordContext();
        const [canUseAppFamiliares, setCanUseAppFamiliares] = useState(record.canUseAppFamiliares);

        useEffect(() => {
            getContactType().then(
                res => {
                    setcontactTypes(res.elements);
                }
            )
        }, []);

        return (<>
            <NifSelectorField onDisableInputs={(value) => props.setDisableInputs(value)} mode={props.mode} ownIdentification={props.ownIdentification} canUseAppFamiliares={canUseAppFamiliares}/>
            <TextInput label={`Nombre${canUseAppFamiliares?" *":""}`} source="name" style={{ marginTop: "2rem", width: '100%' }} disabled={props.disableInputs} validate={validateNameSurnames} />
            <TextInput label={`Apellidos${canUseAppFamiliares?" *":""}`} source="surnames" style={{ width: '100%' }} disabled={props.disableInputs} validate={validateNameSurnames} />
            <TextInput label={`Teléfono Móvil${canUseAppFamiliares?" *":""}`} source="phone1" style={{ width: '100%' }} disabled={props.disableInputs} validate={validatePhones} />
            <TextInput label="Teléfono Fijo" source="phone2" style={{ width: '100%' }} disabled={props.disableInputs} validate={validatePhones} />
            <TextInput label={`Email${canUseAppFamiliares?" *":""}`} source="email" type="email" style={{ width: '100%' }} validate={validateEmail} disabled={props.disableInputs} />
            {
                contactTypes ?
                    <AutocompleteInput
                        label="Relación *"
                        source="typeId"
                        choices={contactTypes}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            <BooleanInput className="full-padd" label="Usuario de App Familiares" source="canUseAppFamiliares" onChange={value => setCanUseAppFamiliares(value)}/>
        </>);
}

const ContactFormToolbar = (props) => {

    return (
        <Toolbar style={{ justifyContent: 'space-between' }}>
            <Button
                label="ra.action.cancel"
                onClick={props.handleCloseClick}
                disabled={props.loading}
            >
                <CloseIcon />
            </Button>
            <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={props.pristine || props.formSaved}
            />
        </Toolbar>
    );
}

const NifSelectorField = (props) => {
    const [identificationNumberList, setIdentificationNumberList] = useState();
    const [nifValue, setNifValue] = useState();
    const validateNif = regex(regexNif, 'No es un NIF/NIE válido');
    const {
        input: { onChange },
        meta: { touched, error }
    } = useField("identificationNumber");

    const form = useForm();

    useEffect(() => {
        apiClient.getNifList().then(res => {
            let nifList = [];
            nifList.push({ "label": "", "value": "" })
            res.forEach(nif => {
                if (nif !== props.ownIdentification) {
                    let nifElement = {};
                    nifElement.label = nif;
                    nifElement.value = nif;
                    nifList.push(nifElement);
                }
            });
            setIdentificationNumberList(nifList);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Only change nif text when is new value
    const onChangeText = (nextValue) => {
        onChange(nextValue)
        // if (!validarDNIoNIE(nextValue)) setError('No es un NIF/NIE válido')
        onChangeNif(nextValue);
        form.change('identificationNumber', nextValue);
        props.onDisableInputs(false);
    }

    // When a user is selected and have to apply form values
    const onChangeUser = (nextValue) => {
        apiClient.bynif(nextValue).then(res => {
            form.change('name', res.name);
            form.change('surnames', res.surnames);
            form.change('phone1', res.phone1);
            form.change('phone2', res.phone2);
            form.change('email', res.email);
            form.change('identificationNumber', nextValue);
        }).finally(() => {
            onChangeNif(nextValue);
            props.onDisableInputs(true);
        })
    }

    // Internal call to change nif value
    const onChangeNif = (nextValue) => {
        setNifValue(nextValue);
    };

    if (props.mode === 'create' && identificationNumberList) {
        return <NifSelector name="nifSelector" choices={identificationNumberList} error={!!(touched && error) ? error : null}
            onChangeText={(nextValue) => onChangeText(nextValue)} onChangeUser={(nextValue) => onChangeUser(nextValue)} value={nifValue} source="identificationNumber" 
            canUseAppFamiliares={props.canUseAppFamiliares}/>
    }

    if (props.mode === 'edit')
        return <TextInput label={`NIF/NIE${props.canUseAppFamiliares?" *":""}`} source="identificationNumber" validate={validateNif} />

    return null
}

const NifSelector = (props) => {
    const [choices] = useState(props.choices);
    const {
        input: { name },
    } = useInput(props);

    if (props && props.choices && props.choices.length > 0) {
        return <Autocomplete
            id="combo-box-demo"
            name={name}
            onChange={(event, newValue) => {
                if (newValue !== null) {
                    props.onChangeUser(newValue.value);
                }
            }}
            onInputChange={(event, newInputValue) => {
                props.onChangeText(newInputValue);
            }}
            getOptionLabel={option => option.label}
            options={choices}
            freeSolo
            style={{ width: 300 }}
            renderInput={(params) => <MuiTextField {...params} error={!!props.error} helperText={props.error} label={`NIF/NIE${props.canUseAppFamiliares?" *":""}`} />}
        />;
    } else {
        return null;
    }
};

export const AddNewContactsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const { values } = useFormState();
    const notify = useNotify();
    const [create, { loading }] = useCreate('contacts');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        const createContact = new CreateContactDto({
            email: values.email,
            name: values.name,
            phone1: values.phone1,
            phone2: values.phone2,
            surnames: values.surnames,
            typeId: values.typeId,
            identificationNumber: values.identificationNumber,
            clientId: record.id,
            canUseAppFamiliares: values.canUseAppFamiliares,
            secret2FA: values.secret2FA,
            qrCodeBase64: values.qrCodeBase64,
            url2FA: values.url2FA
        });
        create(
            { payload: { data: createContact } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar contacto"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar contactos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir contacto</DialogTitle>
                <DialogContent>
                    <ContactForm save={handleSubmit} redirect={location.pathname} mode="create" loading={loading} handleCloseClick={handleCloseClick} user={values} ownIdentification={record.identificationNumber}>
                    </ContactForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

export const DeleteContactsButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar contactos es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="contacts"
                    record={record}
                    redirect={location.pathname}
                    label="borrar contacto"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

export const EditContactsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const { values } = useFormState();
    const notify = useNotify();
    const [edit, { loading }] = useUpdate('contacts');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { ...values } },
            // { payload: { ...values } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Editar contacto"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Editar contacto</DialogTitle>
                <DialogContent>
                    <ContactForm
                        save={handleSubmit}
                        redirect={location.pathname}
                        mode="edit"
                        loading={loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                        user={values}
                    ></ContactForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

// const transformContactEdit = (data) => {
//     if (data.contactRelatives) {
//         data.contactRelatives.forEach((key, index) => {
//             if (key.relatedClient && key.relatedClient.contracts) {
//                 key.relatedClient.contracts = null;
//             }
//         });
//     }
//     return {
//         ...data,
//         contactRelativeId: [data.contactRelativeIds[0]]
//     }
// }