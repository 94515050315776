import {
    RolePermission, UsersPermissions, ProfessionalPermissions, ContactsPermissions,
    UserHistoryPermissions, DevicesPermissions, ComunicationDevicesPermissions, CameraDevicesPermissions
} from "./permisions.model"

export default new RolePermission([
    new UsersPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new ProfessionalPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new ContactsPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new UserHistoryPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new DevicesPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new ComunicationDevicesPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new CameraDevicesPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
])