import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import FavoriteIcon from '@material-ui/icons/Favorite';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import GenderIcon from "@material-ui/icons/Wc";
import DependencyDegreeIcon from "@material-ui/icons/Accessible";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import BaseportIcon from '@material-ui/icons/SettingsInputAntenna';
import GroupIcon from '@material-ui/icons/Group';
import MemoryIcon from '@material-ui/icons/Memory';
import RouterIcon from '@material-ui/icons/Router';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PowerIcon from '@material-ui/icons/Power';
import { useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { CreateOrUpdateMasterTable, MasterTableClient, OhlConstants_MastertableType } from '../services/ohl.virtualCenter.api';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import { resetCache } from '../services/master-tables.service';

const apiClient = new MasterTableClient(process.env.REACT_APP_PUBLIC_API);

const masterTableConfiguration = {
    elements: [
        // {
        //     name: 'provinces',
        //     icon: <ApartmentIcon />,
        //     query: {
        //         "type": "provinces",
        //         "subType": "108"
        //     }
        // }, 
        // {
        //     name: 'countries',
        //     icon: <PublicIcon />,
        //     query: {
        //         "type": "countries"
        //     }
        // }, 
        // {
        //     name: 'languajes',
        //     icon: <GTranslateIcon />,
        //     query: {
        //         "type": "languajes"
        //     }
        // }, 
        {
            name: 'client.likes',
            icon: <FavoriteIcon />,
            query: {
                "type": "client.likes"
            },
            enum: OhlConstants_MastertableType.Clientlikes
        },
        {
            name: 'physical.status',
            icon: <AccessibilityIcon />,
            query: {
                "type": "physical.status"
            },
            enum: OhlConstants_MastertableType.Physicalstatus
        },
        {
            name: 'physical.cognitive.status',
            icon: <EmojiPeopleIcon />,
            query: {
                "type": "physical.cognitive.status"
            },
            enum: OhlConstants_MastertableType.Physicalcognitivestatus
        },
        // {
        //     name: 'client.type',
        //     icon: <AccessibilityIcon />,
        //     query: {
        //         "type": "client.type"
        //     }
        // }, 
        // {
        //     name: 'professional.type',
        //     icon: <SupervisedUserCircleIcon />,
        //     query: {
        //         "type": "professional.type"
        //     }
        // }, 
        {
            name: 'contact.type',
            icon: <AssignmentIndIcon />,
            query: {
                "type": "contact.type"
            },
            enum: OhlConstants_MastertableType.Contacttype
        },
        // {
        //     name: 'communication.device.type',
        //     icon: <WifiIcon />,
        //     query: {
        //         "type": "communication.device.type"
        //     }
        // }, 
        {
            name: 'workingcenter',
            icon: <BusinessCenterIcon />,
            query: {
                "type": "workingcenter"
            },
            enum: OhlConstants_MastertableType.Workingcenter
        },
        {
            name: 'activity.type',
            icon: <LocalActivityIcon />,
            query: {
                "type": "activity.type"
            },
            enum: OhlConstants_MastertableType.Activitytype,
            hasChild: true,
            child: {
                name: 'activity.typeValue',
                query: {
                    "type": "activity.typeValue"

                },
                enum: OhlConstants_MastertableType.ActivitytypeValue
            }
        },
        {
            name: 'client.gender',
            icon: <GenderIcon />,
            query: {
                "type": "client.gender"
            },
            enum: OhlConstants_MastertableType.Gender
        },
        {
            name: 'client.dependencyDegree',
            icon: <DependencyDegreeIcon />,
            query: {
                "type": "client.dependencyDegree"
            },
            enum: OhlConstants_MastertableType.DependencyDegree
        },
        {
            name: 'client.therapeuticProfile',
            icon: <AccountCircleIcon />,
            query: {
                "type": "client.therapeuticProfile"
            },
            enum: OhlConstants_MastertableType.TherapeuticProfile
        },
        {
            name: 'client.civilStatus',
            icon: <GroupIcon />,
            query: {
                "type": "client.civilStatus"
            },
            enum: OhlConstants_MastertableType.ClientCivilStatus
        },
        {
            name: 'client.profession',
            icon: <BusinessIcon />,
            query: {
                "type": "client.profession"
            },
            enum: OhlConstants_MastertableType.ClientProfession
        },
        //Communication Devices
        {
            name: 'communicationDevices.plugModel',
            icon: <PowerIcon />,
            query: {
                "type": "communicationDevices.plugModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesPlugModel
        },
        {
            name: 'communicationDevices.baseportModel',
            icon: <BaseportIcon />,
            query: {
                "type": "communicationDevices.baseportModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesBaseportModel
        },
        {
            name: 'communicationDevices.firmwareModel',
            icon: <MemoryIcon />,
            query: {
                "type": "communicationDevices.firmwareModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesFirmwareModel
        },
        {
            name: 'communicationDevices.tvboxModel',
            icon: <LiveTvIcon />,
            query: {
                "type": "communicationDevices.tvboxModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesTvboxModel
        },
        {
            name: 'communicationDevices.cameraModel',
            icon: <PhotoCameraIcon />,
            query: {
                "type": "communicationDevices.cameraModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesCameraModel
        },
        {
            name: 'communicationDevices.routerModel',
            icon: <RouterIcon />,
            query: {
                "type": "communicationDevices.routerModel"
            },
            enum: OhlConstants_MastertableType.CommunicationDevicesRouterModel
        },
        // {
        //     name: 'activity.typeValue',
        //     icon: <LocalActivityIcon />,
        //     query: {
        //         "type": "activity.typeValue"
        //     },
        //     enum: OhlConstants_MastertableType.ActivitytypeValue
        // }
    ]
}

export const MasterTableList = props => {
    const [masterTable, setmasterTable] = useState(null)
    const [masterTableData, setmasterTableData] = useState(null)
    const [open, setOpen] = useState(null);
    const [openNew, setOpenNew] = useState(false);
    const [newValue, setNewvalue] = useState(null);
    const [newValueNew, setNewvalueNew] = useState(null);
    const [error, setError] = useState(null);
    const [errorNew, setErrorNew] = useState(null);
    const [hasChild, setHasChild] = useState(false);

    useEffect(() => {
        if (masterTable) {
            masterTable.hasChild && setHasChild(true);
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterTable])

    const findSubTypeinMasterTableData = () => {
        const obj = masterTableData?.find(e => e.subType);
        return obj?.subType ? obj?.subType : null;
    }

    const getData = () => {
        apiClient.single(masterTable.query).then(
            res => setmasterTableData(res.elements)
        )
    }

    const getDataChild = (subType) => {
        apiClient.single(masterTable.child.query, subType).then(
            res => {
                const result = res.elements.filter(filterByCode => filterByCode.subType === subType);
                setmasterTableData(result);
            }

        )
    }

    const handleClickOpen = (value) => {
        setOpen(value);
    };

    const handleClickOpenNew = () => {
        setOpenNew(true);
    };

    const handleClose = () => {
        setOpen(null);
        setNewvalue(null);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
        setNewvalue(null);
    };

    const handleSend = () => {
        if (!newValue) {
            return setError('Requerido')
        }
        const newObj = open;
        newObj.value = newValue;
        newObj.subType ? newObj.type = masterTable.child.enum : newObj.type = masterTable.enum;
        apiClient.masterTable([newObj]).then(
            res => {
                newObj.subType ? getDataChild(newObj.subType) : getData();
                handleClose()
                resetCache([masterTable.name])
            }
        )
    };

    const handleSendNew = () => {
        if (!newValueNew) {
            return setErrorNew('Requerido')
        }
        const newObj = new CreateOrUpdateMasterTable();
        newObj.value = newValueNew;
        const subType = findSubTypeinMasterTableData();
        subType ? newObj.type = masterTable.child.enum : newObj.type = masterTable.enum;
        newObj.subType = subType;
        apiClient.masterTable([newObj]).then(
            res => {
                newObj.subType ? getDataChild(newObj.subType) : getData();
                handleCloseNew()
                resetCache([masterTable.name])
            }
        )
    };

    const handleChange = (event) => {
        setNewvalue(event.target.value);
        setError(null)
    };

    const handleChangeNew = (event) => {
        setNewvalueNew(event.target.value);
        setErrorNew(null)
    };

    const handleChild = (row) => {
        setHasChild(false);
        getDataChild(row.code);
    }
    
    const translate = useTranslate();

    if (!masterTable) {
        return (
            <Paper elevation={3} style={{ padding: 20, margin: 50 }}>
                <List component="nav" aria-label="main mailbox folders">
                    {
                        masterTableConfiguration.elements.map(e =>
                        (
                            <ListItem key={e.id} button onClick={(event) => setmasterTable(e)}>
                                <ListItemIcon>
                                    {e.icon}
                                </ListItemIcon>
                                <ListItemText primary={translate('masterTables.' + e.name)} />
                            </ListItem>
                        )
                        )
                    }

                </List>
            </Paper>
        )
    } else {
        if (!masterTableData) {
            return (
                <Paper elevation={3} style={{ padding: 20, margin: 50 }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                        <CircularProgress />
                    </div>
                </Paper>
            )
        }
        return (
            <>
            <Paper elevation={3} style={{ padding: 20, margin: 50 }}>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Id</TableCell>
                                    <TableCell align="right">Código</TableCell> */}
                                    <TableCell align="left">Tipo</TableCell>
                                    <TableCell align="right" sortDirection="asc">Valor ↓</TableCell>
                                    <TableCell align="right"></TableCell>
                                    {hasChild &&<TableCell align="right"></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {masterTableData.map((row) => (
                                    <TableRow key={row.id}>
                                        {/* <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.code}</TableCell> */}
                                        <TableCell align="left">{translate('masterTables.' + row.type)}</TableCell>
                                        <TableCell align="right">{row.value}</TableCell>
                                        <TableCell align="right">
                                            <Button onClick={() => { handleClickOpen(row) }} color="primary">Editar</Button>
                                        </TableCell>
                                        {hasChild && <TableCell align="right" style={{width:150}}>
                                            <Button onClick={() => { handleChild(row) }} color="primary">Ver Hijos</Button>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={() => { handleClickOpenNew() }} >Crear</Button>
                        <Button variant="contained" color="primary" onClick={() => { setmasterTable(null); setmasterTableData(null); setHasChild(false) }} >Volver</Button>
                    </div>
                </Paper>
                <Dialog open={!!open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Editar</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>
                                Recuerde que la modificación de un valor de tablas maestras supone que se modificará cada entidad en la que esté asignado.
                            </span>
                            <span style={{ marginTop: 20, marginLeft: 20 }}>
                                Categoría: {open && translate('masterTables.' + open.type)}
                            </span>
                            <span style={{ marginTop: 10, marginLeft: 20 }}>
                                Antiguo valor: {open && open.value}
                            </span>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newValue"
                            label="Nuevo valor"
                            defaultValue={open && open.value}
                            fullWidth
                            onChange={handleChange}
                            error={error}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSend} color="primary">
                            Editar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={!!openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginLeft: 20 }}>
                                Categoría: {findSubTypeinMasterTableData() ? openNew && translate('masterTables.' + masterTable.child.name) : openNew && translate('masterTables.' + masterTable.name)}
                            </span>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newValueNew"
                            label="Nuevo valor"
                            fullWidth
                            onChange={handleChangeNew}
                            error={errorNew}
                            helperText={errorNew}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNew} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSendNew} color="primary">
                            Crear
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}