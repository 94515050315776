import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { getProfessionalType } from '../../services/master-tables.service';

export const ProfessionalTypeField = (props => {
    const { className, source, emptyText } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    const [valueMT, setvalueMT] = useState()
    const translate = useTranslate();

    useEffect(() => {
        getProfessionalType().then(
            res => {
                setvalueMT(res.elements.find(e => {
                    return e.id === value;
                })?.value)
            }
        )
    }, [value])

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >
            {(valueMT != null && typeof valueMT !== 'string') || valueMT === undefined
                ? JSON.stringify(valueMT)
                : translate('professionalTypeId.' + valueMT) || emptyText}
        </Typography>
    );
});