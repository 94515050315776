import { ExternalInformationClient } from "../services/ohl.virtualCenter.api";
const apiClient = new ExternalInformationClient(process.env.REACT_APP_PUBLIC_API);

class UserHistoryProvider {

    getOne = (resource, params) => {
        return apiClient.historyGet(params.id).then((data) => {
            try { data.dependencyStatus = atob(data.dependencyStatus) } catch { }
            try { data.diagnosticInterpretation = atob(data.diagnosticInterpretation) } catch { }
            try { data.familySocialContext = atob(data.familySocialContext) } catch { }
            try { data.home = atob(data.home) } catch { }
            try { data.objectives = atob(data.objectives) } catch { }
            try { data.other = atob(data.other) } catch { }
            return { data: { ...data } }
        });
    }

    getManyReference = (resource, params) => {
        return apiClient.historyGet(params.id).then(
            res => {
                return { data: [res], total: 1 }
            }
        )
    }

    update = (resource, params) => {
        return apiClient.historyPut(params.data).then(
            (data) => {
                return {
                    data: {
                        ...data,
                        id: data.client.id
                    }
                }
            }
        );
    }
};

export default new UserHistoryProvider();