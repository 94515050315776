import * as React from 'react';
import { useState, useEffect } from 'react'
import { Form } from 'react-final-form';
import {
    Button,
    TextField,
    CircularProgress,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import image from '../assets/image/Verde.png'
import './changePasswordPage.css';
import authProvider from '../provider/auth.provider'


import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import { PasswordField } from './login';


const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);


const BasciAppBar = () => {
    return (
        <>
            <div className='navbar'>
                <nav>navbar</nav>
            </div>
        </>
    )
}

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const ChangePasswordPage = (props, mode = 'create') => {
    const [loading, setLoading] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();
    const [userName, setUserName] = useState(localStorage.getItem('userName') ? localStorage.getItem('userName') : '');
    const [open, setOpen] = React.useState(false);
    let authenticateErrorReason = localStorage.getItem('loginErrorReason');
    const [errorMessage, setErrorMessage] = useState(authenticateErrorReason ? authenticateErrorReason : '');

    useEffect(() => {
        let tokenJsonContent = '';
        if (authenticateErrorReason) {
            openErrorAlert(authenticateErrorReason);
        }
        const cloneToken = async () => {
            let token = localStorage.getItem('token');
            if (token && token !== 'null')
                localStorage.setItem('tokenClone', token);
        };
        const getJsonContent = async () => {
            let tokenClone = localStorage.getItem('tokenClone');
            if (tokenClone && tokenClone !== 'null') {
                tokenJsonContent = parseJwt(tokenClone);
            }
        };
        cloneToken();
        getJsonContent();
        if (tokenJsonContent) {
            setUserName(tokenJsonContent.unique_name);
            localStorage.setItem('userName', tokenJsonContent.unique_name)
        }
        localStorage.removeItem('token');
        // eslint-disable-next-line
    }, [])

    const openErrorAlert = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } else {
            setOpen(false);
        }
    }
    const handleSubmit = async () => {
        if (newPassword === "" || confirmPassword === "") {
            openErrorAlert("No puedes dejar ninguno de los 2 campos de contraseña vacío");
            return;
        }
        if (newPassword !== confirmPassword) {
            openErrorAlert("Las contraseñas no coinciden");
            return;
        }
        await localStorage.setItem('token', localStorage.getItem('tokenClone'));
        setLoading(true);
        authProvider.changePassword(localStorage.getItem('loginPassword'), newPassword, confirmPassword)
            .then((res) => {
                localStorage.removeItem('loginPassword')
                history.push('/')
            })
            .catch(
                (error) => {
                    localStorage.setItem('token', '');
                    console.log(error.error.message);
                    setLoading(false);
                    openErrorAlert(error.error.message);
                    return;
                }
            );
    }

    // const validate = (values) => {
    //     const errors = {};
    //     if (!values.newPassword) {
    //         errors.password = translate('ra.validation.required');
    //     }
    //     if (!values.confirmPassword) {
    //         errors.password = translate('ra.validation.required');
    //     }
    //     return errors;
    // };



    return (
        <Form
            onSubmit={handleSubmit}
            // validate={validate}
            render={() => (
                <form onSubmit={handleSubmit}>
                    <div className='navbar' id={<BasciAppBar />} >
                        <div className='ChangePassword-form'>
                            <div className='ChangePassword-form-style'>
                                <img src={image} alt='logo' />
                                <h1 className='title'>¡Bienvenido a Vera!</h1>
                                <p className='name'><span>{userName}</span></p>
                                <p>por motivos de seguridad es necesario</p>
                                <p>que indiques una nueva contraseña</p>
                            </div>
                            <div className='passwordForm'>

                                <React.Fragment>
                                    <PasswordField label="NUEVA CONTRASEÑA"
                                        loading={false} 
                                        value={newPassword}
                                        onChange={p => setNewPassword(p)} />

                                    <PasswordField label="CONFIRMAR CONTRASEÑA"
                                        loading={false} 
                                        value={confirmPassword}
                                        onChange={p => setConfirmPassword(p)} />
                                    {/* <TextField
                                        id='changePassword'
                                        inputProps={{
                                            renderInput
                                        }}
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}
                                        className='newPassword'
                                        margin="normal"
                                        placeholder='NUEVA CONTRASEÑA'
                                        type='password'
                                        color='primary'

                                    />
                                    <TextField
                                        id='confirmPassword'
                                        InputProps={{
                                            renderInput
                                        }}
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        className='checkPassword'
                                        margin='normal'
                                        placeholder='CONFIRMAR CONTRASEÑA'
                                        type='password'
                                    /> */}
                                    <div className='submitButton'>
                                        <Button onClick={handleSubmit}>
                                            Cambiar Contraseña
                                            {loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        <Alert onClose={handleClose} severity="error">
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </form>
            )}
        />
    )
}

const PasswordField = (props) => {
    const [visibility, setVisibility] = useState(false)

    const handleVisibilityClick = () => {
        setVisibility(!visibility)
    }
    return (
        
        <div className='password-input'>
            <TextField
                id='changePassword'
                inputProps={{
                    renderInput
                }}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                className='newPassword'
                placeholder={props.label}
                type={visibility?'text':'password'}
                color='primary'

            />

            <Button
                style={{height: '100%'}}
                onClick={handleVisibilityClick}
                disabled={props.loading}
            >
                {visibility ?<VisibilityIcon />: <VisibilityOffIcon />}    
            </Button>
       </div>
    )
}



ChangePasswordPage.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default ChangePasswordPage
