import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { useEffect, useState } from 'react';
import { getLanguajes } from '../../services/master-tables.service';

export const LanguajeArrayField = (props => {
    const { className, source, emptyText } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    const [valueMT, setvalueMT] = useState()

    useEffect(() => {
        getLanguajes().then(
            res => {
                const array = [];
                value.forEach(element => {
                    array.push(res.elements.find(e => {
                        return e.id === element;
                    }).value)
                });
                setvalueMT(array)
            }
        )
    }, [value])

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >
            {valueMT && typeof valueMT !== 'string'
                ? valueMT.join(', ')
                : valueMT || emptyText}
        </Typography>
    );
});