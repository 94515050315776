import { FunctionField, ImageField } from "react-admin";
import moment from "moment";
import { getCivilStatus, getCountries, getGender, getLanguajes, getProfession, getProvinces, getWorkingCenter } from "../../services/master-tables.service";
import { useEffect, useState } from "react";
import { useFormState } from "react-final-form";
import { ContactClient } from "../../services/ohl.virtualCenter.api";
import { GetApp } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import {  Tooltip } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Box from '@material-ui/core/Box';

import { saveAs } from 'file-saver';
var XLSX = require("xlsx");
const contactClient = new ContactClient(process.env.REACT_APP_PUBLIC_API)

const formatearFecha = (fecha) => fecha ? moment(fecha).format('DD/MM/YYYY') : ''

export const PanelDatosUsuario = (props) => {
    const [estadoCivil, setEstadoCivil] = useState()
    const [genero, setGenero] = useState()
    const [profesion, setProfesion] = useState()
    const [pais, setPais] = useState()
    const [provincia, setProvincia] = useState()
    const [idiomas, setIdiomas] = useState()
    const [centroTrabajo, setCentroTrabajo] = useState()


    const { values } = useFormState();
    const statusLabel = {
        ACTIVE: 'ACTIVO',
        INACTIVE: 'INACTIVO',
        LEAVE: 'BAJA',
    }
    const statusColors = {
        ACTIVE: '#00ab8e',
        INACTIVE: 'rgb(162 160 160 / 82%)',
        LEAVE: 'rgb(242 32 32 / 63%)',
    }

    useEffect(() => {
        getCivilStatus().then(res => setEstadoCivil(res.elements.find(e => e.id === values.civilStatusId)?.value))
        getGender().then(res => setGenero(res.elements?.find(e => e.id === values.genderId)?.value))
        getProfession().then(res => setProfesion(res.elements?.find(e => e.id === values.professionId)?.value))
        getCountries().then(res => setPais(res.elements?.find(e => e.id === values.countryId)?.value))
        getProvinces().then(res => setProvincia(res.elements?.find(e => e.id === values.provinceId)?.value))
        getWorkingCenter().then(res => setCentroTrabajo(res.elements?.find(e => e.id === values.associatedWorkingCenterId)?.value))
        getLanguajes().then(res => setIdiomas(values.languages.map(l => res.elements?.find(e => e.id === l)?.value)?.join(', ')))
        contactClient.client(values.id).then(contactos => props.setContactos(contactos))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const descargar = () => {
        const xlsxBlob = arrayToXlsx([{
            Nombre: values.name,
            Apellidos: values.surnames,
            Género: values.genderIdentity,
            Estado_Civil: estadoCivil,
            Edad: values.age,
            Estado: statusLabel[values.active],
            Fecha_Nacimiento: formatearFecha(values.birthDate),
            Sexo: genero,
            NIF: values.identificationNumber,
            Profesión: profesion,
            Email: values.email,
            Teléfono: values.cellPhone,
            Dirección: values.address,
            País: pais,
            Provincia: provincia,
            Ciudad: values.city,
            Código_Postal: values.postalCode,
            Idiomas: idiomas,
            Centro_de_trabajo: centroTrabajo,
            Personas_de_Contacto: props.contactos?.map(c => renderContact(c))?.join(', '),
        }]);
        saveAs(xlsxBlob, `DatosUsuario_${values.identificationNumber}_${values.name}_${values.surnames}_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`.replaceAll(' ', '_'));
    }

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        const columnWidths = Object.keys(array[0]).map((col) => {
            const maxWidth = array.reduce((max, row) => Math.max(max, (row[col] || "").toString().length), 10);
            return { wch: maxWidth + 5 }; // Ajusta el margen sumando un valor adicional
        });
        worksheet["!cols"] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };
    return (
        <div className="divide-x"
            style={{
                display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr auto',
                width: '100%', padding: '1rem', backgroundColor: '#f0f0f0',
                borderRadius: '8px'
            }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', paddingRight: '1rem', whiteSpace: 'nowrap' }}>
                {
                    values?.picture?.src ? 
                        <ImageField className="dashboard-user-image" source="picture.src" title="title" />
                    :
                    <PersonIcon style={{fontSize: 80}} />
                }
                
                <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr', height: '100%' }}>
                    <FunctionField render={record => record.genderIdentity} />
                    <FunctionField render={record => estadoCivil} />
                    <FunctionField render={record => `${record?.age ? record.age + ' años' : ''}`} />
                    <FunctionField render={record => 
                        <Box component="span" style={{ 
                            backgroundColor: statusColors[record.active], 
                            color: 'white', padding: '0 0.5rem', borderRadius: '0.8em' 
                        }}>
                            {statusLabel[record.active]}
                        </Box> 
                    } />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                <div style={{ display: 'grid', gridTemplateRows: 'repeat(4, 1fr)', height: '100%' }}>
                    <div>
                        <FunctionField render={record => "Fecha de nacimiento: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => formatearFecha(record.birthDate)} />
                    </div>

                    <div>
                        <FunctionField render={record => "Sexo: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (genero || '')} />
                    </div>

                    <div>
                        <FunctionField render={record => "NIF: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => record.identificationNumber} />
                    </div>

                    <div>
                        <FunctionField render={record => "Profesión: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (profesion || '')} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr', height: '100%' }}>
                    <div>
                        <FunctionField render={record => "Email: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record.email || '')} />
                    </div>

                    <div>
                        <FunctionField render={record => "Teléfono: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record.cellPhone || '')} />
                    </div>

                    {/* <div>
                        <FunctionField render={record => "Nacionalidad: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => record.identificationNumber} />
                    </div> */}

                    <div>
                        <FunctionField render={record => "Dirección: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record.address || '')} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr', height: '100%' }}>
                    <div>
                        <FunctionField render={record => "Pais: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (pais || '')} />
                    </div>
                        
                    <div>
                        <FunctionField render={record => "Provincia: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (provincia || '')} />
                    </div>

                    <div>
                        <FunctionField render={record => "Ciudad: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record.city || '')} />
                    </div>

                    <div>
                        <FunctionField render={record => "CP: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record.postalCode || '')} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                <div style={{ display: 'grid', gridTemplateRows: 'auto auto auto auto', height: '100%', gap: 3 }}>
                    <div>
                        <FunctionField render={record => "Idioma: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => idiomas} />
                    </div>

                    <div>
                        <FunctionField render={record => "Centro de trabajo: "} />
                        <FunctionField style={{fontWeight: 'bold'}} render={record => (centroTrabajo || '')} />
                    </div>

                    <Tooltip title={ReactHtmlParser(props.contactos?.length > 3? props.contactos?.map(c => renderContact(c))?.join('<br>'):'')} placement="bottom">
                        <span>
                            <FunctionField render={record => "Personas de contacto: "} />
                            <FunctionField style={{fontWeight: 'bold'}} render={record => props.contactos?.slice(0, 3)?.map(c => renderContact(c))?.join(', ') + (props.contactos?.length > 3 ? '...' : '')} />
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                <GetApp style={{color: "#00ab8e", cursor: "pointer"}} onClick={descargar} />
            </div>
        </div>
    );
}

const renderContact = (c) => {
    var data = ""
    if (c.name && c.surnames) data = c.name + " " + c.surnames
    else if (c.name) data = c.name
    else if (c.surnames) data = c.surnames
    else if (c.phone1) data = c.phone1
    else if (c.phone2) data = c.phone2
    else if (c.email) data = c.email

    return data
}