import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Bar, XAxis, YAxis, Cell, ResponsiveContainer, Tooltip, ComposedChart, Area } from 'recharts';
import { makeStyles } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './AerialWebView.css';
import {
    AerialClient, SleepSummaryQueryDto, ActivitySummaryQueryDto, ActivityPredictionQueryDto, SleepPredictionQueryDto// ,MotionQueryDto
} from "../../services/ohl.virtualCenter.api";
import { useNotify } from "ra-core";
const aerialClient = new AerialClient(process.env.REACT_APP_PUBLIC_API);

// let init;

const CustomTooltip = ({ active, payload, label }) => {
    const convertirHorasAHorasYMinutos = (horasConDecimales) => {
        const horas = Math?.floor(horasConDecimales);
        const minutos = Math?.round((horasConDecimales % 1) * 60);
      
        const horasTexto = horas !== 1 ? `${horas}h` : '1 hora';
        const minutosTexto = minutos !== 1 ? `${minutos}min` : '1 minuto';
      
        if (horas === 0) {
          return minutosTexto;
        } else if (minutos === 0) {
          return horasTexto;
        } else {
          return `${horasTexto} ${minutosTexto}`;
        }
    }
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{
                backgroundColor: 'white', border: "1px solid black", borderRadius: '0.75rem',
                padding: '0.25rem 1rem'
            }} >
                <p className="label">{`${label}`}</p>
                {payload.map(element => {
                    let label2 = element.dataKey;
                    if (label2.includes("sleep")) {
                        label2 = label2.replace("sleep", "Descanso ")
                    }
                    if (label2.includes("interrupt")) {
                        label2 = label2.replace("interrupt", "Interrupción ")
                    }
                    if (label2 !== "predictionRangeLow" && label2 !== "predictionTopLine") {
                        return <p className="content">{label2}: {convertirHorasAHorasYMinutos(element?.value)} minutos</p>
                    } else {
                        return null;
                    }
                })}
            </div >
        );
    }

    return null;
};

export const AerialWebView = props => {
    let { aerialGroupId, currentStartDate, currentEndDate, habitSelector, activeTab, token } = useParams();
    const notify = useNotify();

    const [aerialSelection] = useState(parseInt(habitSelector));
    const [sleepFromDate] = useState(moment(currentStartDate).utc());
    const [sleepToDate] = useState(moment(currentEndDate).utc());
    const [sleepData, setSleepData] = useState([]);
    const [sleepDataChart, setSleepDataChart] = useState([]);
    const [rowsPerPageSleep, setRowsPerPageSleep] = useState(7);
    const [pageSleep, setPageSleep] = useState(0);

    const [activityFromDate] = useState(moment(currentStartDate).format("YYYY-MM-DD"));
    const [activityToDate] = useState(moment(currentEndDate).format("YYYY-MM-DD"));
    const [activityData, setActivityData] = useState([]);
    const [rowsPerPageActivity, setRowsPerPageActivity] = useState(7);
    const [pageActivity, setPageActivity] = useState(0);
    const [currentTab] = useState(parseInt(activeTab));
    const [activityPeriodSelection] = useState('daily');

    useEffect(() => {
        localStorage.setItem('token', token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles((theme) => ({
        thead: {
            borderBottomColor: '#00A896',
            '& th:first-child': {
                borderRadius: '0.5em 0 0 0'
            },
            '& th:last-child': {
                borderRadius: '0 0.5em 0 0'
            },
            backgroundColor: 'white',
            color: '#5D639',
        }
    }))


    const classes = useStyles();

    const StyledTableCellSleep = withStyles((theme) => ({
        head: {
            color: '#29327B',
            borderBottomColor: '#00A896',
            fontSize: 15,
            padding: "3px 3px 3px 3px",
            gap: "2px",
            height: "10px",
            width: "500px",
            textAlign: "center"
        },
        body: {
            color: '#5D639',
            fontSize: 15,
            padding: "1px 4px 1px 4px",
            gap: "2px",
            height: "10px",
            width: "250px",
            textAlign: "center"
        },
    }))(TableCell);

    const StyledTableCellActivity = withStyles((theme) => ({
        head: {
            color: '#29327B',
            borderBottomColor: '#00A896',
            fontSize: 17,
            padding: "3px 3px 3px 3px",
            gap: "2px",
            height: "30px",
            width: "500px",
            textAlign: "center"
        },
        body: {
            color: '#5D639',
            fontSize: 16,
            padding: "1px 4px 1px 4px",
            gap: "2px",
            height: "30px",
            width: "250px",
            textAlign: "center"
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme?.palette?.action?.hover,
            },
        },
    }))(TableRow);

    const CustomTablePagination = withStyles({
        caption: {
            fontSize: "13px", // change the font size here
        },
    })(TablePagination);

    // Define a custom theme with your desired text color
    const theme = createMuiTheme({
        palette: {
            text: {
                primary: '#5D639A',
            },
        },
    });

    const handleChangePage = (event, newPage) => {
        setPageSleep(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPageSleep(parseInt(event?.target?.value));
        setPageSleep(0);
    };

    const sleepRequest = (e) => {
        if (!aerialGroupId) {
            return;
        }
        const query = new SleepSummaryQueryDto();
        query.fromDate = moment(sleepFromDate).format('yyyy-MM-DDT00:00:00+00:00');
        query.toDate = moment(sleepToDate).add(1, 'days').format('yyyy-MM-DDT00:00:00+00:00');
        query.groupId = aerialGroupId;
        var chartJson = [];

        if ((moment(query.toDate)).add(-1, 'days').isBefore(moment(query.fromDate))) {
            notify('La fecha de fin no puede ser menor que la fecha de inicio', 'error')
            return;
        }

        aerialClient.getSleepSummary(query).then(
            res => {
                if (res.summaries) {
                    let sleepSummaries = res.summaries;
                    sleepSummaries.forEach(element => {
                        if (element.sleepDuration != null && element.sleepDuration.value == null) {
                            element.sleepDuration.value = 0;
                        }
                        if (element.nbInterruptions && element.nbInterruptions.value == null) {
                            element.nbInterruptions.value = 0;
                        }
                    })
                    setSleepData(sleepSummaries);
                    // sleepSummaries
                    setSleepData(res.summaries);
                    for (var m = moment(query.fromDate); m.isSameOrBefore(moment(query.toDate).add(2, 'days')); m.add(1, 'days')) {
                        let elementChartJson = {};
                        elementChartJson.timestamp = m?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                        elementChartJson.day = m?.format('DD-MM');
                        elementChartJson.predictionDuration = 0;
                        elementChartJson.predictionRangeLow = 0;
                        elementChartJson.predictionRangeHigh = 0;
                        elementChartJson.predictionTopLine = 0;
                        chartJson.push(elementChartJson);
                        let highestNightSleep;
                        let highestNightSleepIndex;
                        let interruptNightSumm;
                        for (let i = 0; i < sleepSummaries.length; i++) {
                            if (m.format('yyyy-MM-DD') === (moment(sleepSummaries[i].timestamp).utc().format('yyyy-MM-DD'))) {
                                let sleepCounter = 0;
                                let interruptCounter = 0;
                                elementChartJson.sleepDuration = parseFloat(sleepSummaries[i].sleepDuration.value / 60).toFixed(2);
                                if (res.sleepOrInterrupt[i]) {
                                    res.sleepOrInterrupt[i].forEach(element => {
                                        if (element.type === 0) {
                                            sleepCounter++;
                                            elementChartJson["sleep" + sleepCounter] = parseFloat(element.time / 60).toFixed(2);
                                        } else {
                                            interruptCounter++;
                                            elementChartJson["interrupt" + interruptCounter] = parseFloat(element.time / 60).toFixed(2);
                                        }
                                    });
                                }
                                if (elementChartJson.sleep1) {
                                    highestNightSleep = elementChartJson.sleep1;
                                    interruptNightSumm = 0;
                                    for (let i = 1; i <= 10; i++) {
                                        if (elementChartJson["sleep" + i]) {
                                            if (elementChartJson["sleep" + i] > highestNightSleep) {
                                                highestNightSleep = elementChartJson["sleep" + i];
                                                highestNightSleepIndex = i;
                                            }
                                        }
                                        if (elementChartJson["interrupt" + i]) {
                                            interruptNightSumm += parseFloat(elementChartJson["interrupt" + i]);
                                        }
                                    }
                                    if (interruptNightSumm > 0) {
                                        elementChartJson['sleep' + highestNightSleepIndex] -= interruptNightSumm.toFixed(2);
                                    }
                                }
                            }
                        }
                    }
                    const sleepPredictionQuery = new SleepPredictionQueryDto();
                    for (var key in query) {
                        if (query?.hasOwnProperty(key)) {
                            sleepPredictionQuery[key] = query?.[key];
                            if (key === 'toDate') {
                                sleepPredictionQuery[key] = (moment(sleepToDate).utc().add(4, 'days')).format('yyyy-MM-DDTHH:mm:ss+00:00');
                            }
                        }
                    }
                    sleepPredictionQuery.period = 'daily';
                    aerialClient.getSleepPrediction(sleepPredictionQuery).then(
                        res => {
                            res.predictions.filter(checkSleepPrediction).forEach((element, index) => {
                                chartJson.forEach(chartElement => {
                                    if (moment(chartElement.timestamp).utc().format('yyyy-MM-DD') === (moment(element.timestamp).utc().format('yyyy-MM-DD'))) {
                                        chartElement.predictionDuration = element.sleepDuration.value / 60;
                                        chartElement.predictionRangeLow = element.sleepDuration.rangeLow / 60;
                                        chartElement.predictionRangeHigh = element.sleepDuration.rangeHigh / 60;
                                        chartElement.predictionTopLine = ((element.sleepDuration.rangeHigh - element.sleepDuration.rangeLow) / 60);
                                    }
                                });
                            });
                            setSleepDataChart([...chartJson]);
                        }
                    )
                }
            }
        )
    }

    // const checkBedTime = (row) => {
    //     return row.bedTime.value != null;
    // }

    const RenderBars = (sleepTimeHours, sleepPredictionLow, sleepPredictionHigh) => {
        if (sleepPredictionLow <= sleepTimeHours && sleepTimeHours <= sleepPredictionHigh) {
            return <Cell fill="#00A896" />;
        } else {
            return <Cell fill="#F44343" />;
        }
    }

    const renderTickSleep = (value) => {
        if (value === 10) {
            return '10h+'
        } else {
            return value;
        }
    };

    const renderTickActivity = (value) => {
            if (value === 6) {
                return '6h+'
            } else {
                return value + "h";
            }
    };

    const handleActivityChangePage = (event, newPage) => {
        setPageActivity(newPage);
    };

    const handleActivityChangeRowsPerPage = (event) => {
        setRowsPerPageActivity(parseInt(event.target.value));
        setPageActivity(0);
    };

    const activityRequest = (e) => {
        if (!aerialGroupId) { return; }
        let periodFormatter = "yyyy-MM-DD";

        const query = new ActivitySummaryQueryDto();
        query.fromDate = moment(activityFromDate)?.add(-1,'days').format('yyyy-MM-DDTHH:mm:ss+00:00');
        query.toDate = moment(activityToDate)?.utc()?.format('yyyy-MM-DDTHH:mm:ss+00:00');
        query.groupId = aerialGroupId;
        query.period = activityPeriodSelection;
        const activityPredictionQuery = new ActivityPredictionQueryDto();
        for (var key in query) {
            if (query.hasOwnProperty(key)) {
                activityPredictionQuery[key] = query[key];
            }
        }
        activityPredictionQuery.toDate = moment(activityPredictionQuery.toDate).utc().add(3, 'days').format('yyyy-MM-DDTHH:mm:ss+00:00');
        let elementJson = {};
        let listJson = [];
        aerialClient.getActivitySummary(query).then(
            res => {
                if (res.summaries) {
                    for (var m = moment(query.fromDate).utc(); m.isSameOrBefore(moment(query.toDate).utc()); m.add(1, 'days')) {
                        elementJson = {};
                        elementJson.activityTime = 0;
                        elementJson.activityPercentage = 0;
                        elementJson.activityDate = getActivityDate(m.utc().format('yyyy-MM-DDTHH:mm:ss+00:00'), false);
                        elementJson.activityChartDate = getActivityDate(m.utc().format('yyyy-MM-DDTHH:mm:ss+00:00'), true);
                        elementJson.timestamp = m.utc().format('yyyy-MM-DDTHH:mm:ss+00:00');

                        elementJson.predictionRangeLow = 0;
                        elementJson.predictionRangeHigh = 0;
                        elementJson.predictionTopLine = 0;
                        elementJson.predictionValue = 0;
                        // eslint-disable-next-line
                        res.summaries.filter(checkActivity).forEach(element => {
                            if ((m.utc().format(periodFormatter) === moment(element.timestamp).utc().format(periodFormatter))) {
                                let currentMinutes = parseInt(element.nbMinutes.value);
                                elementJson.activityTime = getActivityTime(currentMinutes);
                                elementJson.activityPercentage = getActivityTimeDecimal(currentMinutes, element?.timestamp);
                                elementJson.activityNewPercentage = getActivityPercentage(currentMinutes, element.timestamp);
                                elementJson.activityDate = getActivityDate(m.format('yyyy-MM-DDTHH:mm:ss+00:00'), false);
                                element.activityChartDate = getActivityDate(m.format('yyyy-MM-DDTHH:mm:ss+00:00'), true);
                            }
                        });
                        listJson.push(elementJson);
                    }
                    aerialClient.getActivityPrediction(activityPredictionQuery).then(
                        res => {
                            res.predictions.filter(checkActivityPrediction).forEach((predictionElement, index) => {
                                listJson.forEach((listJsonItem, jsonIndex) => {
                                    if (moment(predictionElement.timestamp).utc().format(periodFormatter) === moment(listJsonItem.timestamp).utc().format(periodFormatter)) {
                                        listJsonItem.predictionRangeLow = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.rangeLow), predictionElement?.timestamp);
                                        listJsonItem.predictionRangeHigh = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.rangeHigh), predictionElement?.timestamp);
                                        listJsonItem.predictionTopLine = listJsonItem?.predictionRangeHigh - listJsonItem?.predictionRangeLow;
                                        listJsonItem.predictionValue = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.value), predictionElement?.timestamp);
                                    };

                                })
                            })
                            setActivityData([...listJson]);
                        }
                    )
                }
            }
        )


    }

    const getActivityPercentage = (minutes, timestamp) => {
        return parseInt((minutes / 840) * 100);
    };

    const getActivityTimeDecimal = (minutes, timestamp) => {
        if (activityPeriodSelection !== "hourly") {
            // Dividir los minutos por 60 para obtener las horas
            let hours = minutes / 60;
            // Redondear las horas a dos decimales
            return Math.round(hours * 100) / 100;
        } else {
            return minutes;
        }
    };

    const getActivityTime = (minutes) => {
        var hours = Math.floor(minutes / 60);
        var mins = minutes - hours * 60;
        if (mins < 10) { mins = "0" + mins }
        return hours + ":" + mins;
    };

    const getActivityDate = (timestamp, showInChart) => {
        if (showInChart) {
            return moment(timestamp)?.utc().add(1,'days')?.format("DD/MM");
        } else {
            return moment(timestamp)?.utc()?.add(1,'days').format("DD/MM/YYYY");
        }
    };

    const checkActivity = (row) => {
        return row.nbMinutes.value != null;
    }

    const checkActivityPrediction = (row) => {
        return row.nbMinutes.rangeLow != null && row.nbMinutes.rangeHigh != null;
    }

    const checkSleepPrediction = (row) => {
        return row.sleepDuration.rangeLow != null && row.sleepDuration.rangeHigh != null;
    }

    const RenderActivityBars = (activityPercentage, activityPercentageRangeLow, activityPercentageRangeHigh) => {
        if (activityPercentageRangeLow <= activityPercentage && activityPercentage <= activityPercentageRangeHigh) {
            return <Cell fill="#66BC31" />;
        } else {
            return <Cell fill="#F31F1F" />;
        }

    }

    useEffect(() => {

        sleepRequest();
        activityRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {

        if (!aerialGroupId) {
            var tabSpans = document.querySelectorAll('.MuiTab-root > .MuiTab-wrapper');
            tabSpans.forEach(tabSpan => {
                if (tabSpan.innerText === 'AERIAL') {
                    tabSpan.parentElement.style.display = 'none';
                }
            });
        }

        var tabs = document.querySelectorAll('.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiTab-root');
        var saveDeleteFooter = document.querySelectorAll('.MuiToolbar-root.MuiToolbar-regular.toolbar.MuiToolbar-gutters');
        tabs.forEach(tab => {
            let tabText = tab.childNodes[0].innerText;
            if (tabText === 'AERIAL') {
                tab.onclick = function () {
                    saveDeleteFooter[0].style.display = 'none';
                }
            } else if (tabText !== "VER LISTADO" && tabText !== "VER GRÁFICA") {
                tab.onclick = function () {
                    saveDeleteFooter[0].style.display = 'flex';
                }
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {aerialSelection === 1 &&
                <>
                    {currentTab === 1 && <ResponsiveContainer width="100%" aspect={1.3} >
                        <ComposedChart style={{ marginTop: "1rem", marginLeft: "-1rem" }} data={sleepDataChart}>
                            <Tooltip content={<CustomTooltip />} />
                            <Area type="monotone" dataKey="predictionRangeLow" fill="transparent" stackId="sl" />
                            <Area type="monotone" dataKey="predictionTopLine" fill="#4278f5" stroke="#4278f5" stackId="sl" />
                            <Bar dataKey="sleep1" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt1" stackId="b" barSize={15} fill="#000000" />
                            <Bar dataKey="sleep2" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt2" stackId="b" barSize={15} fill="#000000" />
                            <Bar dataKey="sleep3" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt3" stackId="b" barSize={15} fill="#000000" />
                            <Bar dataKey="sleep4" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt4" stackId="b" barSize={15} fill="#000000" />
                            <Bar dataKey="sleep5" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt5" stackId="b" barSize={15} fill="#FF0000" />
                            <Bar dataKey="sleep6" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt6" stackId="b" barSize={15} fill="#FF0000" />
                            <Bar dataKey="sleep7" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt7" stackId="b" barSize={15} fill="#FF0000" />
                            <Bar dataKey="sleep8" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt8" stackId="b" barSize={15} fill="#FF0000" />
                            <Bar dataKey="sleep9" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Bar dataKey="interrupt9" stackId="b" barSize={15} fill="#FF0000" />
                            <Bar dataKey="sleep10" stackId="b" barSize={15}>
                                {
                                    sleepDataChart.map((entry, index) => (
                                        <>{RenderBars(entry.sleepDuration, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <XAxis dataKey="day" />
                            <YAxis ticks={[0, 2, 4, 6, 8, 10]} domain={[0, 10]} tickFormatter={renderTickSleep} />
                        </ComposedChart></ResponsiveContainer>}
                    {currentTab === 0 && <>
                        {/* <p>{currentStartDate} {currentEndDate}</p> */}
                        {/* <p>{pageSleep}</p> */}
                        <ThemeProvider theme={theme}>
                            <Table aria-label="customized table" className="AerialWVTables" style={{ whiteSpace: "normal", margin: "auto", marginTop: "1rem", width: "93%" }}>
                                <TableHead classes={{ root: classes.thead }}>
                                    <TableRow>
                                        <StyledTableCellSleep align="left">Fecha</StyledTableCellSleep>
                                        <StyledTableCellSleep align="left">Inicio descanso</StyledTableCellSleep>
                                        <StyledTableCellSleep align="left">Fin descanso</StyledTableCellSleep>
                                        <StyledTableCellSleep align="left" style={{ whiteSpace: "normal", width: "186px" }}>Descanso (horas)</StyledTableCellSleep>
                                        <StyledTableCellSleep align="left" style={{ whiteSpace: "normal", width: "186px" }}>Interrupciones</StyledTableCellSleep>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sleepData.slice(pageSleep * rowsPerPageSleep, pageSleep * rowsPerPageSleep + rowsPerPageSleep).map((row, index) => (
                                        <StyledTableRow key={row?.timestamp}>
                                            <StyledTableCellSleep>
                                                {ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.format('DD/MM')?.toString() + "</b> "))}
                                            </StyledTableCellSleep>
                                            {row?.bedTime?.value ?
                                                (moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").hour() >= 0 && moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").hour() <= 9) ?
                                                    <StyledTableCellSleep align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp).tz("Europe/Madrid")?.format('DD/MM')?.toString() + "</b> "))}  {moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCellSleep>
                                                    : <StyledTableCellSleep align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp))?.format('DD/MM')?.toString() + "</b> ")}  {moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCellSleep>
                                                : <StyledTableCellSleep align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.utc()?.format('DD/MM')?.toString() + "</b> "))} </StyledTableCellSleep>
                                            }
                                            {row?.wakeUpTime?.value ? <StyledTableCellSleep align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.tz("Europe/Madrid"))?.format('DD/MM')?.toString() + "</b> ")}  {moment.utc(row?.wakeUpTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCellSleep>
                                                : <StyledTableCellSleep align="left"></StyledTableCellSleep>}
                                            <StyledTableCellSleep align="left">{moment().startOf('day').minutes(row?.sleepDuration?.value).format('HH:mm')}</StyledTableCellSleep>
                                            <StyledTableCellSleep align="left"> {parseInt(row?.nbInterruptions?.value)}</StyledTableCellSleep>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <CustomTablePagination
                                            count={sleepData.length}
                                            rowsPerPage={rowsPerPageSleep}
                                            page={pageSleep}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[]}
                                            labelRowsPerPage={<span>Filas por página:</span>}
                                            labelDisplayedRows={({ from = pageSleep * rowsPerPageSleep, to = rowsPerPageSleep + rowsPerPageSleep, count = sleepData.length() }) => `Registros totales: ${count}      ---          Del ${from} al ${to} `}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table></ThemeProvider></>}
                </>
            }

            {
                aerialSelection === 2 &&
                <>
                    {currentTab === 1 && <ResponsiveContainer width="100%" aspect={1.3} ><ComposedChart style={{ marginTop: "1rem", marginLeft: "-1.4rem" }} data={activityData}>
                        <Bar dataKey="activityPercentage" barSize={15} radius={[10, 10, 10, 10]}>
                            {
                                activityData.map((entry, index) => (
                                    <>{RenderActivityBars(entry.activityPercentage, entry.predictionRangeLow, entry.predictionRangeHigh)}</>
                                ))
                            }
                        </Bar>
                        <Area type="monotone" dataKey="predictionRangeLow" fill="transparent" stackId="ac" />
                        <Area type="monotone" dataKey="predictionTopLine" fill="#4278f5" stroke="#4278f5" stackId="ac" />
                        <XAxis dataKey="activityChartDate" />
                        <YAxis ticks={[0, 2, 4, 6]} domain={[0, 6]} tickFormatter={renderTickActivity}/>
                    </ComposedChart></ResponsiveContainer>}
                    {currentTab === 0 &&
                        <ThemeProvider theme={theme}>
                            <Table className="AerialWVTables" aria-label="customized table" style={{ whiteSpace: "normal", margin: "auto", marginTop: "1rem", width: "93%" }}>
                                <TableHead classes={{ root: classes.thead }}>
                                    <TableRow>
                                        <StyledTableCellActivity align="left" style={{ width: "318px" }}>Periodo (Días)</StyledTableCellActivity>
                                        <StyledTableCellActivity align="left" style={{ width: "318px" }}>Movimiento (horas)</StyledTableCellActivity>
                                        <StyledTableCellActivity align="left" style={{ width: "237px" }}>% movimiento</StyledTableCellActivity>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activityData.slice(pageActivity * rowsPerPageActivity, pageActivity * rowsPerPageActivity + rowsPerPageActivity).map((row) => (
                                        <StyledTableRow key={row.timestamp}>
                                            <StyledTableCellActivity align="left">{ReactHtmlParser("<b>" + row.activityDate + "</b> ")}</StyledTableCellActivity>
                                            <StyledTableCellActivity align="left">{ReactHtmlParser("<b>" + row.activityTime + "</b> ")}</StyledTableCellActivity>
                                            <StyledTableCellActivity align="left">{row.activityNewPercentage}</StyledTableCellActivity>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <CustomTablePagination
                                            count={activityData.length}
                                            rowsPerPage={rowsPerPageActivity}
                                            page={pageActivity}
                                            onChangePage={handleActivityChangePage}
                                            onChangeRowsPerPage={handleActivityChangeRowsPerPage}
                                            rowsPerPageOptions={[]}
                                            labelRowsPerPage={<span>Filas por página:</span>}
                                            labelDisplayedRows={({ from = pageActivity * rowsPerPageActivity, to = rowsPerPageActivity + rowsPerPageActivity, count = activityData.length() }) => `Registros totales: ${count}      ---          Del ${from} al ${to} `}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </ThemeProvider>}
                </>
            }
        </>
    );

};