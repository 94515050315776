import { useEffect, useState } from "react";
import documentProvider from "../../provider/userDocuments.provider";

const LogoView = props => {
    const { logo, height  } = props;

    const [document, setdocument] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setdocument(await documentProvider.getDocumentLogos('', {
                identificationNumber: logo.contractId,
                documentName: logo.id+'.png'
            }));
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (!document) return null;
    
    return (             
            <img
                style={{
                display: 'block',
                //maxWidth: '110px',
                height: (height || 35) + 'px',
                objectFit: 'contain'
                }}
                id='base64image'
                src={'data:image/jpeg;base64,' + document.data.base64}
                alt="Imagen"
            />
    )
    

};

export default LogoView;
