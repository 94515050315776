import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import {
    useParams
} from "react-router-dom";
import { JitsiClient, LoginClient, LogsClient } from '../services/ohl.virtualCenter.api';

import { JitsiMeeting } from "@jitsi/react-sdk";
// import { connectSockets } from "../services/socket.service"

const apiClient = new LoginClient(process.env.REACT_APP_PUBLIC_API);
const jitsiClient = new JitsiClient(process.env.REACT_APP_PUBLIC_API);
const logsClient = new LogsClient(process.env.REACT_APP_PUBLIC_API);

let init;
let jitsiApi;

class SSEConnection {
    sse;
    cbRegistry= {};

    constructor(room, client, token) {
        this.room = room
        this.client = client
        this.token = token
        this.connect()
        return this
    }

    connect(){
        this.sse = new EventSource(`${process.env.REACT_APP_PUBLIC_API}/api/SSENotifier/suscribe/${this.room}/${this.client}?access_token=${this.token}`);
        this.sse.onerror = () => {
            if (this.sse.readyState === EventSource.CLOSED) {
                setTimeout(() => this.connect(), 3000);
            }

        };
        this.sse.onopen = () => {
            Object.keys(this.cbRegistry).forEach(eventtype => {
                this.sse.addEventListener(eventtype, this.cbRegistry[eventtype])
            })
        };
    }
    
    on(eventtype, cb){
        this.cbRegistry[eventtype] = cb;
        this.sse.addEventListener(eventtype, cb)
        return this
    }
    close() {
        this.sse.close()
    }
}

export const ActivitiesCall = () => {
    let { activityId, token } = useParams();
    // const [logged, setlogged] = useState(null)
    const [userLogged, setUserLogged] = useState(null)
    const [jitsiToken, setJitsiToken] = useState(null)
    const [roomName, setRoomName] = useState(null)

    useEffect(() => {
        if (init) return
        init = true;
        localStorage.setItem('token', token);

        apiClient.current().then(
            resLogged => {
                new SSEConnection(
                    activityId, 
                    resLogged.userId + "webview",
                    token
                ).on("ChangeLayout",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received ChangeLayout to ${e.data}`})
                    jitsiApi.executeCommand('setTileView', { enabled: e.data === 'split' });
                }).on("ChangeMainParticipant",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received ChangeMainParticipant to ${e.data}`})
                    if (e.data === 'auto') {
                        jitsiApi.executeCommand('setTileView', { enabled: true })
                    } else {
                        jitsiApi.pinParticipant(e.data);
                    }
                }).on("ToggleAudioParticipant",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received ToggleAudioParticipant`})
                    jitsiApi.isAudioMuted().then(muted => {
                        logsClient.logs({
                            log: `(${resLogged.identificationNumber}) Participant Audio on Room ${activityId} is ${muted?'muted':'not muted'} so audio ${muted?'has been reactivated':'hasn\'t been changed'}`
                        })
                        if (muted) {
                            jitsiApi.executeCommand('toggleAudio');
                        }
                    });
                }).on("RefreshParticipant",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received RefreshParticipant`})
                    .then(() => {
                        jitsiApi.executeCommand('hangup');
                        window.location.reload();
                    })
                }).on("LowQualityParticipants",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received LowQualityParticipants`})
                    jitsiApi.executeCommand('setVideoQuality', 320)
                }).on("KickParticipant",(e) => {
                    logsClient.logs({log: `(${resLogged.identificationNumber}) Android WEB ROOM SSE ${activityId} Received KickParticipant`})
                    jitsiApi.executeCommand('kickParticipant', e.data);
                })
                console.log("Llamada a jitsiToken")
                jitsiClient.jitsiToken(activityId).then(
                    res => {
                        console.log(res)
                        if(init){
                            setUserLogged(resLogged)
                            setJitsiToken(res.token)
                            setRoomName(res.room)

                        }
                    }
                )
            }
        )
        return () => {
            init = false; // Indicar que el componente se desmonta
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{height: '100vh'}}>
            {
                !!jitsiToken && !!userLogged && !!roomName ?
                    <JitsiMeeting
                        domain = { process.env.REACT_APP_JITSI_PUBLIC_API }
                        roomName = {roomName}
                        configOverwrite = {{
                            prejoinPageEnabled: false, disableDeepLinking: true, lang: 'es',
                            disableLocalVideoFlip: true,
                            disableSelfView: false
                        }}
                        interfaceConfigOverwrite = {{
                        }}
                        userInfo = {{
                            displayName: getUserName(userLogged)
                        }}
                        onApiReady = { (api) => {
                            jitsiApi = api
            
                            api.addEventListeners({
                                videoConferenceJoined: (data) => {
                                    setTimeout(() => {
                                        api.executeCommand('setVideoQuality', 720)
                                    }, 2000)
                                },
                            });
                        } }
                        getIFrameRef = { (iframeRef) => { iframeRef.style.height = '100%'; iframeRef.style.height = '100%'; } }
                        jwt={jitsiToken}
                        spinner={SpinnerCall}
                    />
                : <SpinnerCall />
            }
        </div>
    )
}


function getUserName(userLogged) {
    if (!userLogged) {
      return 'Usuario';
    }
    if (isNonEmptyString(userLogged?.alias)) {
      return userLogged?.alias;
    }
    if (isNonEmptyString(userLogged?.name)) {
      return userLogged?.name;
    }
    return 'Usuario';
}


function isNonEmptyString(value) {
    return value != null && value.trim() !== '';
}

const SpinnerCall = () => {
    return(
        <div style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            flexDirection: 'column'
        }}>
            <div style={{
                width: 300,
                height: 200,
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                flexDirection: 'column'
            }}>
                <h1>Cargando..</h1>
                <CircularProgress />
            </div>
        </div>
    )
}