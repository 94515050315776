import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '../i18n/spanish'


const i18nProvider = polyglotI18nProvider(locale => {
    switch (locale) {
        case 'es':
            return spanishMessages
        //  case 'en':
        //      return englishMessages
        //  case 'fr':
        //      return frenchMessages
        default:
            break;
    }
},
    'es' // Default locale
);

export default i18nProvider;