import {
    RolePermission, UsersPermissions, ProfessionalPermissions, ActivitiesPermissions, InstallationsPermissions,
    ScheduledActivitiesPermissions, ContactsPermissions, ContractsPermissions, DevicesPermissions, 
    ComunicationDevicesPermissions, UserHistoryPermissions, UserInteractionsPermissions, 
    UserDocumentsPermissions, ScheduledActivitiesOldPermissions, HousingPlanDocumentsPermissions,
    CameraDevicesPermissions, RecordsPermissions, CalendarPermissions, HomePermissions
} from "./permisions.model"

export default new RolePermission([
    new UsersPermissions({enabled: true, list: true, create: false, edit: true, show: true, del: false}),
    new ProfessionalPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new ActivitiesPermissions({enabled: true, list: true, create: true, edit: true, show: false, del: false}),
    new ScheduledActivitiesPermissions({enabled: true, list: true, create: false, edit: false, show: true, del: false}),
    new ContactsPermissions({enabled: true, list: false, create: true, edit: true, show: false, del: false}),
    new UserHistoryPermissions({enabled: true, list: false, create: false, edit: true, show: true, del: false}),
    new UserInteractionsPermissions({enabled: true, list: true, create: true, edit: false, show: true, del: false}),
    new UserDocumentsPermissions({enabled: true, list: true, create: true, edit: true, show: true, del: false}),
    new ScheduledActivitiesOldPermissions({enabled: true, list: true, create: false, edit: false, show: false, del: false}),
    new ContractsPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new DevicesPermissions({enabled: true, list: true, create: false, edit: false, show: false, del: false}),
    new ComunicationDevicesPermissions({enabled: true, list: true, create: false, edit: false, show: false, del: false}),
    new CameraDevicesPermissions({enabled: true, list: true, create: false, edit: false, show: false, del: false}),
    new InstallationsPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new HousingPlanDocumentsPermissions({ enabled: true, list: true, create: false, edit: false, show: true, del: false }),
    new RecordsPermissions({ enabled: true, list: false, create: true, edit: true, show: false, del: true }),
    new CalendarPermissions({ enabled: true, list: true, create: false, edit: false, show: false, del: false }),
    new HomePermissions({ enabled: true, list: true, create: false, edit: false, show: false, del: false }),
])