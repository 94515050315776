import { toBase64 } from "../resources/utils";
import { DocumentClient, OhlConstants_TypeDocument } from "../services/ohl.virtualCenter.api";
const apiClient = new DocumentClient(process.env.REACT_APP_PUBLIC_API);

class HousingPlanDocumentsProvider {

    getOne = (resource, params) => {
        return apiClient.typeDocumentGet(params.identificationNumber, params.documentName, OhlConstants_TypeDocument.HousingPlan).then((data) => {
            return { data }
        });
    }


    create = async (resource, params) => {
        const documentDto = {
            name: params.data.base64.title,
            base64: (await toBase64(params.data.base64.rawFile)).split('base64,')[1],
            identificationNumber: params.data.identificationNumber,
            typeDocument: OhlConstants_TypeDocument.HousingPlan
        }
        return apiClient.documentPost(documentDto).then((data) => {
            return {
                data: {
                    ...data,
                    id: 1
                }
            }
        });
    }

    delete = (resource, params) => {
        return apiClient.typeDocumentDelete(params.previousData.identificationNumber, params.previousData.fileName, OhlConstants_TypeDocument.HousingPlan).then((data) => {
            return {
                data: params.previousData
            }
        });
    }


   /*  list = (resource, params) => {
        return apiClient.typeDocumentGet(params.identificationNumber, OhlConstants_TypeDocument.HousingPlan).then(
            res => {
                return { data: res.documents, total: res.length }
            }
        )
    } */

};

export default new HousingPlanDocumentsProvider();