export const btoaReplacement = (stringToReplace) => {
    if(stringToReplace) {
        return btoa(stringToReplace.replaceAll('“', '"').replaceAll('”', '"').replaceAll('‘', "'")
        .replaceAll('’', "'").replaceAll('€', 'euros').replaceAll('—', '-').replaceAll('–', '-')
        .replaceAll('…', '...').replaceAll('•', '*'));
    } else {
        return null;
    }
}

export const hasJustEmptyWhiteSpaces = (value) => {
    if (value.trim() === "") {
        return true
    } else {
        return false;
    }
}