import { ProfessionalClient } from "../services/ohl.virtualCenter.api";

const apiClient = new ProfessionalClient(process.env.REACT_APP_PUBLIC_API);

class ProfessionalProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                // total: parseInt(headers.get('content-range').split('/').pop(), 10),
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        return apiClient.professionalGet(params.id).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;

        return apiClient.list({
            contractId: params.id,
            pageNumber: page,
            pageSize: perPage,
            ...params.filter
        }).then(
            res => {
                return {
                    data: res.elements,
                    total: res.total
                }
            }
        )
    }

    update = async (resource, params) => {

        if (params.data.picture && params.data.picture.rawFile) {
            let convertedFile = await convertFileToBase64(params.data.picture.rawFile);

            return apiClient.professionalPut({ ...params.data, picture: { title: "professionalPortrait", src: convertedFile } }).then((data) => ({
                data
            }));

        } else {
            return apiClient.professionalPut(params.data).then((data) => ({
                data
            }));
        }

    }

    create = async (resource, params) => {
        if (params.data.picture && params.data.picture.rawFile) {
            let convertedFile = await convertFileToBase64(params.data.picture.rawFile);

            return apiClient.professionalPost({ ...params.data, picture: { title: "professionalPortrait", src: convertedFile } }).then((data) => ({
                data
            }));

        } else {
            
            return apiClient.professionalPost(params.data).then((data) => ({
                data
            }));
        }        
    }

    delete = (resource, params) => {
        return apiClient.professionalDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }

    
    getProfile = (resource, params) => {
        return apiClient.profile(params.identificationNumber).then(response => {
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
    }
};


const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default new ProfessionalProvider();