import { CommunicationDeviceClient } from "../services/ohl.virtualCenter.api";

const apiClient = new CommunicationDeviceClient(process.env.REACT_APP_PUBLIC_API);

class CameraDevicesProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if (params.filter) params.filter.communicationDeviceTypeValue = 'Cámara'
            
        if (params.filter && params.filter.assignType) {
            if (params.filter.assignType === 'all') {
                params.filter.assigned = true;
                params.filter.unassigned = true;
            } else if (params.filter.assignType === 'assigned') {
                params.filter.assigned = true;
                params.filter.unassigned = false;
            } else if (params.filter.assignType === 'unassigned') {
                params.filter.assigned = false;
                params.filter.unassigned = true;
            } else {
                params.filter.assigned = true;
                params.filter.unassigned = true;
            }
        }

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        return apiClient.communicationDeviceGet(params.id).then((data) => {
            return { data }
        });
    }

    update = (resource, params) => {
        return apiClient.communicationDevicePut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.communicationDevicePost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.communicationDeviceDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new CameraDevicesProvider();