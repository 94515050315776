import moment from 'moment';
import { ScheduledActivityClient, ActivityClient } from '../services/ohl.virtualCenter.api';

const apiClient = new ScheduledActivityClient(process.env.REACT_APP_PUBLIC_API);
const activityClient = new ActivityClient(process.env.REACT_APP_PUBLIC_API);

class RecordsProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        let { field, order } = params.sort;

        if (field === 'Id') {
            field = 'StartDate';
            order = 'DESC';
        }

        if (params.filter && params.filter.startDateFrom) {
            params.filter.startDateFrom = moment(params.filter.startDateFrom).format('yyyy-MM-DDTHH:mm:ss')
        }
        if (!params.filter.startDateTo) {
            const tomorrow = moment(params.filter.startDateFrom).startOf('day').add(1, 'days');
            params.filter.startDateTo = tomorrow.format('yyyy-MM-DDTHH:mm:ss')
        } else {
            params.filter.startDateTo = moment(params.filter.startDateTo).add(1, 'days').format('yyyy-MM-DDTHH:mm:ss')
        }

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            origin: "BackOffice",
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        return activityClient.activityGet(params.id).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;

        return apiClient.list({
            activityId: params.id,
            pageNumber: page,
            pageSize: perPage,
            origin: "BackOffice"
        }).then(
            res => {
                return {
                    data: res.elements,
                    total: res.total
                }
            }
        )
    }

    update = (resource, params) => {
        if (params?.data && !params?.data?.isRecord){
            params.data.isRecord = true
        }
        return activityClient.activityPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        if (params?.data && !params?.data?.isRecord){
            params.data.isRecord = true
        }
        let professionalId = localStorage.getItem('recordProfessional')
        let participantId = localStorage.getItem('recordParticipant')

        if (professionalId) params.data.professionalId = professionalId
        if (participantId) params.data.participants = [participantId]

        localStorage.removeItem('recordProfessional')
        localStorage.removeItem('recordParticipant')

        return activityClient.activityPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return activityClient.record(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new RecordsProvider();