import {
    Button,
    Datagrid,
    DeleteButton,
    ReferenceManyField,
    TextField
} from "ra-ui-materialui";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useLocation } from 'react-router';
import {
    SaveButton,
    FileInput,
    FileField,
    useRecordContext,
    useNotify,
    FormWithRedirect,
    useCreate,
    useRefresh,
    required
} from "react-admin";
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import userDocumentsProvider from "../provider/userDocuments.provider";
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import NormalButton from '@material-ui/core/Button';
import { useForm } from 'react-final-form';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ActionDelete from '@material-ui/icons/Delete';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

// const validateUserDocuments = (values) => {
//     const errors = {};
//     if (!values.base64 ) {
//         errors.base64 = 'Requerido';
//     }

//     return errors
// }

export const UserDocumentsList = (props) => {

    return (
        <div>
            <ReferenceManyField
                addLabel={false}
                reference="userDocuments"
                target={props.record.identificationNumber}
            >
                <>
                    <Datagrid>
                        <div style={{ width: 50 }} >
                            <DocumentTypeImage />
                        </div>
                        <TextField label="Nombre del fichero" source="name" sortable={false} />
                        {/* Comentado boton descargar */}
                        <DownloadUserDocumentsButton />
                        {
                            props.permissions && props.permissions.userDocuments.show ?
                                <ViewUserDocumentsButton /> : null
                        }
                        {
                            props.permissions && props.permissions.userDocuments.delete ?
                                <DeleteUserDocumentsButton /> : null
                        }
                    </Datagrid>
                    <AddNewUserDocumentsButton {...props} disabled />
                </>
            </ReferenceManyField>
        </div>
    )
}

export const UserDocumentShow = props => {
    // let { userId, documentName } = useParams();
    const { userId, documentName } = props;
    const [document, setdocument] = useState();
    const [numPages, setnumPages] = useState();
    const [page, setpage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setdocument(await userDocumentsProvider.getOne('', {
                identificationNumber: userId,
                documentName: documentName
            }));
        }
        fetchData();
    }, [userId, documentName])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setnumPages(numPages);
    };

    const goToPrevPage = () =>
        setpage(page - 1);
    const goToNextPage = () =>
        setpage(page + 1);

    if (!document) return null
    if (documentName.toLowerCase().includes(".pdf")) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }
            } >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Document
                        file={"data:application/pdf;base64," + document.data.base64}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={page} />
                    </Document>
                </div>
                <nav style={{ width: 600, display: 'flex', justifyContent: 'space-around' }}>
                    <NormalButton onClick={goToPrevPage} variant="contained" color="primary" disabled={page === 1}>
                        Anterior
                    </NormalButton>
                    <p>
                        Página {page} de {numPages}
                    </p>
                    <NormalButton onClick={goToNextPage} variant="contained" color="primary" disabled={page === numPages}>
                        Siguiente
                    </NormalButton>
                </nav>
            </div >);
    }
    else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }
            } >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ display: 'block' }} id='base64image'
                        src={'data:image/jpeg;base64, ' + document.data.base64} alt="Imagen" />
                </div>
            </div>
        )
    }

};

const DocumentTypeImage = (props) => {
    const record = useRecordContext(props);
    var re = /(?:\.([^.]+))?$/;
    return <FileIcon extension={re.exec(record?.name)[1]} type="acrobat1" {...defaultStyles.docx} />
}

const AddNewUserDocumentsButton = (props) => {
    // const {loadingPerm, permissions} = usePermissions();
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [create, { loading }] = useCreate('userDocuments');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: TransformUserDocumentsEdit(values, props.record.identificationNumber) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // if(loadingPerm) return null;
    if (!props.permissions.userDocuments.create) return null;
    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar documento"
            >
                <AddIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar documentos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir documento</DialogTitle>
                <DialogContent>
                    <FormWithRedirect
                        resource="userDocuments"
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving
                        }) => (
                            <>
                                <DialogContent>
                                    <FileInput label="Documento PDF o Imágenes (jpg,png) (Máximo 50mb)" source="base64" accept="application/pdf,.jpg,.jpeg,.png" maxSize={50000000} validate={[required()]}>
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        label="ra.action.cancel"
                                        onClick={handleCloseClick}
                                        disabled={loading}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </DialogActions>
                            </>
                        )}
                    />
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

const DownloadUserDocumentsButton = ({record}) => (
    <Button
        onClick={async ()=>{
            const doc = await userDocumentsProvider.getOne('', {
                identificationNumber: record.identificationNumberUser,
                documentName: record.name
            })
            // const byteCharacters = atob(doc.data.base64);
            // const byteNumbers = new Array(byteCharacters.length);
            // for (let i = 0; i < byteCharacters.length; i++) {
            //     byteNumbers[i] = byteCharacters.charCodeAt(i);
            // }
            // const byteArray = new Uint8Array(byteNumbers);
            // const blob = new Blob([byteArray], {type: 'application/octet-stream'});
            // const blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl)

            const a = document.createElement("a"); //Create <a>
            a.href = "data:application/octet-stream;base64," + doc.data.base64; //Image Base64 Goes here
            a.download = doc.data.name; //File name Here
            a.click(); //Downloaded file
        }}
        label="Descargar"
    >
        <CloudDownloadIcon />
    </Button>
);

const ViewUserDocumentsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = () => {
        setshowDialog(true);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                label="Ver"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Ver documento {record.name}</DialogTitle>
                <DialogContent>
                    <UserDocumentShow userId={record.identificationNumberUser} documentName={record.name}></UserDocumentShow>
                </DialogContent>
                <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};


const DeleteUserDocumentsButton = (props) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar documentos es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="userDocuments"
                    redirect={location.pathname}
                    record={props.record}
                    label="Borrar"
                >
                    <CloseIcon />
                </DeleteButton>
            </>
        )
    }
};

const TransformUserDocumentsEdit = (values, identificationNumber) => {
    values.identificationNumber = identificationNumber;
    return values;
}