import { TownClient } from "../services/ohl.virtualCenter.api";

const apiClient = new TownClient(process.env.REACT_APP_PUBLIC_API);

let townsCache = {};  // Un objeto para almacenar caché basado en el postalCode
let townsPromiseCache = {}; 


let provincesCache = {};  
let provincesPromiseCache = {}; 
class TownProvider {


    getTowns = ({ postCode, id, pageSize }) => {
        if (townsCache[postCode]) {
            return Promise.resolve(townsCache[postCode]);
        }
        
        if (townsPromiseCache[postCode]) {
            return townsPromiseCache[postCode];
        }
    
        townsPromiseCache[postCode] = new Promise((resolve, reject) => {
            apiClient.list({
                pageSize: pageSize,
                id: id,
                postCode: postCode
            })
            .then(res => {
                townsCache[postCode] = res;
                delete townsPromiseCache[postCode]; // Limpiamos la promesa en curso
                resolve(res);
            })
            .catch(err => {
                delete townsPromiseCache[postCode]; // Limpiamos la promesa en caso de error
                reject(err);
            });
        });
    
        return townsPromiseCache[postCode];
    };



    getProvinces = ({ townId }) => {
        if (provincesCache[townId]) {
            return Promise.resolve(provincesCache[townId]);
        }
        
        if (provincesPromiseCache[townId]) {
            return provincesPromiseCache[townId];
        }
    
        provincesPromiseCache[townId] = new Promise((resolve, reject) => {
            apiClient.provinces(townId)
            .then(res => {
                provincesCache[townId] = res;
                delete provincesPromiseCache[townId]; // Limpiamos la promesa en curso
                resolve(res);
            })
            .catch(err => {
                delete provincesPromiseCache[townId]; // Limpiamos la promesa en caso de error
                reject(err);
            });
        });
    
        return provincesPromiseCache[townId];
    };
};

export default new TownProvider();