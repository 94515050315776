import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { useEffect, useState } from 'react';
import { getComunicationDeviceType } from '../../services/master-tables.service';

export const ComunicationTypeField = (props => {
    const { className, source, emptyText } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    const [valueMT, setvalueMT] = useState()

    useEffect(() => {
        getComunicationDeviceType().then(
            res => {
                setvalueMT(res.elements.find(e => {
                    return e.id === value;
                })?.value)
            }
        )
    }, [value])

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >
            {valueMT != null && typeof valueMT !== 'string'
                ? JSON.stringify(valueMT)
                : valueMT || emptyText}
        </Typography>
    );
});