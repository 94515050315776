import { useFormState } from "react-final-form";
import { SmallCalendarCustom } from "../Calendar/SmallCalendar";
import { FunctionField, TextField } from "react-admin";
import { CommunicationDeviceClient, DocumentClient } from "../../services/ohl.virtualCenter.api";
import { useEffect, useState } from "react";
import moment from "moment";
import { getCommunicationDevicesBaseportModel, getCommunicationDevicesCameraModel, getCommunicationDevicesPlugModel, getCommunicationDevicesRouterModel, getCommunicationDevicesTvboxModel } from "../../services/master-tables.service";
import { InsertDriveFile } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import PostIcon from '@material-ui/icons/Book';
import { GetApp } from '@material-ui/icons';
import { mapaPestanas, PESTANA_DOCUMENTOS, PESTANA_HISTORIA_USUARIO, PESTANA_INTERACCIONES } from "./utils";
import ReactHtmlParser from 'react-html-parser';

import { saveAs } from 'file-saver';
var XLSX = require("xlsx");

const formatearFecha = (fecha) => fecha ? moment(fecha).format('DD/MM/YYYY') : ''
const documentClient = new DocumentClient(process.env.REACT_APP_PUBLIC_API)
const cameraClient = new CommunicationDeviceClient(process.env.REACT_APP_PUBLIC_API)

export const PanelActividad = (props) => {
    const { values } = useFormState();
    const [modeloCamara, setModeloCamara] = useState()
    const [modeloRouter, setModeloRouter] = useState()
    const [modeloTVBox, setModeloTVBox] = useState()
    const [modeloBaseport, setModeloBaseport] = useState()
    const [modeloEnchufe, setModeloEnchufe] = useState()
    const [documentos, setDocumentos] = useState()
    const [camara, setCamara] = useState()

    const history = useHistory()

    useEffect(() => {
        documentClient.list(values.identificationNumber).then(resp => setDocumentos(resp.documents))
        if (values?.androidDevice?.associatedCameraId) {
            cameraClient.communicationDeviceGet(values?.androidDevice?.associatedCameraId).then(camera => setCamara(camera))
        }
        getCommunicationDevicesCameraModel().then(res => setModeloCamara(res.elements))
        getCommunicationDevicesTvboxModel().then(res => setModeloTVBox(res.elements?.find(e => e.id === values?.androidDevice?.associatedTvBoxModelId)?.value))
        getCommunicationDevicesRouterModel().then(res => setModeloRouter(res.elements?.find(e => e.id === values?.communicationDevice?.routerModelId)?.value))
        getCommunicationDevicesBaseportModel().then(res => setModeloBaseport(res.elements?.find(e => e.id === values?.clientExternalPartners?.baseportModelId)?.value))
        getCommunicationDevicesPlugModel().then(res => setModeloEnchufe(res.elements))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const indicePestana = (pestana) => {
        return mapaPestanas[pestana][props.identity.roles]
    }

    const newActivityHandler = () => {
        // localStorage.setItem('recordParticipant', values.id)
        history.push('/activities/create')
    }

    const descargar = () => {
        const xlsxBlob = arrayToXlsx([{
            TVBox: values?.androidDevice?.code,
            Fecha_Instalación_TVBox: formatearFecha(values?.androidDevice?.installationDate),
            Modelo_TVBox: modeloTVBox,
            Versión_App: values.veraAppVersion,
            Fecha_Versión_App: formatearFecha(values.veraAppVersionDate),
            Cámara: values?.androidDevice?.code,
            Modelo_Cámara: (modeloCamara?.find(m => m.id === camara?.cameraModelId)?.value || ''),
            Router: values?.communicationDevice?.code,
            Fecha_Instalación_Router: formatearFecha(values?.communicationDevice?.installationDate),
            Modelo_Router: modeloRouter,
            Datos: values?.communicationDevice?.companyContract,
            Linea: values?.communicationDevice?.companyPhone,
            Baseport: values?.clientExternalPartners?.baseportMac,
            Modelo_Baseport: modeloBaseport,
            Enchufe_1: (values?.clientExternalPartners?.thing1Mac || '') + " (" + (modeloEnchufe?.find(m => m.id === values?.clientExternalPartners?.thing1ModelId)?.value || '')+")",
            Enchufe_2: (values?.clientExternalPartners?.thing2Mac || '') + " (" + (modeloEnchufe?.find(m => m.id === values?.clientExternalPartners?.thing2ModelId)?.value || '')+")",
            Enchufe_3: (values?.clientExternalPartners?.thing3Mac || '') + " (" + (modeloEnchufe?.find(m => m.id === values?.clientExternalPartners?.thing3ModelId)?.value || '')+")",
        }]);
        saveAs(xlsxBlob, `Datos_de_Instalacion_${values.identificationNumber}_${values.name}_${values.surnames}_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`.replaceAll(' ', '_'));
    }

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        const columnWidths = Object.keys(array[0]).map((col) => {
            const maxWidth = array.reduce((max, row) => Math.max(max, (row[col] || "").toString().length), 10);
            return { wch: maxWidth + 5 }; // Ajusta el margen sumando un valor adicional
        });
        worksheet["!cols"] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '3rem', width: '100%' }}>
            <div style={{
                display: 'flex', flexDirection: 'column', gap: '1rem',
                backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
            }}>
                <h3>Agenda de Usuario</h3>
                {
                    props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles !== 'AAPP_TECHNICIAN' ?
                        <>
                            <button onClick={newActivityHandler}
                                style={{
                                    background: 'none', color: 'black',
                                    border: 'none', padding: 0, cursor: 'pointer', textAlign: 'left',
                                    display: 'flex', alignItems: 'center'
                                }}>
                                <PostIcon></PostIcon> <h4>Programe una nueva actividad con <TextField source="name" style={{ fontSize: 14, fontWeight: "bold" }}></TextField></h4>
                            </button>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <SmallCalendarCustom id={values.id} isNextMonth={false} includeUsers={true} weightCustom="fit-content" />
                            </div>
                        </>
                        : null
                }
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column', gap: 3,
                backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3>Dispositivos asignados</h3>
                    {
                        props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles !== 'AAPP_TECHNICIAN' && props.identity.roles !== 'CONTRACTING_AUTHORITY' ?

                            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                                <GetApp style={{ color: "#00ab8e", cursor: "pointer" }} onClick={descargar} />
                            </div> 
                        : null
                    }
                </div>
                {
                    props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles !== 'AAPP_TECHNICIAN' && props.identity.roles !== 'CONTRACTING_AUTHORITY' ?
                        <>
                            <div style={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: '1rem' }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'column', gap: 3,
                                    backgroundColor: 'white', borderRadius: '8px', padding: 4
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: 3 }}>
                                        <h4 style={{width: '100%'}}>Videollamada</h4>
                                        <FunctionField render={record => "Fecha Instalación: "} />
                                        <FunctionField style={{width: '8rem', fontWeight: 'bold'}} render={record => formatearFecha(record?.androidDevice?.installationDate)} />
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', rowGap: 3, columnGap: 6, backgroundColor: 'white', padding: 10, borderRadius: 8 }}>
                                        <FunctionField render={record => "TVBox: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.androidDevice?.code || '')} />

                                        <FunctionField render={record => "Cámara: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (camara?.code || '')} />

                                        <FunctionField render={record => "Modelo: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (modeloTVBox || '')} />

                                        <FunctionField render={record => "Modelo: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (modeloCamara?.find(m => m.id === camara?.cameraModelId)?.value || '')} />

                                        <FunctionField render={record => "Versión app: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.veraAppVersion || '')} />

                                        <FunctionField render={record => "Fecha versión app: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.veraAppVersionDate ? formatearFecha(record.veraAppVersionDate) : '')} />
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex', flexDirection: 'column', gap: 3,
                                    backgroundColor: 'white', borderRadius: '8px', padding: 4
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: 3 }}>
                                        <h4 style={{width: '100%'}}>Conectividad</h4>
                                        <FunctionField render={record => "Fecha Instalación: "} />
                                        <FunctionField style={{width: '8rem', fontWeight: 'bold'}} render={record => formatearFecha(record?.communicationDevice?.installationDate)} />
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gap: 3, backgroundColor: 'white', padding: 10, borderRadius: 8 }}>
                                        <FunctionField render={record => "ID: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => record?.communicationDevice?.code} />

                                        <FunctionField render={record => "Datos: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.communicationDevice?.companyContract || '')} />

                                        <FunctionField render={record => "Modelo: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (modeloRouter || '')} />

                                        <FunctionField render={record => "Linea: "} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.communicationDevice?.companyPhone || '')} />

                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex', flexDirection: 'column', gap: 3,
                                    backgroundColor: 'white', borderRadius: '8px', padding: 4
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: 3 }}>
                                        <h4 style={{width: '100%'}}>Presencia</h4>
                                        <FunctionField render={record => "Fecha Instalación: "} />
                                        <FunctionField style={{width: '8rem', fontWeight: 'bold'}} render={record => formatearFecha(record?.clientExternalPartners?.installationDate)} />
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', rowGap: 3, columnGap: 6, backgroundColor: 'white', padding: 10, borderRadius: 8, height: 'fit-content' }}>
                                        <FunctionField render={() => "Baseport"} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (record?.clientExternalPartners?.baseportMac || '')} />
                                        
                                        <FunctionField render={() => "Enchufe 1"} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => ReactHtmlParser((record?.clientExternalPartners?.thing1Mac || ''))} />
                                        
                                        <FunctionField render={() => "Modelo"} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => (modeloBaseport || '')} />
                                        <span></span>
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => modeloEnchufe?.find(m => m.id === record?.clientExternalPartners?.thing1ModelId)?.value || ''} />
                                        <span></span>
                                        <span></span>
                                        <FunctionField render={() => "Enchufe 2"} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => ReactHtmlParser((record?.clientExternalPartners?.thing2Mac || '') )} />
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => modeloEnchufe?.find(m => m.id === record?.clientExternalPartners?.thing2ModelId)?.value || ''} />
                                        <span></span>
                                        <span></span>
                                        <FunctionField render={() => "Enchufe 3"} />
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => ReactHtmlParser((record?.clientExternalPartners?.thing3Mac || ''))} />
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <FunctionField style={{fontWeight: 'bold'}} render={record => modeloEnchufe?.find(m => m.id === record?.clientExternalPartners?.thing3ModelId)?.value || ''} />
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                }
            </div>
            <div style={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: '1rem', width: '100%' }}>
                <div style={{
                    display: 'flex', flexDirection: 'column', gap: 3,
                    backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
                }}>
                    <h3>Valoraciones</h3>
                    <div style={{ display: 'flex', gap: '1rem', backgroundColor: 'white', borderRadius: 8, height: '100%' }}>
                        {/* <a href={`/#/users/${values.id}/${indicePestana(PESTANA_ESCALAS)}`} style={{backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content', width: 'fit-content',
                            textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white', textDecoration: 'none'}} >Escalas</a>
                        <a href={`/#/users/${values.id}/${indicePestana(PESTANA_PAI)}`} style={{backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content', width: 'fit-content',
                            textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white', textDecoration: 'none'}} >PAI</a>
                        <a href={`/#/users/${values.id}/${indicePestana(PESTANA_OBJETIVOS)}`} style={{backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content', width: 'fit-content',
                            textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white', textDecoration: 'none'}} >Objetivos</a> */}
                    </div>
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column', gap: 8,
                    backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
                }}>
                    <h3>Documentación</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 3, backgroundColor: 'white', padding: 10, borderRadius: 8, height: '100%' }}>
                        {props.identity.roles !== 'AAPP_TECHNICIAN' && documentos?.slice(0, 3)?.map(d => {
                            return (
                                <div key={d.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <FunctionField render={() => d.name} />
                                    <InsertDriveFile />
                                </div>
                            )
                        })}
                    </div>
                    {
                        props.identity.roles !== 'AAPP_TECHNICIAN' ?
                            <>
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <a href={`/#/users/${values.id}/${indicePestana(PESTANA_DOCUMENTOS)}`} style={{
                                        backgroundColor: '#aaaaaa', borderRadius: '20px', height: 'fit-content',
                                        textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color: 'white', textDecoration: 'none'
                                    }} >Ver Más</a>
                                </div>
                            </>
                            : null
                    }
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column', gap: 8,
                    backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
                }}>
                    <h3>Seguimiento de Usuario</h3>
                    {
                        props.identity.roles !== 'LOGISTICS_TECHNICIAN' ?
                            <a href={`/#/users/${values.id}/${indicePestana(PESTANA_HISTORIA_USUARIO)}`} style={{
                                backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content', width: 'fit-content',
                                textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color: 'white', textDecoration: 'none'
                            }} >Historia de usuario</a>
                            : null
                    }
                    {
                        props.identity.roles !== 'AAPP_TECHNICIAN' && props.identity.roles !== 'LOGISTICS_TECHNICIAN' ?
                            <a href={`/#/users/${values.id}/${indicePestana(PESTANA_INTERACCIONES)}`} style={{
                                backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content', width: 'fit-content',
                                textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color: 'white', textDecoration: 'none'
                            }} >Interacciones de usuario</a>
                            : null
                    }
                </div>
            </div>
        </div>
    );
}