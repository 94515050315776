import { LoginClient } from "../services/ohl.virtualCenter.api";
import AdminsPermissions from "./permisions/admin.permissions";
import SuperAdminPermissions from "./permisions/superAdmin.permissions";
import DynamicsManagerPermissions from "./permisions/dynamicsManager.permissions";
import AappTechnicianPermissions from "./permisions/AappTechnician.permissions";
import UserHistoryManagerPermissions from "./permisions/userHistoryManager.permissions";
import AllManagerPermissions from "./permisions/allManager.permissions";
import ClientPermissions from "./permisions/client.permissions";
import ContractingAuthorityPermissions from "./permisions/contractingAuthority.permissions";
import relativePermissions from "./permisions/relative.permissions";
import LogisticTechnicianPermissions from "./permisions/LogisticTechnician.permissions";


const apiClient = new LoginClient(process.env.REACT_APP_PUBLIC_API);

export const isPublicUrl = (url) => {
    return (
        url.includes('login')
    )
}

const authProvider = {
    login: ({ username, password, token }) => {
        return apiClient.authenticate({
            identificationNumber: username,
            password: password,
            token: token
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                localStorage.setItem('token', response.accessToken);
                localStorage.setItem('loginErrorReason', response.reason);
                return Promise.resolve(response);
            })
            .catch((err) => {
                throw new Error(err.error.message)
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    changePassword: (oldPassword, newPassword, confirmPassword) => {
        return apiClient.changeDefaultPassword({
            oldPassword: oldPassword,
            newPassword: newPassword,
            repeatNewPassword: confirmPassword
        })
            .then(response => {
                // if (response.status < 200 || response.status >= 300) {
                //     throw new Error(response.statusText);
                // }
                // localStorage.setItem('token', response.accessToken);

                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    checkAuth: () => {
        if (window.location.hash.includes('activityCall')) {
            return Promise.resolve()
        }
        return apiClient.current()
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        return apiClient.current()
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                localStorage.setItem('permissions', response.roles);
                const responseAuth = {
                    ...response,
                    fullName: response.name,
                    avatar: 'https://eu.ui-avatars.com/api/?name=' + response.name
                }
                return responseAuth;
            })
            .catch((error) => {
                if (window.location.hash.includes('activityCall')) {
                    return Promise.resolve()
                }
                authProvider.logout();
                return Promise.reject({ redirectTo: '/login' });
            });
    },
    getPermissions: async () => {
        let role = localStorage.getItem('permissions');
        if (!role) {
            await authProvider.getIdentity();
            role = localStorage.getItem('permissions');
        }

        if (role === 'ADMIN') {
            return Promise.resolve(AdminsPermissions)
        } else if (role === 'RELATIVE') {
            return Promise.resolve(relativePermissions)
        } else if (role === 'SUPER_ADMIN') {
            return Promise.resolve(SuperAdminPermissions)
        } else if (role === 'DYNAMICS_MANAGER') {
            return Promise.resolve(DynamicsManagerPermissions)
        } else if (role === 'AAPP_TECHNICIAN') {
            return Promise.resolve(AappTechnicianPermissions)
        } else if (role === 'USER_HISTORY_MANAGER') {
            return Promise.resolve(UserHistoryManagerPermissions)
        } else if (role === 'ALL_MANAGER') {
            return Promise.resolve(AllManagerPermissions)
        } else if (role === 'CLIENT') {
            return Promise.resolve(ClientPermissions)
        } else if (role === 'CONTRACTING_AUTHORITY') {
            return Promise.resolve(ContractingAuthorityPermissions)
        } else if (role === 'LOGISTICS_TECHNICIAN') {
            return Promise.resolve(LogisticTechnicianPermissions)
        } else {
            return Promise.reject()
        }
    },
    GetAuth: () => {
        return apiClient.current()
    }
};

export default authProvider;
