import moment from "moment"
import { useEffect, useState } from "react"
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MUIButton from '@material-ui/core/Button';
import { LoginClient } from "../services/ohl.virtualCenter.api";
import { isPublicUrl } from "../provider/auth.provider";
const apiClient = new LoginClient(process.env.REACT_APP_PUBLIC_API);

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const SessionExpireDialog = () => {
    const [show, setShow] = useState(false)
    const verifyToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const tokenParsed = parseJwt(token)
            const expiracion = moment.unix(tokenParsed.exp)
            if (moment().isBetween(expiracion.clone().subtract(5, 'minutes'), expiracion)) {
                setShow(true)
            }
        }
    }

    const checkLocation = () => {
        if (isPublicUrl(window.location.href)) setShow(false)
    }

    useEffect(() => {
        verifyToken()
        checkLocation()
        let tokenInterval = setInterval(verifyToken, 60000)
        let locationInterval = setInterval(checkLocation, 3000)

        return () => {
            clearInterval(tokenInterval)
            clearInterval(locationInterval)
        }
    }, [])

    useEffect(() => {
        const handleLocationChange = () => {
            if (isPublicUrl(window.location.pathname)) {
                setShow(false)
            }
           
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const handleRenewButtonClick = () => {
        apiClient.renew().then(res => {
            localStorage.setItem('token', res.accessToken)
            setShow(false)
        })
    }

    return (
        <Dialog
            fullWidth
            open={show}
        //onClose={handleCloseClick}
        >
            <MuiDialogTitle>
                Caducidad Sesión
            </MuiDialogTitle>
            <DialogContent>

                Tu sesión está a punto de caducar. Por favor renuévala pulsando el siguiente botón

            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <MUIButton
                    style={{ backgroundColor: '#5D639A', borderRadius: '2rem', color: 'white' }}
                    label="Guardar"
                    onClick={handleRenewButtonClick}
                >
                    Renovar Sesión
                </MUIButton>
            </DialogActions >
        </Dialog>
    )
}

export default SessionExpireDialog