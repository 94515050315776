import { FunctionField } from "react-admin";
import { PanelActividad } from "./PanelActividad";
import { PanelDatosUsuario } from "./PanelDatosUsuario";
import { PanelObservaciones } from "./PanelObservaciones";
import { PanelVariado } from "./PanelVariado";
import { useState } from "react";
import Tooltip from '@material-ui/core/Tooltip';


export const DashboardUsuario = (props) => {
    const [contactos, setContactos] = useState()

    return (
        <div className="dashboard-usuario" style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
            <Tooltip title="Hola" placement="right">
                <FunctionField render={(r) => r.name + " " + r.surnames} style={{ fontSize: 18, fontWeight: "bold" }}></FunctionField>
            </Tooltip>
            <PanelDatosUsuario setContactos={setContactos} contactos={contactos} />
            <PanelVariado {...props} />
            <PanelActividad {...props} />
            <PanelObservaciones setContactos={setContactos} contactos={contactos} />
        </div>
    );
}